<template>
  <div v-if="total > 1" class="pagination">
    <div
      v-show="value !== 0"
      class="pagination__arrow pagination__arrow--prev"
      @click="changePage(value - 1)"
    >
      <img src="/icons/chevron-right.svg"/>
    </div>
    <div class="pagination__list">
      <div
        v-for="(page, index) in total"
        :key="index"
        :class="[
        'pagination__item',
        {
          'pagination__item--active': value === index
        }
      ]"
        @click="changePage(index)"
      >
        {{ page }}
      </div>
    </div>
    <div
      v-show="value !== total"
      class="pagination__arrow pagination__arrow--next"
      @click="changePage(value + 1)"
    >
      <img src="/icons/chevron-right.svg"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {}
  },
  props: {
    value: Number,
    total: Number
  },
  methods: {
    changePage(changeToPage) {
      if (changeToPage === this.value) return

      this.$emit('input', changeToPage);
    }
  },
}
</script>

<style lang="scss" src="./Pagination.scss"/>
