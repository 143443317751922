<template>
  <div>
    <img v-if="!loaded" class="loading-gif" src="/img/loading.gif" />

    <div v-else>
      <h2 class="profile__title">
        {{ localization.general.Profile.GlobalPreferences }}
      </h2>
      <div class="profile-tab">
        <div
          class="profile-tab__block profile-tab__block--game-type profile-tab__block__w-half"
        >
          <h3 class="profile-tab__title">
            {{ localization.general.Profile.GameSettings }}
            <FastFaqButton
              @faqToggler="onFaqButtonClick"
              lightName="gameSettings"
              :itemsInFaq="['GameSettings']"
              :style="{ top: '3px', right: '-30px' }"
            />
          </h3>
          <div class="profile-tab__list">
            <div class="profile-tab__list__item">
              <div
                class="profile-tab__list__item__label profile-tab__list__item__label--w-100"
              >
                {{ localization.general.Profile.Timebank }}
              </div>
              <div class="profile-tab__list__item__value">
                <input
                  v-model.number="timebank"
                  class="form-input"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="number"
                  @change="validateTimebank()"
                />
              </div>
            </div>
            <div class="profile-tab__list__item">
              <div
                class="profile-tab__list__item__label profile-tab__list__item__label--w-100"
              >
                {{ localization.general.GlobalPreferences.HandLearnSeries }}
              </div>
              <div class="profile-tab__list__item__value">
                <input
                  v-model.number="handSeries"
                  class="form-input"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="number"
                  @change="validateHandSeries()"
                />
              </div>
            </div>
            <div class="profile-tab__list__item">
              <div
                class="profile-tab__list__item__label profile-tab__list__item__label--w-100"
              >
                {{ localization.general.GlobalPreferences.Parameters }}
              </div>
              <div class="profile-tab__list__item__value">
                <Select
                  v-model="gameMode"
                  :items="gameModes"
                  height="40"
                  width="100%"
                />
                <FastFaqButton
                  @faqToggler="onFaqButtonClick"
                  :itemsInFaq="['GameMode']"
                  lightName="parameters"
                  :style="{ right: '-30px', top: '4px' }"
                />
              </div>
            </div>
            <div class="profile-tab__list__item">
              <div
                class="profile-tab__list__item__label profile-tab__list__item__label--w-100"
              >
                {{ localization.general.GlobalPreferences.AvatarDisplayMode }}
              </div>
              <div class="profile-tab__list__item__value">
                <Select
                  v-model="avatarMode"
                  :items="avatarModes"
                  height="40"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="profile-tab__block profile-tab__block--colors profile-tab__block__w-half"
        >
          <h3 class="profile-tab__title">
            {{ localization.general.Profile.Colors }}
            <FastFaqButton
              @faqToggler="onFaqButtonClick"
              :itemsInFaq="['Colors']"
              lightName="colors"
              :style="{ top: '3px', right: '-30px' }"
            />
          </h3>
          <div class="profile-tab__block--colors__row">
            <button
              v-for="(button, property) in buttons"
              :key="button.name"
              :class="[
                'profile-tab__block--colors__item',
                {
                  'profile-tab__block--colors__item--active':
                    property === activeWeight
                }
              ]"
              :style="{
                '--color': buttons[property].color
              }"
              type="button"
              @click="openWeightColorPicker(property)"
            >
              <span class="profile-tab__block--colors__item__name">
                {{ button.name }}
              </span>
              <div class="profile-tab__block--colors__picker">
                <div class="profile-tab__block--colors__triangle" />
                <div class="profile-tab__block--colors__container">
                  <ColorPicker v-model="buttons[property].color" />
                </div>
              </div>
            </button>
          </div>
          <div class="profile-tab__block--colors__inverse">
            <h3>
              {{ localization.general.GlobalPreferences.Inverse }}
            </h3>
            <input
              type="checkbox"
              v-model="isInverse"
              class="profile-tab__block--colors__inverse--checkbox"
            />
          </div>
        </div>
        <div
          class="profile-tab__block profile-tab__block--positions profile-tab__block__w-half"
        >
          <h3 class="profile-tab__title">
            {{ localization.general.Profile.PositionsNaming }}
          </h3>
          <div class="profile-tab__block--positions__list">
            <div
              v-for="key in Object.keys(positions)"
              :key="key"
              class="profile-tab__block--positions__item"
            >
              <input
                v-model="positions[key]"
                class="profile-tab__block--positions__item__input"
                maxlength="5"
              />
              {{ key }}
            </div>
          </div>
        </div>
        <div
          class="profile-tab__block profile-tab__block--hotkeys profile-tab__block__w-half"
        >
          <h3 class="profile-tab__title">
            {{ localization.general.Profile.HotKeys }}
            <FastFaqButton
              @faqToggler="onFaqButtonClick"
              :itemsInFaq="['HotKeys']"
              lightName="hotKeys"
              :style="{ top: '3px', right: '-30px' }"
            />
          </h3>
          <div class="profile-tab__block--hotkeys__list">
            <div
              v-for="(hotkey, index) in computedHotKeys"
              :key="index"
              class="profile-tab__block--hotkeys__item"
            >
              <input
                :value="getValue(hotkey.value)"
                class="profile-tab__block--hotkeys__item__input"
                readonly
                @keydown="setHotKey($event, hotkey.value)"
                @keypress="$event.preventDefault()"
                @keyup="$event.preventDefault()"
              />
              {{ hotkey.label }}
            </div>
          </div>
        </div>
      </div>
      <Btn
        :loading="updateGlobalPreferencesRequestPending"
        class="profile__save-btn"
        @click="updateGlobalPreferences()"
      >
        {{ localization.general.GlobalPreferences.SaveParameters }}
      </Btn>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@/components/UI/ColorPicker';
import FastFaqButton from '@/components/FastFaqDialog/FastFaqButton';

import {
  GET_GLOBAL_PREFERENCES,
  UPDATE_GLOBAL_PREFERENCES
} from '@/api/globalPreferences';
import Select from '@/components/UI/Select';
import Btn from '@/components/UI/Btn';

export default {
  components: {
    Btn,
    ColorPicker,
    Select,
    FastFaqButton
  },
  data() {
    return {
      updateGlobalPreferencesRequestPending: false,
      loaded: false,
      gameMode: '',
      avatarMode: '',
      openRangePreview: false,
      timebank: null,
      handSeries: null,
      defaultColor: '#ffffff',
      weightColorPicker: '#ffffff',
      activeWeight: null,
      isInverse: false,

      positions: {
        ep: '',
        ep1: '',
        ep2: '',
        ep3: '',
        mp: '',
        mp1: '',
        co: '',
        bu: '',
        sb: '',
        bb: '',
        utg: '',
        utg1: '',
        hj: ''
      },
      buttons: {
        fold: {
          name: 'fold',
          color: ''
        },
        check: {
          name: 'check',
          color: ''
        },
        call: {
          name: 'call',
          color: ''
        },
        raise: {
          name: 'raise',

          color: ''
        },
        allin: {
          name: 'allin',
          color: ''
        }
      },
      hotKeys: {},
      globalPreferencesData: null
    };
  },
  computed: {
    avatarModes() {
      return [
        {
          value: 'PhotoName',
          label: this.localization.general.GlobalPreferences.PhotoName
        },
        {
          value: 'ColorPosition',
          label: this.localization.general.GlobalPreferences.ColorPosition
        }
      ];
    },
    gameModes() {
      return [
        {
          value: 'Advanced',
          label: this.localization.general.GlobalPreferences.Advanced
        },
        {
          value: 'Simple',
          label: this.localization.general.GlobalPreferences.Simple
        },
        {
          value: 'Randomized',
          label: this.localization.general.GlobalPreferences.Randomized
        }
      ];
    },
    computedHotKeys() {
      return [
        {
          label: 'Fold',
          value: 'fold'
        },
        {
          label: 'Call',
          value: 'call'
        },
        {
          label: 'Check',
          value: 'check'
        },
        {
          label: 'Raise',
          value: 'raise'
        },
        {
          label: 'Allin',
          value: 'allin'
        },
        {
          label: this.localization.general.Profile.CurrentRangeHotkey,
          value: 'currentRangePreview'
        },
        {
          label: this.localization.general.Profile.PrevRangeHotkey,
          value: 'prevRangePreview'
        }
      ];
    }
  },

  methods: {
    onFaqButtonClick(nodes) {
      this.$parent.$parent.onFaqButtonClick(nodes);
    },
    closeColorPicker(e) {
      if (
        !e.target.closest('.profile-tab__block--colors__row') &&
        this.activeWeight
      )
        this.activeWeight = null;
    },
    validateHandSeries() {
      if (this.handSeries !== '' && this.handSeries < 1) {
        this.handSeries = 1;
        this.$toast.error(
          this.localization.general.Profile.Validations.HandSeriesIsInvalid
        );
      }
    },
    validateTimebank() {
      if (this.timebank !== '' && this.timebank < 5) {
        this.timebank = 5;
        this.$toast.error(
          this.localization.general.Profile.Validations.TimebankIsInvalid
        );
      }
    },
    getValue(type) {
      if (!this.hotKeys[type]) return;

      let char = String.fromCharCode(this.hotKeys[type].keyCode);

      let modifierKey = '';
      Object.keys(this.hotKeys[type]).forEach(key => {
        if (key !== 'keyCode') {
          if (this.hotKeys[type][key]) {
            modifierKey = key + ' + ';
          }
        }
      });

      return modifierKey + char;
    },
    setHotKey(event, value) {
      if (!String.fromCharCode(event.keyCode).match(/(\w|\s)/g)) return;

      let modifierKey = null;

      if (event.ctrlKey) modifierKey = 'ctrl';
      else if (event.altKey) modifierKey = 'alt';
      else if (event.shiftKey) modifierKey = 'shift';

      let keyExists = false;

      Object.keys(this.hotKeys).forEach(key => {
        /*
          FIRSTLY, DETECT IF SAME KEYCODES.
          ALSO SKIP CHECKING SAME TYPE KEY (e.x. if changing "fold" type, not to check for same value
        */
        if (!this.hotKeys[key]) return;

        if (this.hotKeys[key].keyCode === event.keyCode && key !== value) {
          if (modifierKey) {
            if (this.hotKeys[key][modifierKey]) {
              keyExists = true;
            }
          } else {
            let checkIfAllEmpty = true;
            Object.keys(this.hotKeys[key]).forEach(innerKey => {
              if (innerKey !== 'keyCode') {
                if (this.hotKeys[key][innerKey]) {
                  checkIfAllEmpty = false;
                }
              }
            });

            if (checkIfAllEmpty) {
              keyExists = true;
            }

            if (key === 'call' && this.hotKeys.call.keyCode === event.keyCode) {
              keyExists = false;
            }

            if (
              key === 'check' &&
              this.hotKeys.check.keyCode === event.keyCode
            ) {
              keyExists = false;
            }
          }
        }
      });

      console.log(keyExists, 'keyexists');

      if (keyExists) {
        this.$toast.error(this.localization.general.Profile.HotKeyAlreadyInUse);
        return;
      }

      /* SET VALUES FOR UPDATE REQUEST */
      if (!this.hotKeys[value]) {
        this.hotKeys[value] = {
          keyCode: event.keyCode,
          alt: false,
          shift: false,
          ctrl: false
        };
      } else {
        this.hotKeys[value].keyCode = event.keyCode;
      }

      Object.keys(this.hotKeys[value]).forEach(key => {
        if (key !== 'keyCode') {
          this.hotKeys[value][key] = key === modifierKey;
        }
      });
    },
    async updateGlobalPreferences() {
      if (!this.timebank) {
        this.$toast.error(
          this.localization.general.Profile.Validations.TimebankIsEmpty
        );
        return;
      }

      if (!this.handSeries) {
        this.$toast.error(
          this.localization.general.Profile.Validations.HandSeriesIsInvalid
        );
        return;
      }

      this.updateGlobalPreferencesRequestPending = true;
      let actionsOrder = {};
      if (this.isInverse) {
        actionsOrder = {
          Allin: 0,
          Raise: 1,
          Call: 2,
          Check: 3,
          Fold: 4
        };
      } else {
        actionsOrder = {
          Allin: 4,
          Raise: 3,
          Call: 2,
          Check: 1,
          Fold: 0
        };
      }
      let modifiedPositions = {};
      Object.keys(this.positions).forEach(key => {
        modifiedPositions[key] = this.positions[key].replace(/\s/g, '').length
          ? this.positions[key]
          : '';
      });

      const data = {
        actionColors: {
          allin: this.buttons.allin.color,
          raise: this.buttons.raise.color,
          call: this.buttons.call.color,
          check: this.buttons.check.color,
          fold: this.buttons.fold.color
        },
        actionsOrder,
        game: {
          openRangePreview: this.openRangePreview,
          timebank: this.timebank,
          playerDisplayMode: this.avatarMode,
          correctSeriesLength: this.handSeries
        },
        gameMode: this.gameMode,
        hotKeys: this.hotKeys,
        table: this.globalPreferencesData.table,
        positions: modifiedPositions
      };

      await UPDATE_GLOBAL_PREFERENCES(data);

      this.$track.changeProfileSettings();

      this.updateGlobalPreferencesRequestPending = false;
      this.$toast.success(this.localization.general.Alerts.SuccessfullySaved);
    },
    openWeightColorPicker(property) {
      if (property === this.activeWeight) this.activeWeight = null;
      else this.activeWeight = property;
    }
  },
  async created() {
    this.globalPreferencesData = await GET_GLOBAL_PREFERENCES();
    console.log('get blobal prefs: from GP.vue');
    this.avatarMode = this.globalPreferencesData.game.playerDisplayMode;
    this.gameMode = this.globalPreferencesData.gameMode;
    this.timebank = this.globalPreferencesData.game.timebank;
    this.handSeries = this.globalPreferencesData.game.correctSeriesLength;
    this.openRangePreview = this.globalPreferencesData.game.openRangePreview;
    this.isInverse =
      this.globalPreferencesData.actionsOrder.Allin === 0 ? true : false;

    if (this.globalPreferencesData.hotKeys) {
      this.hotKeys = this.globalPreferencesData.hotKeys;
    }

    Object.keys(this.positions).forEach(key => {
      this.positions[key] =
        this.globalPreferencesData.positions[key] || key.toUpperCase();
    });

    for (let colorProp in this.globalPreferencesData.actionColors) {
      if (this.buttons.hasOwnProperty(colorProp)) {
        this.buttons[colorProp].color = this.globalPreferencesData.actionColors[
          colorProp
        ];
      }
    }

    window.addEventListener('click', this.closeColorPicker);

    this.loaded = true;
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeColorPicker);
  }
};
</script>
