import http from "../utils/http";

export const GET_ITEMS_STATISTICS_OF_AUTH_USER = async (userId = null) => {
  const {data} = await http.get('/Items/Statistics', {
    params: {
      userId
    }
  });
  return data;
};
export const GET_ITEMS_STATISTICS = async (userId = null) => {
  const {data} = await http.get('/Statistics/Items', {
    params: {
      userId
    }
  });
  return data;
};
export const GET_USERS_STATISTICS = async (userId = null) => {
  const {data} = await http.get('/Statistics/Users', {
    params: {
      userId
    }
  });
  return data;
};