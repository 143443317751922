<template>
  <div class="">
    <Expander :value="0">
      <ExpanderItem
        v-for="item in expanderItems"
        :key="item.index"
        :defaultActive="true"
      >
        <template v-slot:content>
          <div class="range-preview-dialog">
            <RangeTableEditor
              :hands="handsForMock"
              :isGameView="true"
              :isReadOnly="true"
              :showStatistics="false"
              isMockTable
            />
          </div>
        </template>
      </ExpanderItem>
    </Expander>
  </div>
</template>

<script>
// import { weight } from './weightData';
import { Expander, ExpanderItem } from '@/components/UI/Expander';
import { mapGetters } from 'vuex';
import RangeTableEditor from '@/components/RangeTableEditor';
import { TABLE_MOCK_RANGE } from '@/constants/handsRange.js';

const fields = ['fold', 'allin', 'call', 'raise'];
// const sumFields = fields.reduce((acc, field) => acc + field, 0);
export default {
  name: 'TableMock',
  components: {
    Expander,
    ExpanderItem,
    RangeTableEditor
  },
  data() {
    return {
      handsTest: {},
      handsList: TABLE_MOCK_RANGE(),
      intervalId: null,
      timeoutId: null
    };
  },
  methods: {
    makeHandObject(handName) {
      let obj = {
        [handName]: {
          fold: 0,
          allin: 0,
          call: 0,
          check: 0,
          raise: 0,
          useForTest: true
        }
      };
      fields.forEach(field => {
        obj[handName][field] = Number((Math.random() * 0.7 + 0.1).toFixed(2));
      });
      let sum = fields.reduce((acc, field) => acc + obj[handName][field], 0);
      if (sum > 1) {
        let maxValue = Math.max(...fields.map(field => obj[handName][field]));
        obj[handName][fields[fields.indexOf(maxValue)]] = maxValue - 0.01;
        sum = fields.reduce((acc, field) => acc + obj[handName][field], 0);
      }
      fields.forEach(field => {
        obj[handName][field] = obj[handName][field] / sum;
      });
      return obj;
    },
    addDataToTable() {
      console.log(this.handsList, 'hands list');
      this.addedHands = [];
      this.intervalId = setInterval(() => {
        let randomHand = this.handsList[
          Math.floor(Math.random() * this.handsList.length)
        ];

        // check if the random hand is already present in the addedHands array
        if (!this.addedHands.includes(randomHand)) {
          let obj = this.makeHandObject(randomHand);
          this.handsTest = { ...this.handsTest, ...obj };
          this.timeoutId = setTimeout(() => {
            this.$delete(this.handsTest, randomHand);
            this.addedHands.splice(this.addedHands.indexOf(randomHand), 1);
          }, 4000);
          this.addedHands.push(randomHand);
        }
        console.log(this.handsTest, 'hands Test 222');
      }, 125);
    }
  },
  computed: {
    ...mapGetters({ actionColors: 'user/actionColors' }),
    expanderItems() {
      return [{ index: 1 }];
    },
    handsForMock() {
      return this.handsTest;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    clearTimeout(this.timeoutId);
  },
  mounted() {
    this.addDataToTable();
    console.log(this.makeHandObject('AAs'), 'Createhand');
  }
};
</script>

<style></style>
