<template>
  <div>
    <div v-if="userLevel <= 1" class="container">
      <h3>Access denied</h3>
    </div>
    <div v-else>
      <div class="c-tabs">
        <router-link
          v-for="item in menuItems"
          :key="item.route.name"
          :class="[
            'c-tabs__item',
            {
              'c-tabs__item--active': currentRoute === item.route.name
            }
          ]"
          :to="item.route"
        >
          {{ item.label }}
        </router-link>
      </div>

      <div class="admin-container container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  computed: {
    ...mapGetters('user', ['userLevel']),

    currentRoute() {
      return this.$route.name;
    },

    menuItems() {
      return [
        {
          route: {
            name: 'Admin Users'
          },
          label: this.localization.general.Profile.Users
        },
        {
          route: {
            name: 'Admin Licenses'
          },
          label: this.localization.general.Profile.Licenses
        },
        {
          route: {
            name: 'Admin Bills'
          },
          label: 'Bills'
        },
        {
          route: {
            name: 'Admin Subscriptions'
          },
          label: this.localization.general.Profile.Subscriptions
        },
        {
          route: {
            name: 'Admin User Statistics'
          },
          label: this.localization.general.Profile.Statistics
        },
        // {
        //   route: {
        //     name: 'Admin Localizations Editor'
        //   },
        //   label: 'Localizations'
        // },
        {
          route: {
            name: 'Admin Premium Ranges Editor'
          },
          label: 'Premium Range Editor'
        },
        {
          route: {
            name: 'Admin License Descriptors'
          },
          label: 'License Descriptors'
        }
      ];
    }
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" src="../../assets/scss/admin.scss" />
