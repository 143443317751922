<template>
  <div v-if="globalPreferences">
    <div class="search-properties">
      <template>
        <div class="search-properties-header">
          <div class="search-properties-header__title">
            {{ localization.general.SearchViewerBtns.Title }}
          </div>

          <div class="search-properties-header__refresh">
            <Btn
              class="range-editor__start-test-btn"
              :loading="false"
              :disabled="isLoading || btnLoading"
              width="0px"
              height="38px"
              onmouseover="event.stopPropagation();"
              @click.prevent.stop="refreshProperties"
            >
              <svg
                class="search-properties-header__refresh-icon"
                data-src="/icons/refresh.svg"
              />
            </Btn>
          </div>
        </div>

        <div class="search-properties__block">
          <div class="search-properties--scroller">
            <Scroller
              class="search-properties--scroller__main"
              v-if="computedSelectedItems.length"
            >
              <div
                class="search-properties--scroller__item"
                v-for="item in computedSelectedItems"
                :key="item.id"
              >
                <img
                  class="item-options-editor__row--link-groups__item__icon"
                  src="/icons/close-wrapped.svg"
                  @click="removeFromLinkedItems(item)"
                />
                {{ item.name }}
              </div>
            </Scroller>
          </div>
          <div class="item-options-editor__row">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.sizeBlock }}
            </div>

            <div
              class="item-options-editor__value item-options-editor__value--btns"
            >
              <Toggler
                v-model="additionalProperties.size"
                :items="tableSizesBasedOnItemType"
                :in-one-row="false"
                :in-two-row="true"
              />
            </div>
          </div>

          <div class="item-options-editor__row">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.hp }}
            </div>

            <div
              :class="[
                'item-options-editor__value',
                'item-options-editor__value--btns-wrap'
              ]"
            >
              <Toggler
                :class="{
                  'search-properties--disabled': !itemSize
                }"
                v-model="additionalProperties.heroPosition"
                :items="possibleHeroPositions"
                @update="setHasCheckValue()"
              />
            </div>
          </div>

          <div class="item-options-editor__row">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.Type }}
            </div>

            <div
              class="item-options-editor__value item-options-editor__value--btns-wrap"
            >
              <Toggler
                v-model="additionalProperties.type"
                :items="possibleTestTypes"
                :class="[
                  'item-options-editor__value',
                  'item-options-editor__value--btns',
                  'item-options-editor__value--w-100',
                  'item-options-editor__value--type-btns',
                  'search-properties--type_btns',
                  {
                    'search-properties--disabled': !additionalProperties.heroPosition
                  }
                ]"
                stretched
                @input="checkTypeClick()"
              />
            </div>
          </div>

          <!-- <div
          v-if="['VS3B', 'SQZ', 'VS4BET'].includes(itemType)"
          class="item-options-editor__row"
        >
          <div class="item-options-editor__label">
            {{ heroBetSizeLabelBasedOnItemType }}
          </div>
          <div class="item-options-editor__value">
            <input
              v-model.number="additionalProperties.r"
              class="item-options-editor__input form-input"
              type="number"
            />
          </div>
        </div> -->

          <template v-if="showOpHpSpInput">
            <div class="item-options-editor__conditional-block">
              <div
                :class="[
                  'item-options-editor__cp-op',
                  'item-options-editor__conditional-block',
                  {
                    'item-options-editor__conditional-block--disabled': !additionalProperties.heroPosition
                  }
                ]"
              >
                <div
                  :class="[
                    'item-options-editor__row',

                    'item-options-editor__conditional-block',
                    {
                      'item-options-editor__row--order-last':
                        itemType === 'SQZ',
                      'item-options-editor__conditional-block--disabled':
                        itemType === 'SQZ' &&
                        !additionalProperties.callerPosition &&
                        selectedTypesRequireCallerPosition
                    }
                  ]"
                >
                  <div class="item-options-editor__label">
                    {{ localization.general.Diapason.op }}
                  </div>

                  <div
                    class="item-options-editor__value item-options-editor__value--btns"
                  >
                    <Toggler
                      v-model="additionalProperties.opponentPosition"
                      :items="possibleOpponentPositions"
                      @input="clickOpponentPosition()"
                    />
                  </div>
                </div>

                <div
                  v-show="selectedTypesRequireCallerPosition"
                  :class="[
                    'item-options-editor__row',
                    'item-options-editor__conditional-block',
                    {
                      'item-options-editor__row--order-last':
                        itemType === 'VS1RC',
                      'item-options-editor__conditional-block--disabled':
                        itemType === 'VS1RC' &&
                        !additionalProperties.opponentPosition
                    }
                  ]"
                >
                  <div class="item-options-editor__label">
                    {{ localization.general.Diapason.cp }}
                  </div>

                  <div
                    class="item-options-editor__value item-options-editor__value--btns-wrap"
                  >
                    <Toggler
                      v-model="additionalProperties.callerPosition"
                      :items="possibleCallerPositions"
                      @input="clickCallerPosition()"
                    />
                  </div>
                </div>
              </div>

              <!-- <div v-if="showOpponentBetSize" class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ opBetSizeLabelBasedOnItemType }}
              </div>
              <div class="item-options-editor__value">
                <input
                  v-model.number="additionalProperties.n"
                  class="item-options-editor__input form-input"
                  type="number"
                  @input="setHasCheckValue()"
                />
              </div>
            </div> -->
            </div>
          </template>

          <div class="item-options-editor__row" v-if="heroStack.length">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.playerStack }}
            </div>

            <div
              :class="[
                'item-options-editor__value item-options-editor__value--btns',
                {
                  'item-options-editor__conditional-block--disabled':
                    (!additionalProperties.opponentPosition &&
                      itemType !== 'RFI') ||
                    (!additionalProperties.callerPosition &&
                      itemType === 'VS1RC')
                }
              ]"
            >
              <Toggler
                v-model.number="additionalProperties.stack"
                @input="clickPlayerStack()"
                :items="possibleHeroStack"
                :class="[
                  'item-options-editor__value',
                  'item-options-editor__value--btns',
                  'item-options-editor__value--w-100'
                ]"
                type="number"
              />
            </div>
          </div>

          <ProgressLinear
            :class="[
              'list-loading',
              'search-properties--progress',
              {
                'search-properties--progress-visible': btnLoading || isLoading
              }
            ]"
          />
        </div>
      </template>
    </div>

    <Btn
      v-if="!isTabletOrMobile && heroStack.length"
      @click="search"
      :disabled="isLoading"
      :loading="btnLoading"
      class="search-properties--btn"
    >
      {{ localization.general.SearchViewerBtns.Search }}
    </Btn>
  </div>
</template>

<script>
import { SEARCH_STACKS } from '@/api/search';
import Scroller from '@/components/UI/Scroller/Scroller';
import ProgressLinear from '@/components/UI/ProgressLinear/ProgressLinear';
import Btn from '@/components/UI/Btn';
import Toggler from '@/components/UI/Toggler';
import rangeHasCheck from '@/utils/itemRange';
import { getTablePositions } from '@/utils/positions';
import { mapState } from 'vuex';

export default {
  name: 'SearchProperties',

  components: { Btn, Toggler, ProgressLinear, Scroller },
  props: {
    searchHandle: Function,
    btnLoading: Boolean,
    refreshDiapasons: Function,
    isActiveDiapason: Boolean,
    selectedItemsToSearch: Array,
    removeItem: Function
  },
  data() {
    return {
      additionalProperties: {
        type: null,
        opponentPosition: null,
        callerPosition: null,
        r: null,
        stack: null,
        n: null,
        size: null,
        heroPosition: null,
        sdsek: false
      },
      lastChangedProp: null,
      propertyChanges: [],
      childBottom: 0,
      isLoading: false,
      heroStack: [],
      hasCheck: false,
      tableSizes: [2, 3, 6, 8],
      testTypes: [
        {
          label: 'Unopened',
          value: 'RFI',
          isDisabled: false
        },
        {
          label: 'VS RFI/Limp',
          value: 'VSOR',
          isDisabled: false
        },
        {
          label: 'VS 3bet',
          value: 'VS3B',
          isDisabled: false
        },
        {
          label: 'VS 4bet raise',
          value: 'VS4BET',
          isDisabled: false
        },
        {
          label: 'VS 1raise+caller',
          value: 'VS1RC',
          isDisabled: false
        },
        {
          label: 'VS SQZ',
          value: 'SQZ',
          isDisabled: false
        }
      ]
    };
  },
  methods: {
    removeFromLinkedItems(itemToRemove) {
      this.$emit('removeItem', itemToRemove);
      // console.log(item, 'ITEMS');
      console.log(itemToRemove, 'DFSD');
      // this.selectedItemsToSearch = this.selectedItemsToSearch.filter(
      //   item => item.value.id !== itemToRemove.id
      // );
    },
    setHotKeyEvents(event) {
      console.log('event hotkey', event);
      if (event && event.keyCode >= 49 && event.keyCode <= 57) {
        if (!this.additionalProperties.size) {
          this.additionalProperties.size = this.tableSizes[event.keyCode - 49];
          this.lastChangedProp = 'size';
        } else if (!this.additionalProperties.heroPosition) {
          const index = event.keyCode - 49;
          if (index < this.possibleHeroPositions.length) {
            this.additionalProperties.heroPosition = this.possibleHeroPositions[
              index
            ].value;
            this.lastChangedProp = 'heroPosition';
          }
        } else if (!this.additionalProperties.type) {
          const index = event.keyCode - 49;
          if (index < this.possibleTestTypes.length) {
            if (!this.possibleTestTypes[index].isDisabled) {
              this.additionalProperties.type = this.possibleTestTypes[
                index
              ].value;
              this.lastChangedProp = 'type';
              this.checkTypeClick();
            }
          }
        } else if (
          (!this.additionalProperties.stack &&
            this.heroStack.length > 1 &&
            (this.possibleOpponentPositions.length < 1 ||
              (this.additionalProperties.type === 'RFI' &&
                this.additionalProperties.heroPosition === 'sb'))) ||
          this.additionalProperties.opponentPosition
        ) {
          console.log('EWREWR');
          const index = event.keyCode - 49;
          if (index < this.heroStack.length) {
            this.additionalProperties.stack = this.possibleHeroStack[index];
            this.clickPlayerStack();
            this.lastChangedProp = 'stack';
          }
        } else if (
          this.additionalProperties.type === 'SQZ' &&
          !this.additionalProperties.callerPosition
        ) {
          const index = event.keyCode - 49;
          if (index < this.possibleCallerPositions.length) {
            this.additionalProperties.callerPosition = this.possibleCallerPositions[
              index
            ].value;
            this.lastChangedProp = 'callerPosition';
          }
        } else if (this.additionalProperties.type === 'RFI') {
          const index = event.keyCode - 49;
          if (index < this.heroStack.length) {
            this.additionalProperties.stack = this.possibleHeroStack[index];
            this.clickPlayerStack();
            this.lastChangedProp = 'stack';
          }
        } else if (!this.additionalProperties.opponentPosition) {
          const index = event.keyCode - 49;
          if (index < this.possibleOpponentPositions.length) {
            this.additionalProperties.opponentPosition = this.possibleOpponentPositions[
              index
            ].value;
            this.lastChangedProp = 'opponentPosition';
          }
        }

        console.log('Key pressed is a number between 1 and 9');

        this.propertyChanges.push(this.lastChangedProp);
      } else if (event && event.keyCode === 32) {
        event.preventDefault();

        console.log('Spacebar pressed');
        if (event && event.keyCode === 32) {
          event.preventDefault();

          console.log('Spacebar pressed');
          if (this.propertyChanges.length > 0) {
            const lastPropIndex = this.propertyChanges.lastIndexOf(
              this.lastChangedProp
            );
            if (lastPropIndex !== -1) {
              this.propertyChanges.splice(lastPropIndex, 1);
            }
            this.additionalProperties[this.lastChangedProp] = null;
            this.lastChangedProp =
              this.propertyChanges.length > 0
                ? this.propertyChanges[this.propertyChanges.length - 1]
                : null;
          }
        }
      } else if (event && event.keyCode === 27) {
        event.preventDefault();
        console.log('Escape key pressed');
        this.refreshProperties();
      }
    },

    updateChildStyles() {
      this.childBottom = this.$el.offsetHeight;
    },
    clickPlayerStack() {
      this.search();
    },
    clickOpponentPosition() {
      if (this.itemType != 'VS1RC' && !this.additionalProperties.stack) {
        if (this.heroStack.length === 1) {
          this.additionalProperties.stack = this.heroStack[0];
          this.search();
          return;
        }
      }

      if (this.itemType != 'VS1RC' && this.additionalProperties.stack) {
        this.search();
      }
      return;
    },
    clickCallerPosition() {
      if (this.itemType === 'VS1RC' && !this.additionalProperties.stack) {
        if (this.heroStack.length === 1) {
          this.additionalProperties.stack = this.heroStack[0];
          this.search();
          return;
        }
      }

      if (this.itemType === 'VS1RC' && this.additionalProperties.stack) {
        this.search();
      }
      return;
      // if (this.itemType === 'VS1RC') {
      //   this.search();
      // }
    },
    checkLastSearchPropertie() {},
    getCustomNameOfPosition(name) {
      if (typeof name !== 'string') return '';

      name = name.toLowerCase();

      let globalPreferencesPositions = this.globalPreferences?.positions;

      console.log(globalPreferencesPositions, 'Global pref pos');
      if (globalPreferencesPositions) {
        if (globalPreferencesPositions.hasOwnProperty(name)) {
          return globalPreferencesPositions[name];
        }
      }
      console.log(name, 'name');
      return name;
    },

    async searchStacks() {
      this.isLoading = true;
      const stacks = await SEARCH_STACKS(this.additionalProperties);
      this.heroStack = stacks.heroStack ? stacks.heroStack : [];
      this.isLoading = false;
      // if (this.itemType === 'RFI' && !stacks.heroStack) {
      //   this.search();
      // }
      console.log(stacks, 'stacks on search');
    },
    checkPossibleType() {
      console.log('CHEEECK');
      const heroType = this.possibleTestTypes.find(
        type => type.value === this.additionalProperties.type
      );
      const possibleTypes = this.possibleTestTypes.filter(
        type => type.isDisabled === false
      );

      if (!heroType) return;
      console.log(heroType, possibleTypes, 'heroType');
      console.log(this.additionalProperties, 'before checkpos');
      this.additionalProperties.type = possibleTypes[0].value;
      console.log(this.additionalProperties, 'after checkpos');
    },
    checkHeroPosition(positions) {
      console.log(positions, 'positions');
      if (!positions.includes(this.additionalProperties.heroPosition)) {
        this.additionalProperties.heroPosition = null;
      }

      this.additionalProperties.opponentPosition = null;
    },
    refreshProperties() {
      this.additionalProperties = {
        type: null,
        opponentPosition: null,
        callerPosition: null,
        r: null,
        stack: null,

        n: null,
        size: null,
        heroPosition: null
      };
      this.heroStack = [];
      this.$emit('refreshDiapasons');
    },
    search() {
      // let additionalProperties = {
      //   callerPosition: 'ep3',
      //   heroPosition: 'ep1',
      //   n: 8,
      //   opponentPosition: 'co',
      //   r: 4,
      //   size: 8,
      //   type: 'VS3B'
      // };
      this.$emit('searchHandle', this.additionalProperties);
    },
    async checkTypeClick() {
      this.additionalProperties.opponentPosition = null;
      await this.searchStacks();

      if (this.possibleOpponentPositions.length === 1) {
        console.log(
          this.additionalProperties.opponentPosition,
          this.possibleOpponentPositions[0].value,
          'value on setHasCheck'
        );
        this.additionalProperties.opponentPosition = this.possibleOpponentPositions[0].value;
        console.log(
          this.additionalProperties.opponentPosition,
          'value on setHasCheck 2'
        );
      }

      if (
        this.additionalProperties.type === 'RFI' &&
        this.heroStack.length === 1
      ) {
        this.additionalProperties.stack = this.heroStack[0];
        this.search();
      }

      if (this.itemType === 'SQZ') {
        if (this.possibleCallerPositions.length === 1) {
          this.additionalProperties.callerPosition = this.possibleCallerPositions[0].value;
        }
      }

      this.hasCheck = this.checkIfCurrentDataMustToggleCheck();
    },
    setHasCheckValue() {
      this.searchStacks();
      this.hasCheck = this.checkIfCurrentDataMustToggleCheck();
    },
    checkIfCurrentDataMustToggleCheck() {
      return rangeHasCheck(
        this.itemType,
        this.additionalProperties.heroPosition,
        this.additionalProperties.n
      );
    },

    makeModifiedItemsOfPosition(items) {
      console.log('makeModified');
      if (items.length === 0) return [];

      return items.map(position => {
        console.log(position, 'position getCustomName');
        return {
          label: this.getCustomNameOfPosition(position),
          value: position
        };
      });
    }
  },
  watch: {
    isActiveDiapason() {
      if (!this.isActiveDiapason) {
        window.addEventListener('keydown', this.setHotKeyEvents);
      } else {
        window.removeEventListener('keydown', this.setHotKeyEvents);
      }
    },
    additionalProperties: {
      deep: true,
      handler(newVal) {
        const allFieldsNull = Object.values(newVal).every(val => val === null);
        if (allFieldsNull) {
          this.propertyChanges = [];
          this.lastChangedProp = null;
        }
      }
    },
    itemSize() {
      this.additionalProperties.heroPosition = null;
      this.additionalProperties.callerPosition = null;
      this.additionalProperties.stack = null;
      // this.heroStack = null;
      this.additionalProperties.type = null;
      this.$emit('refreshDiapasons');
      this.checkPossibleType();
    },
    'additionalProperties.heroPosition': function() {
      this.heroStack = [];
      this.additionalProperties.type = null;
      this.additionalProperties.callerPosition = null;
      this.checkPossibleType();
      this.$emit('refreshDiapasons');
    },
    'additionalProperties.type': function() {
      this.additionalProperties.stack = null;
      // this.additionalProperties.opponentPosition = null;
      this.additionalProperties.callerPosition = null;
      this.$emit('refreshDiapasons');
    },
    'additionalProperties.opponentPosition': function() {
      console.log(
        'look side effects OP,',
        this.additionalProperties.opponentPosition
      );
      console.log(this.possibleCallerPositions, 'possible caller pos');
      if (this.possibleCallerPositions.length === 1) {
        this.additionalProperties.callerPosition = this.possibleCallerPositions[0].value;
      }

      if (this.itemType !== 'VS1RC' && this.itemType !== 'SQZ') {
        console.log(this.itemType, 'itemtype if');
        if (this.heroStack.length === 1) {
          this.additionalProperties.stack = this.heroStack[0];
          this.search();
        }
      }

      if (this.itemType === 'SQZ') {
        if (this.heroStack.length === 1) {
          this.additionalProperties.stack = this.heroStack[0];
          this.search();
        }
      }

      console.log(this.heroStack, 'herostack after op');
      this.$emit('refreshDiapasons');
      // this.additionalProperties.callerPosition = null;
    },
    'additionalProperties.callerPosition': function() {
      if (
        this.itemType === 'VS1RC' &&
        this.additionalProperties.opponentPosition
      ) {
        if (this.heroStack.length) {
          this.additionalProperties.stack = this.heroStack[0];
          console.log('srt');
          this.search();
        }
      }

      if (this.itemType === 'SQZ') {
        if (this.possibleOpponentPositions.length === 1) {
          this.additionalProperties.opponentPosition = this.possibleOpponentPositions[0].value;
        }
      }
      this.$emit('refreshDiapasons');
    },
    'additionalProperties.stack': function() {
      this.$emit('refreshDiapasons');
    }
  },
  mounted() {
    // this.searchStacks();
    this.$nextTick(() => {
      this.childBottom = this.$el.offsetHeight + 'px';
      window.addEventListener('resize', this.updateChildStyles);
      if (!this.isActiveDiapason) {
        window.addEventListener('keydown', this.setHotKeyEvents);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChildStyles);
    window.removeEventListener('keydown', this.setHotKeyEvents);
  },

  computed: {
    ...mapState('user', ['globalPreferences']),
    ...mapState('ui', ['activeBreakpoint']),

    computedSelectedItems() {
      let compressed = this.selectedItemsToSearch.map(item => item.value);
      console.log(this.selectedItemsToSearch, compressed, 'ses');
      return compressed;
    },
    childStyles() {
      console.log(this.$el, 'this el');
      return {
        left: 0,
        bottom: this.childBottom + 'px'
      };
    },
    isTabletOrMobile() {
      if (['sm', 'xs'].includes(this.activeBreakpoint)) {
        return true;
      } else {
        return false;
      }
    },
    itemType() {
      return this.additionalProperties.type;
    },
    possibleHeroStack() {
      return this.heroStack.slice().sort(function(a, b) {
        return a - b;
      });
    },
    itemSize() {
      return this.additionalProperties.size;
    },
    tableParticipants() {
      if (!this.itemSize) return [];
      return getTablePositions(this.itemSize);
    },

    possibleOpponentPositions() {
      if (!this.itemSize) return [];
      if (!this.itemType) return [];

      let tablePositions = getTablePositions(this.itemSize);
      console.log(tablePositions, 'tps');
      const heroPositionIndex = tablePositions.findIndex(
        position => position === this.additionalProperties.heroPosition
      );

      if (heroPositionIndex === -1) return [];

      if (this.itemType === 'VS3B') {
        tablePositions = tablePositions.filter((_, i) => i > heroPositionIndex);
      } else if (this.itemType === 'SQZ') {
        let all = this.tableParticipants;
        let cpInAll = all.findIndex(
          el => el === this.additionalProperties.callerPosition
        );
        tablePositions = all.slice(cpInAll + 1);
      } else if (this.itemType === 'VSOR' || this.itemType === 'VS4BET') {
        console.log(tablePositions, this.itemType, 'tp');
        console.log(heroPositionIndex, 'tp hero index');
        if (
          this.itemType === 'VSOR' &&
          this.itemSize === 6 &&
          this.additionalProperties.heroPosition === 'bb'
        ) {
          tablePositions = tablePositions.filter(
            (_, i) => i < heroPositionIndex
          );
          // tablePositions.shift();
        } else {
          tablePositions = tablePositions.filter(
            (_, i) => i < heroPositionIndex
          );
        }
      } else if (this.itemType === 'VS1RC') {
        let all = this.tableParticipants;
        let hpInAll = all.findIndex(
          el => el === this.additionalProperties.heroPosition
        );
        tablePositions = all.slice(0, hpInAll - 1);
      }
      console.log(tablePositions, 'table positions');
      return this.makeModifiedItemsOfPosition(tablePositions);
    },
    possibleCallerPositions() {
      if (!this.selectedTypesRequireCallerPosition) return [];

      let tablePositions = [];
      let all = this.tableParticipants;

      if (this.itemType === 'SQZ') {
        let hpInAll = all.findIndex(
          el => el === this.additionalProperties.heroPosition
        );
        tablePositions = all.slice(hpInAll + 1, -1);
      } else if (this.itemType === 'VS1RC') {
        let opInAll = all.findIndex(
          el => el === this.additionalProperties.opponentPosition
        );
        let hpInAll = all.findIndex(
          el => el === this.additionalProperties.heroPosition
        );

        tablePositions = all.slice(opInAll + 1, hpInAll);
      }
      return this.makeModifiedItemsOfPosition(tablePositions);
    },
    tableSizesBasedOnItemType() {
      // if (this.itemType === 'VS1RC' || this.itemType === 'SQZ') {
      //   return this.tableSizes.filter(size => size !== 2 && size !== 3);
      // }

      return this.tableSizes;
    },
    showOpponentBetSize() {
      return (
        this.itemType === 'VSOR' ||
        (this.itemType === 'VS1RC' &&
          this.additionalProperties.callerPosition) ||
        this.itemType === 'VS3B' ||
        (this.itemType === 'SQZ' && this.additionalProperties.callerPosition) ||
        this.itemType === 'VS4BET'
      );
    },
    showOpHpSpInput() {
      return this.itemType && this.itemType !== 'RFI';
    },
    selectedTypesRequireCallerPosition() {
      return ['SQZ', 'VS1RC'].includes(this.itemType);
    },

    possibleTestTypes() {
      const excludedTypesMap = {
        '2bb': ['RFI', 'VS3B', 'SQZ', 'VS1RC'],
        '2sb': ['VS1RC', 'SQZ', 'VS4BET', 'VSOR'],
        '3bb': ['RFI', 'VS3B', 'SQZ'],
        '3bu': ['VSOR', 'VS1RC', 'VS4BET'],
        '3sb': ['VS1RC', 'SQZ'],
        '6bb': ['RFI', 'VS3B', 'SQZ'],
        '6sb': ['SQZ'],
        '6ep': ['VSOR', 'VS1RC', 'VS4BET'],
        '6mp': ['VS1RC'],
        '8bb': ['RFI', 'VS3B', 'SQZ'],
        '8sb': ['SQZ'],
        '8ep1': ['VSOR', 'VS1RC', 'VS4BET'],
        '8ep2': ['VS1RC']
      };
      const excludedTypes =
        excludedTypesMap[
          `${this.itemSize}${this.additionalProperties.heroPosition}`
        ];
      if (!excludedTypes) {
        return this.testTypes;
      }
      return this.testTypes.map(type =>
        excludedTypes.includes(type.value)
          ? { ...type, isDisabled: true }
          : type
      );
    },

    possibleHeroPositions() {
      // console.log('default posiitons', defaultPositions);
      // if (!this.itemSize) return [];
      console.log('1');
      if (!this.itemSize) {
        console.log('2');
        let defaultPositions = getTablePositions(6);
        console.log(defaultPositions, 'default positions');
        return this.makeModifiedItemsOfPosition(defaultPositions);
      }
      console.log('3');

      let filteredPositions = [];

      filteredPositions = getTablePositions(this.itemSize);
      console.log(filteredPositions, 'filtered');
      this.checkHeroPosition(filteredPositions);

      return this.makeModifiedItemsOfPosition(filteredPositions);
    },
    heroBetSizeLabelBasedOnItemType() {
      if (this.itemType === 'VS4BET') {
        return this.localization.general.Diapason.vs4BetHeroBetSize;
      }

      return this.localization.general.Diapason.vs3BetHeroBetSize;
    },
    opBetSizeLabelBasedOnItemType() {
      if (this.itemType === 'VS4BET') {
        return this.localization.general.Diapason.vs4BetOpBetSize;
      }
      if (
        this.itemType === 'VS3B' ||
        (this.itemType === 'SQZ' && this.additionalProperties.callerPosition)
      ) {
        return this.localization.general.Diapason.vs3BetOpBetSize;
      }

      return this.localization.general.Diapason.openRaiseOpBetSize;
    }
  }
};
</script>

<style lang="scss" src="./SearchProperties.scss" />
