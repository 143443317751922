<template>
  <AuthBlockComponent>
    <template v-slot:body>
      <div class="auth__tab">
        <div class="auth__tab__item auth__tab__item--active">
          {{ localization.general.FormElements.RestorePassword }}
        </div>
      </div>
      <div class="auth__inner">
        <div class="auth__inputs">
          <div class="form-block">
            <label
              class="form-label-text"
              for="email"
            >
              {{ localization.general.FormElements.EmailLabel }}
            </label>
            <input
              id="email"
              v-model="email"
              class="form-input"
              type="text"
            />
          </div>
        </div>
      </div>
      <Btn
        :loading="requestPending"
        class="auth__button"
        width="100%"
        @click="restorePassword()"
      >
        {{ localization.general.FormElements.Submit }}
      </Btn>
    </template>
    <template v-slot:footer>
      <div class="auth__additional-links">
        <router-link
          class="auth__additional-links__item"
          :to="{
            name: 'Sign In'
          }"
        >
          {{ localization.general.FormElements.Login }}
        </router-link>
        <router-link
          class="auth__additional-links__item"
          :to="{
            name: 'Sign Up'
          }"
        >
          {{ localization.general.FormElements.Registration }}
        </router-link>
      </div>
    </template>
  </AuthBlockComponent>
</template>

<script>
import AuthBlockComponent from "@/components/Auth/AuthBlockComponent";
import Btn from "@/components/UI/Btn";

export default {
  name: 'RestorePassword',
  components: {
    Btn,
    AuthBlockComponent
  },
  data() {
    return {
      requestPending: false,
      email: '',
    };
  },
  methods: {
    restorePassword() {
      if (!this.email) {
        this.$toast.error(this.localization.general.FormElements.EmailPlaceholder + " " + this.localization.general.FrontErrors.FieldIsEmpty);
        return
      }
      if (this.email && !this.validateEmail(this.email)) {
        this.$toast.error(this.localization.general.FrontErrors.EmailIsInvalid);
        return
      }

      this.requestPending = true;
      this.$auth.restorePassword(this.email, (isSuccess) => {
        if (isSuccess) {
          this.$router.push({
            name: 'Sign In'
          });
          this.$toast.success(this.localization.general.FormElements.EmailWithPasswordAreSubmited);
        } else {
          this.$toast.error(this.localization.general.FormElements.CheckData);
        }
        this.requestPending = false;
      });
    },
  },
};
</script>
