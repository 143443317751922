var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"admin-title"},[_vm._v(" Bills ")]),_c('div',{staticClass:"c-card"},[_c('div',{staticClass:"filter-block"},[_c('div',{staticClass:"form-block filter-block__item"},[_c('label',{staticClass:"form-label-text"},[_vm._v(" Type ")]),_c('Select',{attrs:{"items":_vm.types,"clearable":""},on:{"input":function($event){return _vm.getBills()}},model:{value:(_vm.selected.type),callback:function ($$v) {_vm.$set(_vm.selected, "type", $$v)},expression:"selected.type"}})],1),_c('Btn',{on:{"click":function($event){return _vm.getBills()}}},[_vm._v(" "+_vm._s(_vm.localization.general.Simple.Refresh)+" ")])],1),_c('table',{staticClass:"c-simple-table c-simple-table--tr-is-link"},[_vm._m(0),_c('tbody',[(_vm.loaded)?_vm._l((_vm.billsByDate),function(bill){return _c('router-link',{key:bill.id,attrs:{"to":{
            name: 'Admin Users Single',
            params: {
              id: bill.userId
            }
          },"tag":"tr"}},[_c('td',[_vm._v(" "+_vm._s(new Date(bill.creationDateTime).toLocaleString())+" ")]),_c('td',[_vm._v(" "+_vm._s(bill.cartId)+" ")]),_c('td',[_vm._v(" "+_vm._s(bill.totalPrice.discount !== null ? bill.totalPrice.discount.price : bill.totalPrice.value)+" ")]),_c('td',[_vm._v(" "+_vm._s(bill.totalPrice.discount !== null ? bill.totalPrice.discount.value : 0)+" ")]),_c('td',[_vm._v(" "+_vm._s(bill.source)+" ")]),_c('td',[_vm._v(" "+_vm._s(bill.state)+" ")])])}):_vm._e()],2)]),_c('Pagination',{attrs:{"total":_vm.maxPage},on:{"input":function($event){return _vm.getBills()}},model:{value:(_vm.activePage),callback:function ($$v) {_vm.activePage=$$v},expression:"activePage"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v(" Created at ")]),_c('th',[_vm._v(" Cart Id ")]),_c('th',[_vm._v(" Price ")]),_c('th',[_vm._v(" Discount ")]),_c('th',[_vm._v(" Source ")]),_c('th',[_vm._v(" State ")])])])
}]

export { render, staticRenderFns }