<template>
  <div
    :class="[
      'expander',
      {
        'expander--overlaid': overlayContent
      }
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Expander',
  data() {
    return {
      items: [],
      activeExpander: -1,
      key: 1
    };
  },
  props: {
    changeExpanderItem: Function,
    open: Function,
    value: {
      type: Number,
      default: -1
    },
    persistent: {
      type: Boolean,
      default: false
    },
    overlayContent: Boolean
  },
  methods: {
    expandItem(i, isLinked = false) {
      console.log(i, 'Item expander');
      console.log(this.activeExpander, i, 'debug');
      this.activeExpander = this.activeExpander === i ? null : i;
      console.warn(this.activeExpander);
      this.$emit('open');
      // loop over all the tabs
      this.items.forEach((item, index) => {
        if (isLinked) {
          item.isActive = !item.isActive;
        } else {
          console.log(
            item,
            index,
            this.activeExpander,
            this.value,
            'item on expand'
          );
          item.isActive = index === this.activeExpander;
        }
      });
    },
    loadOnMount() {
      this.items = this.$children;
      this.items.forEach((item, index) => {
        item.key = index;
      });
      console.log('load on mount', this.value, this.items);
      this.activeExpander = this.value;
      console.log('EXPAND: loadOnMount');
      this.expandItem(this.value);
    }
  },
  created() {
    if (this.overlayContent && !this.persistent) {
      window.addEventListener('click', e => {
        if (!this.$el.contains(e.target)) {
          console.log('EXPAND: created');
          this.expandItem(null);
        }
      });
    }
  },
  mounted() {
    // if (this.value >= 0) {
    //   this.activeExpander = this.value;
    //   this.expandItem(this.value);
    // }
    this.loadOnMount();
    console.log(this.value, 'this value exp');

    console.log('Expander: mounted');
  },
  watch: {
    activeExpander(newVal, oldVal) {
      console.log(newVal, oldVal, 'dfsd');
      this.$emit('changeExpanderItem', newVal);
      // if (oldVal != null) {
      //   this.$emit('changeExpanderItem', newVal);
      // }
    },
    items() {
      console.log('EXPAND: items');
      this.loadOnMount();
      this.expandItem(this.value);
    },
    value() {
      // this.activeExpander = this.value;
      console.log('EXPAND: value');
      this.expandItem(this.value);
    }
  }
};
</script>

<style lang="scss" src="./Expander.scss" />
