import http from '../utils/http';

export const SEARCH_DIAPASONS = async (params, scopeIds) => {
  console.log();
  let req = scopeIds.join(',');
  const { data } = await http.get(`/Items/Filter?scope=${req}`, {
    params: params
  });
  return data;
};

export const SEARCH_STACKS = async params => {
  console.log(params, 'params');

  const { data } = await http.get(`/Items/Aggregate`, {
    params: params
  });
  return data;
};
