var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'card',
    {
      'card--undefined': _vm.undefinedCard,
      'card--no-card': !(_vm.name && _vm.suit)
    }
  ],style:({
    color: _vm.yellowColor4 ? '#fff' : '',
    'background-color': _vm.yellowColor4 ? _vm.computedColorSuit : '',
    'text-shadow': _vm.yellowColor4
      ? '-2px 0 2px black, 0 2px 2px black, 2px 0 2px black, 0 -2px 2px black'
      : ''
  })},[(!_vm.undefinedCard)?[(!_vm.yellowColor4)?_c('img',{staticClass:"card__suit-icon card__suit-icon--top",attrs:{"src":_vm.iconUrl}}):_vm._e(),_c('div',{staticClass:"card__name"},[_vm._v(" "+_vm._s(_vm.computedName)+" ")]),(!_vm.yellowColor4)?_c('img',{staticClass:"card__suit-icon card__suit-icon--bottom",attrs:{"src":_vm.iconUrl}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }