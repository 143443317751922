import deepClone from '@/utils/dataManipulations';
const fiveParticipants = {
  mp: {
    stack: 50,
    color: null,
    stats: '',
    notes: ''
  },
  co: {
    stack: 50,
    color: null,
    stats: '',
    notes: ''
  },
  bu: {
    stack: 50,
    color: null,
    stats: '',
    notes: ''
  },
  ep1: {
    stack: 50,
    color: null,
    stats: '',
    notes: ''
  },
  ep2: {
    stack: 50,
    color: null,
    stats: '',
    notes: ''
  }
};
const sixParticipants = {
  ep: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  mp: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  co: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  bu: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  sb: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  bb: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  }
};
const threeParticipants = {
  bu: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  sb: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  bb: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  }
};
const twoParticipants = {
  sb: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  bb: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  }
};
const eightParticipants = {
  ep1: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  ep2: {
    stack: 100,
    color: null,
    stats: '',
    notes: ''
  },
  ep3: {
    stack: 100,
    color: null,

    stats: '',
    notes: ''
  },
  mp: {
    stack: 100,
    color: null,

    stats: '',
    notes: ''
  },
  co: {
    stack: 100,
    color: null,

    stats: '',
    notes: ''
  },
  bu: {
    stack: 100,
    color: null,

    stats: '',
    notes: ''
  },
  sb: {
    stack: 100,
    color: null,

    stats: '',
    notes: ''
  },
  bb: {
    stack: 100,
    color: null,

    stats: '',
    notes: ''
  }
};

// const participants = [twoParticipants, threeParticipants, fiveParticipants, sixParticipants, eightParticipants]

const getTableParticipants = size => {
  console.log(size, 'size in getTableParticipants');
  let participants = {};
  switch (size) {
    case 2:
      participants = twoParticipants;
      break;
    case 3:
      participants = threeParticipants;
      break;
    case 5:
      participants = fiveParticipants;
      break;
    case 6:
      participants = sixParticipants;
      break;
    case 8:
      participants = eightParticipants;
      break;

    default:
      break;
  }
  console.log(deepClone(participants), 'PARTICIPANTS DEEP CLONE');
  console.log('participants:00: ');
  console.log(participants);
  return deepClone(participants);
};
export default getTableParticipants;
