<template>
  <div class="container" v-if="loaded">
    <div class="main-row">
      <div
        :class="[
          'ranges-list',
          {
            'hidden-sm': rangesFooterCurrentView !== rangesFooterViewList,
            'visible-sm': rangesFooterCurrentView === rangesFooterViewList
          }
        ]"
      >
        <div class="ranges-list__actions">
          <div class="ranges-list__actions-start">
            <DropdownBtn
              :header-label="actionButton.label"
              :items="menuItemsForStartGame"
              @changeGameOptions="onChangeGameOptions"
              :headerIcon="'icons/start_game.svg'"
              @startAction="handleBtnAction()"
              :disabled="
                (startTestDisabled || !currentItem) &&
                  !updateAvailable &&
                  !selectedItems.length
              "
              background-color="green"
              class="ranges-list__actions__item"
              height="38"
            />
          </div>
          <div class="ranges-list__actions__tools-row">
            <DropdownMenu
              :header-label="localization.general.MainPage.ToolsItemButton"
              :items="menuItemsForCreating"
              :headerIcon="'icons/gear.svg'"
              background-color="blue"
              :isMainMenu="true"
              class="ranges-list__actions__item fix-weight"
              height="38"
            />

            <FastFaqButton
              @faqToggler="onFaqButtonClick"
              :itemsInFaq="[
                'Description',
                'SituationTraining',
                'TableSizes',
                'CreateRangeOrGroup',
                'RangeSettings'
              ]"
              :isLightButton="true"
              lightName="listFaq"
              :style="{
                position: 'relative',
                display: 'flex',
                top: '4px !important',
                right: '-5px'
              }"
            />
          </div>
        </div>

        <List
          ref="rangesList"
          :childrenProvider="getItems"
          :sortFunction="sortItems"
          class="ranges-list__items"
          @currentItemChange="onCurrentItemChange"
          @groupOpen="onGroupOpen"
          @groupOpenWithAllRanges="onGroupOpenWithAllRanges"
          @itemParentChange="onItemParentChange"
          @groupOpenItem="getOpenGroupItemId"
          @selectedItemsChange="onSelectedItemsChange"
          @openSaveChangesDialog="openSaveChangesDialog"
          :isUpdateAvailable="updateAvailable"
          @itemsToUnlink="onItemsToUnlinck"
          :nextItem="nextItem"
          @setItemGroupStatistics="onSetItemGroupStatistics"
        >
          <template v-slot:card-outer="item">
            <div
              v-if="linkingMode && linkingMode !== item.type && item.type === ITEM_TYPE_RANGE"
              class="ranges-list__items__disabled"
            />
          </template>
          <template v-slot:card-body-content="item">
            <div v-if="item.name === ITEM_TYPE_STORE" class="store" />
            <div v-if="item.purchaseInfo" class="purchased" />
          </template>
          <template v-slot:card-inner="item">
            <template v-if="item.name === ITEM_TYPE_STORE">
              <div class="list__item__card__label">
                {{ localization.general.MainPage.PremiumRangesFolder }}
              </div>
            </template>
            <template v-else>
              <div class="list__item__card__label">
                {{ item.name }}
                <svg
                  v-if="
                    item.sharing &&
                      item.type === ITEM_TYPE_GROUP &&
                      item.sharing.FromItemId
                  "
                  data-src="/icons/share-diapason.svg"
                  class="dropdown-btn__header__icon list__item--sharing-icon"
                />
              </div>

              <div
                v-if="item.hasOwnProperty('diapason')"
                class="list__item__card__diapason__info"
              >
                <div
                  v-if="
                    linkingMode &&
                    linkingMode === item.type &&
                    canItemBeLinked(item) &&
                    !isSameItem(item) &&
                    !isIncludedItem(item)
                  "
                  @click="
                    $event.stopPropagation();
                    linkItem(item, item.type);
                  "
                >
                  <img
                    v-if="itemIsLinked(item)"
                    class="list__item__card__diapason__link-to-group"
                    src="/icons/close-wrapped.svg"
                  />
                  <img
                    class="list__item__card__diapason__link-to-group"
                    src="/icons/plus.svg"
                    v-else
                  />
                </div>
                <div
                  v-if="item.hasOwnProperty('price') && item.price !== null"
                  class="list__item__card__diapason__info__price"
                >
                  {{ item.price.value }}$
                </div>
                <template
                  v-if="
                    item.hasOwnProperty('statistics') &&
                      item.statistics !== null
                  "
                >
                  <div
                    v-if="
                      item.statistics.correct > 0 ||
                        item.statistics.incorrect > 0
                    "
                    class="list__item__card__diapason__info__answers"
                  >
                    <div class="incorrect">
                      {{ item.statistics.incorrect }}
                    </div>
                    <div class="correct">
                      {{ item.statistics.correct + item.statistics.incorrect }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </List>
        <div class="ranges-statistics hide-sm">
          <div class="ranges-statistics__values">
            <div class="ranges-statistics__values__item">
              <b>{{ localization.general.MainPage.RangesStatistics }}</b>
              {{ itemsStatistics.regular.diapasons }}
              (+{{ itemsStatistics.premium.diapasons }})
            </div>
            <div class="ranges-statistics__values__item">
              <b>{{ localization.general.MainPage.GroupsStatistics }}</b>
              {{ itemsStatistics.regular.groups }}
              (+{{ itemsStatistics.premium.groups }})
            </div>
          </div>
          <FastFaqButton
            @faqToggler="onFaqButtonClick"
            :itemsInFaq="['NumberOfRanges']"
            lightName="countRanges"
            :style="{ top: '4px !important' }"
          />
        </div>
      </div>
      <div
        :class="[
          'range-editor',
          {
            'hidden-sm': rangesFooterCurrentView !== rangesFooterViewEditor,
            'visible-sm': rangesFooterCurrentView === rangesFooterViewEditor
          }
        ]"
      >
        <StoreRangeViewer v-if="itemIsPremium" :item="currentItem" />
        <DefaultRangeEditor
          v-else
          ref="rangeEditor"
          @onUpdateItemRequestPending="handleUpdate"
          @handleActionButton="onHandleActionButton"
          @handleDisabledButton="onHandleDisabledButton"
          :is-blocked="isBlocked"
          :item="currentItem"
          :rangesOfGroup="rangesOfGroup"
          :statisticsOfGroup="statisticsOfGroup"
          :rangesOfGroupWithAllItems="allRangesOfGroup"
          :isFaqActive="isFaqActive"
          :isTabletOrMobile="isTabletOrMobile"
          @faqButtonClick="onFaqButtonClick"
          @clearStatisticsOfGroup="onClearStatisticsOfGroup"
          @clearStatisticsOfRange="onClearStatisticsOfRange"
          @linkedItemsChange="onLinkedItemsChange"
          @linkingModeToggle="onLinkingModeToggle"
          @startTest="startTest()"
          @setActiveParticipantsToPlay="setActiveParticipantsToPlay"
          @isUpdateAvailable="isUpdateAvailable"
          @openNextItem="onOpenNextItem"
        />
      </div>
    </div>

    <RangeViewChanger @changeView="changeRangesFooterView" />
    <!-- <FastFaqDialog :items="[]" /> -->
    <CreateGroupDialog v-model="showCreateGroupDialog" :handler="createGroup" />
    <CreateRangeDialog v-model="showCreateRangeDialog" :handler="createRange" />
    <FastFaqDialog
      v-model="isFaqActive"
      :itemNodes="faqActiveNodes"
      :activeFaqItems="activeFaqItems"
    />
    <SaveChangesDialog
      v-model="showSaveChangesDialog"
      :handler="saveChanges"
      @openNextItem="onOpenNextItem"
    />
    <DeleteItemsDialog
      v-model="showDeleteDialog"
      :handler="deleteItems"
      :items="computedSelectedItems"
      @itemsDelete="onItemsDelete()"
    />
  </div>
</template>

<script>
import RangeViewsMixin from '../../mixins/RangeViewsMixin';

// import Btn from '@/components/UI/Btn';
import Vue from 'vue';
import DropdownBtn from '@/components/UI/DropdownBtn';

import { mapState } from 'vuex';
import { GET_STORE_ITEMS, GET_STORE_ITEMS_CHILDREN } from '@/api/stores';
import FastFaqButton from '@/components/FastFaqDialog/FastFaqButton';

import {
  CREATE_GROUP,
  CREATE_RANGE,
  DELETE_ITEMS,
  GET_ALL_ITEMS,
  GET_CHILDREN_OF_ITEM,
  ITEMS_COPY,
  MOVE_ITEM
} from '@/api/items';

import DefaultRangeEditor from '@/components/RangeEditor/DefaultRangeEditor';
import StoreRangeViewer from '@/components/StoreRangeViewer';
import SaveChangesDialog from '@/components/SaveChangesDialog';
import { GET_ITEMS_STATISTICS_OF_AUTH_USER } from '@/api/statistics';
import DropdownMenu from '@/components/UI/DropdownMenu';
import { DEFAULT_PARENT_ID } from '@/constants/items';
import {
  ITEM_TYPE_GROUP,
  ITEM_TYPE_RANGE,
  ITEM_TYPE_STORE
} from '@/constants/itemTypes';

export default {
  name: 'Main',
  mixins: [RangeViewsMixin],
  components: {
    // Btn,
    SaveChangesDialog,
    DropdownMenu,
    StoreRangeViewer,
    DefaultRangeEditor,
    DropdownBtn,
    FastFaqDialog: () => import('@/components/FastFaqDialog'),
    FastFaqButton
  },
  data() {
    return {
      actionButton: {},
      activeFaqItems: ['Description'],
      gameOptions: [],
      unlinckItems: [],
      loaded: false,
      ITEM_TYPE_STORE,
      ITEM_TYPE_GROUP,
      ITEM_TYPE_RANGE,
      startTestDisabled: true,
      isFaqActive: false,
      allRangesOfGroup: [],
      statisticsOfGroup: [],
      updateItemRequestPending: false,
      faqActiveNodes: [0],
      activeParicipantsToPlay: {},
      selectedItemsActiveParicipants: {},
      nextItem: {},
      groupOpenItem: {},
      updateAvailable: false,
      showSaveChangesDialog: false,
      itemsStatistics: {
        regular: {
          diapasons: 0,
          groups: 0
        },
        premium: {
          diapasons: 0,
          groups: 0
        }
      }
    };
  },

  computed: {
    ...mapState('ui', ['activeBreakpoint']),

    isTabletOrMobile() {
      if (['sm', 'xs'].includes(this.activeBreakpoint)) {
        return true;
      } else {
        return false;
      }
    },
    itemIsSelected() {
      if (!this.currentItem) return false;

      return this.currentItem.id !== ITEM_TYPE_STORE;
    },
    itemIsPremium() {
      return this.itemIsSelected
        ? this.currentItem.hasOwnProperty('price') || this.currentItem.store
        : null;
    }
  },
  provide() {
    return {
      tabletOrMobile: this.isTabletOrMobile
    };
  },
  methods: {
    onSetItemGroupStatistics(hands) {
      console.log(hands, 'jeew');
      console.log(this, 'ITEM oN OPEN groUp');
      this.statisticsOfGroup = hands;
    },
    onItemsToUnlinck(items) {
      this.unlinckItems = items;
    },
    onChangeGameOptions(array) {
      Vue.$track.gameSettingsUpdate();
      this.gameOptions = array;
      localStorage.setItem('gameOptions', JSON.stringify(this.gameOptions));
      console.log(this.gameOptions, 'Start game with this options');
    },
    onHandleDisabledButton(value) {
      console.log('start test disabled', value);
      this.startTestDisabled = value;
    },
    handleUpdate(value) {
      this.updateItemRequestPending = value;
    },
    handleBtnAction() {
      this.$refs.rangeEditor.startTest();
    },
    onHandleActionButton(action) {
      this.actionButton = action;
      this.actionButton = {
        label: this.localization.general.MainPage.StartTest,
        handler: this.startTest,
        btnColor: 'green'
      };
      console.log('action', action);
    },
    onFaqButtonClick(nodes) {
      console.log(nodes, 'noddd');
      this.activeFaqItems = nodes;

      this.isFaqActive = true;
    },
    async onOpenNextItem() {
      console.log(this.nextItem, this.currentItem, 'NEXT ITEM IN OPEN');
      await this.$refs.rangesList.openItem(this.nextItem);
      console.log(this.currentItem, this.nextItem, 'HELLO');
      this.currentItem = this.nextItem.value;
    },
    async saveChanges() {
      await this.$refs.rangeEditor.runAction(true);
    },
    openSaveChangesDialog(item) {
      console.log('work oscd: ', item);
      this.nextItem = item;
      this.showSaveChangesDialog = true;
      // this.$refs.rangeEditor.runAction();
    },
    isUpdateAvailable(available) {
      this.updateAvailable = available;
    },
    getOpenGroupItemId(item) {
      console.log(item, 'LIST RECURSION ITEM');
      this.groupOpenItem = item;
    },
    changeRangesFooterView(view) {
      this.rangesFooterCurrentView = view;
      //TODO: Add scroll lock/unlock here
    },
    setActiveParticipantsToPlay(value) {
      console.log(value, 'Value coming to setactive');
      this.activeParicipantsToPlay = {
        ...value
      };
      console.log(this.activeParicipantsToPlay, 'Value coming to setactive');
      console.log(
        'MAIN VIEW: this.activeParticipantToPlay',
        this.activeParicipantsToPlay
      );
    },
    async listRecursion(item, arResult = []) {
      console.log('START REC', item, arResult);
      const promises = item.children.map(async child => {
        if (child.value.type === ITEM_TYPE_RANGE) {
          arResult.push(child.value.id);
        } else {
          // let tempChild = child;
          child.children = await child.childrenProvider();
          console.log(child.children, 'MAIN VIEW: child children');

          await this.listRecursion(child, arResult);
        }
      });
      await Promise.all(promises);
      return arResult;
    },
    async getItems(item) {
      if (item) {
        if (item.store) {
          let children = item.id
            ? await GET_STORE_ITEMS_CHILDREN(item.id)
            : await GET_STORE_ITEMS();

          return children.map(item => {
            item.store = true;
            item.dragAndDropIn = false;
            item.dragAndDropOut = false;
            return item;
          });
        }
        console.log('worked children');

        return await GET_CHILDREN_OF_ITEM(item.id);
      } else {
        const items = await GET_ALL_ITEMS();
        return [...items.groups, ...items.diapasonsWithoutGroup];
      }
    },
    onItemParentChange(data) {
      this.itemParentChangeHandler(data, MOVE_ITEM);
    },
    onClearStatisticsOfRange() {
      let items = this.$refs.rangesList.getItemsOfCurrent();
      console.log(items, 'items when i clear stats');
      console.log(this.currentItem.diapason.statistics.hands, 'handfs');
      Object.keys(this.currentItem.diapason.statistics.hands).forEach(hand => {
        this.currentItem.diapason.statistics.hands[hand].correct = 0;
        this.currentItem.diapason.statistics.hands[hand].incorrect = 0;
      });

      let item = items.map(item => item);
      console.log('item returned', item);
      this.$refs.rangesList.refreshStats(items);
    },
    onClearStatisticsOfGroup() {
      let items = this.$refs.rangesList.getItemsOfCurrent();

      this.$refs.rangesList.refreshStats(items);
      let stats = this.currentItem.statistics;
      console.log(this.currentItem, 'stats to clear item');
      console.log(stats, 'stats to clear');
      console.log(items, 'items in clear group');
      items
        .filter(item => item.type === ITEM_TYPE_RANGE)
        .forEach(item => {
          Object.keys(item.diapason.statistics.hands).forEach(hand => {
            item.diapason.statistics.hands[hand].correct = 0;
            item.diapason.statistics.hands[hand].incorrect = 0;
          });

          item.statistics.correct = 0;
          item.statistics.incorrect = 0;
        });
    },
    async startTest() {
      let diapasonsForTest = [];
      // let openGroupDiapasonsForTest = [];

      let rangesList = this.$refs.rangesList;
      console.log(
        rangesList.$refs.rangesList,
        'MAIN VIEW: RANGES LIST IN START TEST'
      );

      let selectedItems = rangesList.selectedItems;
      let currentItem = rangesList.currentItem;
      let selectedItemsRecursion = [];
      console.log(selectedItems, 'MAIN VIEW: SELECTED ITEMS');
      console.log(currentItem, 'MAIN VIEW: CURRENT ITEMS');

      let items = selectedItems.length > 0 ? selectedItems : [currentItem];
      console.log(items, 'items selected');

      // diapasonsForTest.push(this.currentItem.id);
      // diapasonsForTest.push(this.currentItemParentId);
      console.log(diapasonsForTest, 'diapasons for test parentId');
      console.log(this.currentItem, 'GROUP OPEN item');

      for (let item of items) {
        console.log(item, 'SELECTED ITEM IN FOR LOOP');
        if (!diapasonsForTest.includes(item.value.id))
          diapasonsForTest.push(item.value.id);
      }
      // for (let item of openGroupDiapasonsForTest) {
      //   if (!diapasonsForTest.includes(item)) diapasonsForTest.push(item);
      // }
      // for (let item of selectedItemsRecursion) {
      //   if (!diapasonsForTest.includes(item.id)) diapasonsForTest.push(item.id);
      // }

      console.log(diapasonsForTest, 'DIAPAZONS FINAL');
      console.log(
        selectedItemsRecursion,
        'MAIN VIEW: Selected items recursion'
      );

      console.log('MAIN VIEW: Diapason For Test', diapasonsForTest);
      localStorage.setItem(
        'diapasonsForTest',
        JSON.stringify(diapasonsForTest)
      );
      // let gameOptionFiltered = this.gameOptions.filter(option => option.value);

      localStorage.setItem('gameOptions', JSON.stringify(this.gameOptions));

      console.log(diapasonsForTest, 'MAIN VIEW: DIAPASONS FOR TEST');
      this.$router.push({
        name: 'Game'
      });
    },
    async copyItem() {
      await this.handleCopyItem(ITEMS_COPY);
      await this.updateItemsStatistics();
    },

    async createGroup(newGroup) {
      const items = this.$refs.rangesList.getItemsOfCurrent();
      let order = this.getLastOrder(items);

      newGroup.order = order;
      newGroup.parentId = this.currentItemParentId ?? DEFAULT_PARENT_ID;

      await CREATE_GROUP(newGroup).then(newGroupID => {
        this.$refs.rangesList.addData({
          id: newGroupID,
          name: newGroup.name,
          type: 'Group',
          order: order,
          parentId: this.currentItemParentId ?? DEFAULT_PARENT_ID,
          diapason: null,
          purchaseInfo: null
        });
      });
    },
    async createRange(newRange) {
      const items = this.$refs.rangesList.getItemsOfCurrent();
      const ranges = items.filter(item => item.type === ITEM_TYPE_RANGE);

      let order = this.getLastOrder(ranges);

      newRange.order = order;
      newRange.parentId = this.currentItemParentId;

      await CREATE_RANGE(newRange).then(newItemID => {
        try {
          this.$refs.rangesList.addData({
            type: ITEM_TYPE_RANGE,
            id: newItemID,
            order: order,
            parentId: this.currentItemParentId,
            purchaseInfo: null,
            name: newRange.name,
            diapason: {
              additionalProperties: newRange.additionalProperties,
              participants: newRange.participants,
              tableFormat: newRange.tableFormat,
              statistics: {
                correctAnswers: 0,
                incorrectAnswers: 0,
                hands: {}
              }
            }
          });
        } catch (error) {
          console.log(error, 'ERROR WITH CREATING');
        }

        this.itemsStatistics.regular.diapasons++;
      });
    },

    async deleteItems() {
      await this.handleDeletion(DELETE_ITEMS);
      await this.updateItemsStatistics();
    },
    async updateItemsStatistics() {
      this.itemsStatistics = await GET_ITEMS_STATISTICS_OF_AUTH_USER();
    }
  },

  watch: {
    isFaqActive() {
      if (this.isFaqActive) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
  async created() {
    this.$track.openRanges();

    await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');
    await this.updateItemsStatistics();
    console.log(this.$root, 'this root');

    this.loaded = true;
  }
};
</script>
