<template>
  <Dialog
    v-model="computedValue"
    class="modal-dialog--width-largest modal-dialog--height-largest"
  >
    <DialogHeader>
      <slot name="title">
        User Ranges Selector
      </slot>
    </DialogHeader>
    <DialogContent>
      <UserRangesSelector
        ref="userRangeSelector"
        :isAvailable="isAvailable"
        :item-select-required="itemSelectRequired"
        :multiple-select="multipleSelect"
        :premium="premium"
        :types="types"
        @rangesSelected="onRangesSelected"
      />
    </DialogContent>
    <DialogFooter>
      <Btn @click="selectItems()">
        Select
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/UI/Dialog';
import UserRangesSelector from '../UserRangesSelector';
import Btn from '@/components/UI/Btn';

export default {
  name: 'UserRangesSelectorDialog',
  data: function() {
    return {
      userRangesSelectorValues: {
        selectedItems: []
      }
    };
  },
  props: {
    premium: {
      type: Boolean,
      default: false
    },
    isAvailable: {
      type: Boolean,
      default: false
    },
    value: Boolean,
    types: String,
    itemSelectRequired: {
      type: Boolean,
      default: true
    },
    multipleSelect: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Btn,
    UserRangesSelector,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader
  },

  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    selectItems() {
      this.$refs.userRangeSelector.selectItems();
      this.$emit('rangesSelect', this.userRangesSelectorValues);
      this.closeModal();
    },
    onRangesSelected(value) {
      this.userRangesSelectorValues = value;
    },
    closeModal() {
      this.$emit('input', false);
    }
  }
};
</script>
<style lang="scss" src="./UserRangesSelectorDialog.scss" />
