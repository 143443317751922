export default {
  namespaced: true,

  mutations: {
    SET_BREAKPOINT(state, breakpoint) {
      state.activeBreakpoint = breakpoint;
    },
    SET_LOCALIZATION(state, language) {
      state.activeLang = language;
      state.localization = require(`@/localizations/${language}.json`);
    }
  },
  state: {
    activeBreakpoint: null,
    localization: {},
    activeLang: '',
  }
};