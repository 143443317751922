<template>
  <div>
    <ExpanderItem
      :preventAction="true"
      :linkedIsActive="this.$props.parentLinkedIsActive"
      :isLinked="true"
    >
      <template v-slot:header>
        <div
          class="item-options-editor__table-participants"
          :style="{ 'padding-right': '35px !important' }"
        >
          <Scroller ref="scroller" class="item-options-editor__btns-row">
            <div
              v-for="tableParticipant in extendedTableParticipants"
              :key="tableParticipant.value"
              :class="[
                'item-options-editor__table-participants__item',
                {
                  'item-options-editor__table-participants__item--active':
                    actTablePart === tableParticipant.value
                }
              ]"
              @click="setActiveTableParticipant(tableParticipant.value)"
            >
              <div class="item-options-editor__table-participants__item__name">
                <div
                  class="item-options-editor__table-participants__item__desc"
                >
                  <span v-show="tableParticipant.isHeroPosition">Hero</span>
                  <span v-show="tableParticipant.isOpponentPosition">OP</span>
                </div>
                {{ tableParticipant.label }}
              </div>
              <div
                :style="{
                  'background-color': tableParticipant.bgColor,
                  'border-color': tableParticipant.bgColor,
                  color: tableParticipant.textColor
                }"
                class="item-options-editor__table-participants__item__stack"
              >
                {{ tableParticipant.stack ? tableParticipant.stack : '' }}
              </div>
            </div>
          </Scroller>
        </div>
        <FastFaqButton
          @faqToggler="faqToggler"
          :itemsInFaq="['OpponentRanges']"
          lightName="positionTabs"
          :style="{ top: '4px !important' }"
        />
      </template>

      <template v-slot:content>
        <div class="item-options-editor__selected-table-participant-info">
          <div class="item-options-editor__row">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.Stack }}
            </div>
            <div class="item-options-editor__value" v-if="inputValue">
              <input
                v-model.number="inputValue.stack"
                class="item-options-editor__input form-input"
              />
            </div>
          </div>
          <div class="item-options-editor__row item-options-editor__row--color">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.Color }}
            </div>
            <div class="item-options-editor__value" v-if="inputValue">
              <ColorPicker v-model="inputValue.color" />
            </div>
          </div>
          <div class="item-options-editor__row">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.Statistics }}
            </div>
            <div class="item-options-editor__value" v-if="inputValue">
              <input
                v-model="inputValue.stats"
                class="item-options-editor__input form-input"
              />
            </div>
          </div>
          <div class="item-options-editor__row">
            <div class="item-options-editor__label">
              {{ localization.general.Diapason.Notes }}
            </div>
            <div class="item-options-editor__value" v-if="inputValue">
              <input
                v-model="inputValue.notes"
                class="item-options-editor__input form-input"
              />
            </div>
          </div>
        </div>
      </template>
    </ExpanderItem>
  </div>
</template>

<script>
import Scroller from '@/components/UI/Scroller/Scroller';
import FastFaqButton from '@/components/FastFaqDialog/FastFaqButton';

import ColorPicker from '@/components/UI/ColorPicker';
import { ExpanderItem } from '@/components/UI/Expander';
import { getCustomNameOfPosition } from '@/utils/positions';
import { CHECK_IF_COLOR_IS_LIGHT } from '@/utils/colors';

export default {
  name: 'PositionTabs',
  components: { Scroller, ExpanderItem, ColorPicker, FastFaqButton },
  props: {
    activeTableParticipant: [Object, String],
    tableParticipants: Array,
    parts: Object,
    dataItem: Object,
    parentLinkedIsActive: Boolean
  },
  data() {
    return {
      tempParts: {}
    };
  },

  methods: {
    faqToggler(nodes) {
      this.$parent.$parent.$parent.faqToggler(nodes);
    },
    getCustomNameOfPosition,
    setActiveTableParticipant(val) {
      this.$emit('setActiveTableParticipant', val);
    },
    updatePrts(payload) {
      this.$emit('updateParts', payload);
    }
    // upadteTempParts(val) {
    //   this.tempParts = {};
    //   this.tempParts.push(val);
    //   console.log(this.tempParts, 'TEMPPARTS');
    // }
  },

  watch: {
    parts: {
      handler(val) {
        console.log('HELLchanged', val);
        // this.$emit('onChangePositionTab'); - вызывает функцию апдейта
      }
    }
  },
  computed: {
    extendedTableParticipants() {
      console.log(
        this.$props.tableParticipants,
        'CALL EXTENDED TABLE PARTICIPANTS'
      );
      console.log(this.$props.parts, 'CALL EXTENDED TABLE PARTICIPANTS1');
      return this.$props.tableParticipants.map(value => {
        let part = this.$props.parts[value];
        console.log(value, 'I am value');
        console.log(part, 'i am part');
        // let part = this.$props.dataItem.participants[value];
        console.log(part, value, this.$props.parts, 'WHUT');

        let color = part.color;
        if (value === this.$props.dataItem.additionalProperties.hp) {
          this.$emit('setHeroPosition', value);
          console.log('THIS IS HERO POSITION, ', value);
        }

        let payload = {
          label: getCustomNameOfPosition(value),
          textColor: CHECK_IF_COLOR_IS_LIGHT(color) ? '#000' : '#fff',
          bgColor: color,
          stack: part.stack,
          value: value,
          isHeroPosition:
            value === this.$props.dataItem.additionalProperties.hp,
          isOpponentPosition:
            value === this.$props.dataItem.additionalProperties.op
        };
        // this.updatePrts(payload);
        // this.upadteTempParts(payload);
        return payload;
      });
    },
    actTablePart() {
      return this.$props.activeTableParticipant;
    },
    inputValue: {
      get() {
        return this.$props.parts[this.activeTableParticipant];
      },
      set(val) {
        console.log('SETTT');
        this.$emit('input', val);
      }
    }
  }
};
</script>

<style></style>
