<template>
  <div
    v-show="isActive"
    class="tabs__item"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: "Tab",

  data() {
    return {
      key: null,
      isActive: false
    }
  }
}
</script>
