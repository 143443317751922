<template>
  <div
    :class="{'tabs__nav__item--active': isActive}"
    class="tabs__nav__item"
    @click="selectTab()"
  >
    <slot/>

    <i
      v-if="icon"
      :class="icon"
    />
  </div>
</template>

<script>

export default {
  name: "TabsNavBtn",
  data() {
    return {
      key: null,
      isActive: false
    }
  },
  props: {
    icon: {
      type: String,
      default: null
    }
  },
  methods: {
    selectTab() {
      this.$parent.selectTab(this.key)
    }
  }
}
</script>