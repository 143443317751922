<template>
  <div v-if="localizedItemInfo" class="store-range-viewer">
    <div class="store-range-viewer__name">
      {{ localizedItemInfo.name }}
    </div>
    <div class="store-range-viewer__description">
      {{ localizedItemInfo.shortDescription }}
    </div>
    <div
      class="store-range-viewer__content"
      v-html="localizedItemInfo.content"
    >
    </div>
    <div class="store-range-viewer__buttons">
      <Btn color="blue">
        В корзину
      </Btn>
      <div class="store-range-viewer__buttons__buy">
        <div class="store-range-viewer__buttons__buy__selected">
          {{ localization.general.MainPage.RangesNumberInCart }}
        </div>
        <Btn color="blue">
          {{ localization.general.MainPage.BuyPremiumRange }}
        </Btn>
      </div>

    </div>

  </div>
</template>

<script>
import Btn from "@/components/UI/Btn";

export default {
  name: "StoreRangeViewer",
  props: {
    item: Object
  },
  components: {
    Btn
  },
  computed: {
    lang() {
      return this.$store.getters['user/language']
    },
    localizedItemInfo() {
      if (this.item.localization.hasOwnProperty(this.lang))
        return this.item.localization[this.lang]

      return null
    }
  },
  methods: {},
  mounted() {

  }
}
</script>

<style lang="scss" src="./StoreRangeViewer.scss"/>
