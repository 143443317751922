<template>
  <div>
    <h2 class="profile__title">
      {{ localization.general.Profile.ReferralsHeader }}
    </h2>
    <div class="profile-tab">
      <div class="profile-tab__block profile-tab__block--referrals">
        <table class="c-simple-table">
          <thead>
          <tr>
            <th class="profile-tab__block--referrals__mobile-cell">
              {{ localization.general.Profile.ColumnIdentifier }}
            </th>
            <th class="profile-tab__block--referrals__mobile-cell">
              <div class="hide-xxs">
                {{ localization.general.Profile.ColumnRegistrationDate }} /
                Profit
              </div>
            </th>
            <th class="hide-sm">
              {{ localization.general.Profile.ColumnIdentifier }}
            </th>
            <th class="hide-sm">
              {{ localization.general.Profile.ColumnRegistrationDate }}
            </th>
            <th class="hide-sm">
              {{ localization.general.Profile.ColumnTotalProfit }}
            </th>
            <th class="hide-sm">
              {{ localization.general.Profile.ColumnLastMonthProfit }}
            </th>
            <th class="hide-sm">
              {{ localization.general.Profile.ColumnCurrentMonthProfit }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="referral in referrals"
            :key="referral.id"
          >
            <td class="profile-tab__block--referrals__mobile-cell">
              {{ referral.identifier }}
              <div class="profile-tab__block--referrals__mobile-column">
                <span>{{ localization.general.Profile.MobileProfit }}</span>
                <span>{{ localization.general.Profile.MobileColumnCurrentMonthProfit }}</span>
                <span>{{ localization.general.Profile.MobileColumnLastMonthProfit }}</span>
                <span>{{ localization.general.Profile.MobileColumnTotalProfit }}</span>
              </div>
            </td>
            <td class="profile-tab__block--referrals__mobile-cell">
              {{ new Date(referral.registrationDate).toLocaleString() }}
              <div class="profile-tab__block--referrals__mobile-column">
                <span>&nbsp;</span>
                <span>{{ referral.currentMonthProfit }}</span>
                <span>{{ referral.lastMonthProfit }}</span>
                <span>{{ referral.totalProfit }}</span>

              </div>
            </td>
            <td class="hide-sm">{{ referral.identifier }}</td>
            <td class="hide-sm">{{ new Date(referral.registrationDate).toLocaleString() }}</td>
            <td class="hide-sm">{{ referral.currentMonthProfit }}</td>
            <td class="hide-sm">{{ referral.lastMonthProfit }}</td>
            <td class="hide-sm">{{ referral.totalProfit }}</td>

          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import {GET_AUTH_USER_REFERRALS} from "@/api/referrals";

export default {
  name: 'ReferralsTab',
  data() {
    return {
      referrals: [],

      activePage: 0,
      itemsPerPage: 25,
      maxPage: 1,
    };
  },
  methods: {
    async getReferrals(pageNumber, itemsPerPage) {
      const data = await GET_AUTH_USER_REFERRALS({
        pageNumber, itemsPerPage
      });

      this.referrals = data.data;
      this.activePage = data.pageNumber;
      this.maxPage = data.pagesCount;
    },
  },
  created() {
    this.getReferrals(this.activePage, this.itemsPerPage);
  },
};
</script>