<template>
  <div
    :class="[
      'scroller-wrapper',
      {
        'scroller-wrapper--has-scroll': hasScroll
      }
    ]"
  >
    <div v-dragscroll="true" class="scroller-row">
      <slot />
    </div>
  </div>
</template>

<script>
import { dragscroll } from 'vue-dragscroll';
export default {
  name: 'Scroller',
  directives: {
    dragscroll
  },
  data() {
    return {
      hasScroll: false,
      observer: null,

      clientWidth: 0,
      fullWidth: 0,
      currentScrollPosition: 0,

      SCROLL_PX: 30
    };
  },
  methods: {
    detectIfScrollExists() {
      const tableParticipantsRows = document.querySelector('.scroller-row');
      this.fullWidth = tableParticipantsRows.scrollWidth;
      this.clientWidth = tableParticipantsRows.clientWidth;
      this.hasScroll = this.fullWidth > this.clientWidth;

      if (this.hasScroll) {
        // tableParticipantsRows.addEventListener('wheel', e => {
        //   this.currentScrollPosition =
        //     this.clientWidth + tableParticipantsRows.scrollLeft;
        //   let scrollLeftPosition = tableParticipantsRows.scrollLeft;
        //   let directionOfWheelIsForward = e.deltaY > 0;
        //   // Not run event, if scrolling back and scroll is at start
        //   if (!directionOfWheelIsForward && scrollLeftPosition === 0) return;
        //   if (
        //     directionOfWheelIsForward &&
        //     this.currentScrollPosition >= this.fullWidth
        //   )
        //     return;
        //   e.preventDefault();
        //   let directionOfWheel = directionOfWheelIsForward
        //     ? this.SCROLL_PX
        //     : -this.SCROLL_PX;
        //   tableParticipantsRows.scrollLeft += directionOfWheel;
        // });
      }
    }
  },
  mounted() {
    this.observer = new MutationObserver(() => {
      this.detectIfScrollExists();
    });

    this.observer.observe(this.$el, {
      childList: true,
      subtree: true
    });

    this.detectIfScrollExists();
  }
};
</script>

<style lang="scss" src="./Scroller.scss" />
