<template>
  <div class="container faq-container">
    <FaqExpanderList :items="items" />
  </div>
</template>

<script>
import FaqExpanderList from '../../components/FaqExpanderList/FaqExpanderList.vue';
import marked from 'marked';

export default {
  components: {
    FaqExpanderList
  },
  computed: {
    items() {
      let buffer = this.localization.faq;

      const result = [];
      Object.keys(buffer).forEach(faqItem => {
        let item = {};
        item.title = buffer[faqItem].name;
        item.value = marked(buffer[faqItem].content);
        item.category = buffer[faqItem].category;
        result.push(item);
      });

      return result;
    }
  }
};
</script>

<style scoped>
.faq-container {
  margin: 43px auto;
  /* width: 80%; */
}
</style>
