/* eslint-disable no-unused-vars */
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('ui', ['activeLang', 'localization'])
  },
  methods: {
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    translationsAndLicensesToPriceBlocks(translations, licenses) {
      let priceBlocks = [];

      /// Licenses
      // {
      //   "id": "prefloper-user-base-monthly",
      //   "tag": "prefloper-user-base",
      //   "subscribe": true,
      //   "term": 1,
      //   "interval": "Month",
      //   "maxItemsPerUser": 100,
      //   "name": "Monthly",
      //   "price": {
      //     "value": 9,
      //     "discount": null
      //   },
      //   "localization": {
      //     "eng": {
      //       "name": "Monthly",
      //       "description": ""
      //     }
      //   },
      //   "storeProductDescriptors": {
      //     "Fastspring": {
      //       "Id": "managed-subscription",
      //       "type": "FastspringStoreProductDescriptor"
      //     }
      //   },
      //   "benefits": [
      //     "maxRangesQuantity",
      //     "availableOnAllDevices",
      //     "freeRanges",
      //     "freeUpdates",
      //     "7daysRefund"
      //   ]
      // }

      /// translations
      // {
      //   "day": "День",
      //   "week": "Неделя",
      //   "month": "Месяц",
      //   "quarter": "Квартал",
      //   "year": "Год",
      //   "daily": "Ежедневно",
      //   "weekly": "Еженедельно",
      //   "monthly": "Ежемесячно",
      //   "quarterly": "Ежеквартально",
      //   "yearly": "Ежегодно",
      //   "buyLicense": "Купить сейчас!",
      //   "billed": "Счет",
      //   "payOneTime": "Единоразовый платёж",
      //   "benefits": {
      //     "saveComparingToMostExpensive": "Экономия $(saveComparingToMostExpensive.year)$",
      //     "maxRangesQuantity": "Возможность создания до $(maxItemsPerUser) диапазонов",
      //     "availableOnAllDevices": "Доступно на всех устройствах",
      //     "freeRanges": "Бесплатные диапазоны",
      //     "gto6MaxCachsPack": "GTO пак для 6 макс кэш",
      //     "freeUpdates": "Бесплатные обновления",
      //     "7daysRefund": "7-дневная гарантия полного возврата",
      //     "14daysRefund": "14-дневная гарантия полного возврата"
      //   }
      // }

      ///////////////////////////////////////
      /// calc mostExpensivePrice
      let mostExpensivePrice = 0;
      for (const license of licenses) {
        let titlePrice = license.price.value;
        const interval = license.interval.toLowerCase();
        if (license.term == '3') {
          titlePrice = titlePrice / 3;
        }
        if (interval == 'year') {
          titlePrice = titlePrice / 12;
        }

        if (mostExpensivePrice < titlePrice) {
          mostExpensivePrice = titlePrice;
        }
      }

      for (const license of licenses) {
        const interval = license.interval.toLowerCase();

        ///////////////////////////////////////
        /// calc textPrice
        let textPrice = '';
        if (license.subscribe) {
          let subinterval = '';
          if (interval == 'day') {
            subinterval = 'daily';
          }
          if (interval == 'week') {
            subinterval = 'weekly';
          }
          if (interval == 'month') {
            subinterval = 'monthly';
          }
          if (license.term == '3') {
            subinterval = 'quarterly';
          }
          if (interval == 'year') {
            subinterval = 'yearly';
          }
          textPrice =
            translations['billed'] +
            ' ' +
            translations[subinterval].toLowerCase() +
            ' $' +
            license.price.value +
            ' (USD)';
        }

        ///////////////////////////////////////
        /// calc title and thriftyPriceMonth
        let discount = 0;
        let titlePrice = 0;
        let titleInterval = license.price.value;
        let thriftyPriceMonth = 0;

        if (interval == 'year') {
          titlePrice = license.price.value / 12;
          titleInterval = translations['month'].toLowerCase();
          thriftyPriceMonth =
            (mostExpensivePrice * 12 - license.price.value) / 12;
        } else if (interval == 'month') {
          titlePrice = license.price.value;
          titleInterval = translations['month'].toLowerCase();
          thriftyPriceMonth = false;
        } else if (interval == 'day') {
          titlePrice = license.price.value;
          titleInterval = translations['day'].toLowerCase();
          thriftyPriceMonth = false;
        } else if (interval == 'week') {
          titlePrice = license.price.value;
          titleInterval = translations['week'].toLowerCase();
          thriftyPriceMonth = false;
        }
        if (license.term == '3') {
          /// для квартала особый случай
          titlePrice = license.price.value / 3;
          titleInterval = translations['month'].toLowerCase();
          thriftyPriceMonth =
            (mostExpensivePrice * 3 - license.price.value) / 3;
        }
        ////////////////////////////////////////
        /// fields
        let fields = [];
        for (const item of license.benefits) {
          if (translations.benefits[item]) {
            let str = translations.benefits[item];

            ////////////////////////////////////
            /// replace variables
            str = str.replace('$(maxItemsPerUser)', license.maxItemsPerUser);
            if (thriftyPriceMonth) {
              str = str.replace(
                '$(saveComparingToMostExpensive.year)',
                thriftyPriceMonth * 12
              );
              str = str.replace(
                '$(saveComparingToMostExpensive.month)',
                thriftyPriceMonth
              );
              str = str.replace(
                '$(saveComparingToMostExpensive)',
                thriftyPriceMonth * 12
              );
            }

            if (item === 'saveComparingToMostExpensive') {
              discount = str;
            } else {
              if (str.indexOf('$(') == -1) {
                ///  $( ??? ) not show
                fields.push(str);
              }
            }
          }
        }
        /////////////////////////////////////////
        const block = {
          id: license.id,
          header: '$' + titlePrice + ' / ' + titleInterval,
          month: '',
          currency: '',
          discount: discount,
          red: '',
          strike: '',
          textPrice: textPrice,
          fields: fields,
          button: translations.buyLicense
        };

        priceBlocks.push(block);
      }

      return priceBlocks;
    },
    translationsSubscriptionsToPriceBlocks(translations, subscriptions) {
      let priceBlocks = [];

      ///////////////////////////////////////
      /// calc mostExpensivePrice
      let mostExpensivePrice = 0;
      for (const subscription of subscriptions) {
        let titlePrice = subscription.price.value;
        const interval = subscription.interval.toLowerCase();
        if (subscription.term == '3') {
          titlePrice = titlePrice / 3;
        }
        if (interval == 'year') {
          titlePrice = titlePrice / 12;
        }

        if (mostExpensivePrice < titlePrice) {
          mostExpensivePrice = titlePrice;
        }
      }

      for (const subscription of subscriptions) {
        const interval = subscription.interval.toLowerCase();

        ///////////////////////////////////////
        /// calc textPrice
        let textPrice = '';
        if (subscription.subscribe) {
          let subinterval = '';
          if (interval == 'day') {
            subinterval = 'daily';
          }
          if (interval == 'week') {
            subinterval = 'weekly';
          }
          if (interval == 'month') {
            subinterval = 'monthly';
          }
          if (subscription.term == '3') {
            subinterval = 'quarterly';
          }
          if (interval == 'year') {
            subinterval = 'yearly';
          }
          textPrice =
            translations['billed'] +
            ' ' +
            translations[subinterval].toLowerCase() +
            ' $' +
            subscription.price.value +
            ' (USD)';
        }

        ///////////////////////////////////////
        /// calc title and thriftyPriceMonth
        let titlePrice = 0;
        let titleInterval = subscription.price.value;
        let thriftyPriceMonth = 0;

        if (interval == 'year') {
          titlePrice = subscription.price.value / 12;
          titleInterval = translations['month'].toLowerCase();
          thriftyPriceMonth =
            (mostExpensivePrice * 12 - subscription.price.value) / 12;
        } else if (interval == 'month') {
          titlePrice = subscription.price.value;
          titleInterval = translations['month'].toLowerCase();
          thriftyPriceMonth = false;
        } else if (interval == 'day') {
          titlePrice = subscription.price.value;
          titleInterval = translations['day'].toLowerCase();
          thriftyPriceMonth = false;
        } else if (interval == 'week') {
          titlePrice = subscription.price.value;
          titleInterval = translations['week'].toLowerCase();
          thriftyPriceMonth = false;
        }
        if (subscription.term == '3') {
          /// для квартала особый случай
          titlePrice = subscription.price.value / 3;
          titleInterval = translations['month'].toLowerCase();
          thriftyPriceMonth =
            (mostExpensivePrice * 3 - subscription.price.value) / 3;
        }
        const block = {
          id: subscription.id,
          userId: subscription.userId,
          header: titlePrice + '$ / ' + titleInterval,
          result: subscription.charge?.result,
          state: subscription.state,
          lastPaymentDateTime: new Date(
            subscription.charge?.lastPaymentDateTime
          ).toLocaleString(),
          creationDateTime: new Date(
            subscription.creationDateTime
          ).toLocaleString()
        };

        priceBlocks.push(block);
      }

      return priceBlocks;
    }
  }
};
