import http from "../utils/http";

export const SEARCH_SUBSCRIPTIONS = async (params) => {
  const {data} = await http.get('Subscriptions/Search', {
    params: params
  });
  return data;
};
export const GET_SUBSCRIPTIONS = async (params) => {
  const {data} = await http.get('Subscriptions', {
    params: params
  });
  return data;
};
export const GET_SUBSCRIPTION = async id => {
  const {data} = await http.get('Subscriptions/' + id);
  return data;
};
export const CHARGE_SUBSCRIPTIONS = async force => {

  const {data} = await http.post('Subscriptions/Charge', {
    force: force
  });

  return data;

};
export const SYNCHRONIZE_SUBSCRIPTIONS = async () => {

  const {data} = await http.post('Subscriptions/Synchronize');

  return data;

};
export const DELETE_SUBSCRIPTION = async id => await http.delete('Subscriptions/' + id);