<template>
  <div>
    <h2 class="profile__title">
      {{ localization.general.Common.Information }}
    </h2>
    <div class="profile-tab">
      <div class="profile-tab__block profile-tab__block--user-info">
        <h3 class="profile-tab__title">
          {{ localization.general.Profile.UserDataHeader }}
        </h3>

        <div class="profile-tab__list">
          <div class="profile-tab__list__item">
            <div class="profile-tab__list__item__label">
              {{ localization.general.Profile.EmailField }}
            </div>
            <div class="profile-tab__list__item__value">
              {{ userInfo.email }}
            </div>
          </div>
          <div class="profile-tab__list__item">
            <div class="profile-tab__list__item__label">
              {{ localization.general.Profile.RegistrationTimeField }}
            </div>
            <div class="profile-tab__list__item__value">
              {{ new Date(userInfo.registrationDateTime).toLocaleString() }}
            </div>
          </div>
          <div class="profile-tab__list__item" v-if="userLimits">
            <div class="profile-tab__list__item__label">
              {{ localization.general.Profile.RangesLimit }}
            </div>
            <div class="profile-tab__list__item__value">
              {{ userLimits.items }}
            </div>
          </div>
          <div class="profile-tab__list__item" v-if="userLimits">
            <div class="profile-tab__list__item__label">
              {{ localization.general.Profile.SharingKey }}
            </div>
            <div class="profile-tab__list__item__value">
              {{ sharingKey ? sharingKey : '' }}

              <Btn
                class="profile-tab__list__item__btn"
                :loading="false"
                :disabled="isLoading || btnLoading"
                width="0px"
                height="38px"
                onmouseover="event.stopPropagation();"
                @click.prevent.stop="getUserSharingKey"
              >
                <svg
                  class="search-properties-header__refresh-icon"
                  data-src="/icons/refresh.svg"
                />
              </Btn>
            </div>
          </div>
          <div
            v-if="referralIsEnabled"
            class="profile-tab__list__item profile-tab__list__item--referrals"
          >
            <div class="profile-tab__list__item__label">
              {{ localization.general.Profile.ReferralLink }}
            </div>
            <div class="profile-tab__list__item__value">
              <div class="profile-tab__list__item__value--referrals__link">
                {{ userInfo.referrer.referralLink }}
              </div>

              <button
                class="profile-tab__list__item__btn"
                @click="copyReferralLink()"
              >
                {{ this.localization.general.Actions.Copy }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="profile-tab__block profile-tab__block__w-half profile-tab__block--licenses"
      >
        <h3 class="profile-tab__title">
          {{ localization.general.Profile.Licenses }}
        </h3>
        <table class="profile-tab__table c-simple-table">
          <thead>
            <tr>
              <th class="profile-tab__table__mobile-cell show-sm">
                {{ localization.general.Profile.ColumnTag }} /
                {{ localization.general.Profile.ColumnEndTime }}
              </th>
              <th class="hide-sm">
                {{ localization.general.Profile.ColumnTag }}
              </th>
              <th class="hide-sm">
                {{ localization.general.Profile.ColumnEndTime }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(license, index) in licensesByDate"
              :key="license.id"
              :class="{ pair: !!(index % 2) }"
            >
              <td class="profile-tab__table__mobile-cell show-sm">
                {{ license.tag }}
                <br />
                <span>
                  {{ new Date(license.endDateTime).toLocaleString() }}
                </span>
              </td>
              <td class="hide-sm">{{ license.tag }}</td>
              <td class="hide-sm">
                {{ new Date(license.endDateTime).toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="profile-tab__block profile-tab__block__w-half profile-tab__block--subscriptions"
      >
        <h3 class="profile-tab__title">
          {{ localization.general.Profile.Subscriptions }}
        </h3>
        <br />
        <template v-if="subscriptions.length === 0">
          {{ localization.general.Profile.NoActiveSubscriptions }}
        </template>
        <table v-else class="profile-tab__licenses__table simple-table">
          <thead>
            <tr>
              <th>
                {{ localization.general.Profile.SubscriptionPrice }}
              </th>
              <th>
                {{
                  localization.general.Profile.SubscriptionLastPaymentDateTime
                }}
              </th>
              <th>
                {{ localization.general.Profile.SubscriptionChargeState }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subscription in subscriptions" :key="subscription.id">
              <td>
                {{ subscription.header }}
              </td>
              <td>
                {{ subscription.lastPaymentDateTime }}
              </td>
              <td>
                {{
                  localization.general.Profile[
                    'SubscriptionChargeState' + subscription.result
                  ]
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
// import { GET_LANG_STORE } from '@/api/localizations';
import { GET_USER_LIMITS } from '@/api/userLimits';
import { GET_SUBSCRIPTIONS } from '@/api/subscriptions';
import { mapState } from 'vuex';
import { GET_SHARING_KEY } from '@/api/sharingKey';
import Btn from '@/components/UI/Btn';

export default {
  name: 'UserDataTab',
  components: { Btn },
  data() {
    return {
      userLimits: null,
      subscriptions: [],
      subscriptionsLoaded: false,
      sharingKey: null,
      isLoading: false
    };
  },
  props: {
    referralIsEnabled: Boolean
  },
  methods: {
    async getUserSharingKey() {
      this.isLoading = true;
      console.log('get sharing key');
      const newSharingKey = await GET_SHARING_KEY();
      this.sharingKey = newSharingKey;
      this.$store.commit('user/SET_USER_SHARING_KEY', this.sharingKey);
      this.isLoading = false;
    },
    async loadSubscriptions() {
      // const pLangStore = await GET_LANG_STORE(this.activeLang, 'store');
      const subscriptions = await GET_SUBSCRIPTIONS({
        pageNumber: 0,
        itemsForPage: 10,
        state: 'active'
      });

      this.subscriptions = subscriptions;
      this.subscriptionsLoaded = true;
    },
    cutText(text, limit) {
      if (text.length > limit) {
        return text.slice(0, limit) + '...';
      } else {
        return text;
      }
    },
    copyReferralLink() {
      let dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = this.userInfo.referrer.referralLink;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.$toast.success(this.localization.general.Alerts.Copied);
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),

    checkIfAdmin() {
      if (this.userInfo.activeLicenseKeys) {
        return this.userInfo.activeLicenseKeys.find(
          license => license.licenseId === 'admin'
        );
      }
      return false;
    },
    licensesByDate() {
      const defaultId = '00000000-0000-0000-0000-000000000000';
      console.log('work licenses');
      if (this.userInfo.activeLicenseKeys) {
        const arrOr = this.userInfo.activeLicenseKeys;
        const newarr = arrOr.sort((a, b) => {
          return moment(a.endDateTime).diff(b.endDateTime);
        });

        const filteredByNullGuid = newarr.filter(item => item.id != defaultId);
        console.log(filteredByNullGuid, 'filtered arr');

        return filteredByNullGuid;
      } else {
        return null;
      }
    }
  },
  async mounted() {
    this.userLimits = await GET_USER_LIMITS();
    this.sharingKey = this.userInfo.sharingKey;

    console.log(this.userInfo, ' storrr');
  },
  async created() {
    await this.loadSubscriptions();
  },
  watch: {
    activeLang() {
      this.loadSubscriptions();
    }
  }
};
</script>
