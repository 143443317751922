<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="footer__socials">
          <a
            class="footer__socials__item"
            :href="'mailto:' + localization.landing.Email"
          >
            {{ localization.landing.Email }}
          </a>
        </div>
      </div>
      <div class="row footer__row-padding">
        <div class="footer__socials">
          <a
            class="footer__socials__item"
            :href="localization.landing.SkypeUrl"
          >
            <img src="/icons/socials/skype.svg" />
          </a>
          <a
            class="footer__socials__item"
            :href="localization.landing.DiscordUrl"
          >
            <img src="/icons/socials/discord-icon.svg" />
          </a>
          <a
            class="footer__socials__item"
            :href="localization.landing.TelegramUrl"
          >
            <img src="/icons/socials/telegram.svg" />
          </a>
        </div>
      </div>
      <div class="row footer__row-padding">
        <div class="footer__copyright">© PokerIQ, 2023</div>

        <div class="footer__ukraine">
          Made in Ukraine <img src="/icons/socials/map.svg" alt="UKRAINE" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    setCurrentPage(index) {
      this.$store.dispatch('user/SET_CURRENT_MAIN_PAGE', index);
    }
  }
};
</script>

<style lang="scss">
@import './Footer.scss';
</style>
