export default {
  server() {

    return `${this.SERVER_URL()}${this.CURRENT_VERSION()}`

  },

  SERVER_URL() {
    return process.env.VUE_APP_API_URL
  },

  CURRENT_VERSION() {
    return process.env.VUE_APP_API_VERSION
  }
}
