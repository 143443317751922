const ORDERED_HANDS_RANGE = () => [
  'AA',
  'KK',
  'QQ',
  'JJ',
  'TT',
  '99',
  'AKs',
  '88',
  'AQs',
  'AJs',
  '77',
  'KQs',
  'ATs',
  'AKo',
  'KJs',
  'QJs',
  '66',
  'KTs',
  'AQo',
  'A9s',
  'QTs',
  'JTs',
  'AJo',
  'KQo',
  'A8s',
  '55',
  'K9s',
  'A7s',
  'ATo',
  'A5s',
  'KJo',
  'Q9s',
  'T9s',
  'J9s',
  'A6s',
  'A4s',
  'QJo',
  'KTo',
  '44',
  'A3s',
  'K8s',
  'QTo',
  'JTo',
  'A2s',
  'K7s',
  'Q8s',
  'A9o',
  '98s',
  'T8s',
  'J8s',
  'K6s',
  'A8o',
  '33',
  'K5s',
  '87s',
  'K9o',
  'Q7s',
  'K4s',
  '97s',
  'A7o',
  'T9o',
  'J7s',
  'T7s',
  'Q9o',
  'A5o',
  'J9o',
  'Q6s',
  'K3s',
  '76s',
  '22',
  'K2s',
  'A6o',
  'Q5s',
  'A4o',
  '86s',
  'K8o',
  'Q4s',
  '96s',
  '65s',
  'A3o',
  'J6s',
  'T6s',
  '98o',
  'Q3s',
  'T8o',
  '75s',
  'Q8o',
  'J5s',
  'K7o',
  'J8o',
  'A2o',
  'Q2s',
  '54s',
  '85s',
  'J4s',
  'K6o',
  '87o',
  '64s',
  '95s',
  'T5s',
  'J3s',
  '97o',
  'K5o',
  'J2s',
  'T4s',
  '74s',
  'T7o',
  '53s',
  'Q7o',
  'J7o',
  'T3s',
  'K4o',
  '76o',
  '84s',
  'Q6o',
  '43s',
  '94s',
  'T2s',
  'K3o',
  '86o',
  '63s',
  '93s',
  'Q5o',
  'K2o',
  '65o',
  '96o',
  '92s',
  '73s',
  '52s',
  'T6o',
  'Q4o',
  'J6o',
  '75o',
  '83s',
  '54o',
  '42s',
  '82s',
  'Q3o',
  'J5o',
  '85o',
  '62s',
  'Q2o',
  '64o',
  '32s',
  'J4o',
  '95o',
  '72s',
  'T5o',
  'J3o',
  '74o',
  '53o',
  'T4o',
  'J2o',
  'T3o',
  '84o',
  '43o',
  '94o',
  'T2o',
  '93o',
  '63o',
  '73o',
  '52o',
  '92o',
  '83o',
  '42o',
  '82o',
  '62o',
  '32o',
  '72o'
];

const SEQUENTIAL_HANDS_RANGE = () => [
  'AA',
  'AKs',
  'AQs',
  'AJs',
  'ATs',
  'A9s',
  'A8s',
  'A7s',
  'A6s',
  'A5s',
  'A4s',
  'A3s',
  'A2s',
  'AKo',
  'KK',
  'KQs',
  'KJs',
  'KTs',
  'K9s',
  'K8s',
  'K7s',
  'K6s',
  'K5s',
  'K4s',
  'K3s',
  'K2s',
  'AQo',
  'KQo',
  'QQ',
  'QJs',
  'QTs',
  'Q9s',
  'Q8s',
  'Q7s',
  'Q6s',
  'Q5s',
  'Q4s',
  'Q3s',
  'Q2s',
  'AJo',
  'KJo',
  'QJo',
  'JJ',
  'JTs',
  'J9s',
  'J8s',
  'J7s',
  'J6s',
  'J5s',
  'J4s',
  'J3s',
  'J2s',
  'ATo',
  'KTo',
  'QTo',
  'JTo',
  'TT',
  'T9s',
  'T8s',
  'T7s',
  'T6s',
  'T5s',
  'T4s',
  'T3s',
  'T2s',
  'A9o',
  'K9o',
  'Q9o',
  'J9o',
  'T9o',
  '99',
  '98s',
  '97s',
  '96s',
  '95s',
  '94s',
  '93s',
  '92s',
  'A8o',
  'K8o',
  'Q8o',
  'J8o',
  'T8o',
  '98o',
  '88',
  '87s',
  '86s',
  '85s',
  '84s',
  '83s',
  '82s',
  'A7o',
  'K7o',
  'Q7o',
  'J7o',
  'T7o',
  '97o',
  '87o',
  '77',
  '76s',
  '75s',
  '74s',
  '73s',
  '72s',
  'A6o',
  'K6o',
  'Q6o',
  'J6o',
  'T6o',
  '96o',
  '86o',
  '76o',
  '66',
  '65s',
  '64s',
  '63s',
  '62s',
  'A5o',
  'K5o',
  'Q5o',
  'J5o',
  'T5o',
  '95o',
  '85o',
  '75o',
  '65o',
  '55',
  '54s',
  '53s',
  '52s',
  'A4o',
  'K4o',
  'Q4o',
  'J4o',
  'T4o',
  '94o',
  '84o',
  '74o',
  '64o',
  '54o',
  '44',
  '43s',
  '42s',
  'A3o',
  'K3o',
  'Q3o',
  'J3o',
  'T3o',
  '93o',
  '83o',
  '73o',
  '63o',
  '53o',
  '43o',
  '33',
  '32s',
  'A2o',
  'K2o',
  'Q2o',
  'J2o',
  'T2o',
  '92o',
  '82o',
  '72o',
  '62o',
  '52o',
  '42o',
  '32o',
  '22'
];

const TABLE_MOCK_RANGE = () => [
  'AA',
  'AKs',
  'AJs',
  'ATs',
  'A9s',
  'KQs',
  'KJs',
  'K4s',
  'K3s',
  'K2s',
  'AQo',
  'J9s',
  'J8s',
  'J7s',
  'J6s',
  'J5s',
  'J4s',
  'J3s',
  'QTo',
  'JTo',
  '99',
  '97s',
  '95s',
  '94s',
  'T8o',
  '98o',
  '84s',
  'A7o',
  'K7o',
  'Q7o',
  'J7o',
  '97o',
  '87o',
  '77',
  '76s',
  '75s',
  '73s',
  '72s',
  'A6o',
  'K6o',
  'J6o',
  'T6o',
  '96o',
  '76o',
  '66',
  '65s',
  '95o',
  '85o',
  '75o',
  '65o',
  '44',
  '43s',
  '42s',
  '33',
  '32s',
  'A2o',
  'Q2o',
  'J2o',
  '22'
];

export { ORDERED_HANDS_RANGE, SEQUENTIAL_HANDS_RANGE, TABLE_MOCK_RANGE };
