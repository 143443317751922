/* eslint-disable */
export const GET_RESULT = (
  item,
  { raise = 0, check = 0, call = 0, fold = 0, allin = 0 }, // extracting into vars with default values for answers
  cards,
  mode,
  situation = null,
  randomNumber = null,
  isOppAllin = false,
  otherAllinStack = false,
  isInverse = false
) => {
  console.log(raise, check, call, fold, allin, 'answers');
  // return if win
  const WIN = () => ({
    item,
    answer: { raise, call, fold, allin, check },
    result: 1, // won
    cards,
    hand: item.name
  });
  // return if lost

  const LOS = () => ({
    item,
    answer: { raise, call, fold, allin, check },
    result: 0, // lost
    cards,
    hand: item.name
  });

  // if nothing (fold is correct then) true if fold 100 (default)
  const IS_DEFAULT = fold => {
    return fold == 100;
  };

  // if nothing (fold/call is correct then)
  const IS_DEFAULT_CHECK = check => {
    return check == 100;
  };

  const { weight } = item;

  console.log('WEIGHT', weight);
  console.log(mode);
  console.log(situation);
  // ADVANCED
  if (mode == 'Advanced') {
    // extracting correct weight answer (obj) from item

    if (situation == 'VsRFIr1hBB') {
      const IS_RAISE = raise => raise === Math.round(weight.raise);
      const IS_CHECK = check => check === Math.round(weight.check);
      const IS_ALLIN = allin => allin === Math.round(weight.allin);

      // if no weight or empty values
      if (
        !weight ||
        (weight.raise === 0 && weight.check === 0 && weight.allin === 0)
      ) {
        return IS_DEFAULT_CHECK(check) ? WIN() : LOS();
      }
      // if answer 100% and true
      if (IS_CHECK(check) && check == 100) return WIN();
      if (IS_ALLIN(allin) && allin == 100) return WIN();
      if (IS_CHECK(check) && IS_ALLIN(allin)) return WIN();
      if (IS_RAISE(raise) && IS_CHECK(check) && IS_ALLIN(allin)) return WIN();

      // if 50%/50% and true check pass
      if (IS_RAISE(raise) && IS_CHECK(check) && raise + check == 100)
        return WIN();

      // if allin and fold true answers
      if (IS_ALLIN(allin) && IS_CHECK(check)) return WIN();
      // if all 0% - win
      if (
        IS_ALLIN(allin || 0) &&
        IS_RAISE(raise || 0) &&
        IS_CHECK(check || 0)
      ) {
        return WIN();
      }

      /// else return loss if none win case returns true(win)
      return LOS();
    }

    //DEFAULT FOR ADVANCED

    // compare incoming values with correct answer values in weight
    const IS_RAISE = raise => raise == Math.round(weight.raise);
    const IS_CALL = call => call == Math.round(weight.call);
    const IS_FOLD = fold => fold == Math.round(weight.fold);
    const IS_ALLIN = allin => allin == Math.round(weight.allin);

    // if no weight or empty values
    if (
      !weight ||
      (weight.raise == 0 &&
        weight.call == 0 &&
        weight.fold == 0 &&
        weight.allin == 0)
    ) {
      // then fold is correct - check if fold
      return IS_DEFAULT(fold) ? WIN() : LOS();
    }
    // if pure 100% answer - check it
    // if answer 100% and true
    if (IS_CALL(call) && call == 100) return WIN();
    if (IS_RAISE(raise) && raise == 100) return WIN();
    if (IS_FOLD(fold) && fold == 100) return WIN();
    if (IS_ALLIN(allin) && allin == 100) return WIN();
    // if triple % answer (call, fold, allin)(if all true/correct)
    if (IS_CALL(call) && IS_FOLD(fold) && IS_ALLIN(allin)) return WIN();
    // if triple % answer (raise, fold, allin) (if all true/correct)
    if (IS_RAISE(raise) && IS_FOLD(fold) && IS_ALLIN(allin)) return WIN();
    // if triple % answer (raise, call, allin) (if all true/correct)
    if (IS_RAISE(raise) && IS_CALL(call) && IS_ALLIN(allin)) {
      return WIN();
    }
    // if 50%/50% and true check pass
    if (IS_RAISE(raise) && IS_FOLD(fold) && raise + fold == 100) return WIN();
    if (IS_CALL(call) && IS_FOLD(fold) && call + fold == 100) return WIN();
    if (IS_CALL(call) && IS_RAISE(raise) && call + raise == 100) return WIN();
    // if allin and fold true answers
    if (IS_ALLIN(allin) && IS_FOLD(fold)) return WIN();
    // if all 0% - win
    if (
      IS_ALLIN(allin || 0) &&
      IS_RAISE(raise || 0) &&
      IS_CALL(call || 0) &&
      IS_FOLD(fold || 0)
    )
      return WIN();

    /// else return loss if none win case returns true(win)
    return LOS();
  }
  // SIMPLE
  else if (mode == 'Simple') {
    console.log('simple mode resulting');
    console.log(raise, call, fold, allin);
    const ansObj = { raise, call, fold, allin, check };
    let playerAnswer;

    for (let prop in ansObj) {
      if (ansObj[prop] == 100) {
        playerAnswer = prop;
      }
    }
    console.log(playerAnswer + ' is player answer');

    let largestWeight = Math.max(
      weight.raise,
      weight.call,
      weight.check,
      weight.fold,
      weight.allin
    );
    console.log('largest weight found:' + largestWeight);

    const getKeysWithHighestValue = o => {
      const correctWeights = [];
      for (let prop in o) {
        if (o[prop] == largestWeight) {
          correctWeights.push(prop);
        }
      }
      console.log('getKeysWithHighestValue(correct answer):' + correctWeights);

      return correctWeights;
    };

    //array with correct action/s (fold allin call raise)
    let correctAnswers = getKeysWithHighestValue(weight);

    // check if player won
    // if any correct action in player answer
    let isWin = correctAnswers.some(correctAns => correctAns == playerAnswer);
    // if no weight or empty values (not filled)
    if (
      !weight ||
      (weight.raise == 0 &&
        weight.check == 0 &&
        weight.call == 0 &&
        weight.fold == 0 &&
        weight.allin == 0)
    ) {
      // check if player's fold/check value = 100
      if (situation == 'VsRFIr1hBB') {
        return IS_DEFAULT_CHECK(check) ? WIN() : LOS();
      } else {
        // default
        return IS_DEFAULT(fold) ? WIN() : LOS();
      }
    } else if (isWin) {
      return WIN();
    }
    return LOS();
  }
  // Randomized
  else if (mode == 'Randomized') {
    console.log('Randomized mode resulting');
    console.log(weight, 'weights randomized ');
    const foldWeight = weight.fold;
    const callWeight = weight.call;
    const checkWeight = weight.check;
    const raiseWeight = weight.raise;
    const allinWeight = weight.allin;

    // const foldWeight = weight.allin;
    // const callWeight = weight.call;
    // const checkWeight = weight.raise;
    // const raiseWeight = weight.check;
    // const allinWeight = weight.fold;
    // random %
    // see if ans (fld, call, allin. raise) match random %
    let foldRange = 0;
    let callRange = 0;
    let raiseRange = 0;
    let allinRange = 0;
    let checkRange = 0;

    if (foldWeight) {
      foldRange = foldWeight;
    }
    if (checkWeight) {
      checkRange = checkWeight;
    }
    if (situation === 'VsRFIr1hBB') {
      console.log('sitttt', situation);
      if (isInverse) {
        if (allinWeight) {
          allinRange = allinWeight;
        }
        if (raiseWeight) raiseRange = allinWeight + raiseWeight;
        if (checkWeight) checkRange = checkWeight + raiseWeight + allinWeight;
        console.log(allinRange, raiseRange, checkRange, 'tre');
      } else {
        if (raiseWeight) raiseRange = checkWeight + raiseWeight;
        if (allinWeight) allinRange = checkWeight + raiseWeight + allinWeight;
      }
    } else {
      console.log(isInverse, 'isinverrrss');
      if (isInverse) {
        console.log('reversed123', weight);
        if (allinWeight) allinRange = allinWeight;
        if (raiseWeight) raiseRange = allinWeight + raiseWeight;
        if (callWeight) callRange = allinWeight + callWeight + raiseWeight;

        if (foldWeight)
          foldRange = raiseWeight + allinWeight + callWeight + foldWeight;
      } else {
        if (callWeight) callRange = foldWeight + callWeight;
        if (raiseWeight) raiseRange = foldWeight + callWeight + raiseWeight;
        if (allinWeight)
          allinRange = foldWeight + callWeight + raiseWeight + allinWeight;
      }
    }
    console.log(
      'rangesx usual',
      foldRange,
      checkRange,
      callRange,
      raiseRange,
      allinRange
    );

    function swapNonZeroValues(arr) {
      let nonZeroValues = arr.filter(val => val !== 0);
      let count = nonZeroValues.length;
      let temp;

      for (let i = 0; i < count / 2; i++) {
        temp = nonZeroValues[i];
        nonZeroValues[i] = nonZeroValues[count - 1 - i];
        nonZeroValues[count - 1 - i] = temp;
      }

      let j = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== 0) {
          arr[i] = nonZeroValues[j++];
        }
      }

      return arr;
    }

    console.log('random ' + randomNumber);

    const randomNumberMatchDetect = num => {
      let swapped = swapNonZeroValues([
        foldRange,
        checkRange,
        callRange,
        raiseRange,
        allinRange
      ]);
      console.log(swapped, 'SWAPPED');
      // const invFoldRange = swapped[0];
      // const invCheckRange = swapped[1];
      // const invCallRange = swapped[2];
      // const invRaiseRange = swapped[3];
      // const invAllinRange = swapped[4];
      const invFoldRange = foldRange;
      const invCheckRange = checkRange;
      const invCallRange = callRange;
      const invRaiseRange = raiseRange;
      const invAllinRange = allinRange;
      if (isInverse) {
        console.log(
          invFoldRange,
          invCheckRange,
          invCallRange,
          invRaiseRange,
          invAllinRange,
          num,
          'rangesx reversed'
        );
        if (situation === 'VsRFIr1hBB') {
          if (num <= allinRange) {
            return 'allin';
          }
          if (num > allinRange && num <= raiseRange) {
            return 'raise';
          }
          if (num > raiseRange && num <= checkRange) {
            return 'check';
          }
        } else {
          //fold 0 call 50 raise 0 allin 100
          //0 100 0 50
          //75 > 0 && 50 <= 75
          //num = 75
          // num > raiseRange && callRange <= num
          //75 > 50 && 50 <= 75
          //Allin
          //75 < 100 && 75 >= 0
          // num <= raiseRange && num
          //Raise
          //num
          if (invAllinRange >= num) {
            //fold25 < num 75-100
            return 'allin';
          }
          if (num > invAllinRange && num <= invRaiseRange) {
            return 'raise';
          }

          if (num > invRaiseRange && num <= invCallRange) {
            return 'call';
          }
          if (num > invCallRange && num <= invFoldRange) {
            return 'fold';
          }
        }
      } else {
        if (situation === 'VsRFIr1hBB') {
          console.log(situation, 'SITuATION');
          if (num <= checkRange) {
            return 'check';
          }
          if (num > checkRange && num <= raiseRange) {
            return 'raise';
          }
          if (num > raiseRange && num <= allinRange) {
            return 'allin';
          }
        } else {
          if (num <= foldRange) {
            return 'fold';
          }
          if (num > foldRange && num <= callRange) {
            return 'call';
          }

          if (num > callRange && num <= raiseRange) {
            return 'raise';
          }
          if (num > raiseRange && num <= allinRange) {
            return 'allin';
          }
        }
      }
      return null;
    };

    let randomNumberMatch = randomNumberMatchDetect(randomNumber);
    correctAnswerRandomizer = randomNumberMatch;
    console.log('random num matches ' + randomNumberMatch);

    const ansObj = { raise, call, fold, allin, check };

    let playerAnswer;

    // find player answer (100)
    for (let prop in ansObj) {
      if (ansObj[prop] == 100) {
        playerAnswer = prop;
      }
    }
    console.log(playerAnswer + ' is player answer');

    let isWin;

    console.log(randomNumberMatch, playerAnswer, isInverse, 'rndmnum');
    isWin = randomNumberMatch === playerAnswer;

    //Код отвечает за правильный ответ колл если ситуация подходит выбранной
    // if (situation === 'VsRFIr1hBB') {
    //   // simulate check
    //   if (playerAnswer === 'check') {
    //     isWin = true;
    //   }
    // }

    // if (isOppAllin && !otherAllinStack) {
    //   if (playerAnswer === 'call') {
    //     isWin = true;
    //   }
    // }

    // if no weight or empty values (not filled)
    if (
      !weight ||
      (weight.raise == 0 &&
        weight.call == 0 &&
        weight.check == 0 &&
        weight.fold == 0 &&
        weight.allin == 0)
    ) {
      // check if player's fold/check value = 100
      if (situation == 'VsRFIr1hBB') {
        correctAnswerRandomizer = 'Check';
        return IS_DEFAULT(check) ? WIN() : LOS();
      } else {
        // default
        correctAnswerRandomizer = 'Fold';
        return IS_DEFAULT(fold) ? WIN() : LOS();
      }
    } else if (isWin) {
      return WIN();
    }
    return LOS();
  }
};

export const GET_ALL_WEIGHT_FROM_ARRAY = hands => {
  let weights = [];
  console.log(hands, 'HANDS IN ');
  hands.map(item => {
    const { raise, call, fold, allin, check } = item.hand.weight;
    let weight = {
      raise: isNaN(raise * 100) ? 0 : +(raise * 100).toFixed(0),
      check: isNaN(check * 100) ? 0 : +(check * 100).toFixed(0),
      call: isNaN(call * 100) ? 0 : +(call * 100).toFixed(0),
      fold: isNaN(fold * 100) ? 0 : +(fold * 100).toFixed(0),
      allin: isNaN(allin * 100) ? 0 : +(allin * 100).toFixed(0)
    };
    weights.push(weight);
  });

  return weights;
};

export const GET_ALL_WEIGHT = hands => {
  let weights = [];
  for (let key in hands) {
    const { raise, call, fold, allin, check } = hands[key];

    let weight = {
      raise: isNaN(raise * 100) ? 0 : +(raise * 100).toFixed(0),
      check: isNaN(check * 100) ? 0 : +(check * 100).toFixed(0),
      call: isNaN(call * 100) ? 0 : +(call * 100).toFixed(0),
      fold: isNaN(fold * 100) ? 0 : +(fold * 100).toFixed(0),
      allin: isNaN(allin * 100) ? 0 : +(allin * 100).toFixed(0)
    };

    weights.push(weight);
  }
  console.log(weights, 'WEIGHTS');
  return weights;
};

export const GET_CARDS_FROM_OBJECT = hands => {
  let result = [];
  for (let key in hands) {
    const { raise, call, fold, allin, check } = hands[key];

    let weight = {
      raise: isNaN(raise * 100) ? 0 : raise * 100,
      check: isNaN(check * 100) ? 0 : check * 100,
      call: isNaN(call * 100) ? 0 : call * 100,
      fold: isNaN(fold * 100) ? 0 : fold * 100,
      allin: isNaN(allin * 100) ? 0 : +(allin * 100).toFixed(0)
    };

    let card = {
      name: key,
      use: hands[key].useForTest,
      weight: weight
    };
    result.push(card);
  }
  return result.filter(h => h.use);
};

export const GET_CARDS_FROM_ONE_OBJECT = hand => {
  console.log('hand1', hand);
  let out = [];
  let handKey = hand.hand.handKey;
  let useForTest = hand.hand.gamePreference.useForTest;
  let { raise, call, fold, allin, check } = hand.hand.weight;
  let weight = {
    raise: isNaN(raise * 100) ? 0 : raise * 100,
    check: isNaN(check * 100) ? 0 : check * 100,
    call: isNaN(call * 100) ? 0 : call * 100,
    fold: isNaN(fold * 100) ? 0 : fold * 100,
    allin: isNaN(allin * 100) ? 0 : +(allin * 100).toFixed(0)
  };

  let card = {
    name: handKey,
    use: useForTest,
    weight: weight
  };

  out.push(card);
  console.log(out, card, 'OUT + CARD');

  return out;
};

export const GET_CARDS_FROM_ARRAY = hands => {
  let result = [];
  hands.map(item => {
    const { raise, call, fold, allin, check } = item.hand.weight;
    let weight = {
      raise: isNaN(raise * 100) ? 0 : raise * 100,
      check: isNaN(check * 100) ? 0 : check * 100,
      call: isNaN(call * 100) ? 0 : call * 100,
      fold: isNaN(fold * 100) ? 0 : fold * 100,
      allin: isNaN(allin * 100) ? 0 : +(allin * 100).toFixed(0)
    };

    let card = {
      name: item.hand.handKey,
      use: item.hand.gamePreference,
      weight: weight
    };
    result.push(card);
  });
  console.log(result, 'RESULT');
  return result.filter(h => h.use);
};

let correctAnswerRandomizer;

export const GET_CORRECT_ANSWER_RANDOMIZER = () => correctAnswerRandomizer;

export const isWeightValid = weight => {
  if (weight) {
    if (
      weight.fold > 0 ||
      weight.call > 0 ||
      weight.raise > 0 ||
      weight.allin > 0 ||
      weight.check > 0
    )
      return true;
  }
  return false;
};
