<template>
  <div>
    <Expander @changeExpanderItem="onChangeExpanderItem">
      <ExpanderItem>
        <template v-slot:header>
          <!-- TODO: add localez and v-if item.sharing -->
          <div class="sharing-expander--header">
            <div>Sharing</div>
          </div>
        </template>
        <template v-slot:header-buttons>
          <transition name="fade">
            <img
              v-if="isOpenedExpander"
              class="list__item__card__diapason__link-to-group"
              src="/icons/plus.svg"
              @click.prevent.stop="addNewSharingItem"
            />
          </transition>
        </template>

        <template v-slot:content>
          <!-- Coming data -->
          <div
            class="sharing-expander"
            v-for="(item, index) in sortedSharingItems"
            :key="item.CreationDateTime"
          >
            <div class="sharing-expander__utility-buttons">
              <div class="sharing-expander--item__check">
                <Checkbox v-model="usersToSync[item.UserEmail]" />
              </div>
              <div class="sharing-expander--item__delete">
                <img
                  class="item-options-editor__row--link-groups__item__icon"
                  src="/icons/close-wrapped.svg"
                  @click="deleteFromSharingItems(index)"
                />
              </div>
            </div>

            <div class="sharing-expander--item">
              <span class="sharing-expander--item__label">{{
                localization.general.Sharing.Email
              }}</span>
              {{ item.UserEmail }}
            </div>
            <div class="sharing-expander--item">
              <span class="sharing-expander--item__label">{{
                localization.general.Sharing.SharingKey
              }}</span>
              {{ item.UserSharingKey }}
            </div>
            <div class="sharing-expander--item">
              <span class="sharing-expander--item__label">{{
                localization.general.Sharing.Created
              }}</span>
              {{ item.CreationDateTime ? item.CreationDateTime : '' }}
            </div>
            <div class="sharing-expander--item">
              <span class="sharing-expander--item__label">{{
                localization.general.Sharing.Updated
              }}</span>
              {{ item.UpdatedDateTime ? item.UpdatedDateTime : '' }}
            </div>

            <!-- If we need sharig error code -->
            <!-- <div class="sharing-expander--item">
              <span class="sharing-expander--item__label"
                >Sharing Error Code</span
              >
              {{ item.SharingErrorCode ? item.SharingErrorCode : '' }}
            </div> -->

            <!-- New data -->
          </div>

          <!-- Temp sharing items here -->
          <div
            class="sharing-expander"
            v-for="(item, index) in tempSharingItems"
            :key="index"
          >
            <div class="sharing-expander__utility-buttons">
              <div class="sharing-expander--item__check"></div>
              <div class="sharing-expander--item__delete">
                <img
                  class="item-options-editor__row--link-groups__item__icon"
                  src="/icons/close-wrapped.svg"
                  @click="deleteFromTempSharingItems(index)"
                />
              </div>
            </div>
            <div class="sharing-expander--item">
              <span class="sharing-expander--item__label">{{
                localization.general.Sharing.Email
              }}</span>
              <input
                v-model="item.UserEmail"
                class="form-input"
                maxlength="32"
                minlength="4"
                required
                type="email"
              />
            </div>
            <div class="sharing-expander--item">
              <span class="sharing-expander--item__label">{{
                localization.general.Sharing.SharingKey
              }}</span>
              <input
                v-model="item.UserSharingKey"
                class="form-input"
                maxlength="32"
                minlength="4"
                required
                type="text"
              />
            </div>
          </div>
          <div class="sharing-expander--buttons">
            <Btn
              :usual="!isUpdateAvailable"
              :outlined="!isUpdateAvailable"
              @click="saveSharingData()"
              :loading="isLoadingSave"
              :color="btnSaveColor"
              :disabled="isLoadingSync || !isUpdateAvailable"
              >{{ localization.general.Sharing.Save }}</Btn
            >

            <Btn
              outlined
              @click="syncSharingData()"
              :loading="isLoadingSync"
              :disabled="isSyncDisabled"
              >{{ localization.general.Sharing.Sync }}</Btn
            >
          </div>
        </template>
      </ExpanderItem>
    </Expander>
    <Dialog
      v-model="isModalOpen"
      persistent
      class="modal-dialog--size-to-content "
    >
      <DialogHeader persistent>
        {{ localization.general.Sharing.Progress }}
      </DialogHeader>
      <DialogContent>
        <div v-if="!syncProgressEnd">
          <ProgressBar :progress="syncProgress" />
        </div>
        <div class="sharing-expander__dialog--sync-done" v-else>
          {{ localization.general.Sharing.SyncDone }}
        </div>
      </DialogContent>
      <DialogFooter>
        <Btn @click="closeModal" v-if="syncProgressEnd">OK</Btn>
      </DialogFooter>
    </Dialog>
  </div>
</template>

<script>
import Checkbox from '@/components/UI/Checkbox/Checkbox';
import { Expander, ExpanderItem } from '@/components/UI/Expander';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';
import ProgressBar from '@/components/ProgressBar';
import {
  SAVE_SHARING_ITEMS,
  REFRESH_SHARING_ITEMS,
  GET_REFRESH_PROGRESS_LRO
} from '@/api/sharingKey.js';
export default {
  name: 'SharingExpander',
  data() {
    return {
      usersToSync: {},
      isSyncDisabled: true,
      isOpenedExpander: false,
      isLoadingSync: false,
      isLoadingSave: false,
      userEmail: '',
      userSharingKey: '',
      sharingErrorCode: '',
      tempSharingItems: [],
      sharingItemsFromServer: [],
      isUpdateAvailable: false,
      pollingTimeout: null,
      isModalOpen: false,
      syncProgress: 0,
      syncProgressEnd: false
    };
  },
  props: {
    item: Object,
    updateUserSharingItems: Function
  },
  components: {
    Expander,
    ExpanderItem,
    Btn,
    Checkbox,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    ProgressBar
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
      this.isLoadingSync = false;
      this.syncProgressEnd = false;
      this.syncProgress = 0;
    },
    checkSyncStatus() {
      this.isSyncDisabled = !Object.values(this.usersToSync).some(
        value => value
      );
    },
    onChangeExpanderItem(item) {
      console.log(item, ' item');
      if (item === undefined || item === null || item < 0) {
        this.isOpenedExpander = false;
      } else {
        this.isOpenedExpander = true;
      }

      console.log(item, 'item on open exp');
    },
    checkEmailExistsField() {
      this.tempSharingItems = this.tempSharingItems.filter(item => {
        console.log(
          item.UserEmail,
          !!this.validateEmail(item.UserEmail),
          'itemUsrEm'
        );
        return !!this.validateEmail(item.UserEmail);
      });

      this.sharingItemsFromServer = this.sharingItemsFromServer.filter(item => {
        console.log(
          item.UserEmail,
          !!this.validateEmail(item.UserEmail),
          'itemUsrEm'
        );
        return !!this.validateEmail(item.UserEmail);
      });
    },
    addNewSharingItem() {
      this.isUpdateAvailable = true;
      const item = {
        UserEmail: '',
        UserSharingKey: ''
      };
      this.tempSharingItems.push(item);
      console.log('addNewSharingItem', item);
    },
    deleteFromSharingItems(index) {
      this.isUpdateAvailable = true;
      this.sharingItemsFromServer.splice(index, 1);
      console.log(index, 'delete item ');
    },
    deleteFromTempSharingItems(index) {
      this.tempSharingItems.splice(index, 1);
      if (!this.item?.sharing?.Users.length && !this.tempSharingItems.length) {
        this.isUpdateAvailable = false;
      }
    },
    async saveSharingData() {
      let saveUsers = [];
      if (
        !this.tempSharingItems.length &&
        !this.sharingItemsFromServer.length
      ) {
        // saveUsers.push({});
        // console.log('THIS saveUsers:', saveUsers);
      } else {
        this.checkEmailExistsField();
        saveUsers = [...this.tempSharingItems, ...this.sharingItemsFromServer];
        console.log(saveUsers, 'sdfds');
      }
      try {
        this.isLoadingSave = true;
        const response = await SAVE_SHARING_ITEMS({
          itemId: this.item.id,
          users: [...saveUsers]
        });

        if (!response) {
          console.log('NOT RESPONSE');
          this.sharingItemsFromServer = [];
          this.isUpdateAvailable = false;
          this.isLoadingSave = false;
          this.tempSharingItems = [];
          this.$emit('updateUserSharingItems', []);
          this.$toast.success('Saved');
          return;
        }
        console.log('saveSharingData', response);
        this.creationDateTime = response.CreationDateTime;
        this.updatedDateTime = response.UpdatedDateTime;
        this.sharingItemsFromServer = response.Users;
        this.$emit('updateUserSharingItems', response.Users);
        this.tempSharingItems = [];
        this.$toast.success('Saved');
        this.isUpdateAvailable = false;
        this.isLoadingSave = false;
      } catch (error) {
        console.log(error, 'error response');
        this.isLoadingSave = false;
      }
    },

    async syncSharingData() {
      this.isLoadingSync = true;

      const userEmails = this.filterEmailsToSync(this.usersToSync);
      let response = await REFRESH_SHARING_ITEMS({
        itemId: this.item.id,
        userEmails: userEmails
      });

      if (response) {
        this.isModalOpen = true;
        // this.syncProgress = response.progress * 100;
        this.pollForProgress(response.id);
      }
      console.log('syncSharingData', response);
    },
    async pollForProgress(taskId) {
      const pollInterval = 3000;

      console.log('pollForProgress: taskId', taskId);

      //Helper Function to fetch progress

      const fetchProgress = async () => {
        try {
          const progressResponse = await GET_REFRESH_PROGRESS_LRO({
            id: taskId
          });

          console.log(progressResponse, 'progressResponse');
          this.syncProgress = progressResponse.progress * 100;

          if (progressResponse.progress >= 1) {
            // this.isModalOpen = false;
            this.isLoadingSync = false;
            return;
          }

          this.pollingTimeout = setTimeout(fetchProgress, pollInterval);
        } catch (error) {
          console.log('fetch progress error', error);
          this.isModalOpen = false;
          this.isLoadingSync = false;
        }
      };
      this.pollingTimeout = setTimeout(fetchProgress, pollInterval);
    },
    filterEmailsToSync(emails) {
      return Object.keys(emails).reduce((filteredEmails, email) => {
        if (emails[email] === true) {
          filteredEmails.push(email);
        }
        return filteredEmails;
      }, []);
    }
  },
  computed: {
    sortedSharingItems() {
      console.log(this.sharingItemsFromServer, 'dsfds');
      return this.sharingItemsFromServer
        .slice()
        .sort(
          (a, b) => new Date(a.CreationDateTime) - new Date(b.CreationDateTime)
        );
    },
    btnSaveColor() {
      return this.isUpdateAvailable ? 'red' : '';
    },
    formattedUpdatedDateTime() {
      if (this.updatedDateTime) {
        return new Date(this.updatedDateTime).toLocaleString();
      } else {
        return '';
      }
    },
    formattedCreationDateTime() {
      if (this.updatedDateTime) {
        return new Date(this.creationDateTime).toLocaleString();
      } else {
        return '';
      }
    }
  },
  watch: {
    syncProgress(newVal) {
      if (newVal >= 100) {
        setTimeout(() => {
          this.syncProgressEnd = true;
        }, 1000);

        // this.syncProgressEnd = true;
      }
    },
    usersToSync: {
      handler() {
        this.checkSyncStatus();
      },
      deep: true
    },
    isOpenedExpander(newVal, oldVal) {
      console.log(newVal, oldVal, 'expitems');
    },
    item() {
      this.isUpdateAvailable = false;
      this.sharingItemsFromServer = this.item?.sharing?.Users.slice() || [];
      this.tempSharingItems = [];
    }
  },
  mounted() {
    console.log(this.item, 'this item sharing');

    this.sharingItemsFromServer = this.item?.sharing?.Users.slice() || [];
  }
};
</script>

<style lang="scss" scoped src="./SharingExpander.scss" />
