var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'board',
      {
        'board--wrong': _vm.wrong
      }
    ]},[_c('div',{staticClass:"board__bg"},[_c('div',{staticClass:"board__bg__item board__bg__item--bordered-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--inner-border-top-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--outer-border-top-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--outer-border-bottom-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--cascade"}),_c('div',{staticClass:"board__bg__item board__bg__item--green-layer",style:({
          background: _vm.bgColor
        })}),_c('div',{staticClass:"board__bg__item board__bg__item--wrong board__bg__item--wrong-layer"}),_c('div',{staticClass:"board__bg__item board__bg__item--inner-shadow"})]),_vm._l((_vm.players),function(player,index){return _c('Position',{key:player.name,ref:"position",refInFor:true,attrs:{"bet":player.rate,"p_bet":player.p_rate ? player.p_rate : 0,"allParticipant":player.participant,"cards":player.cards,"color":_vm.getColor(player.name),"isDealer":player.d,"isMe":player.position === 0,"playerName":player.name,"positionNumber":player.position,"pot":player.bank,"tableSize":player.size,"players":_vm.players,"avatar":_vm.shuffledAvatars[index],"isAvatarMode":_vm.isAvatarMode,"isColoredCards":_vm.isColoredCards},on:{"clickPlayer":_vm.onClickPlayer,"closeOtherItems":_vm.onCloseOtherItems,"contextCallHandTypePicker":_vm.onContextCallHandTypePicker},scopedSlots:_vm._u([(player.isMe)?{key:"timer",fn:function(){return [_vm._t("timer")]},proxy:true}:null],null,true)})}),_c('div',{staticClass:"pot"},[_vm._v("Pot: "+_vm._s(_vm.potNumber))]),(_vm.globalShortDesk)?_c('div',{staticClass:"sub_pot"},[_c('div',{staticClass:"game__player-position__bet__chips",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"game__player-position__bet__chips__item game__player-position__bet__chips__item--aqua",staticStyle:{"display":"block","margin-bottom":"36px"}},_vm._l((_vm.chipsStackSizeIntegerPartShortDesk),function(item){return _c('div',{key:item,staticClass:"game__player-position__bet__chip"})}),0),_c('div',{staticClass:"game__player-position__bet__chips__item game__player-position__bet__chips__item--blue",staticStyle:{"display":"block","margin-bottom":"36px"}},_vm._l((_vm.chipsStackSizeFractionalPartShortDesk),function(item){return _c('div',{key:item,staticClass:"game__player-position__bet__chip"})}),0)]),_c('div',{staticClass:"game__player-position__bet__value"},[_vm._v(" "+_vm._s(_vm.potNumberSumma)+" ")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }