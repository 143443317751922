<template>
  <section class="range-slider">
    <input
      v-model="currentValue"
      :disabled="isReadOnly"
      :max="maxValue"
      :min="minValue"
      :step="step"
      class="range-slider__input"
      type="range"
      @input="valueChanged"
    />
    <div class="range-slider__bar">
      <div
        :style="{
          width: currentValue / 1.69 + '%'
        }"
        class="range-slider__filled"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'RangeSlider',
  data() {
    return {
      currentValue: 0
    };
  },
  props: {
    value: Number,
    maxValue: Number,
    minValue: Number,
    step: Number,
    isReadOnly: Boolean
  },
  computed: {},
  methods: {
    reset() {
      this.currentValue = 0;
    },
    valueChanged() {
      this.$emit('rangeSliderChange', this.currentValue);
      console.log(this.currentValue, 'this current slider');
    },
    getValue() {
      return this.currentValue;
    }
  },
  mounted() {
    this.currentValue = this.value;
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentValue = newVal;
      }
    }
  }
};
</script>
<style lang="scss">
@import './RangeSlider.scss';
</style>
