<template>
  <transition name="fade">
    <div :class="[computedClasses, active ? 'isShow' : '']" v-if="active">
      <div class="game__player-position__stats-tooltip--inner">
        <div class="game__player-position__stats-tooltip__notes">
          {{ notesField }}
        </div>
<!--        <div-->
<!--          class="game__player-position__stats-tooltip__stats"-->
<!--          v-html="filterStats"-->
<!--        ></div>-->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      text: 'STR ывыф 2 13 123',
      playerInfo: this.computedParticipants,
      statsField: '',
      notesField: ''
    };
  },
  props: {
    active: Boolean,
    player: String,
    participant: Object,
    statsIconPosition: String,
    isMe: Boolean,
    isTopPlayer: Boolean
  },

  filters: {
    colorizeNumber(text) {
      console.log(text, 'coming to colorize');
      let regex = /\d+/g;
      let number = (text.match(regex) || []).join(' / ');
      console.log('number regexp', number);
      return number;
    },
    colorizeText(text) {
      let regex = /[a-zA-Z]+[-_]*[a-zA-Z]+/g;
      let str = (text.match(regex) || []).join(' ');
      console.log('text regexp', str);
      return str;
    }
  },
  computed: {
    filterStats() {
      function span(number) {
        return `<span class="game__player-position__stats-tooltip__stats--number">${number}
          </span>`;
      }
      function containsNumber(str) {
        return /\d/.test(str);
      }
      let str = this.statsField.split(' ');
      let output = [];
      str.forEach(item => {
        if (containsNumber(item)) {
          output.push(span(item));
        } else {
          output.push(item);
        }
      });
      return output.join(' ');
    },
    computedClasses() {
      console.log(this.active, 'is Active');
      let classes = ['game__player-position__stats-tooltip'];

      if (this.statsIconPosition === 'left') {
        classes.push('game__player-position__stats-tooltip--left');
      } else {
        classes.push('game__player-position__stats-tooltip--right');
      }
      if (this.isTopPlayer)
        classes.push('game__player-position__stats-tooltip--top');
      if (this.isMe) classes.push('game__player-position__stats-tooltip--me');
      return classes;
    }
  },
  methods: {
    setStatsFields() {
      this.statsField = this.participant.stats;
      this.notesField = this.participant.notes;
    }
  },
  created() {
    this.setStatsFields();
  },
  watch: {
    participant: {
      handler() {
        this.setStatsFields();
      }
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes bounce {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.isShow {
  /* transition: bounce 0.5s ease-in-out; */
  animation: bounce 0.5s 1;
}
</style>
