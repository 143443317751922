var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"main-row"},[_c('div',{class:[
        'ranges-search',
        {
          'hidden-sm': _vm.searchFooterCurrentView !== _vm.searchFooterViewMain,
          'visible-sm': _vm.searchFooterCurrentView === _vm.searchFooterViewMain
        }
      ]},[_c('SearchProperties',{ref:"searchProperties",attrs:{"btnLoading":_vm.btnLoading,"selectedItemsToSearch":_vm.selectedItems,"isActiveDiapason":_vm.items.length > 0},on:{"searchHandle":_vm.onSearchHandle,"refreshDiapasons":_vm.onRefreshDiapasons,"removeItem":_vm.onRemoveItem}})],1),_c('div',{class:[
        'ranges-diapasons',
        {
          'hidden-sm': _vm.searchFooterCurrentView !== _vm.searchFooterViewDiapasons,
          'visible-sm': _vm.searchFooterCurrentView === _vm.searchFooterViewDiapasons
        }
      ]},[(!_vm.items.length && 0)?_c('TableMock'):_c('DiapasonsList',{attrs:{"items":_vm.items},on:{"refreshState":_vm.refreshHotkeyDiapason}})],1)]),_c('SearchViewChanger',{on:{"changeView":_vm.changeRangesFooterView}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }