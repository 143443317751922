<template>
  <div class="auth__tab">
    <router-link
      :class="[
        'auth__tab__item',
        {
          'auth__tab__item--active': $route.name === 'Sign In'
        }
      ]"
      :to="{
        name: 'Sign In'
      }"
    >
      {{ localization.general.FormElements.Login }}
    </router-link>
    <router-link
      :class="[
        'auth__tab__item',
        {
          'auth__tab__item--active': $route.name === 'Sign Up'
        }
      ]"
      :to="{
        name: 'Sign Up'
      }"
    >
      {{ localization.general.FormElements.Registration }}
    </router-link>
  </div>
</template>


<script>
export default {
  name: "AuthBlockToggler"
}
</script>

<style lang="scss" src="../../assets/scss/auth.scss"/>