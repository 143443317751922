<template>
  <Dialog v-model="computedValue" class="add-license-dialog modal-dialog--size-to-content modal-dialog--width-medium">
    <DialogHeader>
      Add License
    </DialogHeader>
    <DialogContent>
      <div class="form-block">
        <label class="form-label-text">
          License ID
        </label>
        <select
          v-model="selectedLicense"
          class="form-input"
          @change="selectLicense()"
        >
          <option
            v-for="descriptor in licenseDescriptors"
            :key="descriptor.id"
            :value="descriptor"
          >
            {{ descriptor.id }}
          </option>
        </select>
      </div>
      <template v-if="selectedLicense">
        <div class="form-block">
          <label>
            <b>Tag:</b>
            {{ selectedLicense.tag }}
          </label>
        </div>
        <div class="form-block">
          <div class="form-group__title">
            <input
              v-model="checkbox.interval"
              type="checkbox"
              @change="toggleCheckboxes('interval')"
            />
            <label>
              Interval
            </label>
          </div>
          <select
            v-model="newLicense.interval"
            :disabled="!checkbox.interval"
            class="form-input"
          >
            <option
              v-for="interval in intervals"
              :key="interval"
              :value="interval"
            >
              {{ interval }}
            </option>
          </select>
        </div>
        <div class="form-block">
          <div class="form-group__title">
            <input
              v-model="checkbox.term"
              type="checkbox"
              @change="toggleCheckboxes('term')"
            />
            <label>
              Term
            </label>
          </div>
          <input
            v-model="newLicense.term"
            :disabled="!checkbox.term"
            class="form-input"
            type="text"
          />
        </div>

        <div class="form-block">
          <div class="form-group__title">
            <input
              v-model="checkbox.price"
              type="checkbox"
              @change="togglePrice()"
            />
            <label>
              Price
            </label>
          </div>
          <input
            v-model="priceForLicense"
            :disabled="!checkbox.price"
            class="form-input"
            type="text"
          />
        </div>
        <div v-if="checkbox.price" class="form-block">
          <label class="form-label-text">
            Source
          </label>
          <select
            v-model="sourceForLicense"
            class="form-input"
          >
            <option
              v-for="source in sources"
              :key="source"
              :value="source"
            >
              {{ source }}
            </option>
          </select>
        </div>
      </template>
    </DialogContent>
    <DialogFooter>
      <Btn
        outlined
        @click="closeModal()"
      >
        {{ localization.general.Simple.Cancel }}
      </Btn>
      <Btn
        @click="addLicense()"
      >
        Create
      </Btn>


    </DialogFooter>
  </Dialog>
</template>

<script>
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "@/components/UI/Dialog";

import {GET_LICENSE_DESCRIPTORS} from '@/api/licenseDescriptors';
import Btn from "@/components/UI/Btn";

export default {
  name: "AddLicenseDialog",
  data() {
    return {
      licenseDescriptors: [],

      sources: ['Fastspring', 'Other'],
      intervals: ['Year', 'Quarter', 'Month', 'Day'],

      checkbox: {
        interval: false,
        term: false,
        price: true
      },

      selectedLicense: null,
      priceForLicense: null,
      sourceForLicense: "Other",
      newLicense: {
        licenseId: null,
        interval: null,
        term: null,
        purchaseInfo: {
          cartId: null,
          cartItemId: null,
          price: {
            value: this.priceForLicense,
            discount: null
          },
          source: this.sourceForLicense
        },
      }
    }
  },
  components: {Btn, Dialog, DialogContent, DialogFooter, DialogHeader},
  props: {
    value: Boolean,
    userId: String
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    togglePrice() {
      if (this.checkbox.price) {
        this.newLicense.purchaseInfo = {
          cartId: null,
          cartItemId: null,
          price: {
            value: this.priceForLicense,
            discount: null
          },
          source: this.sourceForLicense
        }
      } else {
        this.newLicense.purchaseInfo = null;
      }

    },

    selectLicense() {
      this.priceForLicense = this.selectedLicense.price;
      this.newLicense.licenseId = this.selectedLicense.id;
      this.newLicense.term = this.selectedLicense.term;
      this.newLicense.interval = this.selectedLicense.interval;
    },
    toggleCheckboxes(type) {
      if (!this.checkbox[type]) {
        this.newLicense[type] = this.selectedLicense[type];
      }
    },

    async addLicense() {
      /* Validation */
      if (!this.selectedLicense) {
        this.$toast.error('License Descriptor was not selected')
        return
      }
      if (this.checkbox.price && !this.priceForLicense) {
        this.$toast.error('Price was not set')
        return
      }


      /* Pass price values & emit */
      if (this.checkbox.price) {
        this.newLicense.purchaseInfo.price.value = this.priceForLicense;
        this.newLicense.purchaseInfo.source = this.sourceForLicense;
      }

      this.$emit('licenseCreate', {
        newLicense: this.newLicense,
        tag: this.selectedLicense.tag
      });

      this.closeModal();
    },

    closeModal() {
      this.$emit('input', false);
    }
  },
  async created() {
    this.licenseDescriptors = await GET_LICENSE_DESCRIPTORS();
    this.newLicense.userId = this.userId;
  }
}
</script>
<style lang="scss" src="./AddLicenseDialog.scss">
</style>