<template>
  <div>
    <h2 class="admin-title">
      {{ localization.general.Profile.Users }}
    </h2>

    <div class="c-card">
      <div class="filter-block">
        <div class="form-block filter-block__item filter-block__item-w--100">
          <label class="form-label-text">
            {{ localization.general.Profile.SearchUser }}
          </label>
          <input
            v-model="email"
            class="form-input"
            placeholder="Email"
            type="text"
            @keydown.enter="search"
          />
        </div>

      </div>

      <img
        v-if="!ready"
        class="loading-gif"
        src="/img/loading.gif"
      />
      <template v-else>
        <table class="c-simple-table c-simple-table--tr-is-link">
          <thead>
          <tr>
            <th style="width: 200px">
              {{ localization.general.Profile.ColumnId }}
            </th>
            <th>
              {{ localization.general.Profile.ColumnEmail }}
            </th>
            <th>
              {{
                localization.general.Profile
                  .ColumnRegistrationDateTime
              }}
            </th>
            <th>
              {{
                localization.general.Profile
                  .ColumnLastAccessDateTime
              }}
            </th>
          </tr>
          </thead>
          <tbody>
          <router-link
            v-for="(user, index) in users"
            :key="user.id"
            :class="[
              'user',
              {
                pair: !!(index % 2)
              }
            ]"
            :to="{
              name: 'Admin Users Single',
              params: {
                id: user.id
              }
            }"
            tag="tr"
          >
            <td>{{ user.id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ new Date(user.registrationDateTime).toLocaleString() }}</td>
            <td>{{ new Date(user.lastAccessDateTime).toLocaleString() }}</td>

          </router-link>
          </tbody>
        </table>
        <div
          v-if="users.length === 0"
          class="c-alert c-alert--warning"
        >
          Result is empty
        </div>
      </template>
      <Pagination
        v-model="activePage"
        :total="maxPage"
        @input="changePage(activePage)"
      />

    </div>

  </div>
</template>

<script>

import {GET_USERS, SEARCH_USER} from "@/api/users";
import Pagination from "@/components/UI/Pagination/Pagination";

export default {
  name: 'AdminUsersIndex',
  components: {Pagination},

  data() {
    return {
      userSubscriptions: [],
      users: [],
      ready: false,

      activePage: 0,
      maxPage: 1,
      itemsPerPage: 25,
      email: '',
      searchResult: null,
    };
  },
  methods: {
    nextPage() {
      this.changePage(this.activePage + 1);
    },
    prevPage() {
      this.changePage(this.activePage - 1);
    },
    changePage(newPage) {
      this.getUsers(newPage, this.itemsPerPage)
      this.$router.push({
        name: this.$route.name,
        query: {
          page: newPage
        }
      })
    },
    async getUsers(pageNumber, itemsPerPage) {
      this.ready = false;

      const data = await GET_USERS(pageNumber, itemsPerPage);
      this.users = data.data;
      this.activePage = data.pageNumber;
      this.maxPage = data.pagesCount;

      this.ready = true;
    },
    async search() {
      if (this.email) {
        this.users = [
          await SEARCH_USER({
            email: this.email
          })
        ]
      } else {
        await this.getUsers(this.activePage ?? 0, this.itemsPerPage);
      }

    },

  },
  created() {
    if (this.$route.query.page) this.activePage = parseInt(this.$route.query.page);
  },
  mounted() {
    this.getUsers(this.activePage, this.itemsPerPage);
  },
};
</script>

