import http from "../utils/http";

export const GET_LICENSE_DESCRIPTORS = async () => {
  const {data} = await http.get('/LicenseDescriptors');
  return data;
};
export const UPDATE_LICENSE_DESCRIPTOR = async DTO => {
  await http.put('/LicenseDescriptors', DTO);
};
export const CREATE_LICENSE_DESCRIPTOR = async DTO => {
  const {data} = await http.post('/LicenseDescriptors', DTO);
  return data;
};