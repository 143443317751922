import http from "../utils/http";

export const GET_USERS = async (pageNumber, itemsPerPage) => {
  const {data} = await http.get(`Users`, {
    params: {
      pageNumber, itemsPerPage
    }
  })
  return data;
};
export const SEARCH_USER = async params => {
  const {data} = await http.get('Users/Search', {
    params
  });
  return data;
};
export const GET_USER = async id => {
  const {data} = await http.get(`Users/${id}`);
  return data;
};
export const GET_AUTH_USER_INFO = async () => {
  return await http.get('Users/Me');
}