<template>
  <Dialog
    ref="dialog"
    v-model="computedValue"
    class="game-help-dialog modal-dialog--width-large modal-dialog--height-large"
  >
    <DialogHeader>
      {{ localization.general.Game.Help }}
    </DialogHeader>
    <DialogContent ref="dialogContent" class="game-help-dialog__content">
      <div v-html="helpContent" />
    </DialogContent>
    <DialogFooter
      :class="[
        'game-help-dialog__footer',
        {
          'game-help-dialog__footer--has-scroll': hasScroll
        }
      ]"
    >
      <div class="game-help-dialog__action__buttons">
        <div class="game-help-dialog__mode">
          <div class="game-help-dialog__mode__label">
            {{ localization.general.GlobalPreferences.Parameters }}
          </div>
          <Select
            v-model="gameMode"
            :height="48"
            :items="gameModes"
            :width="290"
            background-color="white"
            class="game-help-dialog__mode__select"
            highlighted-text
            rounded
            show-from-top
          />
        </div>

        <Btn
          :loading="updateGameModeRequestPending"
          class="game-help-dialog__button"
          @click="save"
        >
          {{ localization.general.Simple.Ok }}
        </Btn>
      </div>
    </DialogFooter>
  </Dialog>
</template>

<script>
import { UPDATE_GLOBAL_PREFERENCES } from '@/api/globalPreferences';

import marked from 'marked';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/UI/Dialog';
import Select from '@/components/UI/Select/Select';
import Btn from '@/components/UI/Btn';
import { mapGetters } from 'vuex';

export default {
  name: 'GameHelpDialog',
  components: {
    Btn,
    Select,
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader
  },
  data() {
    return {
      newGroupName: '',
      initialGameMode: '',
      helpContent: '',
      gameMode: '',
      userData: null,
      hasScroll: true,

      updateGameModeRequestPending: false
    };
  },
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      globalPreferences: 'user/globalPreferences'
    }),
    gameModes() {
      return [
        {
          label: this.localization.general.GlobalPreferences.Simple,
          value: 'Simple'
        },
        {
          label: this.localization.general.GlobalPreferences.Randomized,
          value: 'Randomized'
        },
        {
          label: this.localization.general.GlobalPreferences.Advanced,
          value: 'Advanced'
        }
      ];
    },
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    async save() {
      this.updateGameModeRequestPending = true;

      if (this.globalPreferences.gameMode !== this.gameMode) {
        this.globalPreferences.gameMode = this.gameMode;

        await UPDATE_GLOBAL_PREFERENCES(this.globalPreferences);
        this.$emit('changeGameMode', this.gameMode);
      }
      this.updateGameModeRequestPending = false;
      this.$refs.dialog.closeModal();
    },
    getHelpContent() {
      console.log(this.localization.faq, 'LOCALIZATION PRICES');
      // let res = this.localization.gameHelp;
      let res = this.localization.faq.GameMode.content;
      // let finalText = res.text;
      // console.log(finalText, 'final text');
      this.helpContent = marked(res);
    }
  },
  mounted() {
    this.getHelpContent();

    this.$nextTick(() => {
      let dialogContent = this.$refs.dialogContent.$el;

      dialogContent.addEventListener('scroll', () => {
        let scrolledHeight =
          dialogContent.clientHeight + dialogContent.scrollTop;
        let fullHeight = dialogContent.scrollHeight;

        this.hasScroll = fullHeight > scrolledHeight;
      });
    });
  },
  async created() {
    this.gameMode = this.globalPreferences.gameMode;
    console.log('get blobal prefs: from GHD');
    //Old
    // this.userData = await GET_GLOBAL_PREFERENCES();
  }
};
</script>
<style lang="scss" src="./GameHelpDialog.scss" />
