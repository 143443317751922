<template>
  <div>
    <ProgressLinear class="list-loading" v-if="!loaded" />
    <div v-else class="list">
      <div
        class="list__top"
        @dragleave="onTopDragLeave()"
        @dragover="onTopDragOver($event)"
        @drop="onDropToTop()"
      >
        <hr v-show="dragOnTop" class="list__item__card__hr" />
      </div>
      <transition-group class="list__column" name="list" tag="div">
        <ListItem
          v-for="item in computedItems"
          :key="item.value.id"
          :allowDrop="allowDrop"
          :currentItem="currentItem"
          :disableDragOnIconMouseLeave="disableDragOnIconMouseLeave"
          :dragFromItem="dragFromItem"
          :draggable="draggable"
          :enableDragOnIconHover="enableDragOnIconHover"
          :item="item"
          :multipleSelect="multipleSelect"
          :onCardDragOver="onCardDragOver"
          :onCardLeave="onCardLeave"
          :onDrag="onDrag"
          :onDragEnd="onDragEnd"
          :onDragStart="onDragStart"
          :onDrop="onDrop"
          :onDropToBottomOfParent="onDropToBottomOfParent"
          :onItemCheck="onItemCheck"
          :onItemClick="onItemClick"
          :onParentBottomDragLeave="onParentBottomDragLeave"
          :onParentBottomDragOver="onParentBottomDragOver"
          :selectedItems="selectedItems"
          :sortFunction="sortFunction"
        >
          <template v-slot:card-outer="slotItem">
            <slot v-bind="slotItem" name="card-outer" />
          </template>
          <template v-slot:card-inner="slotItem">
            <slot v-bind="slotItem" name="card-inner" />
          </template>
          <template v-slot:card-body-content="slotItem">
            <slot v-bind="slotItem" name="card-body-content" />
          </template>
        </ListItem>
      </transition-group>
    </div>
  </div>
</template>

<script>
import ListItem from '@/components/UI/List/ListItem';
import { ITEM_TYPE_GROUP, ITEM_TYPE_RANGE } from '@/constants/itemTypes';
import { DEFAULT_PARENT_ID } from '@/constants/items';
import deepClone from '@/utils/dataManipulations';
import ProgressLinear from '@/components/UI/ProgressLinear/ProgressLinear';
import { mapGetters } from 'vuex';

import { GET_HANDS_STORIES } from '@/api/items';

export default {
  name: 'List',
  components: { ProgressLinear, ListItem },
  data() {
    return {
      DEPTH_LENGTH_ITEM_HAS_GRANDPARENT: 2,
      dragOnTop: false,
      items: [],
      depth: 0,

      currentItem: null,
      selectedItems: [],

      oldParentItem: null,
      dragFromItem: null,

      loaded: false
    };
  },
  props: {
    premiumEditor: {
      type: Boolean,
      default: false
    },
    setItemGroupStatistics: {
      type: Function
    },
    itemsToUnlink: {
      type: Function
    },
    nextItem: {
      type: Object
    },
    openSaveChangesDialog: {
      type: Function
    },
    isUpdateAvailable: {
      type: Boolean,
      default: false
    },
    multipleSelect: {
      type: Boolean,
      default: true
    },
    draggable: {
      type: Boolean,
      default: true
    },
    childrenProvider: Function,
    sortFunction: {
      type: Function,
      default: items => {
        return items.sort((a, b) => {
          let aType = a.value.type;
          let bType = b.value.type;

          let aName = a.value.name;
          let bName = b.value.name;
          if (aType === ITEM_TYPE_GROUP) {
            if (aType === bType) {
              return aName.localeCompare(bName);
            } else {
              return -1;
            }
          }

          if (aType === ITEM_TYPE_RANGE) {
            if (aType === bType) {
              return aName.localeCompare(bName);
            } else {
              return 0;
            }
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedItemsFromStore: 'items/getSelectedItems'
    }),
    computedItems() {
      return this.sortFunction(this.items);
    },
    currentItemIsGroup() {
      return this.currentItem?.value?.type === ITEM_TYPE_GROUP;
    },
    currentItemIsRange() {
      return this.currentItem?.value?.type === ITEM_TYPE_RANGE;
    },
    currentItemIsInRoot() {
      return this.currentItem?.value?.parentId === DEFAULT_PARENT_ID;
    }
  },
  methods: {
    reformatArrayToObject(hands) {
      const reformattedObject = {};
      if (hands){
        for (const entry of hands) {
          const { handKey, correct, incorrect, time } = entry;
          reformattedObject[handKey] = { correct, incorrect, time };
        }
      }

      return reformattedObject;
    },
    checkAllUnlincableGroupItems(grandParentItem, currentItem) {
      let items = [];

      items.push(grandParentItem.value.id);

      const findChildItems = item => {
        if (item.children && item.children.length > 0) {
          for (const child of item.children) {
            if (child.value.type === ITEM_TYPE_GROUP) {
              items.push(child.value.id);
              findChildItems(child);
            }
          }
        }
      };

      const findParentItems = item => {
        if (item.value.parentId !== DEFAULT_PARENT_ID) {
          let parent = this.findItemById(this.items, item.value.parentId);

          if (parent) {
            items.push(parent.value.id);
          }
          findParentItems(parent);
        }
      };

      findChildItems(currentItem);
      findParentItems(currentItem);

      return items;
    },

    findGrandParentRecursively(itemToSearch) {
      let grandParent = null;
      const find = item => {
        if (item.value.parentId != DEFAULT_PARENT_ID) {
          let parent = this.findItemById(this.items, item.value.parentId);
          console.log(parent, item, 'recursive check');
          find(parent);
        } else {
          console.log(item, 'item in recs2');
          grandParent = item;
        }
      };

      find(itemToSearch);

      return grandParent;
    },
    checkItemsFromStore() {
      const storeItems = this.$store.state.items?.selectedItemsToSearch;
      storeItems.forEach(obj => {
        const matchingItem = this.items.find(
          item => item.value.id === obj.value.id
        );
        console.log(matchingItem, 'matching item');
        if (matchingItem) {
          this.onItemCheck(matchingItem);
        }
      });
    },
    onTopDragOver(e) {
      e.preventDefault();
      this.dragOnTop = true;
    },
    onTopDragLeave() {
      this.dragOnTop = false;
    },

    makeItem(item) {
      return item.type === ITEM_TYPE_GROUP
        ? this.makeGroupItem(item)
        : this.makeRangeItem(item);
    },
    makeGroupItem(group) {
      const functionForChildren = group.hasOwnProperty('childrenProvider')
        ? () => this.loadFn(group.childrenProvider())
        : () => this.loadFn(this.childrenProvider(group));

      return {
        value: group,
        childrenProvider: functionForChildren,
        children: [],
        dragAndDropIn: group.hasOwnProperty('dragAndDropIn')
          ? group.dragAndDropIn
          : true,
        dragAndDropOut: group.hasOwnProperty('dragAndDropOut')
          ? group.dragAndDropOut
          : true,
        loading: false,
        isOpened: false,
        showBottomHr: false,
        showHr: false,
        hasChildSelected: false, // true, if at least one element, and not all elements are selected
        allChildrenChecked: false, // true, if all elements selected
        showDragEffect: false
      };
    },
    makeRangeItem(diapason) {
      return {
        children: null,
        childrenProvider: null,
        value: diapason,
        dragAndDropIn: diapason.hasOwnProperty('dragAndDropIn')
          ? diapason.dragAndDropIn
          : true,
        dragAndDropOut: diapason.hasOwnProperty('dragAndDropOut')
          ? diapason.dragAndDropOut
          : true,
        isOpened: false,
        showHr: false,
        showDragEffect: false
      };
    },

    async loadFn(fn) {
      const items = await fn;

      return items.map(item => this.makeItem(item));
    },
    async refresh() {
      this.items = await this.loadFn(this.childrenProvider());
    },
    async refreshParent() {
      if (this.currentItem.value.parentId === DEFAULT_PARENT_ID) {
        this.items = await this.loadFn(this.childrenProvider());
      }

      const item = this.findItemById(
        this.items,
        this.currentItem.value.parentId
      );
      item.children = await item.childrenProvider();
      item.isOpened = true;
    },

    setNameForCurrentItem(value) {
      this.currentItem.value.name = value;
    },

    // CHECKBOX METHODS
    async onItemCheck(item) {
      console.log(item, 'item');
      // console.log(this.$store, 'store after');
      let itemIsChecked = this.selectedItems.some(
        selectedItem => selectedItem.value.id === item.value.id
      );
      if (!itemIsChecked) {
        this.checkItem(item);
      } else {
        this.uncheckItem(item);
      }

      const parentId = item.value.parentId;

      if (parentId !== DEFAULT_PARENT_ID) {
        let parentOfItem = this.findItemById(this.items, parentId);

        console.log(parentOfItem, 'parent of Item');
        this.checkIfAllChildrenChecked(parentOfItem);
        this.checkIfChildHasChecked(parentOfItem);
      }

      const uncheckChildItem = item => {
        console.log(item, 'item in uncheckChildItem');
        if (item.children) {
          item.children.forEach(child => {
            console.log('uncheckChildItem', child, child.value.name);
            child.hasChildSelected = false;
            uncheckChildItem(child);
          });
        }
      };

      if (item.value.type === ITEM_TYPE_GROUP) {
        console.log(item, 'item in check ccheckbox');
        //Фикс бага с бордером и галочкой(иногда не отчекивается при чек/анчек парента и айтемов во вложенной группе)
        uncheckChildItem(item);
        // item.allChildrenChecked = true;
        item.hasChildSelected = false;
      }
      this.trackGrandParentItem(item.value.parentId);
      this.emitSelectedChange();
    },
    uncheckItem(item) {
      let allGroupIDsForChildrenUncheck = [item.value.id];

      //Uncheck for GROUPS childrens
      if (item.value.type === ITEM_TYPE_GROUP) {
        item.allChildrenChecked = false;
        item.hasChildSelected = false;
        let findIDsForUncheck = item => {
          let children = item.isOpened
            ? item.children
            : this.selectedItems.filter(
                selectedItem => selectedItem.value.parentId === item.value.id
              );

          children
            .filter(child => child.value.type === ITEM_TYPE_GROUP)
            .forEach(child => {
              allGroupIDsForChildrenUncheck.push(child.value.id);
              findIDsForUncheck(child);
            });
        };

        findIDsForUncheck(item);
      }

      this.selectedItems = this.selectedItems.filter(selectedItem => {
        return (
          !allGroupIDsForChildrenUncheck.includes(
            selectedItem.value.parentId
          ) && selectedItem.value.id !== item.value.id
        );
      });
      this.$store.commit('items/SET_SELECTED_ITEMS', this.selectedItems);
    },

    checkItem(item) {
      console.log('checks');
      this.selectedItems.push(item);
      if (item.value.type === ITEM_TYPE_GROUP && item.isOpened) {
        this.pushItemChildrenToSelectedItems(item);

        item.allChildrenChecked = true;
        item.hasChildSelected = false;
      }
      this.$store.commit('items/SET_SELECTED_ITEMS', this.selectedItems);

      console.log(this.$store, 'store after');
    },
    trackGrandParentItem(parentId) {
      const checkItemHierarchy = itemId => {
        let item = this.findItemById(this.items, itemId);
        if (!item) return;
        this.checkIfAllChildrenChecked(item);
        this.checkIfChildHasChecked(item);

        let parentItem = this.findItemById(this.items, item.value.parentId);
        if (!parentItem || parentItem.value.parentId === DEFAULT_PARENT_ID) {
          this.checkIfAllChildrenChecked(parentItem);
          this.checkIfChildHasChecked(parentItem);

          console.log('debugsh', parentItem, parentItem?.value?.name);
          return;
        }

        this.checkIfAllChildrenChecked(parentItem);
        this.checkIfChildHasChecked(parentItem);

        checkItemHierarchy(parentItem.value.parentId);
      };

      checkItemHierarchy(parentId);

      //TODO: to fix deep checkmark
    },
    checkIfAllChildrenChecked(parentItem) {
      console.log(parentItem, 'PARENT ITEM');
      console.log(parentItem.children, 'PARENT ITEM: CHIldrens');
      if (!parentItem || !Array.isArray(parentItem.children)) {
        console.log('Invalid parent item or children array.');
        return;
      }
      console.log(
        parentItem.children.every(child => {
          console.log(child, 'child in parent check');
          // return child.value.id === this.selectedItems.
          this.itemIsChecked(child);
        }),
        'parent is true checked?'
      );
      parentItem.allChildrenChecked = parentItem.children.every(child => {
        console.log(child, 'child in parent check');
        // return this.selectedItems.some(
        //   selectedItem => selectedItem.value.id === child.value.id
        // );
        return this.itemIsChecked(child);
      });

      if (parentItem.allChildrenChecked) {
        this.selectedItems.push(parentItem);
      } else {
        let parentItemId = parentItem.value.id;
        if (parentItemId !== DEFAULT_PARENT_ID) {
          let indexOfParentItemForDeleting = this.selectedItems.findIndex(
            selectedItem => selectedItem.value.id === parentItemId
          );

          if (indexOfParentItemForDeleting === -1) return;
          this.selectedItems.splice(indexOfParentItemForDeleting, 1);
        }
      }
    },

    recursionCheckChild(grandPa) {
      let hasChecked = false;
      console.log(grandPa, 'grandPa?');

      if (!grandPa) return;

      grandPa.children.map(child => {
        console.log(child, child.value.name, 'child recursion');
        if (child?.hasChildSelected || grandPa.hasChildSelected) {
          hasChecked = true;
          return hasChecked;
        }
        if (child.children) this.recursionCheckChild(child);
      });
      return hasChecked;
    },

    checkIfChildHasChecked(parentItem) {
      if (!parentItem) return;
      let childCheck = this.recursionCheckChild(parentItem);
      console.log(childCheck, parentItem, 'childCheck');

      parentItem.hasChildSelected =
        parentItem.children.some(
          child =>
            this.itemIsChecked(child) ||
            (child.hasChildSelected && !child.allChildrenChecked)
        ) && !parentItem.allChildrenChecked;

      if (parentItem.value.parentId === DEFAULT_PARENT_ID) {
        console.log(parentItem, 'prttt');
        parentItem.hasChildSelected = this.recursionCheckChild(parentItem);
      }
    },
    // END CHECKBOXES FUNCTIONS

    /* API OF COMPONENT */
    setCurrentItemByID(itemID) {
      console.log('CURRENT ITEM MUTATION: setCurrentItemByID');
      this.currentItem = this.findItemById(this.items, itemID);
    },
    getValuesOfItems(items) {
      return items.map(item => item.value);
    },
    refreshStatToChildren(item) {
      let emptyStats = { correct: 0, incorrect: 0, time: 0 };
      if (item.children) {
        item.children.forEach(children => {
          console.log(children, 'child recurssz');
          children.value.statistics = emptyStats;
          if (children.children) this.refreshStatToChildren(children);
        });
      }
    },
    refreshStatsToParent(item, statsToRecalculate) {
      console.log(item, 'item to refresh parent');
      if (item.value.parentId != DEFAULT_PARENT_ID) {
        let parent = this.findItemById(this.items, item.value.parentId);
        console.log(parent, 'parent in recursion');
        parent.value.statistics = {
          correct:
            parent.value?.statistics.correct - statsToRecalculate.correct,
          incorrect:
            parent.value?.statistics.incorrect - statsToRecalculate.incorrect,
          time: parent?.value.statistics.time - statsToRecalculate.time
        };
        this.refreshStatsToParent(parent, statsToRecalculate);
      }
    },
    refreshStats(items) {
      console.log('GROUP ITEMS TO REFRESH', items);
      let emptyStats = { correct: 0, incorrect: 0, time: 0 };
      //записываем статистику чтобы минусануть у групп сверху
      let stats = this.currentItem.value.statistics;

      console.log(this.currentItem, 'thiscure');
      //Первая рекурсия отвечает за перебор всех чилдренов и обнуление статистики
      this.refreshStatToChildren(this.currentItem);

      this.refreshStatsToParent(this.currentItem, stats);
      console.log(stats, 'currange list stats');
      this.currentItem.value.statistics = emptyStats;
      console.log(this.items, 'this.items on clear stattts');
    },
    getItemsOfCurrent() {
      let items = [];

      if (
        !this.currentItem ||
        (this.currentItemIsRange && this.currentItemIsInRoot)
      ) {
        items = this.items;
      } else if (this.currentItemIsGroup) {
        items = this.currentItem.children;
      } else if (this.currentItemIsRange && !this.currentItemIsInRoot) {
        const parent = this.findItemById(
          this.items,
          this.currentItem.value.parentId
        );
        items = parent.children;
      }

      return this.getValuesOfItems(items);
    },
    setItems(items) {
      this.items = items;
    },
    /* END API OF COMPONENT */

    async onItemClick(item) {
      if (item.loading) return;

      const clickedSameItem = this.currentItem?.value?.id === item.value.id;
      if (this.isUpdateAvailable) {
        this.$emit('openSaveChangesDialog', item);
        return;
      }
      if (clickedSameItem) {
        this.closeItem(item);
      } else {
        await this.openItem(item);
      }
      this.emitCurrentChange();
    },
    closeItem(item) {
      this.currentItem = null;

      item.isOpened = false;
      if (item.value.type === ITEM_TYPE_GROUP) {
        item.children = [];
      }
    },

    async openItem(item) {
      console.log(item, 'item in open item');
      this.currentItem = item;
      console.log(this.currentItem, 'CURRENT ITEM MUTATION: openItem 1 ');
      this.$emit('groupOpenItem', this.currentItem);

      if (item.value.type === ITEM_TYPE_GROUP) {
        let childRanges = [];
        let allRangesInGroup = [];

        if (!item.isOpened) {
          item.loading = true;
          item.children = await item.childrenProvider();

          // item.value.diapason.statistics.hands = itemsStatistics;
          console.log('this.premiumEditor')
          if (!this.premiumEditor) {
            console.log('this.premiumEditor: ')
            console.log(this.premiumEditor)
            console.log('item.value.id')
            console.log(item.value.id)
            const itemsStatistics = await GET_HANDS_STORIES({
              itemId: item.value.id
            });

            console.log(itemsStatistics, 'itemsstat on open');
            console.log('itemsStatistics!!!: ')
            console.log(itemsStatistics)
            console.log(item, 'item on opens');
            if (item.value.diapason !== null) {
              item.value.diapason.statistics.hands = this.reformatArrayToObject(itemsStatistics);
            } else {
              // Handle the case when diapason is null
              console.log("diapason is null");
            }

          }

          // this.$emit('setItemGroupStatistics', itemsStatistics);
          //Рекурсия по всем ренжам в группах
          // await this.listRecursion(item, childRanges);
          item.loading = false;
        }
        let itemParent = this.findGrandParentRecursively(item);

        let allItemsToUnlinck = this.checkAllUnlincableGroupItems(
          itemParent,
          item
        );

        this.$emit('itemsToUnlink', allItemsToUnlinck);

        if (this.itemIsChecked(item)) {
          this.pushItemChildrenToSelectedItems(item);
        }
        item.children.forEach(function(child) {
          if (child.value.type === ITEM_TYPE_RANGE) {
            childRanges.push(child.value);
          }
          allRangesInGroup.push(child.value);
        });
        this.$emit('groupOpenWithAllRanges', allRangesInGroup);
        this.$emit('groupOpen', childRanges);
      }
      item.isOpened = true;
      console.log(this.currentItem, 'CURRENT ITEM MUTATION: openItem 2 ');
    },

    pushItemChildrenToSelectedItems(item) {
      let pushItemChildrenToSelectedItems = item => {
        item.children.forEach(child => {
          if (!this.itemIsChecked(child)) {
            this.selectedItems.push(child);
          }

          // call function recursively if child item is group
          if (child.value.type === ITEM_TYPE_GROUP) {
            pushItemChildrenToSelectedItems(child);
          }
        });
      };
      pushItemChildrenToSelectedItems(item);
    },

    addData(newItem) {
      console.log(newItem, 'NEW ITEM F');
      const modifiedItem = this.makeItem(newItem);

      let currentItemIsGroup =
        this.currentItem?.value?.type === ITEM_TYPE_GROUP;
      if (
        !this.currentItem ||
        (!currentItemIsGroup &&
          this.currentItem.value.parentId === DEFAULT_PARENT_ID)
      ) {
        /* IF CURRENT ITEM IS DEFAULT OR NOTHING SELECTED - PUSH TO FIRST PARENT TREE  */
        console.log('addData 1');
        this.items.push(modifiedItem);
      } else if (currentItemIsGroup && this.currentItem.isOpened) {
        /* IF CURRENT ITEM IS GROUP - PUSH TO IT"S CHILDREN */
        console.log('addData 2');
        this.currentItem.children.push(modifiedItem);
      } else {
        /* IF CURRENT ITEM IS SIMPLE ITEM - PUSH TO IT'S PARENT */
        console.log('addData 3');
        const foundParent = this.findItemById(
          this.items,
          this.currentItem.value.parentId
        );
        foundParent.children.push(modifiedItem);
      }

      this.onItemClick(modifiedItem);

      if (currentItemIsGroup) {
        console.log('addData 4');
        this.$emit('groupOpen', []);
      }
      console.log(currentItemIsGroup, 'NEW ITEM S');
      this.emitCurrentChange();
    },

    /* ITEM DRAG FUNCTIONS */
    onDragStart(item) {
      this.dragFromItem = item;
    },
    onDragEnd() {
      this.dragFromItem = null;
    },
    onDropToTop() {
      // DEEP CLONE DRAGGED ITEM
      let movedItem = deepClone(this.dragFromItem);
      movedItem.childrenProvider = this.dragFromItem.childrenProvider;
      this.dragFromItem = null;
      this.dragOnTop = false;

      // AVOID FUNCTION RUN IF DROPPED TO IT's CHILDREN ITEMS
      if (movedItem.value.id === this.items[0].value.id) return;

      // GET DRAGGED ITEM PARENT BEFORE MOVING
      let oldParentData = this.findItemById(
        this.items,
        movedItem.value.parentId
      );

      // IF GROUP IS OPENED, THEN DROP ITEM INSIDE GROUP
      // ELSE, GET ITEM'S PARENT ID, AND SET DRAGGED ITEM'S EQUAL TO IT

      this.deleteItemById(this.items, movedItem.value.id);

      movedItem.value.parentId = DEFAULT_PARENT_ID;

      this.items.unshift(movedItem);

      this.$emit('itemParentChange', {
        droppedItem: movedItem.value,
        toItem: {
          type: 'Main'
        },

        newParentChildren: this.getValuesOfItems(this.items),
        newParent: null,

        oldParent: oldParentData ? oldParentData.value : null
      });
    },
    onDropToBottomOfParent(toParent) {
      // DEEP CLONE DRAGGED ITEM
      let movedItem = deepClone(this.dragFromItem);
      movedItem.childrenProvider = this.dragFromItem.childrenProvider;

      this.dragFromItem = null;
      toParent.showBottomHr = false;

      // AVOID FUNCTION RUN IF DROPPED TO IT's CHILDREN ITEMS
      if (movedItem.value.id === this.items[0].value.id) return;

      // GET DRAGGED ITEM PARENT BEFORE MOVING
      let oldParentData = this.findItemById(
        this.items,
        movedItem.value.parentId
      );

      // IF GROUP IS OPENED, THEN DROP ITEM INSIDE GROUP
      // ELSE, GET ITEM'S PARENT ID, AND SET DRAGGED ITEM'S EQUAL TO IT
      let newParent = {};

      this.deleteItemById(this.items, movedItem.value.id);

      movedItem.value.parentId = toParent.value.parentId;
      if (toParent.value.parentId === DEFAULT_PARENT_ID) {
        this.items.unshift(movedItem);
      } else {
        newParent = this.findItemById(this.items, toParent.value.parentId);
        newParent.children.unshift(movedItem);
      }

      const children = newParent.children
        ? this.getValuesOfItems(newParent.children)
        : this.getValuesOfItems(this.items);

      this.$emit('itemParentChange', {
        droppedItem: movedItem.value,
        toItem: toParent.value,

        newParentChildren: children,
        newParent: newParent.value,

        oldParent: oldParentData ? oldParentData.value : null
      });
    },
    onDrop(toItem) {
      // DEEP CLONE DRAGGED ITEM
      let movedItem = JSON.parse(JSON.stringify(this.dragFromItem));
      movedItem.childrenProvider = this.dragFromItem.childrenProvider;
      this.dragFromItem = null;

      // HIDE BLUE DROP INDICATOR ON DROP
      toItem.showHr = false;

      // DONT RUN FUNCTION IF DROPPED TO DRAGGED ELEMENTS POSITION
      if (toItem.value.id === movedItem.value.id) return;

      // DONT RUN FUNCTION IF DROPPED TO IT's CHILDREN ITEMS
      if (movedItem.value.id === toItem.value.parentId) return;

      // DONT RUN FUNCTION IF DRAG & DROP FOR ITEM IS DISABLED
      if (!toItem.dragAndDropIn) return;

      // GET DRAGGED ITEM PARENT BEFORE MOVING
      let oldParentData = this.findItemById(
        this.items,
        movedItem.value.parentId
      );

      let newParent = {};
      // IF GROUP IS OPENED, THEN DROP ITEM INSIDE GROUP
      // ELSE, GET ITEM'S PARENT ID, AND SET DRAGGED ITEM'S EQUAL TO IT

      this.deleteItemById(this.items, movedItem.value.id);

      if (toItem.value.type === ITEM_TYPE_GROUP && toItem.isOpened) {
        movedItem.value.parentId = toItem.value.id;
        toItem.children.unshift(movedItem);
        newParent = toItem;
      } else {
        movedItem.value.parentId = toItem.value.parentId;
        if (toItem.value.parentId === DEFAULT_PARENT_ID) {
          this.items.unshift(movedItem);
        } else {
          newParent = this.findItemById(this.items, toItem.value.parentId);
          newParent.children.unshift(movedItem);
        }
      }
      const children = newParent.children
        ? this.getValuesOfItems(newParent.children)
        : this.getValuesOfItems(this.items);

      this.$emit('itemParentChange', {
        droppedItem: movedItem.value,
        toItem: toItem.value,
        groupOpened: toItem.isOpened,

        newParentChildren: children,
        newParent: newParent.value,

        oldParent: oldParentData ? oldParentData.value : null
      });
    },
    onDrag(e) {
      e.preventDefault();
    },
    allowDrop(e) {
      e.preventDefault();
    },
    enableDragOnIconHover(item) {
      if (item.dragAndDropOut) {
        item.showDragEffect = true;
      }
    },
    disableDragOnIconMouseLeave(item) {
      item.showDragEffect = false;
    },
    dragLeave(item) {
      return !item.dragAndDropOut;
    },
    onParentBottomDragOver(e, item) {
      if (item.dragAndDropIn) {
        e.preventDefault();
      }

      if (this.dragFromItem.value.id === item.value.parentId) return;

      item.showBottomHr = item.dragAndDropIn;
    },

    onParentBottomDragLeave(item) {
      item.showBottomHr = false;
    },
    onCardDragOver(e, item) {
      if (item.dragAndDropIn) {
        e.preventDefault();
      }

      if (this.dragFromItem.value.id === item.value.parentId) return;

      item.showHr = item.dragAndDropIn;
    },
    onCardLeave(item) {
      item.showHr = false;
    },
    /* END ITEM DRAG FUNCTIONS */

    /* HELPER FUNCTIONS */
    findItemById(items, id) {
      if (id === DEFAULT_PARENT_ID) return false;

      return items.reduce((a, item) => {
        if (a) return a;
        if (item.value.id === id) return item;
        if (item.value.type === ITEM_TYPE_GROUP)
          return this.findItemById(item.children, id);
      }, null);
    },
    deleteItemById(items, id) {
      items.some((item, index) => {
        if (item.value.id === id) {
          items.splice(index, 1);
        } else if (item.value.type === ITEM_TYPE_GROUP) {
          this.deleteItemById(item.children, id);
        }
      });
    },
    /* END HELPER FUNCTIONS */

    /* DELETING FUNCTIONS */
    deleteCurrentItem() {
      this.deleteItemById(this.items, this.currentItem.value.id);
      this.currentItem = null;

      this.emitCurrentChange();
    },
    deleteSelectedItems() {
      const checkIfCurrentAlsoDeleted = this.selectedItems.find(
        item => item.value.id === this.currentItem?.value?.id
      );

      if (checkIfCurrentAlsoDeleted) {
        this.currentItem = null;
        this.emitCurrentChange();
      }

      this.selectedItems.forEach(selectedItem => {
        this.deleteItemById(this.items, selectedItem.value.id);
      });

      this.selectedItems = [];
      this.emitSelectedChange();
    },

    emitCurrentChange() {
      this.$emit(
        'currentItemChange',
        this.currentItem ? this.currentItem.value : null
      );
    },
    emitSelectedChange() {
      this.$emit(
        'selectedItemsChange',
        this.selectedItems.map(item => item.value)
      );
    },

    itemIsChecked(item) {
      console.log(item, this.selectedItems, 'item in itemischecked');
      return this.selectedItems.some(
        selectedItem => selectedItem.value.id === item.value.id
      );
    }
  },
  watch: {
    selectedItems: {
      handler(items) {
        if (items.length > 0) {
          console.log('items in search 2', this.selectedItems);
        }
      }
    }
  },
  async mounted() {
    this.items = await this.loadFn(this.childrenProvider());
    this.loaded = true;
    console.log(this.$store.state.items, 'storestate');
    this.checkItemsFromStore();

    // this.$store.commit('items/SET_SELECTED_ITEMS', []);
    console.log(this.$store.state.items, 'storestate 2');
  }
};
</script>

<style lang="scss" src="./List.scss" />
