<template>
  <div
    :class="[
      'fast-faq-button',
      isBlink && blinkFaq ? 'blink-idea' : '',
      isFaqActive ? 'isShow' : '',
      hasIdleLight && !isBlink && isClicked ? 'hasIdleLight' : '',
      isShouldLight && !isClicked ? 'idleLight' : ''
    ]"
    @click="toggleFaq"
  >
    <img src="/icons/idea_enabled.svg" alt="" />
    <img src="/icons/idea_disabled_dark.svg" alt="" v-if="isDarkButton" />
    <img
      src="/icons/idea_disabled_light.svg"
      alt=""
      v-else-if="isLightButton"
    />
    <img src="/icons/idea_disabled.svg" alt="" v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBlink: false,
      isFaqActive: false,
      isClicked: false
    };
  },
  props: {
    itemsInFaq: {
      type: Array
    },
    lightName: {
      type: String,
      default: ''
    },
    faqItem: {
      type: String,
      default: ''
    },

    hasIdleLight: {
      type: Boolean,
      default: true
    },
    isShouldLight: {
      type: Boolean,
      default: true
    },
    faqToggler: Function,
    blinkFaq: {
      type: Boolean,
      default: false
    },

    isLightButton: {
      type: Boolean,
      default: false
    },
    isDarkButton: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleFaq() {
      this.$track.fastFaq();

      this.isBlink = false;
      this.isClicked = true;
      localStorage.setItem('isBlinkActive', this.isBlink);
      console.log(this.itemsInFaq, 'dfdsf');

      const faqItemsString = localStorage.getItem('faqItems');
      const faqItems = faqItemsString ? JSON.parse(faqItemsString) : {};

      // const faqItemsObject = localStorage.getItem('faqItems')

      console.log(this.lightName, 'this ligsd');

      faqItems[this.lightName] = this.isClicked;

      localStorage.setItem('faqItems', JSON.stringify(faqItems));

      this.$emit('faqToggler', this.itemsInFaq);
    }
  },
  mounted() {
    console.log(localStorage.getItem('isBlinkActive'), 'blnk');
    let blink = JSON.parse(localStorage.getItem('isBlinkActive')) ?? true;

    const faqItemsString = localStorage.getItem('faqItems');
    const faqItems = faqItemsString ? JSON.parse(faqItemsString) : {};

    console.log(faqItems[this.lightName], this.lightName, 'faqitems on load');
    this.isClicked = faqItems[this.lightName] ?? false;
    console.log(this.isClicked, this.lightName, 'BLINK??');
    this.isBlink = blink;
  }
};
</script>

<style lang="scss" scoped src="./FastFaqButton.scss" />
