const state = {
  selectedItemsToSearch: []
};

const getters = {
  getSelectedItems: state => {
    return state.selectedItemsToSearch;
  }
};

const mutations = {
  SET_SELECTED_ITEMS(state, data) {
    console.log(data, 'datacomes');
    state.selectedItemsToSearch = data.filter(
      item => item.value.type === 'Group'
    );
    console.log(state.selectedItemsToSearch, 'data after');
  },

  REMOVE_ITEM(state, data) {
    console.log(state, data, 'dsfdsfsd');
    state.selectedItemsToSearch = state.selectedItemsToSearch.filter(item => {
      console.log(item, data, 'data obj');
      return item.value.id != data.id;
    });
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
