<!-- ProgressBar.vue -->

<template>
  <div class="progressbar-container">
    <div class="progressbar" :style="{ width: progress + '%' }"></div>
    <div class="progress-text">{{ progress.toFixed(1) }}%</div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100
    }
  }
};
</script>

<style>
/* Add your CSS styles for the progress bar here */
.progressbar-container {
  position: relative;
  width: 320px;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

progress {
  opacity: 0;
}

.progressbar {
  height: 100%;
  background-color: #12b7db;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: black;
  font-size: 14px;
}
</style>
