/* eslint-disable */

import VueCookies from 'vue-cookies';
import Vue from 'vue';

import { GET_LICENSE_PRICES } from '@/api/licenses';
import { GET_GLOBAL_PREFERENCES } from '@/api/globalPreferences';
import {
  GET_LANG_FAQ,
  GET_LANG_GAME_HELP,
  GET_LANG_GENERAL,
  GET_LANG_LANDING,
  GET_LANG_STORE
} from '@/api/localizations';

const LANGUAGE_RU = 'ru';
const LANGUAGE_ENG = 'en';

function VALIDATE_LANGUAGE(lang) {
  if (lang === LANGUAGE_RU || lang === LANGUAGE_ENG) {
    return lang;
  }

  return LANGUAGE_ENG;
}

const state = {
  globalPreferences: null,
  actionsOrder: {
    allin: 0,
    call: 0,
    check: 0,
    fold: 0,
    raise: 0
  },
  actionColors: {
    fold: '',
    call: '',
    allin: '',
    check: '',
    raise: ''
  },
  table: null,
  globalShortDesk: '',
  cardStyle: '',
  userInfo: null,
  userEmail: null,
  userLevel: 0,
  licenses: [],
  prices: null,
  currentMainPage: 1
};

const getters = {
  language: state => {
    if (state.language) return VALIDATE_LANGUAGE(state.language);

    if (localStorage['language'])
      return VALIDATE_LANGUAGE(localStorage['language']);

    let lang = window.navigator.userLanguage || window.navigator.language;
    lang = lang.slice(0, 2);
    if (lang == 'ru' || lang == 'be' || lang == 'uk') {
      return LANGUAGE_RU;
    } else {
      return LANGUAGE_ENG;
    }
  },
  licenses: state => {
    if (state.licenses) {
      return state.licenses;
    } else {
      state.licenses = VueCookies.get('userLicenses');
      return state.licenses;
    }
  },
  userLevel: state => {
    if (state.userLevel) {
      return state.userLevel;
    } else {
      state.userLevel = VueCookies.get('userLevel');
      return state.userLevel;
    }
  },
  actionColors: state => {
    console.log(state, 'state in glob');
    return {
      call: state.actionColors.call || '#77DBA5',
      raise: state.actionColors.raise || '#F59A9A',
      allin: state.actionColors.allin || '#CF80D6',
      check: state.actionColors.check || '#F59A9A',
      fold: state.actionColors.fold || '#92E2FC'
    };
  },
  actionsOrder: state => {
    console.log(state, 'state in glob actions');
    return {
      allin: state.actionsOrder.Allin || 4,
      call: state.actionsOrder.Call || 2,
      check: state.actionsOrder.Check || 1,
      fold: state.actionsOrder.Fold || 0,
      raise: state.actionsOrder.Raise || 3
    };
  },
  cardStyle: state => {
    return state.cardStyle === 'YellowColor4' ? true : false;
  },
  table: state => {
    return {
      tableColor: state.table?.tableColor ?? '#293042',
      backgroundColor: state.table?.backgroundColor ?? '#5d6270'
    };
  },
  globalShortDesk: state => {
    return state.globalShortDesk
  },
  localization: state => {
    return state.localization;
  },
  globalPreferences: state => {
    return state.globalPreferences;
  }
};

const actions = {
  SET_CURRENT_MAIN_PAGE({ commit }, number) {
    commit('SET_CURRENT_MAIN_PAGE', number);
  },
  async LOAD_GLOBAL_PREFERENCES({ commit }) {
    const data = await GET_GLOBAL_PREFERENCES();
    console.log('data in global pref', data);
    commit('play/SET_ACTIVE_MODE', data.gameMode, { root: true });
    console.log('DATA 1', data.table);
    commit('SET_ACTIONS_ORDER', data.actionsOrder);
    console.log('DATA 2');
    commit('SET_ACTION_COLORS', data.actionColors);
    console.log('DATA 3', data);
    commit('SET_CARD_STYLE', data.game.cardStyle);
    commit('SET_TABLE_COLORS', data.table);
    commit('SET_GLOBAL_PREFERENCES', data);
  }
};

const mutations = {
  SET_USER_SHARING_KEY(state, key) {
    console.log(state, key, 'set_user_share');
    state.userInfo.sharingKey = key;
  },
  SET_USER_INFO(state, DTO) {
    state.userInfo = DTO;
    state.userEmail = DTO.email;
  },
  SET_GLOBAL_PREFERENCES(state, data) {
    state.globalPreferences = data;
  },
  SET_CURRENT_MAIN_PAGE(state, number) {
    state.currentMainPage = number;
  },
  SET_ACTIONS_ORDER(state, value) {
    state.actionsOrder = value;
  },
  SET_ACTION_COLORS(state, value) {
    state.actionColors = value;
  },

  SET_CARD_STYLE(state, value) {
    state.cardStyle = value;
  },

  SET_TABLE_COLORS(state, value) {
    state.table = value;
  },
  SET_LANGUAGE: (state, data) => {
    state.language = data;
  },
  SET_LOCALIZATION_GENERAL: (state, data) => {
    state.localization.general = data;
  },
  SET_LOCALIZATION_FAQ: (state, data) => {
    state.localization.faq = data;
  },
  SET_LOCALIZATION_GAME_HELP: (state, data) => {
    state.localization.gameHelp = data;
  },
  SET_LOCALIZATION_STORE: (state, data) => {
    state.localization.store = data;
  },
  SET_LOCALIZATION_LANDING: (state, data) => {
    state.localization.landing = data;
  },
  SET_LEVEL(state, level) {
    state.userLevel = level;
  },
  SET_USER_LICENSES(state, licenses) {
    state.licenses = licenses;
  },
  SET_PRICES: (state, data) => {
    state.prices = data;
  },
  SET_GLOBAL_SHORT_DESK_CHECK(state, newValue) {
    state.globalShortDesk = newValue;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
