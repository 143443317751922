<template>

  <div class="tabs">
    <slot/>
  </div>
</template>

<script>

export default {
  name: "Tabs",
  data() {
    return {
      tabs: []
    }
  },
  props: {
    value: Number
  },
  methods: {
    selectTab() {
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === this.value)
      })
    }
  },
  created() {
    this.tabs = this.$children;
    this.tabs.forEach((tab, index) => {
      tab.key = index;
    })
  },
  mounted() {
    this.selectTab()
  },
  watch: {
    value() {
      this.selectTab();
    },
  }
}
</script>

<style lang="scss" src="./Tabs.scss"/>