export const DEFAULT_PARENT_ID = '00000000-0000-0000-0000-000000000000';

export const VS4BET = 'VS4BET';

export const VS3B = 'VS3B';

export const SQZ = 'SQZ';

export const VS1RC = 'VS1RC';

export const RFI = 'RFI';

export const VSOR = 'VSOR';

export const COMPATIBLE_ITEMS = {
  RFI: [VS3B, SQZ],
  VSOR: [VS4BET],
  VS3B: [VS3B],
  VS4BET: [],
  VS1RC: [VS4BET],
  SQZ: [VS3B],
}