<template>
  <div
    :class="[
      'board',
      {
        'board--wrong': wrong
      }
    ]"
  >
    <div class="board__bg">
      <div class="board__bg__item board__bg__item--bordered-layer" />
      <div class="board__bg__item board__bg__item--inner-border-top-layer" />
      <div class="board__bg__item board__bg__item--outer-border-top-layer" />
      <div class="board__bg__item board__bg__item--outer-border-bottom-layer" />
      <div class="board__bg__item board__bg__item--cascade" />
      <div
        :style="{
          background: bgColor
        }"
        class="board__bg__item board__bg__item--green-layer"
      />
      <div
        class="board__bg__item board__bg__item--wrong board__bg__item--wrong-layer"
      />

      <div class="board__bg__item board__bg__item--inner-shadow" />
    </div>

    <Position
      v-for="(player, index) in players"
      :key="player.name"
      :bet="player.rate"
      :p_bet="player.p_rate ? player.p_rate : 0"
      :allParticipant="player.participant"
      :cards="player.cards"
      :color="getColor(player.name)"
      :isDealer="player.d"
      :isMe="player.position === 0"
      :playerName="player.name"
      :positionNumber="player.position"
      :pot="player.bank"
      :tableSize="player.size"
      :players="players"
      @clickPlayer="onClickPlayer"
      ref="position"
      @closeOtherItems="onCloseOtherItems"
      :avatar="shuffledAvatars[index]"
      :isAvatarMode="isAvatarMode"
      :isColoredCards="isColoredCards"
      @contextCallHandTypePicker="onContextCallHandTypePicker"
    >
      <template v-if="player.isMe" v-slot:timer>
        <slot name="timer" />
      </template>
    </Position>
    <div class="pot">Pot: {{ potNumber }}</div>


      <div class="sub_pot" v-if="globalShortDesk">
        <div class="game__player-position__bet__chips" style="display: flex;">
          <div
            class="game__player-position__bet__chips__item game__player-position__bet__chips__item--aqua"
            style="display: block; margin-bottom: 36px;"
          >
            <div
              v-for="item in chipsStackSizeIntegerPartShortDesk"
              v-bind:key="item"
              class="game__player-position__bet__chip"
            />
<!--            <div-->
<!--              class="game__player-position__bet__chip"-->
<!--            />-->
          </div>
          <div
            class="game__player-position__bet__chips__item game__player-position__bet__chips__item--blue"
            style="display: block; margin-bottom: 36px;"
          >
            <div
              v-for="item in chipsStackSizeFractionalPartShortDesk"
              v-bind:key="item"
              class="game__player-position__bet__chip"
            />
          </div>
        </div>
        <div class="game__player-position__bet__value">
          {{potNumberSumma}}
        </div>
      </div>


  </div>
</template>

<script>
/* eslint-disable */

import Position from '@/components/Game/Position';
import { getTablePositions } from '@/utils/positions';
import avatarsJson from '@/utils/avatars.json';
import _ from 'lodash';
import { VSOR, RFI, VS1RC, SQZ, VS3B, VS4BET } from '@/constants/items';
import { mapGetters } from 'vuex';

const unknownHand = () => [{}, {}];
const noHand = () => [];

const isCard = (name, epIndex, index, hero) => {
  if (epIndex == 0) return unknownHand();
  if (name == hero || name == 'SB' || name == 'BB') return unknownHand();
  return epIndex <= index ? noHand() : unknownHand();
};

const getPositionNumber = (
  positionName,
  heroPosition,
  tableSize,
  participants,
  availableTablePositions,
  positionIndex
) => {
  if (positionIndex >= tableSize) return false;
  if (positionName.toLowerCase() == heroPosition.toLowerCase()) return 0;

  if (tableSize == 2) {
    if (participants[positionName.toLowerCase()]) {
      return 3;
    } else {
      return false;
    }
  }
  if (tableSize == 3) {
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  if (tableSize == 5) {
    console.log('positionName.toLowerCase(): '+positionName.toLowerCase())
    console.log('participants: '+participants[positionName.toLowerCase()])
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  if (tableSize == 6) {
    console.log('positionName.toLowerCase(): '+positionName.toLowerCase())
    console.log('participants: ')
    console.log(participants[positionName.toLowerCase()])
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  if (tableSize == 8) {
    if (participants[positionName.toLowerCase()]) {
      return availableTablePositions.shift();
    } else {
      return false;
    }
  }
  return false;
};

// Unopened
const formatterRFI = (hero, hX, participants, size, hand, globalShortDesk) => {
  console.log('formatterRFI')
  let order = getTablePositions(size);
  let heroIndex = order.findIndex(el => el == hero);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let ep = size == 8 || size == 5 ? 'ep1' : 'ep';
  let epIndex = newOrder.findIndex(el => el == ep);
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 5) sizes = [1, 3, 5, 7];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  const isCardRFI = (el, epIndex, i, hero) => {
    // console.log('isCardRFI!!!')
    // console.log(isCardRFI)
    if (size == 2) return unknownHand();
    if (size == 3 && heroIndex === 0) return unknownHand();
    if (size == 3 && heroIndex === 1 && i != 2) return unknownHand();
    // if (size == 5 && heroIndex === 1) return unknownHand();
    return isCard(el, epIndex, i, hero);
  };

  let result = newOrder.map((el, i) => {
    let name = el.toUpperCase();
    let rate;
    let p_rate;
    console.log('globalShortDesk globalShortDesk: '+globalShortDesk)
    p_rate = 1;
    if (globalShortDesk) {
      // rate = el == 'bu' ? 2 : 1;
      rate = el == 'co' ? 1 : el == 'bu' ? 2 : 1;
      console.log('globalShortDesk: el: ' + el)
    } else {
      rate = el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0;
      console.log('globalShortDesk: el: ' + el)
    }
    console.log('globalShortDesk sum: ' + rate)
    return {
      name: name,
      participant: participants[el],
      cards: hero == el ? hand : isCardRFI(el, epIndex, i, hero),
      position: getPositionNumber(el, hero, size, participants, sizes, i),
      // rate: el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0,
      rate: rate,
      p_rate: el == 'bu' ? p_rate+p_rate : p_rate,
      // d: size == 2 ? el == 'sb' : el == 'bu',
      // d: size == 2 ? el == 'mp' : el == 'co',
      d: globalShortDesk ? (size == 2 ? el == 'mp' : el == 'bu') : (size == 2 ? el == 'sb' : el == 'bu'),
      isView: i <= size,
      rfi: true,
      size: size,
      bank:
        i == 0
          ? hX
          : participants[el.toLowerCase()]
            ? participants[el.toLowerCase()].stack
            : 0
    };
  });
  return result;
};

// VS 3bet
const formatterVS3B = ({ hp, r, op, size, participants, n, hand }, globalShortDesk) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex(el => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  let p_rate = 1;
  if (size == 3) sizes = [2, 4];
  if (size == 5) sizes = [2, 3, 5, 6];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];

  return newOrder.map((el, i) => {
    let name = el.toUpperCase();
    console.log('hp: '+hp)
    console.log('el: '+el)
    console.log('super op: '+op)
    console.log('size: '+size)
    console.log('+n: '+n)
    console.log('+r: '+r)
    console.log('+p_rate: '+p_rate)

    return {
      name: name,
      participant: participants[el],
      cards: hp == el ? hand : el == op ? unknownHand() : noHand(),
      position: getPositionNumber(el, hp, size, participants, sizes, i),
      p_rate: (el==op && el=='bu') ? +p_rate+n : hp == el ? p_rate+r : el == op ? +n+p_rate : el == 'bu' ? p_rate+p_rate : p_rate,
      rate: globalShortDesk ? ((el==op && el=='bu') ? +p_rate+r : el == op ? +r+p_rate : el == hp ? +r+p_rate : el == 'bu' ? p_rate+p_rate : p_rate) : el == op ? +n : el == hp ? +r : el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0,
      d: size == 2 ? el == 'sb' : el == 'bu',
      size: size,
      bank: participants[el.toLowerCase()].stack
    };
  });
};

// VS SQZ
const formatterSQZ = ({ hp, r, op, cp, size, participants, n, hand }, globalShortDesk) => {
  console.log('hp: '+hp);
  console.log('r: '+r);
  console.log('op: '+op);
  console.log('size: '+size);
  console.log('n: '+n);
  let order = getTablePositions(size);
  let heroIndex = order.findIndex(el => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  let p_rate = 1;
  if (size == 3) sizes = [2, 4];
  if (size == 5) sizes = [2, 3, 5, 6];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];

  const getRateshortDeskPrate = el => {
    console.log('getRateshortDeskPrate el: '+el);
    let rate = 0;
    if(el==op && el=='bu'){
      rate = +p_rate+n
    }else if (el == op) {
      rate = +n+p_rate;
    } else if (el == cp) {
      rate = +r+p_rate;
    } else if (el == hp) {
      rate = +r+p_rate;
    } else if (el == 'bu') {
      rate = +p_rate+p_rate;
    }else {
      rate = p_rate;
    }
    console.log('rate: '+rate);
    return rate;
  };
  const getRateshortDesk = el => {
    let rate = 0;
    console.log('getRateshortDesk el: '+el);
    if(el==op && el=='bu'){
      rate = +p_rate+r
    }else if (el == op) {
      rate = +p_rate+r;
    } else if (el == cp) {
      rate = +r+p_rate;
    } else if (el == hp) {
      rate = +r+p_rate;
    } else if (el == 'bu') {
      rate = +p_rate+p_rate;
    }else {
      rate = p_rate;
    }
    console.log('rate: '+rate);
    return rate;
  };

  const getRate = el => {
    let rate = 0;
    console.log('getRate el: '+el);
    if (el == op) {
      rate = +n+100;
    } else if (el == cp) {
      rate = +r;
    } else if (el == hp) {
      rate = +r;
    } else if (el == 'sb') {
      rate = 0.5;
    }else if (el == 'bb') {
      rate = 1;
    }else {
      rate = 0;
    }
    console.log('rate: '+rate);
    return rate;
  };

  return newOrder.map((el, i) => {
    let name = el.toUpperCase();
    return {
      name: name,
      participant: participants[el],
      cards: hp == el ? hand : el == op || el == cp ? unknownHand() : noHand(),
      position: getPositionNumber(el, hp, size, participants, sizes, i),
      p_rate: getRateshortDeskPrate(el),
      rate: globalShortDesk ? getRateshortDesk(el) : getRate(el),
      d: size == 2 ? el == 'sb' : el == 'bu',
      size: size,
      bank: participants[el.toLowerCase()].stack
    };
  });
};

// VS RFI/Limp
const formatterVSOR = ({ hp, op, size, participants, n, hand }, globalShortDesk) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex(el => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  if (size == 3) sizes = [2, 4];
  if (size == 5) sizes = [2, 3, 5, 6];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  // let ep = size == 8 ? 'ep1' : 'ep';
  let ep = size == 8 || size == 5 ? 'ep1' : 'ep';
  let epi = newOrder.findIndex(el => el == ep);
  let hpR = 0;
  let defaultV = 0;




  const isCardVSOR = (epi, eli) => (epi > eli ? unknownHand() : noHand());

  if (hp == 'bb') {
    hpR = 1;
  }
  if (hp == 'sb') {
    hpR = 0.5;
  }

  return newOrder.map((el, i) => {
    let name = el.toUpperCase();

    let rate;
    let p_rate=1;

    console.log('hp: '+hp)
    console.log('el: '+el)
    console.log('op: '+op)
    console.log('size: '+size)
    console.log('+n: '+n)
    console.log('+p_rate: ')
    console.log(el == op ? +n : el == hp ? p_rate : 0)
    console.log('+rate: ')
    console.log(hp == el ? p_rate : el == op ? p_rate+p_rate+n : p_rate)

    console.log('globalShortDesk start: ')
    console.log('globalShortDesk globalShortDesk: '+globalShortDesk)


    let size_n = 6
    if (globalShortDesk) {
      rate = el == op ? +n : el == hp ? p_rate : 0;
      size_n = 5
      // rate = el == op ? +n : el == hp ? hpR : el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0;
      console.log('globalShortDesk: +')
    } else {
      rate = el == op ? +n : el == hp ? hpR : el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0;
      console.log('globalShortDesk: else')
    }
    return {
      name: name,
      participant: participants[el],
      cards:
        hp == el
          ? hand
          : el == op
            ? unknownHand()
            : size < size_n
              ? unknownHand()
              : isCardVSOR(epi, i),
      position: getPositionNumber(el, hp, size, participants, sizes, i),
      rate:rate,
      p_rate:hp == el ? p_rate : el == op ? p_rate+n : el == 'bu' ? p_rate+p_rate : p_rate,
      d: el == 'bu',
      size: size,
      bank: participants[el.toLowerCase()].stack,
      default: defaultV
    };
  });
};

// VS 1raise+caller
const formatterVS1RC = ({ hp, op, cp, size, participants, n, hand }, globalShortDesk) => {
  // r = 1
  // size = 8
  let p_rate = 1;
  let size_n = 6
  if(globalShortDesk){
    size_n = 5
  }
  // get array of current positions with certain order
  let order = getTablePositions(size);
  // hero position index in order
  let heroIndex = order.findIndex(el => el == hp);
  // move Hero to beginning and change general order
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  // strange shit - opponents size?
  if (size == 3) sizes = [2, 4];
  if (size == 5) sizes = [2, 3, 5, 6];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  // ep = ep1 if 8 else ep
  // if 8 then ep is not single and starts 'ep1' (few ep's)
  let ep = size == 8 || size == 5 ? 'ep1' : 'ep';
  // ep index
  let epIndex = newOrder.findIndex(el => el == ep);
  // hero position RATE
  let hpR = 0;
  // DEFAULT VALUE??????????
  let defaultV = 0;
  // different HP bet rate for different positions
  if (hp == 'bb') {
    hpR = 1;
  }
  if (hp == 'sb') {
    hpR = 0.5;
  }

  // compare epIndex and current array element index. if epIndex bigger return [1,2]
  const isCardVS1RC = (el, i) => {
    let opponentHand = null;
    if (el == op || el == cp) {
      opponentHand = unknownHand();
    }
    if (opponentHand == null) {
      if (size < size_n) opponentHand = unknownHand();
      else opponentHand = epIndex > i ? unknownHand() : noHand();
    }
    return opponentHand;
  };
  const getRate = el => {
    let rate = 0;
    if (el == op) {
      rate = +n;
    } else if (el == hp) {
      rate = +hpR;
    } else if (el == cp) {
      rate = +n;
    } else if (el == 'sb') {
      rate = 0.5;
    } else if (el == 'bb') {
      rate = 1;
    } else {
      rate = 0;
    }
    return rate;
  };

  const getRateshortDesk = el => {
    let rate = 0;
    if(el==op && el=='bu'){
      rate = +p_rate+p_rate
    } else if (el == op) {
      rate = +p_rate;
    } else if (el == hp && el == 'bu') {
      rate = +p_rate+p_rate;
    } else if (el == hp) {
      rate = +p_rate;
    } else if (el == cp) {
      rate = +p_rate;
    } else if (el == 'bu') {
      rate = +p_rate+p_rate;
    }  else {
      rate = p_rate;
    }
    return rate;
  };
  const getRateshortDeskPrate = el => {
    let rate = 0;
    if(el==op && el=='bu'){
      rate = +p_rate+p_rate
    }else if (el == op) {
      rate = +n+p_rate;
    } else if (el == hp && el == 'bu') {
      rate = +p_rate+p_rate;
    } else if (el == hp) {
      rate = +p_rate;
    } else if (el == cp) {
      rate = +n+p_rate;
    } else if (el == 'bu') {
      rate = +p_rate+p_rate;
    }  else {
      rate = p_rate;
    }
    return rate;
  };

  let result = newOrder.map((el, i) => {
    let name = el.toUpperCase();
    return {
      name: name,
      participant: participants[el],
      cards: hp == el ? hand : isCardVS1RC(el, i),
      position: getPositionNumber(el, hp, size, participants, sizes, i),
      // rate: getRate(el),
      p_rate: getRateshortDeskPrate(el),
      rate: globalShortDesk ? getRateshortDesk(el) : getRate(el),
      d: el == 'bu',
      size: size,
      bank: participants[el.toLowerCase()].stack,
      default: defaultV
    };
  });

  return result;
};

// VS 4bet raise
const formatterVS4BET = ({ hp, op, r, participants, size, n, hand, cp }, globalShortDesk) => {
  let order = getTablePositions(size);
  let heroIndex = order.findIndex(el => el == hp);
  let newOrder = [...order.slice(heroIndex), ...order.slice(0, heroIndex)];
  let sizes;
  let p_rate = 1;
  if (size == 3) sizes = [2, 4];
  if (size == 5) sizes = [2, 3, 5, 6];
  if (size == 6) sizes = [1, 2, 3, 4, 5];
  if (size == 8) sizes = [1, 2, 3, 4, 5, 6, 7];
  return newOrder.map((el, i) => {
    let name = el.toUpperCase();
    return {
      name: name,
      participant: participants[el],
      items: el == op ? unknownHand() : noHand(),
      cards: hp == el ? hand : el == op || el == cp ? unknownHand() : noHand(), //сюда поставить вместо нулл нормальное форматирование
      position: getPositionNumber(el, hp, size, participants, sizes, i),
      // p_rate:p_rate,
      // rate:
      //   el == op ? +n : el == hp ? +r : el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0,

      p_rate: el==op=='bu' ? p_rate+p_rate+n : hp == el ? p_rate+r : el == op ? +n+p_rate : el == 'bu' ? p_rate+p_rate : p_rate,
      rate: globalShortDesk ? ( el == op ? +r+p_rate : el == hp ? +r+p_rate : el == 'bu' ? +p_rate+p_rate :  p_rate) : el == op ? +n : el == hp ? +r : el == 'sb' ? 0.5 : el == 'bb' ? 1 : 0,
      d: el == 'bu',
      size: size,
      isView: i <= size,
      bank: participants[el].stack
    };
  });
};
export default {
  name: 'Board',
  props: {
    bgColor: String,
    timebank: Number,
    hand: Object,
    checkOtherAllinStack: Function,
    participants: Object,
    handCards: Array,
    prevGame: Object,
    range: Object,
    isWrong: Boolean,
    isShuffle: Boolean,
    isLinkedDiapasonActive: Boolean,
    isColoredCards: Boolean,
    clickHandTypePicker: Function,

    isAvatarMode: Boolean
  },
  components: {
    Position
  },
  data() {
    return {
      wrong: false,
      avatars: avatarsJson,
      shuffledAvatars: avatarsJson,
      opAllin: false
    };
  },
  methods: {
    onContextCallHandTypePicker(elemRect) {
      this.$emit('clickHandTypePicker', elemRect);
    },
    shuffleAvatars() {
      this.shuffledAvatars = _.shuffle(this.avatars);
      console.log('AVATAR RANDOM WORK');
    },
    onCloseOtherItems(player) {
      this.$refs.position.forEach(child => {
        if (
          child.$refs?.statistic?.isOpen &&
          child.$refs?.statistic?.activePlayer != player.toUpperCase()
        ) {
          child.$refs?.statistic?.closeItem();
        }
      });
      console.log(this.$refs, 'refz');
    },
    onClickPlayer(pos) {
      console.log(pos, 'POSITION PLAYER');
      this.$emit('openDiapasonInfo', pos);
    },
    getColor(key) {
      if (this.participants && this.participants[key.toLowerCase()]) {
        return this.participants[key.toLowerCase()].color;
      }
      return '';
    },
    isOpAllinAndPLayersHaveStack() {
      if (this.opAllin) {
        let output = this.players
          .filter(player => player.cards.length > 0)
          .filter(player => !player.isMe)
          .some(player => player.bank - player.rate > 0);
        console.log(this.opAllin, output, 'isOpAllinAndPLayersHaveStack');
        this.$emit('checkOtherAllinStack', output);
      } else {
        this.$emit('checkOtherAllinStack', false);
      }
    },

    isOpAllin() {
      let { hp, op, n } = this.range.additionalProperties;
      console.log(op, this.gameData[0].name, 'player names');
      console.log(hp, op, n, this.range, 'HP, OP , R');
      if (op && n > 0) {
        let opPlayer = this.gameData.filter(
          player => player.name.toLowerCase() === op
        )[0];
        console.log(opPlayer.bank, opPlayer.rate, 'OP PLAYER');
        if (opPlayer.bank - opPlayer.rate <= 0) {
          this.opAllin = true;
        } else {
          this.opAllin = false;
        }
      } else {
        this.opAllin = false;
      }

      this.$emit('isOpAllin', this.opAllin);
    }
  },
  mounted() {
    this.isOpAllin();
    this.isOpAllinAndPLayersHaveStack();
  },
  watch: {
    hand: {
      handler() {
        this.isOpAllinAndPLayersHaveStack();
        this.isOpAllin();
        if (this.isShuffle) {
          this.shuffleAvatars();
        }
      },
      deep: true
    },
    prevGame() {
      // запускается каждый раз, когда меняется игра  -
      // НЕ ПОКАЗЫВАТЬ ОШИБКУ СРАЗУ, ДАБЫ СОЗДАТЬ НЕКИЙ ЭФФЕКТ МЕРЦАНИЯ СТОЛА :D
      //Первый if отвечает за мерцание стола при неправильном ответе на привязку
      // this.isOpAllin();
      if (this.prevGame?.isLinkedHand && !this.prevGame.result) {
        this.wrong = true;
        setTimeout(() => {
          this.wrong = false;
        }, 150);
      } else if (!this.prevGame.result) {
        this.wrong = true;
        setTimeout(() => {
          this.wrong = false;
        }, 150);
      }
    }
  },

  computed: {
    ...mapGetters({
      globalShortDesk: 'user/globalShortDesk',
      // globalShortDesk() {
      //   return true
      // }
    }),

    players() {
      return this.gameData
        .filter(item => typeof item.position !== 'boolean')
        .map(item => {
          item.isMe = item.position === 0;
          return item;
        });
    },
    potNumber() {
      return this.gameData.reduce(
        (acc, val) => (val.rfi ? acc + val.rate : acc + val.rate),
        this.gameData[0].default ? this.gameData[0].default : 0
      );
    },
    potNumberSumma() {
      // console.log(acc + val.rate)
      console.log('this.gameData[0].default: ')
      console.log(this.gameData)

      return this.gameData.reduce(
        (acc, val) => (acc + val.p_rate),
        this.gameData[0].default ? this.gameData[0].default : 0
      );
    },
    chipsStackSizeIntegerPartShortDesk() {
      return Math.floor(this.potNumberSumma / 10); // фішки по 10
    },
    chipsStackSizeIntegerPart() {
      return Math.floor(this.potNumberSumma); // Отримати цілу частину
    },
    chipsStackSizeFractionalPartShortDesk() {
      return this.potNumberSumma - this.chipsStackSizeIntegerPartShortDesk*10; // менше 10
    },
    chipsStackSizeFractionalPart() {
      return this.potNumberSumma - this.chipsStackSizeIntegerPart; // Отримати дробову частину
    },
    getTrueAnswer() {
      if (this.prevGame) {
        const { call, fold, raise } = this.prevGame.item.weight;
        let string = '';
        if (call) string += 'Call ' + call + '; ';
        if (fold) string += 'Fold ' + fold + '; ';
        if (raise) string += 'Raise ' + raise + '; ';
        if (!string) string += 'Fold 100; ';
        return string.slice(0, string.length - 2);
      }
      return '';
    },

    gameData() {
      const {
        additionalProperties: { type, r, n, size, hp, op, cp, sdsek},
        participants
      } = this.range;
      console.log('this.globalShortDesk: type: ' + type)
      console.log('this.globalShortDesk: shortDesk: ' + sdsek)
      switch (type) {
        case RFI: {
          // const { hero, hX, oX } = data;
          console.log('this.globalShortDesk: ' + this.globalShortDesk)
          return formatterRFI(
            hp,
            participants[hp.toLowerCase()].stack,
            participants,
            size,
            this.handCards,
            this.globalShortDesk
          );
        }
        case VS3B: {
          return formatterVS3B({
            r,
            n,
            size,
            hp,
            op,
            participants,
            hand: this.handCards
          },this.globalShortDesk);
        }
        case VSOR: {
          return formatterVSOR({
            r,
            n,
            size,
            hp,
            op,
            participants,
            hand: this.handCards
          }, this.globalShortDesk);
        }
        case VS1RC: {
          return formatterVS1RC({
            r,
            n,
            size,
            hp,
            op,
            cp,
            participants,
            hand: this.handCards
          }, this.globalShortDesk);
        }
        case SQZ: {
          return formatterSQZ({
            r,
            n,
            size,
            hp,
            op,
            cp,
            participants,
            hand: this.handCards
          }, this.globalShortDesk);
        }
        case VS4BET: {
          return formatterVS4BET({
            r,
            n,
            size,
            hp,
            op,
            participants,
            hand: this.handCards
          }, this.globalShortDesk);
        }
        default: {
          return null;
        }
      }
    }
  }
};
</script>
