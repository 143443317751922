import Vue from 'vue';
import store from './store';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import { GET_AUTH_USER_INFO } from '@/api/users';
import { LICENSE_ADMIN, LICENSE_USER_BASE } from '@/constants/licenses';
import { parseJwt } from '@/utils/jwt';

async function updateUser() {
  await GET_AUTH_USER_INFO().then(response => {
    const data = response.data;

    const licenses = data.activeLicenseKeys;

    const checkForLicense = licenseName => {
      return licenses.some(license => license.tag === licenseName);
    };

    store.commit('user/SET_USER_INFO', data);
    store.commit('user/SET_USER_LICENSES', licenses);

    let level;
    if (checkForLicense(LICENSE_ADMIN)) {
      level = 2;
    } else if (checkForLicense(LICENSE_USER_BASE)) {
      level = 1;
    } else {
      level = 0;
    }

    store.commit('user/SET_LEVEL', level);
  });
}

export default {
  getAuthUser() {
    updateUser();
  },
  signInWithExternalServices(DTO) {
    console.log(DTO, 'DTO VUE');
    Vue.$track.login();

    this.requestPending = true;

    return new Promise((resolve, reject) => {
      axios
        .post('Auth/External', DTO)
        .then(response => {
          this.login(response.data.token)
            .then(jwt => resolve(jwt))
            .catch(error => reject(error));
        })
        .catch(error => {
          console.log(error, 'ERROR');
          reject(error);
        });
    });
  },

  SIGNIN_AS_USER(userId) {
    console.log(userId, 'get user');
    return new Promise((resolve, reject) => {
      this.getToken(token => {
        axios
          .get('/Auth/Token', {
            params: {
              userId: userId
            },
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + token
            }
          })
          .then(response => {
            this.login(response.data.token)
              .then(jwt => resolve(jwt))
              .catch(error => reject(error));
          })
          .catch(error => {
            console.log(error, 'getUserToken error');
            reject(error);
          });
      });
    });
  },

  login(token) {
    return new Promise((resolve, reject) => {
      let jwt = parseJwt(token);

      if (!jwt) {
        reject(new Error('ParseJWT error'));
        return;
      }

      updateUser();
      VueCookies.set('token', token, new Date(jwt.exp * 1000));

      resolve(jwt);
    });
  },
  clearAuthorize() {
    console.log('clear authorize');
    store.commit('user/SET_LEVEL', 0);
    VueCookies.remove('token');
  },
  getToken(callback = false) {
    // callback(token)
    if (callback) {
      callback(VueCookies.get('token'));
    }
    return VueCookies.get('token');
  },
  isLogged() {
    return VueCookies.isKey('token');
  },

  signup(email, password, callback) {
    Vue.$track.beginRegistration();

    let referralCode = VueCookies.isKey('referral_code')
      ? VueCookies.get('referral_code')
      : '';

    axios
      .post(
        `/Auth/Register`,
        {
          email: email,
          password: password,
          referralCode
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        callback(true, response);
      })
      .catch(error => {
        callback(false, error);
      });
  },
  signin(email, password, callback) {
    Vue.$track.login();

    axios
      .post(
        `/Auth/Login`,
        {
          email: email,
          password: password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        this.login(response.data.token)
          .then(jwt => {
            callback(true, jwt);
          })
          .catch(error => {
            callback(false, error);
          });
      })
      .catch(error => {
        callback(false, error);
      });
  },
  changePassword(oldPassword, newPassword, callback) {
    this.getToken(token => {
      axios
        .post(
          `/Auth/ChangePassword`,
          {
            old: oldPassword,
            new: newPassword
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
              Accept: 'application/json'
            }
          }
        )
        .then(response => {
          callback(true, response);
        })
        .catch(error => {
          callback(false, error);
        });
    });
  },
  restorePassword(email, callback) {
    Vue.$track.restorePassword();

    axios
      .post(
        `/Auth/RestorePassword`,
        {
          email: email
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(response => {
        callback(true, response);
      })
      .catch(error => {
        callback(false, error);
      });
  },
  confirmRegistration(code, email, callback) {
    Vue.$track.confirmRegistration();

    axios
      .post(
        `/Auth/ConfirmRegistration`,
        {
          email: email,
          code: code
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }
      )
      .then(response => {
        this.login(response.data.token)
          .then(jwt => {
            callback(true, jwt);
          })
          .catch(error => {
            callback(false, error);
          });
      })
      .catch(error => {
        callback(false, error);
      });
  },

  logout(callback) {
    Vue.$track.logout();

    this.getToken(token => {
      axios
        .post('/Auth/Logout', null, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then(response => {
          store.commit('user/SET_LEVEL', 0);
          localStorage.removeItem('diapasonsForTest');
          this.clearAuthorize();

          callback(true, response);
        })
        .catch(error => {
          console.log(error);
          callback(false, error);
        });
    });
  }
};
