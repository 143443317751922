<template>
  <div class="premium-item-editor">
    <div class="premium-item-editor__field">
      <p>Price Modifier</p>
      <Select
        v-model="selected.priceModifier"
        :items="priceModifiers"
        clearable
        item-text="name"
        item-value="id"
        width="100%"
      />
    </div>
    <div class="premium-item-editor__field">
      <p>Availability Layer</p>
      <Select
        v-model="selected.layer"
        :items="layers"
        clearable
        item-text="name"
        item-value="id"
        width="100%"
      />
    </div>
    <div class="premium-item-editor__field">
      <p>Price</p>

      <input v-model.number="selected.price" class="form-input" type="number" />
    </div>
  </div>
</template>

<script>
import { GET_PRICE_MODIFIERS } from '@/api/priceModifiers';
import { GET_LAYERS } from '@/api/layers';
import Select from '@/components/UI/Select/Select';

export default {
  name: 'PremiumItemAvailabilityEditor',
  components: { Select },

  props: {
    priceModifier: String,
    layer: String,
    price: Number
  },
  // computed: {
  //   selectedValue: {
  //     get() {
  //       return this.selected;
  //     },
  //     set(val) {
  //       console.log('selectedVal,', val);
  //       this.selected = val;
  //       this.$emit('input', val);
  //     }
  //   }
  // },
  data() {
    return {
      layers: [],
      priceModifiers: [],

      selected: {
        priceModifier: null,
        layer: null,
        price: null
      }
    };
  },
  methods: {
    getChanges() {
      return this.selected;
    },
    loadValues() {
      this.selected.priceModifier = this.priceModifier;
      this.selected.layer = this.layer;
      this.selected.price = this.price;
    }
  },

  watch: {
    layer() {
      this.loadValues();
    },
    price() {
      this.loadValues();
    },
    priceModifier() {
      this.loadValues();
    }
  },

  async mounted() {
    this.priceModifiers = await GET_PRICE_MODIFIERS();
    this.layers = await GET_LAYERS();

    this.loadValues();
  }
};
</script>
<style lang="scss" src="./PremiumItemAvailabilityEditor.scss" />
