<template>
  <Dialog
    v-model="computedValue"
    class="create-group-dialog modal-dialog--size-to-content modal-dialog--width-medium"
  >
    <DialogHeader>
      {{ localization.general.MainPage.NewGroup }}
    </DialogHeader>
    <DialogContent>
      <div class="form-block">
        <label class="form-label-text">
          {{ localization.general.MainPage.GroupName }}
        </label>
        <input
          ref="itemName"
          v-model="newGroup.name"
          class="form-input"
          type="text"
          @keyup.enter="addItem()"
        />
      </div>
    </DialogContent>
    <DialogFooter>
      <Btn
        :disabled="!newGroup.name"
        :loading="requestPending"
        @click="addItem()"
      >
        {{ localization.general.Simple.Save }}
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "@/components/UI/Dialog";
import Btn from "@/components/UI/Btn";


export default {
  name: "CreateGroupDialog",
  data() {
    return {
      newGroup: {
        name: ''
      },
      requestPending: false
    }
  },
  components: {Btn, Dialog, DialogContent, DialogFooter, DialogHeader},

  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    async addItem() {
      this.requestPending = true;

      await this.handler(this.newGroup)
        .then(() => {
          this.newGroup.name = '';
          this.closeModal();
        })
        .catch(error => {
          if (error.Code === 'items_limit_reached') {
            this.$toast.error('Items Limit Reached')
          }
        })
        .then(() => {
          this.requestPending = false;
        });

    },

    closeModal() {
      this.$emit('input', false);

    }
  },
  props: {
    currentItem: {
      type: Object
    },
    value: {
      type: Boolean
    },
    handler: {
      type: Function
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$refs.itemName.focus();
      }
    }
  }
}
</script>
<style lang="scss" src="./CreateGroupDialog.scss">
</style>