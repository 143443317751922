import http from "../utils/http";

export const GET_LAYERS = async () => {
  const {data} = await http.get('AvailabilityLayers');
  return data;
};
export const EDIT_LAYER = async DTO => {
  const {data} = await http.put('AvailabilityLayers', DTO);
  return data;
};
export const CREATE_LAYER = async (newLayer) => {

  const {data} = await http.post('AvailabilityLayers', {
    params: {
      newLayer
    }
  });

  return data;

};
export const DELETE_LAYER = async id => {
  await http.delete('AvailabilityLayers', {
    params: {
      id
    }
  });

};