import http from "../utils/http";

export const GET_GLOBAL_PREFERENCES = async () => {
  const {data} = await http.get('GlobalPreferences');
  return data;
};
export const PATCH_GLOBAL_PREFERENCES = async dto => {
  await http.patch('GlobalPreferences', dto);
};

export const UPDATE_GLOBAL_PREFERENCES = async dto => {
  let url = 'GlobalPreferences';
  await http.put(url, dto);
};
