<template>
  <div class="randomized-weight">
    <img class="randomized-weight__icon" src="/icons/dice.svg"/>
    <div class="randomized-weight__indicator">
      <span> {{ value }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WeightForRandomizedMode',
  props: ['weights', 'value'],

  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
  },
  computed: {
    weightsIterator() {
      let result = [];
      if (this.weights?.length > 0) {
        let length = this.weights.length;
        let temp = [];
        for (let j = 0; j < length; j++) {
          temp[j] = this.weights[j];
        }
        for (let i = 0; i < length; i++) {
          let indexLimit = (this.weights.length) - result.length;
          let index = this.getRandomInt(indexLimit);
          let element = temp[index];
          temp.splice(index, 1);
          result.push(element);
        }
      }
      console.log(result);
      return result;
    },
    containerGridColumnsStyle() {
      if (this.weightsIterator?.length > 0) {
        let result = "grid-template-columns: ";
        let weights = this.weightsIterator;
        for (let i = 0; i < weights.length; i++) {
          let w = weights[i];
          result += w + "fr ";
        }
        return result;
      }
      return "";
    },
    indicatorStyle() {
      if (this.value) {
        return `left: ${this.value}%`;
      }
      return "";
    },
    valueStyle() {
      if (this.value) {
        if (this.value > 50) {
          let alignedValue = this.value - 10;
          return `left: ${alignedValue}%`;
        } else {
          let alignedValue = this.value + 10;
          return `left: ${alignedValue}%`;
        }
      }
      return "";
    }
  }
};
</script>