<template>
  <div
    :class="[
      'dropdown-btn',
      {
        'dropdown-btn--disabled': disabled,
        'dropdown-btn--active': showDropdownBody,
        'dropdown-btn--bg-blue': !borderColor && backgroundColor === 'blue',
        'dropdown-btn--bg-green': !borderColor && backgroundColor === 'green',
        'dropdown-btn--bg-dark': !borderColor && backgroundColor === 'dark',
        'dropdown-btn--outlined-gray':
          !backgroundColor && borderColor === 'gray'
      }
    ]"
  >
    <div
      :style="`--height: ${height}px`"
      class="dropdown-btn__header"
      @click="runAction()"
    >
      <svg
        v-if="headerIcon"
        :data-src="headerIcon"
        class="dropdown-btn__header__icon"
      />
      <div
        class="dropdown-btn__header__label "
        :style="{ 'font-size': fontSize ? fontSize + 'px' : null }"
      >
        {{ headerLabel }}
      </div>
      <div
        class="dropdown-btn__header__arrow"
        @click.prevent="toggleDropdownBody($event)"
      >
        <svg data-src="/icons/arrow-down.svg" />
      </div>
    </div>
    <div class="dropdown-btn__body">
      <div
        v-for="(item, index) in dataItems"
        :key="index"
        class="dropdown-btn__item"
      >
        <Checkbox
          v-model="item.value"
          :label="item.label"
          class="dropdown-btn__checkbox"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import ProgressCircular from '@/components/UI/Progressircular';
import Checkbox from '@/components/UI/Checkbox';

export default {
  name: 'DropdownBtn',
  components: { Checkbox },
  data() {
    return {
      showDropdownBody: false,
      dataItems: [],

      selectedItems: []
    };
  },
  computed: {
    computeProgressCircularColor() {
      return this.borderColor ? '#404C61' : 'white';
    }
  },
  props: {
    changeGameOptions: Function,
    disabled: Boolean,
    headerIcon: String,
    headerLabel: String,
    startAction: Function,

    fontSize: {
      type: Number || String || null,
      default: null
    },
    height: {
      type: String,
      default: '48'
    },
    borderColor: String,
    backgroundColor: String,

    items: {
      type: Array,
      default: () => {
        return [
          {
            icon: '',
            label: '',
            handler: () => console.log('Run func')
          }
        ];
      }
    }
  },
  methods: {
    updateSelection() {
      this.selectedItems = this.dataItems
        .filter(item => item.value)
        .map(item => item.label);
    },

    runAction() {
      this.$emit('changeGameOptions', this.dataItems);
      this.$emit('startAction');
      console.log('run');
    },
    toggleDropdownBody(e) {
      e.preventDefault();
      e.stopPropagation();

      this.showDropdownBody = !this.showDropdownBody;
    },
    async runHandler() {
      this.showDropdownBody = false;
    },
    loadItems() {
      const activeItems = JSON.parse(localStorage.getItem('gameOptions')) ?? {};

      console.log();

      if (!Object.keys(activeItems).length) {
        this.dataItems = this.items.map(item => {
          return item;
        });
        localStorage.setItem('gameOptions', JSON.stringify(this.dataItems));
      } else {
        console.log(activeItems, this.dataItems, 'dfsdfsd');
        this.dataItems = activeItems.map(item => {
          return item;
        });
      }

      console.log('activeitems', activeItems);

      console.log(this.items, 'itemscreate');
      // this.dataItems = this.items.map(item => {
      //   return item;
      // });
    },
    hideDropdownBodyIfClickIsOut(e) {
      if (!this.$el.contains(e.target) && this.showDropdownBody) {
        this.showDropdownBody = false;
      }
    }
  },

  created() {
    document.addEventListener('click', this.hideDropdownBodyIfClickIsOut);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideDropdownBodyIfClickIsOut);
  },
  async mounted() {
    this.loadItems();
    // this.updateSelection();
  },
  watch: {
    dataItems: {
      handler() {
        this.$emit('changeGameOptions', this.dataItems);
      },
      deep: true
    },
    items() {
      this.loadItems();
    }
  }
};
</script>
<style lang="scss" src="./DropdownBtn.scss" />
