import http from "../utils/http";

export const GET_AUTH_USER_REFERRALS = async (params) => {
  const {data} = await http.get('/Referrals', {
    params
  });
  return data;
};
export const GET_REFERRALS = async (userId, params) => {
  const {data} = await http.get('/Referrals/' + userId, {
    params
  });
  return data;
};
export const SET_REFERRALS = async (DTO, callback) => {
  await http.put("/Users/ReferralSystem/Set", DTO).then((response) => {
    callback(true, response)
  }).catch((error) => {
    callback(false, error)
  });
};