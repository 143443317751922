<template>
  <div class="container">
    <div class="main-row">
      <div
        :class="[
          'ranges-search',
          {
            'hidden-sm': searchFooterCurrentView !== searchFooterViewMain,
            'visible-sm': searchFooterCurrentView === searchFooterViewMain
          }
        ]"
      >
        <SearchProperties
          @searchHandle="onSearchHandle"
          ref="searchProperties"
          :btnLoading="btnLoading"
          :selectedItemsToSearch="selectedItems"
          @refreshDiapasons="onRefreshDiapasons"
          :isActiveDiapason="items.length > 0"
          @removeItem="onRemoveItem"
        />
      </div>

      <div
        :class="[
          'ranges-diapasons',
          {
            'hidden-sm': searchFooterCurrentView !== searchFooterViewDiapasons,
            'visible-sm': searchFooterCurrentView === searchFooterViewDiapasons
          }
        ]"
      >
        <TableMock v-if="!items.length && 0" />

        <DiapasonsList
          :items="items"
          @refreshState="refreshHotkeyDiapason"
          v-else
        />
      </div>
    </div>

    <SearchViewChanger @changeView="changeRangesFooterView" />
  </div>
</template>

<script>
import Vue from 'vue';
import { SEARCH_DIAPASONS } from '@/api/search';
import { SearchViewChanger } from '@/components/SearchViewChanger';
import SearchProperties from '@/views/Dashboard/Search/SearchProperties';
import DiapasonsList from '@/views/Dashboard/Search/DiapasonsList';
import TableMock from './TableMock';
import { mapGetters } from 'vuex';

import {
  SEARCH_VIEW_MAIN,
  SEARCH_VIEW_DIAPASONS
} from '@/components/SearchViewChanger';
export default {
  name: 'Search',
  components: { SearchViewChanger, SearchProperties, DiapasonsList, TableMock },
  data() {
    return {
      searchFooterCurrentView: SEARCH_VIEW_MAIN,
      btnLoading: false,
      items: []
    };
  },
  computed: {
    ...mapGetters({
      selectedItems: 'items/getSelectedItems'
    }),
    searchFooterViewDiapasons() {
      return SEARCH_VIEW_DIAPASONS;
    },
    searchFooterViewMain() {
      return SEARCH_VIEW_MAIN;
    }
  },
  methods: {
    onRemoveItem(item) {
      console.log(item, 'item to remove');
      this.$store.commit('items/REMOVE_ITEM', item);
    },
    refreshHotkeyDiapason() {
      this.items = [];
      this.$refs.searchProperties.refreshProperties();
      window.location.hash = 'main';
      this.changeRangesFooterView('SEARCH_VIEW_MAIN');
    },
    onRefreshDiapasons() {
      this.items = [];
      // this.$refs.searchProperties.refreshProperties();
    },

    async onSearchHandle(additionalProperties) {
      this.btnLoading = true;
      console.log(
        this.$store.state.items?.selectedItemsToSearch,
        'storestate search'
      );
      const scopeSearch = this.$store.state.items.selectedItemsToSearch.map(
        item => item.value.id
      );

      console.log(scopeSearch, 'scopeSearch');

      const diapasons = await SEARCH_DIAPASONS(
        additionalProperties,
        scopeSearch
      );
      this.btnLoading = false;
      this.items = diapasons;
      if (!this.items.length) {
        this.$toast.error(this.localization.general.Profile.NothingFind);
        return;
      }
      window.location.hash = 'diapasons';
      console.log(diapasons, 'ad prop');
      Vue.$track.searchRanges();
    },
    changeRangesFooterView(view) {
      console.log(view, 'viewonchange');
      this.searchFooterCurrentView = view;
    }
  },
  async mounted() {
    Vue.$track.openSearch();
    await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');

    console.log(this.$store.state.items, 'storestate 3');
  }
};
</script>

<style lang="scss" src="@/assets/scss/search.scss" />
