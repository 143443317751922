import http from "../utils/http";

export const SEARCH_BILLS = async params => {
  const {data} = await http.get('/Bills/Search', {
    params
  });
  return data;
};
export const GET_USER_BILLS = async params => {
  const {data} = await http.get('/Bills', {
    params
  });
  return data;
};