<template>
  <div
    :class="computedClasses"
    :style="{
      'width': computeWidth,
      'height': computeHeight,
    }"
  >
    <div
      :style="{
        '--height': height + 'px',
        'border-radius': rounded ? (height / 2) + 'px' : false
      }"
      class="select__header"

      @click="toggleDropdownBody()"
    >
      <div class="select__header__label">
        {{ selectedItemLabel }}
      </div>
      <div class="select__header__arrow">
        <svg data-src="/icons/arrow-down.svg"/>
      </div>
      <svg
        v-if="clearable"
        v-show="value"
        class="select__header__clear"
        data-src="/icons/close.svg"
        @click="clearSelection"
      />
    </div>
    <div class="select__body">
      <div
        v-for="item in items"
        :key="item[itemValue]"
        class="select__item"
        @click="selectItem(item)"
      >
        {{ item[itemText] }}
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Select',

  data() {
    return {
      selectedItemLabel: '',
      selectedItemValue: '',
      showDropdownBody: false
    };
  },
  props: {
    highlightedText: Boolean,
    rounded: {
      type: Boolean,
      default: false,
    },
    showFromTop: Boolean,
    clearable: Boolean,
    value: null,

    placeholder: String,
    height: {
      type: [Number, String],
      default: 48
    },
    width: {
      type: [Number, String],
      default: 150
    },
    itemText: {
      type: String,
      default: 'label'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    borderColor: String,
    backgroundColor: String,

    items: {
      type: Array,
      default: () => {
        return [
          {
            label: 'Test label',
            value: 'test'
          }
        ]
      }
    },
  },
  methods: {
    clearSelection(e) {
      e.stopPropagation();

      this.selectedItemLabel = null;
      this.$emit('input', null);
    },
    selectItem(item) {
      this.selectedItemLabel = item[this.itemText];
      this.selectedItemValue = item[this.itemValue];

      this.$emit('input', this.selectedItemValue);

      this.showDropdownBody = false;
    },
    toggleDropdownBody() {
      this.showDropdownBody = !this.showDropdownBody;
    },
    findItemByValue(value) {
      return this.items.find(item => item[this.itemValue] === value);
    },
    loadOnMount() {
      const selectedItem = this.findItemByValue(this.value);

      if (selectedItem) {
        this.selectedItemValue = this.value;
        this.selectedItemLabel = selectedItem[this.itemText]
      } else {
        this.selectedItemLabel = this.placeholder
      }
    }
  },
  computed: {
    computeWidth() {
      return this.width + (typeof this.width === 'number' ? 'px' : '');
    },
    computeHeight() {
      return this.height + (typeof this.height === 'number' ? 'px' : '');
    },
    computedClasses() {
      let defaultClasses = ['select'];
      if (this.backgroundColor) defaultClasses.push('select--bg', 'select--bg-' + this.backgroundColor);
      if (this.rounded) defaultClasses.push('select--rounded');
      if (this.showFromTop) defaultClasses.push('select--show-from-top');
      if (this.showDropdownBody) defaultClasses.push('select--active');
      if (this.highlightedText) defaultClasses.push('select--highlighted');

      return defaultClasses;
    }
  },


  async mounted() {
    this.loadOnMount();

    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.showDropdownBody = false
      }
    })
  },
  watch: {
    items() {
      this.loadOnMount()
    }
  }

};
</script>
<style lang="scss" src="./Select.scss"/>
