<template>
  <div class="levels-field">
    <div
      v-for="(level, index) in maxLevel + 1"
      :key="index"
      @click="setLevel(index)"
      :class="[
        'levels-field__item',
        activeLevel === index + 1 ? 'levels-field__item--active' : '',
        isFilledLevel(index) ? 'levels-field__item--filled' : ''
      ]"
    >
      {{ index + 1 }}
    </div>
    <!-- если надо добавить + -->
    <!-- <div class="levels-field__item" @click="addLevel">
      &#43;
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'LevelsField',
  data() {
    return {
      activeLevel: 1,
      levelsCount: 2
    };
  },
  props: {
    maxLevel: Number,
    getActiveLevel: Function,
    filledLevels: {
      type: Array,
      default: () => []
    }
  },
  computed: {},

  methods: {
    isFilledLevel(level) {
      return this.filledLevels.includes(level);
    },
    deleteLevel() {
      this.levelsCount--;
    },
    addLevel() {
      this.levelsCount++;
    },
    setLevel(level) {
      console.log(level, 'active level');
      this.activeLevel = level + 1;
      this.$emit('getActiveLevel', this.activeLevel);
    }
  }
};
</script>

<style lang="scss" src="./LevelsField.scss" />
