var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color-picker"},[_c('div',{staticClass:"color-picker__wrapper"},_vm._l((_vm.colors),function(column,index){return _c('div',{key:index,staticClass:"color-picker__column",style:({
        width: _vm.itemWidth
      })},_vm._l((column),function(color){return _c('div',{key:color,class:[
          'color-picker__item',
          {
            'color-picker__item--active': _vm.value === color
          }
        ],style:({
          'background-color': color,
          height: _vm.itemHeight
        }),on:{"click":function($event){return _vm.updateInput(color)}}})}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }