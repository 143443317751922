<template>
  <div class="game__actions__advanced">
    <div
      v-for="item in btns"
      :key="item"
      :class="[
        'game__actions__advanced__item',
        {
          'game__actions__advanced__item--active': active === item
        }
      ]"
      @click="setPercentage(item)"
    >
      {{ item }}%
    </div>
  </div>
</template>
<script>
export default {
  name: 'PercentageSelectorsForAdvancedMode',
  props: {
    btns: Array,
    current: Number
  },
  data() {
    return {
      active: null
    }
  },

  methods: {
    setPercentage(percentage) {
      this.active = percentage;
      this.$emit('changeBtn', percentage);
    }
  },
  watch: {
    current(val) {
      this.setPercentage(val);
    }
  }
};
</script>