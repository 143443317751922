var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'langs',
    {
      'langs--dark': _vm.dark,
      'langs--uppercase': _vm.uppercase,
      'langs--bold': _vm.bold
    }
  ]},[(_vm.mobile)?_vm._l((_vm.langs),function(lang){return _c('div',{key:lang.value,class:[
        'langs__item',
        {
          'langs__item--active': _vm.activeLang === lang.value
        }
      ],on:{"click":function($event){return _vm.changeLang(lang.value)}}},[_vm._v(" "+_vm._s(lang.label)+" ")])}):[_c('div',{staticClass:"langs__item",style:({ color: _vm.langDropdownActive ? '#12b7db' : '#fff' }),on:{"click":function($event){return _vm.openLangDropdown()}}},[_vm._v(" "+_vm._s(_vm.computeActiveLang)+" "),_c('svg',{staticClass:"langs__item__icon",attrs:{"data-src":"/icons/triangle.svg"}}),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.langDropdownActive),expression:"langDropdownActive"}],staticClass:"langs__item__dropdown-body"},_vm._l((_vm.computedDropdownLangs),function(lang){return _c('div',{key:lang.value,staticClass:"langs__item__dropdown-item",on:{"click":function($event){return _vm.changeLang(lang.value)}}},[_vm._v(" "+_vm._s(lang.label)+" ")])}),0)])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }