<template>
  <label
    :class="[
      'checkbox-container',
      {
        'checkbox-container--bordered': bordered,
        'checkbox-container--no-label': !label
      }
    ]"
  >
    {{ label }}
    <input
      v-model="dataModel"
      :value="value"
      type="checkbox"
      :disabled="disabled"
      @change="updateInput"
    />

    <span class="checkbox-container__checkmark" />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'model'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    model: null,
    value: null,
    label: String,
    bordered: Boolean
  },
  data() {
    return {
      dataValue: this.value,
      dataModel: this.model
    };
  },
  methods: {
    updateInput(event) {
      const isArray = Array.isArray(this.dataModel);

      // If values of checkbox is just a standalone v-model toggler (e.g true/false toggler)
      if (!isArray) {
        this.$emit('input', !this.model);
        return;
      }

      // If values of checkbox is array (e.g. multiple values picker : fruits - apple, orange, bananas);
      let isChecked = event.target.checked;
      if (isChecked) {
        this.dataModel.push(this.value);
      } else {
        this.dataModel = this.dataModel.findIndex(item => {
          // If item is object, then find by it's ItemValue Property name
          // If just a primitive type, then by it's whole value
          if (item instanceof Object) {
            return item[this.itemValue] === this.dataModel[this.itemValue];
          } else {
            return item === this.dataModel;
          }
        });
      }
    }
  },
  watch: {
    model(val) {
      this.dataModel = val;
    }
  }
};
</script>

<style lang="scss" src="./Checkbox.scss" />
