const isUnic = (itemValue, index, arr) => arr.indexOf(itemValue) == index;
// filter out non unique values by index check

const isMatchingDefault = (n) => n == 25 || n == 50 || n == 75;
// is mathing default value 


const setStatic = (n) => {
  // set default false option if correct is default value (25, 50, 75)
  switch (n) {
    case 75:
      return [25, 50];
    case 50:
      return [25, 75];
    case 25:
      return [50, 75];
  }
  return [n];
};


export const GET_STATE_RADIO_BUTTONS = (allWeight, weight, situation) => {
  // allWeight - all cells with weight (array with objects (cells))
  // weight - current cell obj w weights

  let weightValues;

  if (situation == 'VsRFIr1hBB') {
    console.log('VsRFIr1hBB buttons');
    // extract values due object bug in prev solution
    const {allin, raise, check} = weight;
    weightValues = [allin, raise, check];
  } else {
    // DEFAULT
    // get array of weight values
    weightValues = Object.values(weight);
  }


  // console.log('weightValues');
  // console.log(weightValues);

  let result = [];

  // get array of fixed correct unique values for current diapason's cell
  let uniqValues = weightValues
    .filter(isUnic)
    .filter((el) => el)
    .map((el) => +el.toFixed(0));
  // console.log('uniqValues');
  // console.log(uniqValues);


  // all next unique non default values left in the game
  let allValues = allWeight
    .map((el) => Object.values(el))
    .reduce((acc, val) => [...acc, ...val], [])
    .filter(isUnic)
    .filter((n) => n)
    .filter((n) => n !== 100)
    .map((el) => +el.toFixed(0))
    .reduce((acc, val) => {
      if (uniqValues.includes(val)) return acc;
      return [...acc, val];
    }, []);

  // console.log('allValues');
  // console.log(allValues);

  // current result
  result = uniqValues;

  if (allValues.length == 0 && result.length == 0) return [25, 50, 75];

  // if 2 unique values
  if (result.length == 2) {
    // console.log('result.length == 2');
    let [first, second] = result;
    // if any of two match default
    if (isMatchingDefault(first) && isMatchingDefault(second)) {
      result = [first, second, ...setStatic(first), ...setStatic(second)].filter((n) => n);
      result = result.filter(isUnic);

      //  result = result.filter(isUnic);
    } else {
      // else 2 unique + 1 false
      result = [...result, ...allValues.slice(0, 2)];
      // console.log('else');
      // console.log(result);
    }
  }
  if (result.length == 1) {
    // console.log('result.length == 1');
    // if 100
    if (result[0] == 100) {
      // generate 3 false values
      result = [25, 50, 75];
    } else if (isMatchingDefault(result[0])) {
      // if match default values - merge default correct and 2 default wrong (false)
      result = [...result, ...setStatic(result[0])];
    } else {
      // if no default match
      // add 2 next values as wrong
      result = [...result, ...allValues.slice(0, 3)];

    }
  }

  if (result.length == 0) {

    allValues = allValues.sort(() => Math.random() * 100);
    // generate 3 false values
    result = [...allValues.slice(0, 4)];
  }


  let arr = [25, 50, 75];

  // if not 3 correct values - add fale values
  while (result.length < 3) {
    result = [...result, arr.pop()].filter(isUnic);
  }

  // exclude 100 and sort the final
  return result.filter((el) => el !== 100).sort();
}