<template>
  <div
    :class="[
      'langs',
      {
        'langs--dark': dark,
        'langs--uppercase': uppercase,
        'langs--bold': bold
      }
    ]"
  >
    <template v-if="mobile">
      <div
        v-for="lang in langs"
        :key="lang.value"
        :class="[
          'langs__item',
          {
            'langs__item--active': activeLang === lang.value
          }
        ]"
        @click="changeLang(lang.value)"
      >
        {{ lang.label }}
      </div>
    </template>
    <template v-else>
      <div
        class="langs__item"
        @click="openLangDropdown()"
        :style="{ color: langDropdownActive ? '#12b7db' : '#fff' }"
      >
        {{ computeActiveLang }}
        <svg class="langs__item__icon" data-src="/icons/triangle.svg" />

        <transition name="fade">
          <div class="langs__item__dropdown-body" v-show="langDropdownActive">
            <div
              class="langs__item__dropdown-item"
              v-for="lang in computedDropdownLangs"
              :key="lang.value"
              @click="changeLang(lang.value)"
            >
              {{ lang.label }}
            </div>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import { LANG_ENG, LANG_RU, LANG_UA } from '@/constants/languages';
import Vue from 'vue';

export default {
  name: 'LangSwitcher',
  props: {
    bold: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      langDropdownActive: false,
      langs: [
        {
          label: 'en',
          value: LANG_ENG
        },
        {
          label: 'ru',
          value: LANG_RU
        },
        {
          label: 'ua',
          value: LANG_UA
        }
      ]
    };
  },
  computed: {
    computedDropdownLangs() {
      return this.langs.filter(lang => this.activeLang != lang.value);
    },
    computeActiveLang() {
      return this.activeLang;
    }
  },
  methods: {
    openLangDropdown() {
      this.langDropdownActive = !this.langDropdownActive;
    },
    changeLang(lang) {
      console.log('lang to change', lang);
      Vue.$track.setLang(lang);
      localStorage.setItem('activeLang', lang);
      this.$store.commit('ui/SET_LOCALIZATION', lang);
      this.$emit('changeLang', lang);
    }
  },
  mounted() {
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.langDropdownActive = false;
      }
    });
  }
};
</script>

<style lang="scss" src="./LangSwitcher.scss" />
