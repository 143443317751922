<template>
  <div class="benefits-selector">
    <div
      v-for="(benefit, benefitIndex) in benefits"
      :key="benefit"
      class="benefits-selector__item"
    >
      <input
        :id="benefitIndex"
        v-model="selectedBenefits"
        :value="benefitIndex"
        type="checkbox"
      >
      <label :for="benefitIndex">
        {{ benefit }}
      </label>
    </div>
  </div>
</template>

<script>


export default {
  name: 'BenefitsSelector',
  props: {
    passedBenefits: Array
  },
  data() {
    return {
      selectedBenefits: []
    };
  },
  computed: {
    benefits() {
      return this.localization.store.benefits
    }
  },
  methods: {
    getChanges() {
      return this.selectedBenefits;
    }
  },

  watch: {
    passedBenefits(value) {
      this.selectedBenefits = value
    }
  }

};
</script>
<style lang="scss" src="./BenefitsSelector.scss"/>
