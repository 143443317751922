<template>
  <div class="container profile">
    <UserDataTab :referralIsEnabled="referralIsEnabled" />
    <GlobalPreferences />
    <ReferralsTab v-if="referralIsEnabled" />
    <ChangePassword />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserDataTab from '@/components/ProfileTabs/UserDataTab';
import ReferralsTab from '@/components/ProfileTabs/ReferralsTab';
import GlobalPreferences from '@/components/ProfileTabs/GlobalPreferences';
import ChangePassword from '@/components/ProfileTabs/ChangePassword';

export default {
  name: 'Profile',
  components: {
    UserDataTab,
    ReferralsTab,
    ChangePassword,
    GlobalPreferences
  },
  computed: {
    ...mapState('user', ['userInfo']),
    referralIsEnabled() {
      return (
        this.userInfo &&
        this.userInfo.referrer &&
        this.userInfo.referrer.isReferralSystemEnabled
      );
    }
  },
  async created() {
    this.$track.openProfile();
  }
};
</script>
<style lang="scss" src="../../assets/scss/profile.scss" />
