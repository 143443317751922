<template>
  <div class="modal-dialog__body">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "DialogContent"
}
</script>

<style scoped>

</style>