/* eslint-disable */
import {GET_LANG_LANDING, GET_LANG_PRICE} from '@/api/localizations';

const state = {
  landingLangPrice: {},
  landingLang: {}
};

const getters = {
  getLandingPriceLang: state => {
    return state.landingLangPrice;
  },
  getLandingLang: state => {
    return state.landingLang;
  },
};

const actions = {
  async LOAD_LOCALIZATION_PRICE({commit}, lang) {
    const langBody = await GET_LANG_PRICE(lang);
    commit('SET_LANDING_LANG_PRICE', langBody);
  },
  async LOAD_LOCALIZATION_LANDING({commit}, lang) {
    const langBody = await GET_LANG_LANDING(lang);
    commit('SET_LOCALIZATION_LANDING', langBody);
  },
};

const mutations = {
  SET_LOCALIZATION_LANDING: (state, data) => {
    state.landingLang = data;
  },
  SET_LANDING_LANG_PRICE: (state, data) => {
    state.landingLangPrice = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};