var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'expander__item',
    {
      'expander__item--active': _vm.isActive,
      'expander__item--accent': _vm.accent
    }
  ]},[_c('div',{class:[
      'expander__item__header',
      { 'expander__item--tabs_height': _vm.preventAction }
    ],on:{"click":function($event){return _vm.toggle()}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preventAction),expression:"!preventAction"}],staticClass:"expander__item__header__opened-icon",attrs:{"src":"/icons/chevron-right.svg"}}),_c('div',{staticClass:"expander__item__header__title"},[_vm._t("header")],2),_c('div',{staticClass:"expander__item__header__right"},[_vm._t("header-buttons")],2)]),_c('div',{ref:"content",staticClass:"expander__item__content"},[_c('div',{staticClass:"expander__item__content__wrapper"},[_c('div',{ref:"inner"},[_vm._t("content")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }