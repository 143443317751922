<template>
  <div class="radio-group">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "RadioGroup",
  props: {
    value: null,
  },
  data() {
    return {
      radios: []
    }
  },
  methods: {
    emitChanges(val) {
      this.$emit('input', val);
      this.setValue(val);
    },
    setValue(val) {
      this.radios.forEach(radio => {
        radio.isChecked = val === radio.value;
      })
    }
  },
  mounted() {
    this.radios = this.$children;
    this.setValue(this.value);
  }
}
</script>

<style lang="scss" src="./Radio.scss"/>
