<template>
  <label class="radio-container">
    {{ label }}
    <input
      :checked="isChecked"
      :value="value"
      type="radio"
      :disabled="disabled"
      @input="updateInput"
    />
    <span class="radio-container__input" />
  </label>
</template>

<script>
export default {
  name: 'Radio',
  data() {
    return {
      isChecked: false
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: null,
    label: String
  },
  methods: {
    updateInput() {
      this.$parent.emitChanges(this.value);
    }
  }
};
</script>
