import http from "../utils/http";

export const GET_STORE_LICENSES = async () => {
  const {data} = await http.get('/Store/Licenses');
  return data;
};
export const GET_STORE_CART_ACTIVE = async () => {
  const {data} = await http.get('/Store/Cart/Active');
  return data;
};
export const GET_STORE_LICENSES_DESCRIPTORS = async () => {
  const {data} = await http.get('/Store/Licenses/Descriptors');
  return data;
};
export const GET_STORE_ITEMS = async () => {
  const {data} = await http.get('/Store/Items');
  return data;
};
export const GET_STORE_ITEMS_ITEMS = async (items) => {
  const {data} = await http.get('/Store/Items/' + items);
  return data;
};
export const GET_STORE_ITEMS_CHILDREN = async (itemId) => {
  const {data} = await http.get('/Store/Items/' + itemId + '/Children');
  return data;
};
export const SAVE_STORE_CART = async ({licenses, premiumItems}) => {
  await http.put('Store/Cart', {
    licenses,
    premiumItems
  });
};
export const CREATE_STORE_FASTSPRING_CART = async (licenses) => {

  const {data} = await http.post('Store/Fastspring/Cart', {
    "licenses": licenses,
    "premiumItems": []
  });

  return data;

};