var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-changer show-sm"},[_c('div',{staticClass:"search-changer__row"},[_c('div',{class:[
        'search-changer__item',
        {
          'search-changer__item--active': _vm.currentViewIsMain
        }
      ],on:{"click":function($event){return _vm.setHash('main')}}},[_c('svg',{staticClass:"search-changer__item__icon",attrs:{"data-src":"/icons/search.svg"}}),_vm._v(" "+_vm._s(_vm.localization.general.SearchViewerBtns.Search)+" ")]),_c('div',{class:[
        'search-changer__item',
        {
          'search-changer__item--active': _vm.currentViewIsDiapasons
        }
      ],on:{"click":function($event){return _vm.setHash('diapasons')}}},[_c('svg',{staticClass:"search-changer__item__icon",attrs:{"data-src":"/icons/table.svg"}}),_vm._v(" "+_vm._s(_vm.localization.general.SearchViewerBtns.Diapasons)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }