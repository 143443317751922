<template>
  <Dialog
    v-model="computedValue"
    class="create-group-dialog modal-dialog--size-to-content modal-dialog--width-medium"
  >
    <DialogHeader>
      {{ localization.general.MainPage.SaveLastChangesTitle }}
    </DialogHeader>
    <DialogContent>
      <div class="form-block">
        <label class="form-label-text">
          {{ localization.general.MainPage.SaveLastChangesText }}
        </label>
      </div>
    </DialogContent>
    <DialogFooter>
      <Btn :loading="requestPending" @click="saveLastChanges()">
        {{ localization.general.Simple.Yes }}
      </Btn>
      <Btn @click="openNextItemWithoutSave()">
        {{ localization.general.Simple.No }}
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';

export default {
  name: 'SaveChangesDialog',
  data() {
    return {
      requestPending: false
    };
  },
  components: { Btn, Dialog, DialogContent, DialogFooter, DialogHeader },

  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        console.log('this.value', this.value);
        this.$emit('openNextItem');
        this.$emit('input', false);
      }
    }
  },
  methods: {
    async saveLastChanges() {
      this.requestPending = true;
      await this.handler()
        .then(() => this.closeModal())
        .then(() => {
          this.requestPending = false;
        });
    },
    openNextItemWithoutSave() {
      this.$emit('openNextItem');
      this.closeModal();
    },
    closeModal() {
      this.$emit('input', false);
    }
  },
  props: {
    openNextItem: {
      type: Function
    },
    value: {
      type: Boolean
    },
    handler: {
      type: Function
    }
  }
};
</script>
<style lang="scss" src="./SaveChangesDialog.scss"></style>
