<template>
  <div
    :class="[
      'string-parser',
      {
        'string-parser--disabled': disabled
      }
    ]"
  >
    <input
      v-model="stringForParse"
      :class="{
        'string-parser__input--invalid': this.result.isError
      }"
      :disabled="disabled"
      :placeholder="localization.general.TextFieldParser.Input"
      class="string-parser__input"
      @input="onInput"
      @keyup.enter="getColodes()"
    />

    <svg
      :class="['string-parser__icon', { 'string-parser--disabled': disabled }]"
      data-src="icons/arrow-right.svg"
      @click="getColodes()"
    />
    <FastFaqButton
      @faqToggler="onFaqButtonClick"
      :itemsInFaq="['ImportRanges']"
      :isLightButton="true"
      lightName="rangeStringParser"
      :style="{ right: '-30px', top: '4px !important' }"
    />
  </div>
</template>

<script>
import { getColodesFromString } from '@/utils/colode';
import FastFaqButton from '@/components/FastFaqDialog/FastFaqButton';

export default {
  name: 'RangeStringParser',
  data() {
    return {
      stringForParse: '',
      result: {},
      buttonStyle: {
        width: '34px',
        height: '34px'
      }
    };
  },
  components: {
    FastFaqButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    faqToggler: Function,

    rangeStringParse: Function
  },
  methods: {
    onFaqButtonClick(nodes) {
      this.$emit('faqToggler', nodes);
    },
    getColodes() {
      if (this.disabled) return;

      this.$track.parseHands();

      this.result = getColodesFromString(this.stringForParse);
      this.stringForParse = '';
      this.$emit('rangeStringParse', this.result);
    },
    onInput() {
      console.log('input string', this.stringForParse);
      this.result.isError = false;
    }
  }
};
</script>
<style lang="scss" src="./RangeStringParser.scss" />
