import http from '../utils/http';

export const UPDATE_USER_LIMIT = async data =>
  await http.put('/UserLimits', data);

export const GET_USER_LIMITS = async userId => {
  if (userId) {
    const { data } = await http.get(`/UserLimits?userId=${userId}`);
    return data;
  }

  const { data } = await http.get(`/UserLimits`);
  return data;
};
