import http from "../utils/http";

export const GET_PRICE_MODIFIERS = async () => {
  const {data} = await http.get('PriceModifiers');
  return data;
};
export const EDIT_PRICE_MODIFIER = async DTO => {
  const {data} = await http.put('PriceModifiers', DTO);
  return data;
};
export const CREATE_PRICE_MODIFIER = async (newPriceModifier) => {

  const {data} = await http.post('PriceModifiers', {
    params: {
      newPriceModifier
    }
  });

  return data;

};
export const DELETE_PRICE_MODIFIER = async id => {
  await http.delete('PriceModifiers', {
    params: {
      id
    }
  });
};
