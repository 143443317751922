<template>
  <div class="modal-dialog__header">
    <div class="modal-dialog__title">
      <slot />
    </div>
    <img
      v-if="!persistent"
      class="modal-dialog__close"
      src="/icons/close.svg"
      @click="$parent.closeModal()"
    />
  </div>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: {
    persistent: {
      type: Boolean,
      default: false
    }
  }
};
</script>
