<template>
  <div class="statistics-row">
    <GroupBox accent>
      <template v-slot:header>
        {{ localization.general.Profile.UsersStatistics }}
      </template>
      <template v-slot:content>
        <table class="c-simple-table">
          <tbody>
            <tr>
              <td>
                {{ localization.general.Profile.ActiveUsersToday }}
              </td>
              <td>{{ usersStatistics.activeUsersToday }}</td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.ActiveUsersThisWeek }}
              </td>
              <td>{{ usersStatistics.activeUsersThisWeek }}</td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.ActiveUsersThisMonth }}
              </td>
              <td>{{ usersStatistics.activeUsersThisMonth }}</td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.NewUsersToday }}
              </td>
              <td>{{ usersStatistics.newUsersToday }}</td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.NewUsersThisWeek }}
              </td>
              <td>{{ usersStatistics.newUsersThisWeek }}</td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.NewUsersThisMonth }}
              </td>
              <td>{{ usersStatistics.newUsersThisMonth }}</td>
            </tr>
            <tr>
              <td>
                {{ localization.general.Profile.TotalUsersNumber }}
              </td>
              <td>{{ usersStatistics.totalUsersNumber }}</td>
            </tr>
          </tbody>
        </table>
        <Btn @click="loadUserStatistics()" :loading="isUserStatisticsLoading">
          Load
        </Btn>
      </template>
    </GroupBox>
    <GroupBox accent>
      <template v-slot:header>
        Ranges Statistics
      </template>
      <template v-slot:content>
        <table class="c-simple-table">
          <tbody>
            <tr>
              <td>
                Regular Ranges:
              </td>
              <td>{{ itemsStatistics.regular.diapasons }}</td>
            </tr>
            <tr>
              <td>
                Regular Groups:
              </td>
              <td>{{ itemsStatistics.regular.groups }}</td>
            </tr>

            <tr>
              <td>
                Premium Ranges:
              </td>
              <td>{{ itemsStatistics.premium.diapasons }}</td>
            </tr>
            <tr>
              <td>
                Premium Groups:
              </td>
              <td>{{ itemsStatistics.premium.groups }}</td>
            </tr>
          </tbody>
        </table>
        <Btn @click="loadRangeStatistics()" :loading="isRangeStatisticsLoading">
          Load
        </Btn>
      </template>
    </GroupBox>
  </div>
</template>

<script>
import { GET_ITEMS_STATISTICS, GET_USERS_STATISTICS } from '@/api/statistics';
import GroupBox from '../../../components/GroupBox/GroupBox';
import Btn from '@/components/UI/Btn';

export default {
  name: 'UsersStatistics',
  components: { Btn, GroupBox },
  data() {
    return {
      isUserStatisticsLoading: false,
      isRangeStatisticsLoading: false,
      usersStatistics: {},
      itemsStatistics: {
        premium: {},
        regular: {}
      }
    };
  },
  methods: {
    async loadRangeStatistics() {
      this.isRangeStatisticsLoading = true;
      this.itemsStatistics = await GET_ITEMS_STATISTICS();
      this.isRangeStatisticsLoading = false;
    },
    async loadUserStatistics() {
      this.isUserStatisticsLoading = true;
      this.usersStatistics = await GET_USERS_STATISTICS();
      this.isUserStatisticsLoading = false;
    }
  }
};
</script>
<style lang="scss">
.statistics-row {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  .group-box__item {
    width: 500px;
    max-width: 50%;
    margin: 0 15px;

    &__content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin-top: auto;
      }
    }
  }
}
</style>
