var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth__tab"},[_c('router-link',{class:[
      'auth__tab__item',
      {
        'auth__tab__item--active': _vm.$route.name === 'Sign In'
      }
    ],attrs:{"to":{
      name: 'Sign In'
    }}},[_vm._v(" "+_vm._s(_vm.localization.general.FormElements.Login)+" ")]),_c('router-link',{class:[
      'auth__tab__item',
      {
        'auth__tab__item--active': _vm.$route.name === 'Sign Up'
      }
    ],attrs:{"to":{
      name: 'Sign Up'
    }}},[_vm._v(" "+_vm._s(_vm.localization.general.FormElements.Registration)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }