<template>
	<div class="range-warning">
		<img src="/img/warning.png" alt="warning" class="range-warning--icon">
		<p class="range-warning--title">{{ localization.general.MainPage.RangeWarning }}</p>
	</div>
</template>

<script>
export default {
	name: 'RangeWarning',
}
</script>

<style lang="scss" src="./RangeWarning.scss"/>