<template>
  <transition>

    <div
      :class="computedClasses"
      :data-position-number="positionNumber"
      :data-table-size="tableSize"
    >

      <Stats
        v-if="playerToParticipant"
        v-show="
          playerToParticipant.notes.trim()
        "
        :activePlayer="playerName"
        :participant="playerToParticipant"
        :statsIconPosition="statsIconPosition"
        :isMe="isMe"
        :isTopPlayer="isTopPlayer"
        ref="statistic"
      />

<!--      ++++++++++{{playerToParticipant}}&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
      <div class="player__hand" @contextmenu="emitHandTypePicker($event)">
        <div class="player__hand player__hand-stat" v-if="!isMe && allParticipant.stats">{{allParticipant.stats}}</div>
        <Card
          v-for="({ m, card }, i) in cards"
          :key="i"
          :name="card"
          :suit="m"
          :yellowColor4="isColoredCards"
        />
      </div>
      <div class="game__player-position__block" @click="clickHandler">
        <!-- Блок отвечает за цвет бекграуда на аватарке(обводка), а так же, за урл аватарки-->
        <div
          :class="[
            'game__player-position__ava',

            { avatar_after: isAvatarMode }
          ]"
          :style="{
            background: createBackgroundGradient,
            '--bgColor': color || 'rgba(39, 49, 66, 1)',
            '--url': `url(${isMe ? '/favicon-96x96.png' : avatar.src})`
          }"
        >
          <!-- Цвет бекграуда круга игрока в компьютед createBackroundAvatar -->
          <div
            :style="{
              color: checkIfBGColorIsLight ? 'inherit' : 'white',

              'background-color': createBackroundAvatar
            }"
          >
            <!-- Если выбран аватар мод то здесь флаш для отображения автара -->
            <template v-if="false">
              <img
                v-if="!isMe"
                :src="avatar.src"
                class="game__player-position__ava__circle__image"
              />
              <img
                v-if="isMe"
                src="/favicon-96x96.png"
                class="game__player-position__ava__circle__image"
              />
            </template>
          </div>
        </div>
        <div
          class="game__player-position__info"
          :style="{
            background: createBackgroundGradient
          }"
        >

          <div class="game__player-position__info__wrapper">
            <h4 class="game__player-position__name">
              <span>
                {{
                  isAvatarMode
                    ? isMe
                      ? 'Hero'
                      : avatar.name
                    : getCustomNameOfPosition(playerName)
                }}
              </span>
            </h4>

            <h5 class="game__player-position__stack">
              <!-- {{ pot - bet }} BB -->
              <!-- Отображение стека игрока -->
              <template v-if="!globalShortDesk && pot - bet == 0 && cards && cards.length">
                All In
              </template>
              <template v-if="globalShortDesk && pot - p_bet == 0 && cards && cards.length">
                All In
              </template>
              <template v-else>
                <template v-if="!globalShortDesk && isMe">{{ pot - bet }} BB</template>
                <template v-if="globalShortDesk && isMe">{{ pot - p_bet }} BB</template>

                <!-- <template v-if="(card.length && !isMe) || (bet > 0 && !isMe)">
                {{ pot - bet }} BB
              </template> -->
                <template v-if="!globalShortDesk && bet >= 0 && cards.length && !isMe > 0">{{ pot - bet }} BB</template>
                <template v-if="globalShortDesk && p_bet >= 0 && cards.length && !isMe > 0">{{ pot - p_bet }} BB</template>

                <!-- <template v-if="bet == 0.5 && cards.length && !isMe > 0">
                {{ pot - bet }} BB
              </template>

              <template v-if="bet === 1 && cards.length && !isMe > 0"
                >{{ pot - bet }} BB</template
              > -->
                <template v-if="cards && !cards.length && !isMe > 0"
                  >(Fold)</template
                >
              </template>
            </h5>
          </div>
        </div>
      </div>
      <div v-if="isDealer" class="game__player-position__dealer-icon">
        D
      </div>
      <!--
      разметка для показа фишек - я бы мог просто загрузить иконку в img,
      но с этим вариантом можно менять размер, кол-во, отступ между фишками
    !-->

      <div v-if="bet && !globalShortDesk" class="game__player-position__bet">
        <div class="game__player-position__bet__chips">
          <div
            class="game__player-position__bet__chips__item game__player-position__bet__chips__item--aqua"
          >
            <div
              v-for="item in chipsStackSize[0]"
              v-bind:key="item"
              class="game__player-position__bet__chip"
            />
          </div>
          <div
            class="game__player-position__bet__chips__item game__player-position__bet__chips__item--blue"
          >
            <div
              v-for="item in chipsStackSize[1]"
              v-bind:key="item"
              class="game__player-position__bet__chip"
            />
          </div>
        </div>
        <div class="game__player-position__bet__value">
          {{ bet }}
        </div>
      </div>
<!--      isDealer-->
      <div v-else-if="globalShortDesk && p_bet > 1" class="game__player-position__bet">
        <div class="game__player-position__bet__chips">
          <div
            class="game__player-position__bet__chips__item game__player-position__bet__chips__item--aqua"
          >
            <div
              v-for="item in chipsStackSize[0]"
              v-bind:key="item"
              class="game__player-position__bet__chip"
            />
          </div>
          <div
            class="game__player-position__bet__chips__item game__player-position__bet__chips__item--blue"
          >
            <div
              v-for="item in chipsStackSize[1]"
              v-bind:key="item"
              class="game__player-position__bet__chip"
            />
          </div>
        </div>
        <div class="game__player-position__bet__value">
          {{ p_bet - 1 }}
        </div>
      </div>

      <slot name="timer" />
      <div class="game__player-position__statistics" v-if="isMe && playerToParticipant.stats">
        <div class="game__player-position__statistics--center">{{playerToParticipant.stats}}</div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';
import { CHECK_IF_COLOR_IS_LIGHT } from '@/utils/colors';
import { getCustomNameOfPosition } from '@/utils/positions';
import Card from '@/components/Game/Card';
import Stats from '@/components/Game/Stats/Stats';

export default {
  name: 'Position',
  components: {
    Card,
    Stats
  },
  props: {
    cards: {
      type: Array,
      default: () => []
    },
    isAvatarMode: Boolean,
    allParticipant: Object,
    isColoredCards: Boolean,
    contextCallHandTypePicker: Function,
    avatar: Object,
    players: Array,
    tableSize: [Number, String],
    playerName: String,
    positionNumber: [String, Number],
    isDealer: [Boolean, undefined],
    isMe: Boolean,
    bet: [Number, undefined],
    p_bet: [Number, undefined],
    pot: Number,
    color: String
  },
  data() {
    return {
      statsIconPosition: '',
      isTopPlayer: false
    };
  },
  watch: {
    tableSize() {
      this.getStatsIconPosition();
    }
  },
  created() {
    this.getStatsIconPosition();
  },
  methods: {
    clickHandler() {
      console.log('CLICKED777', this.playerName);
      this.$emit('clickPlayer', this.playerName);
    },
    emitHandTypePicker(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!this.isMe) return;
      console.log(e, 'EVENT CONTEXT');
      let bodyRect = document.body.getBoundingClientRect();
      let elem = document.querySelector('.player__hand');
      let elemRect = elem.getBoundingClientRect();
      let offset = elemRect.top - bodyRect.top;
      console.log(bodyRect, elem, elemRect, offset, 'rectangles');
      this.$emit('contextCallHandTypePicker', elemRect);
    },
    getCustomNameOfPosition,
    getStatsIconPosition() {
      console.log('changed');
      const OWN_PLAYER_POSITION = 0;
      const RIGHT = 'right';
      const LEFT = 'left';
      if (this.positionNumber === OWN_PLAYER_POSITION) {
        this.statsIconPosition = RIGHT;
        return;
      } else {
        this.statsIconPosition = LEFT;
      }

      /* Выбираем расположение исходя из размера и номера позиции */

      /* TODO: переписать это волокно из свитчей */
      switch (this.tableSize) {
        case 2:
          switch (this.positionNumber) {
            case 0:
              this.statsIconPosition = RIGHT;
              break;
            case 3:
              this.statsIconPosition = LEFT;
              this.isTopPlayer = true;

              break;
          }
          break;
        case 3:
          switch (this.positionNumber) {
            case 0:
              this.statsIconPosition = RIGHT;
              break;
            case 2:
              this.statsIconPosition = LEFT;
              break;
            case 4:
              this.statsIconPosition = RIGHT;
              break;
          }
          break;
        case 5:
          switch (this.positionNumber) {
            case 0:
              this.statsIconPosition = RIGHT;
              break;
            case 1:
              this.statsIconPosition = LEFT;
              break;
            case 2:
              this.statsIconPosition = LEFT;
              break;
            case 3:
              this.statsIconPosition = LEFT;
              this.isTopPlayer = true;
              break;
            case 4:
              this.statsIconPosition = RIGHT;
              break;
          }
          break;
        case 6:
          switch (this.positionNumber) {
            case 0:
              this.statsIconPosition = RIGHT;
              break;
            case 1:
              this.statsIconPosition = LEFT;
              break;
            case 2:
              this.statsIconPosition = LEFT;
              break;
            case 3:
              this.statsIconPosition = LEFT;
              this.isTopPlayer = true;
              break;
            case 4:
              this.statsIconPosition = RIGHT;
              break;
            case 5:
              this.statsIconPosition = RIGHT;
              break;
          }
          break;
        case 8:
          switch (this.positionNumber) {
            case 0:
              this.statsIconPosition = RIGHT;
              break;
            case 1:
              this.statsIconPosition = LEFT;
              break;
            case 2:
              this.statsIconPosition = LEFT;
              break;
            case 3:
              this.statsIconPosition = LEFT;
              break;
            case 4:
              this.statsIconPosition = LEFT;
              this.isTopPlayer = true;
              break;
            case 5:
              this.statsIconPosition = RIGHT;
              break;
            case 6:
              this.statsIconPosition = RIGHT;
              break;
            case 7:
              this.statsIconPosition = RIGHT;
              break;
          }
          break;
      }
    }
  },
  computed: {
    ...mapGetters({
      globalPreferences: 'user/globalPreferences',
      globalShortDesk: 'user/globalShortDesk',
      // globalShortDesk() {
        // return this.dataItem.additionalProperties.shortDesk;
        // return this.dataItem.additionalProperties.shortDesk;
      // }
    }),
    playerToParticipant() {
      if (this.players) {
        let player = this.players.find(({position}) => position === this.positionNumber);
        console.log(player, 'PLAYER ITEM TO STATS');
        if (player) return player.participant;
      }
      return null;
    },
    playerToDialer() {
      if (this.players) {
        let player = this.players[this.positionNumber];
        console.log(this.positionNumber, '-this.positionNumber');
        console.log(player, ' - PLAYER ITEM TO STATS p_rate');
        if (player) return player;
      }
      return null;
    },
    maxBetSize() {
      var max = 0;

      if (this.players) {
        this.players.forEach(player => {
          let bet = player.rate;
          if (bet > max) {
            max = bet;
          }
        });
      }

      if (max < 0) {
        max = 0;
      }

      return max;
    },
    chipsStackSize() {
      if (this.maxBetSize == 0) return [[], []];
      let step = this.bet / this.maxBetSize;
      let chips = Math.round(Math.max(1, step * 6));
      let left = Math.min(3, chips);
      let right = Math.max(0, chips - 3);
      let leftArray = [];
      let rightArray = [];
      for (let i = 0; i < left; i++) {
        leftArray[i] = i;
      }
      for (let i = 0; i < right; i++) {
        rightArray[i] = i;
      }
      let result = [leftArray, rightArray];
      return result;
    },

    checkIfBGColorIsLight() {
      return CHECK_IF_COLOR_IS_LIGHT(this.color);
    },
    createBackroundAvatar() {
      if (!this.color)
        return;

      return `linear-gradient(180deg, ${this.color}, #15181e) !important`;
    },
    createBackgroundGradient() {
      if (!this.color)
        return;

      return `linear-gradient(180deg, ${this.color}, #15181e) !important`;
    },
    computedClasses() {
      let classes = ['game__player-position'];

      /*
       Нулевая позиция всегда у пользователя,
       и в любом размере стола он должен находиться
       вертикально внизу и
       горизонтально по центру.

       Если позиция принадлежит оппоненту - задаем ему соответсвующий класс
      */
      const OWN_PLAYER_POSITION = 0;
      if (this.positionNumber === OWN_PLAYER_POSITION) {
        classes.push(
          'game__player-position--is-me',
          'game__player-position--v-bottom-h-center',
          'game__player-position--ava-from-left',
          'game__player-position--position-left'
        );
        return classes;
      } else {
        classes.push('game__player-position--is-opponent');
      }
      console.log(this.cards, 'THIS CARDS??', this.positionNumber);
      // if (!this.bet || !this.cards?.length) {
      //   classes.push('game__player-position--no-bet');
      // }

      /* Выбираем расположение исходя из размера и номера позиции */

      /* TODO: переписать это волокно из свитчей */
      switch (this.tableSize) {
        case 2:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
          }
          break;
        case 3:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
          }
          break;
        case 5:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 1:
              classes.push(
                'game__player-position--v-bottom-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-center-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
            case 5:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 6:
              classes.push(
                'game__player-position--v-center-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 7:
              classes.push(
                'game__player-position--v-bottom-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
          }
          break;
        case 6:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 1:
              classes.push(
                'game__player-position--v-bottom-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 5:
              classes.push(
                'game__player-position--v-bottom-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
          }
          break;
        case 8:
          switch (this.positionNumber) {
            case 0:
              classes.push(
                'game__player-position--v-bottom-h-center',
                'game__player-position--ava-from-left',
                'game__player-position--position-left'
              );
              break;
            case 1:
              classes.push(
                'game__player-position--v-bottom-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 2:
              classes.push(
                'game__player-position--v-center-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 3:
              classes.push(
                'game__player-position--v-top-h-left',
                'game__player-position--ava-from-right',
                'game__player-position--position-left'
              );
              break;
            case 4:
              classes.push(
                'game__player-position--v-top-h-center',
                'game__player-position--ava-from-right',
                'game__player-position--position-right'
              );
              break;
            case 5:
              classes.push(
                'game__player-position--v-top-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 6:
              classes.push(
                'game__player-position--v-center-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
            case 7:
              classes.push(
                'game__player-position--v-bottom-h-right',
                'game__player-position--ava-from-left',
                'game__player-position--position-right'
              );
              break;
          }
          break;
      }

      return classes;
    }
  }
};
</script>
