<template>
  <button
    :class="computeClasses"
    :style="{
      width: computeWidth,
      height: computeHeight
    }"
    @click="runClickEvent"
  >
    <ProgressCircular
      v-if="loading !== null"
      v-show="loading"
      :color="computeProgressCircularColor"
    />
    <span class="c-button__text">
      <svg
        v-if="icon && color === 'green'"
        :data-src="icon"
        :alt="icon"
        class="c-button__icon"
      />
      <svg
        v-else-if="saveIcon && color === 'red'"
        :data-src="saveIcon"
        :alt="saveIcon"
        class="c-button__icon"
      />
      <slot />
    </span>
  </button>
</template>
<script>
import ProgressCircular from '@/components/UI/ProgressCircular';

export default {
  name: 'Btn',
  components: { ProgressCircular },
  data() {
    return {
      updateItemRequestPending: false
    };
  },
  props: {
    saveIcon: String,
    icon: String,
    width: [Number, String],
    height: [Number, String],
    usual: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'blue'
    }
  },
  computed: {
    computeProgressCircularColor() {
      return this.outlined ? '#404C61' : '#fff';
    },
    computeWidth() {
      return this.width + (typeof this.width === 'number' ? 'px' : '');
    },
    computeHeight() {
      return this.height + (typeof this.height === 'number' ? 'px' : '');
    },
    computeClasses() {
      let classes = ['c-button'];

      if (!this.usual) {
        let btnColor = 'c-button--' + this.color;
        classes.push(btnColor);
      }

      if (this.loading) classes.push('c-button--loading');

      if (this.outlined) classes.push('c-button--outlined');
      if (!this.outlined) classes.push('c-button--bg');
      if (this.usual) classes.push('c-button--usual');

      return classes;
    }
  },
  methods: {
    runClickEvent(e) {
      if (!this.loading) this.$emit('click', e);
    },
    customEvent(event) {
      this.$emit('click', event);
    }
  }
};
</script>

<style lang="scss" src="./Btn.scss" />
