var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-changer show-sm"},[_c('div',{staticClass:"view-changer__row"},[_c('div',{class:[
        'view-changer__item',
        {
          'view-changer__item--active': _vm.currentViewIsList
        }
      ],on:{"click":function($event){return _vm.setHash('list')}}},[_c('svg',{staticClass:"view-changer__item__icon",attrs:{"data-src":"/icons/list.svg"}}),_vm._v(" "+_vm._s(_vm.localization.general.RangeViewerBtns.List)+" ")]),_c('div',{class:[
        'view-changer__item',
        {
          'view-changer__item--active': _vm.currentViewIsEditor
        }
      ],on:{"click":function($event){return _vm.setHash('editor')}}},[_c('svg',{staticClass:"view-changer__item__icon",attrs:{"data-src":"/icons/table.svg"}}),_vm._v(" "+_vm._s(_vm.localization.general.RangeViewerBtns.Table)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }