import Vue from "vue";
import router from "../router";
import store from "../store";
import auth from "../auth";

export default function errorHandler(error) {
  if (error.response) {
    window.console.log(error.response);

    if (error.response.status == 401) {
      if (router.history.current.path !== "/signin") {
        Vue.$toast.error(store.state.ui.localization.general.Simple.NeedAutorize);
        auth.clearAuthorize();
        router.push("/signin");
      }
    }
  } else {
    window.console.log(error);
  }
}
