const trackingProperties = {
  categories: {
    engagement: 'engagement'
  },
  actions: {
    createDiapason: 'create_diapason',
    createGroup: 'create_group',
    editDiapason: 'edit_diapason',
    editGroup: 'edit_group',
    removeDiapason: 'remove_diapason',
    removeGroup: 'remove_group',
    startGame: 'start_game',
    finishGame: 'finish_game',
    login: 'login',
    logout: 'logout',
    beginRegistration: 'begin_registration',
    confirmRegistration: 'confirm_registration',
    restorePassword: 'restore_password',
    buyLicense: 'buy_license',
    buyLicenseCompleted: 'buy_license_completed',
    buyLicenseCompletedError: 'buy_license_completed_error',
    openRanges: 'open_ranges',
    openFaq: 'open_faq',
    openProfile: 'open_profile',
    openPrice: 'open_price',
    landingSignIn: 'landing_open_sign_in',
    openSignIn: 'open_sign_in',
    openLanding: 'open_landing',
    parseHands: 'parse_hands',
    changeProfileSettings: 'change_profile_settings',
    changePassword: 'change_password',
    activateLinkingMode: 'activate_linking_mode',
    fastFaq: 'fast_faq',
    openSearch: 'open_search',
    searchRanges: 'search_ranges',
    viewFoundRange: 'view_found_range',
    playFoundRange: 'play_found_range',
    gameSettingsUpdate: 'game_settings_update'
  },
  labels: {
    method: 'method'
  }
};

export default {
  install(Vue) {
    const gtag = Vue.$gtag;

    const trackEnabled = process.env.VUE_APP_TRACK_ENABLED;
    Vue.$track = Vue.prototype.$track = {
      parseHands() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.parseHands, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      setLang(lang) {
        if (trackEnabled) {
          gtag.event(`set_lang_${lang}`, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openSearch() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openSearch, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      searchRanges() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.searchRanges, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      viewFoundRange() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.viewFoundRange, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      playFoundRange() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.playFoundRange, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      gameSettingsUpdate() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.gameSettingsUpdate, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },

      changeProfileSettings() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.changeProfileSettings, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      changePassword() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.changePassword, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      activateLinkingMode() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.activateLinkingMode, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      fastFaq() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.fastFaq, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      createDiapason() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.createDiapason, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      createGroup() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.createGroup, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },

      editDiapason() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.editDiapason, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      editGroup() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.editGroup, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },

      deleteDiapason() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.removeDiapason, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      deleteGroup() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.removeGroup, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },

      startGame() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.startGame, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      finishGame() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.finishGame, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },

      login() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.login, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      logout() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.logout, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      beginRegistration() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.beginRegistration, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      confirmRegistration() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.confirmRegistration, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      restorePassword() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.restorePassword, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      buyLicense() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.buyLicense, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openRanges() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openRanges, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openFaq() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openFaq, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openProfile() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openProfile, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openPrice() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openPrice, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      landingSignIn() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.landingSignIn, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openSignIn() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openSignIn, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      openLanding() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.openLanding, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      buyLicenseCompleted() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.buyLicenseCompleted, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      },
      buyLicenseCompletedError() {
        if (trackEnabled) {
          gtag.event(trackingProperties.actions.buyLicenseCompletedError, {
            event_category: trackingProperties.categories.engagement,
            event_label: trackingProperties.labels.method
          });
        }
      }
    };
  }
};
