import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=841a4614&scoped=true"
import script from "./Stats.vue?vue&type=script&lang=js"
export * from "./Stats.vue?vue&type=script&lang=js"
import style0 from "./Stats.vue?vue&type=style&index=0&id=841a4614&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "841a4614",
  null
  
)

export default component.exports