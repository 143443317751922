<template>
  <div class="dashboard">
    <Header @faqButtonClick="onFaqButtonClick" />

    <router-view ref="view" />
    <FastFaqDialog v-model="isFaqActive" :activeFaqItems="faqActiveNodes" />
    <Footer
      :class="{
        'hide-sm':
          $route.path === '/main' ||
          $route.path === '/admin/premium-ranges' ||
          $route.path === '/search'
      }"
    />
  </div>
</template>

<script>
import RangeViewsMixin from '../mixins/RangeViewsMixin';
import Footer from '@/components/Layout/Footer';
import Header from '@/components/Layout/Header';

export default {
  name: 'Dashboard',
  components: {
    Header,
    Footer,
    FastFaqDialog: () => import('@/components/FastFaqDialog')
  },
  mixins: [RangeViewsMixin],

  data() {
    return {
      currentItem: null,
      selectedItems: [],
      faqActiveNodes: ['HotKeys'],
      isFaqActive: false,
      itemsStatistics: {
        regular: {
          diapasons: 0,
          groups: 0
        },
        premium: {
          diapasons: 0,
          groups: 0
        }
      }
    };
  },
  watch: {
    isFaqActive() {
      if (this.isFaqActive) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
  methods: {
    onFaqButtonClick(nodes) {
      console.log(nodes, 'noddd');
      this.faqActiveNodes = nodes;
      this.isFaqActive = true;
    }
  },
  created() {
    if (!this.$auth.isLogged()) {
      this.$router.push({
        name: 'Sign In'
      });
    }
  }
};
</script>

<style lang="scss" src="../assets/scss/dashboard.scss" />
