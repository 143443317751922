import http from '../utils/http';

export const GET_SHARING_KEY = async () => {
  const { data } = await http.put('Users/SharingKey');
  return data.newSharingKey;
};

export const SET_SHARING_HOST = async ({ userId, sharingHost }) => {
  await http.put('Users/SharingHost/Set', {
    userId: userId,
    sharingHost: sharingHost
  });
};

export const SAVE_SHARING_ITEMS = async ({ itemId, users }) => {
  const { data } = await http.put('Items/Sharing', {
    itemId: itemId,
    users: users
  });
  return data;
};

export const REFRESH_SHARING_ITEMS = async ({ itemId, userEmails }) => {
  const { data } = await http.put('Items/Sharing/Refresh', {
    itemId: itemId,
    userEmails: userEmails
  });
  return data;
};

export const GET_REFRESH_PROGRESS_LRO = async ({ id }) => {
  const { data } = await http.get(`Lro/${id}`, {
    params: {}
  });
  return data;
};
