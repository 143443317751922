<template>
  <div
    :class="{'group-box__item--accent': accent}"
    class="group-box__item"
  >
    <div
      class="group-box__item__header"
    >
      <slot name="header"/>
    </div>
    <div
      ref="content"
      class="group-box__item__content"
    >
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupBox",
  props: {
    accent: Boolean
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" src="./GroupBox.scss"/>
