<template>
  <div class="tabs__nav">
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'TabsNav',
  props: {
    value: Number
  },
  data() {
    return {
      tabs: [],
    };
  },
  methods: {
    selectTab(i) {

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })

      this.$emit('input', i)

    }
  },

  mounted() {
    this.tabs = this.$children;

    this.$children.forEach((tab, index) => {
      tab.key = index;
    })

    this.selectTab(this.value);

  },
  watch: {
    '$children'() {
      this.tabs = this.$children;

      this.tabs.forEach((tab, index) => {
        console.log(index);
        tab.key = index;

      })
    }
  }
}
</script>

<style lang="scss">
@import "Tabs";
</style>

