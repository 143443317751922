<template>
  <div class="color-picker">
    <div class="color-picker__wrapper">
      <div
        v-for="(column, index) in colors"
        :key="index"
        :style="{
          width: itemWidth
        }"
        class="color-picker__column"
      >
        <div
          v-for="color in column"
          :key="color"
          :class="[
            'color-picker__item',
            {
              'color-picker__item--active': value === color
            }
          ]"
          :style="{
            'background-color': color,
            height: itemHeight
          }"
          @click="updateInput(color)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',

  props: {
    itemWidth: String,
    itemHeight: String,
    value: String,
    column: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Array,
      default: () => [
        ['#4D4D4D', '#333', '#000'],
        ['#999', '#808080', '#666'],
        ['#eee', '#ccc', '#b3b3b3'],
        ['#F44E3B', '#D33115', '#9F0500'],
        ['#FE9200', '#E27300', '#C45100'],
        ['#FCDC00', '#FCC400', '#FB9E00'],
        ['#DBDF00', '#B0BC00', '#808900'],
        ['#A4DD00', '#68BC00', '#194D33'],
        ['#68CCCA', '#16A5A5', '#0C797D'],
        ['#73D8FF', '#009CE0', '#0062B1'],
        ['#AEA1FF', '#836DFF', '#653294'],
        ['#FDA1FF', '#FA28FF', '#AB149E']
      ]
    }
  },
  data() {
    return {
      dataValue: this.value
    };
  },
  methods: {
    updateInput(color) {
      this.$emit('input', color === this.value ? null : color);
    }
  }
};
</script>

<style lang="scss" src="./ColorPicker.scss" />
