export default {
  data() {
    return {
      activeParticipant: 'sb',
      heroPosition: null
    };
  },

  props: {
    rangesOfGroup: Array,
    item: Object,
    isBlocked: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rangeForItemOptionsEditor() {
      if (!this.isRange) return null;
      return {
        name: this.item.name,
        participants: this.item.diapason.participants,
        additionalProperties: this.item.diapason.additionalProperties
      };
    },
    groupForItemOptionsEditor() {
      if (!this.isGroup) return null;

      return {
        name: this.item.name,
        linksGroup: this.item.linksGroup,
        linkedItems: []
      };
    },
    computedRangeHands() {
      return this.isGroup
        ? []
        : this.item.diapason.participants[this.activeParticipant].hands;
    },

    isNullItem() {
      return this.item === null;
    },
    isRange() {
      return this.item?.type === 'Diapason';
    },
    isGroup() {
      return this.item?.type === 'Group';
    },
    itemName() {
      return this.item ? this.item.name : '';
    },
    computedRange() {
      return this.isRange ? this.item : null;
    },
    computedRangeInfo() {
      if (this.isRange) return this.item.diapason;

      if (this.isGroup) return this.groupHandsRange;

      return null;
    }
  },
  methods: {
    setHeroPos(val) {
      this.heroPosition = val;
    },
    setActiveParticipant(val) {
      if (this.activeParticipant === val) return;
      this.activeParticipant = val;
    }
  },
  watch: {
    item(value) {
      if (!value) return;
      //  if (value.type === ITEM_TYPE_GROUP) return;
      this.activeParticipant = this.item.diapason.additionalProperties.hp;
    }
  }
};
