var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"game",style:({
    'background-color': _vm.table.backgroundColor
  })},[(!_vm.gameStatisticsDialog)?[(_vm.isTouchpad)?_c('Dialog',{staticClass:"modal-dialog--size-to-content",model:{value:(_vm.colorPicker),callback:function ($$v) {_vm.colorPicker=$$v},expression:"colorPicker"}},[_c('DialogHeader',[_vm._v(" "+_vm._s(_vm.colorPickerTypeTitle)+" ")]),_c('DialogContent',[_c('ColorPicker',{staticClass:"color-picker-popup__row",attrs:{"colors":_vm.backgroundColors,"item-height":"50px","item-width":"50px"},on:{"input":_vm.setColor}})],1)],1):_vm._e(),(!_vm.isTouchpad)?_c('div',{staticClass:"color-picker-popup",class:{
        'color-picker-popup--active': _vm.colorPicker
      },style:({
        top: _vm.colorPickerPosition.y,
        left: _vm.colorPickerPosition.x
      })},[_c('div',{staticClass:"color-picker-popup__overlay",on:{"click":function($event){return _vm.closeHandTypePicker()}}}),_c('div',{staticClass:"color-picker-popup__container"},[_c('div',{staticClass:"color-picker-popup__title"},[_vm._v(" "+_vm._s(_vm.colorPickerTypeTitle)+" ")]),_c('ColorPicker',{staticClass:"color-picker-popup__row",attrs:{"colors":_vm.backgroundColors,"item-height":"50px","item-width":"50px"},on:{"input":_vm.setColor}})],1)]):_vm._e(),_c('div',{staticClass:"game__header"},[_c('div',{staticClass:"game__header__info"},[_c('div',{staticClass:"game__timebank"},[_c('svg',{attrs:{"height":"0","width":"0"}},[_c('defs',[_c('linearGradient',{attrs:{"id":"cl1","gradientUnits":"objectBoundingBox","x1":"0","x2":"1","y1":"0","y2":"1"}},[_c('stop',{attrs:{"stop-color":"#e32a89"}}),_c('stop',{attrs:{"offset":"100%","stop-color":"#498a98"}})],1)],1)]),_c('svg',{staticClass:"game__timebank__svg",attrs:{"viewBox":"0 0 100 100","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{staticClass:"game__timebank__circle"},[_c('circle',{staticClass:"game__timebank__path-elapsed",attrs:{"cx":"50","cy":"50","r":"45","stroke":"url(#cl1)"}}),_c('path',{ref:"timebankRemaining",class:[
                  'game__timebank__path-remaining',
                  'game__timebank__path-remaining--' + _vm.timebankType
                ],attrs:{"d":"\n                  M 50, 50\n                  m -45, 0\n                  a 45,45 0 0,0 90,0\n                  a 45,45 0 0,0 -90,0\n                ","stroke-dasharray":"283"}})])]),_c('span',{ref:"gameContentTimebankLabel",staticClass:"game__timebank__label"},[_vm._v(" "+_vm._s(_vm.timeLeft)+" ")])]),(false)?_c('div',{staticClass:"game__hands-remained"},[_c('img',{attrs:{"src":"/img/game/hands-remained.svg"}}),_vm._v(" "+_vm._s(_vm.handsLeft)+" ")]):_vm._e()]),_vm._m(0),_c('div',{staticClass:"game__header__btns-row"},_vm._l((_vm.gameHeaderBtns),function(btn,index){return _c('Btn',{key:index,class:[
            'game__header__btn--' + btn.classModificator,
            'game__header__btn'
          ],attrs:{"color":"white","height":"38px","outlined":""},on:{"click":btn.handler}},[_c('span',{staticClass:"game__header__btn__show-on-desktop"},[_vm._v(_vm._s(btn.label))]),_c('svg',{staticClass:"game__header__btn__show-on-mobile game__header__btn__show-on-mobile__icon",attrs:{"data-src":btn.icon}})])}),1)]),(_vm.isTouchpad)?_c('Dialog',{staticClass:"modal-dialog--size-to-content",model:{value:(_vm.isHandTypePickerActive),callback:function ($$v) {_vm.isHandTypePickerActive=$$v},expression:"isHandTypePickerActive"}},[_c('DialogHeader',[_vm._v(" "+_vm._s(this.localization.general.Board.StyleCardTitle)+" ")]),_c('DialogContent',{staticClass:"modal-dialog--htp-padding"},[_c('HandTypePicker',{attrs:{"colored":_vm.isYellowCards,"isTouchpad":_vm.isTouchpad},on:{"input":_vm.setCardStyle}})],1)],1):_vm._e(),(!_vm.isTouchpad)?_c('div',{staticClass:"hand-type-picker-popup",class:{
        'hand-type-picker-popup--active': _vm.isHandTypePickerActive
      },style:({
        top: _vm.handTypePickerPosition.y,
        left: _vm.handTypePickerPosition.x
      })},[_c('div',{staticClass:"color-picker-popup__overlay",on:{"click":function($event){return _vm.closeColorPicker()}}}),_c('div',{staticClass:"hand-type-picker-popup__container"},[_c('HandTypePicker',{attrs:{"colored":_vm.isYellowCards,"isTouchpad":_vm.isTouchpad},on:{"input":_vm.setCardStyle}})],1)]):_vm._e()]:_vm._e(),(_vm.isLoading)?[_c('div',{staticClass:"game--loading"},[_c('vue-loaders',{attrs:{"name":"ball-grid-pulse"}})],1)]:_vm._e(),(!_vm.isLoading && _vm.currentRange)?[_c('div',{ref:"gameContent",staticClass:"game__content",on:{"contextmenu":function($event){return _vm.callColorPickerOnContextMenu($event, 'Main Background')}}},[_c('Board',{ref:"board",attrs:{"bgColor":_vm.computedBoardColor,"hand":_vm.hand,"isLinkedDiapasonActive":_vm.isLinkedDiapasonActive,"handCards":_vm.handCards,"isShuffle":_vm.isShuffle,"isWrong":_vm.isWrongAnswer,"participants":_vm.participants,"prevGame":_vm.prevGame,"range":_vm.currentRange.diapason,"timebank":_vm.timebank,"isAvatarMode":_vm.isAvatarMode,"isColoredCards":_vm.isYellowCards},on:{"openDiapasonInfo":_vm.onOpenDiapasonInfo,"isOpAllin":_vm.isOpAllin,"checkOtherAllinStack":_vm.onCheckOtherAllinStack,"clickHandTypePicker":_vm.callHandTypePickerContext},nativeOn:{"contextmenu":function($event){return _vm.callColorPickerOnContextMenu($event, 'Board')}},scopedSlots:_vm._u([{key:"timer",fn:function(){return [_c('div',{staticClass:"game__timer"},[_c('div',{staticClass:"game__timer__row"},_vm._l((_vm.linearTimerIndicatorLength),function(second){return _c('div',{key:second,class:[
                  'game__timer__item',
                  {
                    'game__timer__item--filled':
                      second <= _vm.computedLinearSeconds
                  }
                ]})}),0)])]},proxy:true}],null,false,1479992074)})],1),(_vm.hand)?_c('ButtonsForAnswer',{attrs:{"allWeight":_vm.currentRangeWeights,"isHaveAllin":_vm.isHaveAllin,"isHaveCheck":_vm.isHaveCheck,"randomNumber":_vm.randomNumber,"situation":_vm.situation,"weight":_vm.hand.weight,"isHintOptionActive":_vm.hintModeOption,"isLoadingLinkedHands":_vm.loadingLinkedHand,"resultForHint":{
        hand: _vm.hand,
        handCards: _vm.handCards,
        getGameMode: _vm.getGameMode,
        situation: _vm.situation,
        randomNumber: _vm.randomNumber,
        isOpAllin: _vm.isOpAllin,
        otherAllinStack: _vm.otherAllinStack,
        isInverse: _vm.isInverse
      },"isInverse":_vm.isInverse,"isFoldButtonActive":_vm.isFoldButton,"otherAllinStack":_vm.otherAllinStack,"additionalProperties":_vm.currentRange.diapason.additionalProperties,"opAllin":_vm.opAllin},on:{"finishAnswer":_vm.finishGame},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.prevGame)?_c('div',{class:[
            'game__info',
            {
              'game__info--active': _vm.gameInfoIsActive
            }
          ]},[_c('div',{class:[
              'game__info__toggler',
              {
                'game__info--loss': !_vm.prevGame.result,
                'game__info--win': _vm.prevGame.result
              }
            ],on:{"click":function($event){return _vm.toggleGameInfo()}}},[_c('img',{attrs:{"src":"/icons/triangle-right.svg"}})]),_c('div',{staticClass:"game__info__content"},[_c('PreviousGameInfo',{ref:"prevGame",attrs:{"game":_vm.prevGame},on:{"showPrevRange":_vm.showPrevRange}}),_c('svg',{staticClass:"game__info__close",attrs:{"data-src":"/icons/close.svg"},on:{"click":function($event){return _vm.toggleGameInfo()}}})],1)]):_vm._e()]},proxy:true}],null,false,2445330522)}):_vm._e(),_c('RangePreviewDialog',{attrs:{"highlightHands":_vm.previewHand,"range":_vm.previewRange,"inverse":_vm.isInverse,"clickedHand":_vm.clickedHand},model:{value:(_vm.isRangePreviewOpened),callback:function ($$v) {_vm.isRangePreviewOpened=$$v},expression:"isRangePreviewOpened"}}),_c('GameHelpDialog',{on:{"changeGameMode":_vm.changeGameMode},model:{value:(_vm.isHelpOpened),callback:function ($$v) {_vm.isHelpOpened=$$v},expression:"isHelpOpened"}})]:_vm._e(),_c('GameStatisticsDialog',{attrs:{"statData":_vm.statistics,"heatMapHands":_vm.heatMapHands},on:{"finishGame":_vm.completeGamesAndQuit},model:{value:(_vm.gameStatisticsDialog),callback:function ($$v) {_vm.gameStatisticsDialog=$$v},expression:"gameStatisticsDialog"}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"game__logo"},[_c('img',{attrs:{"src":"/img/logo.svg"}})])
}]

export { render, staticRenderFns }