<template>
  <div>
    <Expander persistent>
      <ExpanderItem
        class="range-editor__options"
        @getIsActiveState="expanderIsActive"
      >
        <template v-slot:header>
          <div v-if="item">{{ item.name }}</div>
        </template>
        <template v-slot:header-buttons>
          <template v-if="isAdmin">
            <Btn
              class="range-editor__start-test-btn"
              :disabled="btnSaveDisabled"
              :loading="btnSaveLoading"
              height="38px"
              :icon="'icons/start_game.svg'"
              :color="btnSaveColor"
              onmouseover="event.stopPropagation();"
              @click.prevent.stop="handleBtnAction()"
            >
              {{ btnSaveLabel }}
            </Btn>
          </template>
          <template v-else>
            <!-- <Btn
              v-if="tabletOrMobile"
              class="range-editor__start-test-btn"
              :disabled="btnDisabled"
              :loading="btnLoading"
              height="38px"
              :icon="'icons/start_game.svg'"
              :color="btnColor"
              onmouseover="event.stopPropagation();"
              @click.prevent.stop="handleBtnAction()"
            >
              {{ btnLabel }}
            </Btn> -->
            <!-- включить кнопку в едиторе -->
            <!-- <DropdownBtn
              v-if="tabletOrMobile"
              :header-label="btnLabel"
              :items="menuItemsForStartGame"
              @changeGameOptions="onChangeGameOptions"
              :headerIcon="'icons/start_game.svg'"
              @startAction="handleBtnAction()"
              :disabled="btnDisabled"
              background-color="green"
              class="ranges-list__actions__item"
              height="38"
            /> -->

            <Btn
              v-if="isNeedButton"
              class="range-editor__start-test-btn"
              :disabled="btnSaveDisabled"
              :loading="btnSaveLoading"
              height="38px"
              :icon="'icons/start_game.svg'"
              :color="btnSaveColor"
              onmouseover="event.stopPropagation();"
              @click.prevent.stop="handleBtnAction()"
            >
              {{ btnSaveLabel }}
            </Btn>
          </template>
        </template>
        <template v-slot:content>
          <div
            :class="[
              'item-options-editor',
              {
                'item-options-editor--readonly': isReadOnly
              }
            ]"
          >

            <div class="item-options-editor__row">
              <div class="item-options-editor__label" />
              <div
                class="item-options-editor__value"
                :style="{ position: 'relative', 'pointer-events': 'all' }"
              >
                <button
                  :class="[
                    'item-options-editor__link-groups-btn',
                    {
                      'item-options-editor__link-groups-btn--active': linkingMode
                    },
                    'item-options-editor__link-groups-btn--turnOnPointerEvents'
                  ]"
                  @click="toggleLinkingMode()"
                >
                  {{ linkingModeBtnText }}
                </button>
                <FastFaqButton
                  @faqToggler="onFaqButtonClick"
                  :itemsInFaq="['LinkingMode', 'PlayingLinkingMode']"
                  lightName="linkingMode"
                  :style="{
                    top: '10px !important',
                    left: '310px'
                  }"
                />
              </div>
            </div>

            <div
              class="item-options-editor__row item-options-editor__row--link-groups"
            >
              <div class="item-options-editor__label">
                {{ localization.general.ItemGroupOptions.LinkedItems }}
              </div>
              <div class="item-options-editor__value">
                <img
                  v-if="linkedItemsLoading"
                  class="loading-gif"
                  src="/img/loading.gif"
                />
                <template v-else>
                  <i v-if="linkedItems.length === 0">
                    {{ localization.general.Common.Empty }}
                  </i>
                  <div
                    v-for="(linkedItem, index) in linkedItems"
                    v-else
                    :key="linkedItem.id"
                    class="item-options-editor__row--link-groups__item"
                  >
                    <img
                      v-show="linkingMode"
                      class="item-options-editor__row--link-groups__item__icon"
                      src="/icons/close-wrapped.svg"
                      @click="removeFromLinkedItems(index)"
                    />
                    {{ linkedItem.name }}
                  </div>
                </template>
              </div>
            </div>

            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.name }}
              </div>
              <div class="item-options-editor__value">
                <input
                  v-model="dataItem.name"
                  class="form-input item-options-editor__input item-options-editor__input--w-100"
                />
              </div>
            </div>
            <div class="item-options-editor__row">
              <Toggler
                v-model="dataItem.additionalProperties.type"
                :items="testTypes"
                class="
              item-options-editor__value
              item-options-editor__value--btns
              item-options-editor__value--w-100
              item-options-editor__value--type-btns
            "
                stretched
                @input="showCheck()"
              />
            </div>

            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.sizeBlock }}
              </div>
              <div
                class="item-options-editor__value item-options-editor__value--btns"
              >
                <Toggler
                  v-model="dataItem.additionalProperties.size"
                  :items="tableSizesBasedOnItemType"
                  in-one-row
                  @input="updateParts"
                />
              </div>
            </div>
            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.sdsek }}
              </div>
              <div
                class="item-options-editor__value item-options-editor__value--btns"
              >
                <div class="sharing-expander--item__check">
                  <Checkbox v-model="dataItem.additionalProperties.sdsek" />
<!--                  <Checkbox v-model="shortDesk" />-->
                </div>
              </div>
            </div>
            <div class="item-options-editor__row">
              <div class="item-options-editor__label">
                {{ localization.general.Diapason.hp }}
              </div>
              <div
                class="item-options-editor__value item-options-editor__value--btns-wrap"
              >

                <Toggler
                  v-model="dataItem.additionalProperties.hp"
                  :items="possibleHeroPositions"
                  @update="showCheck()"
                />
              </div>
            </div>
            <div
              v-if="['VS3B', 'SQZ', 'VS4BET'].includes(itemType)"
              class="item-options-editor__row"
            >
              <div class="item-options-editor__label">
                {{ heroBetSizeLabelBasedOnItemType }}
              </div>
              <div class="item-options-editor__value">
                <input
                  v-model.number="dataItem.additionalProperties.r"
                  class="item-options-editor__input form-input"
                  type="number"
                />
              </div>
            </div>
            <template v-if="showOpHpSpInput">
              <div class="item-options-editor__conditional-block">
                <div
                  :class="[
                    'item-options-editor__cp-op',
                    'item-options-editor__conditional-block',
                    {
                      'item-options-editor__conditional-block--disabled': !dataItem
                        .additionalProperties.hp
                    }
                  ]"
                >
                  <div
                    v-show="selectedTypesRequireCallerPosition"
                    :class="[
                      'item-options-editor__row',
                      'item-options-editor__conditional-block',
                      {
                        'item-options-editor__row--order-last':
                          itemType === 'VS1RC',
                        'item-options-editor__conditional-block--disabled':
                          itemType === 'VS1RC' &&
                          !dataItem.additionalProperties.op
                      }
                    ]"
                  >
                    <div class="item-options-editor__label">
                      {{ localization.general.Diapason.cp }}
                    </div>
                    <div
                      class="item-options-editor__value item-options-editor__value--btns-wrap"
                    >
                      <Toggler
                        v-model="dataItem.additionalProperties.cp"
                        :items="possibleCallerPositions"
                      />
                    </div>
                  </div>
                  <div
                    :class="[
                      'item-options-editor__row',
                      'item-options-editor__conditional-block',
                      {
                        'item-options-editor__row--order-last':
                          itemType === 'SQZ',
                        'item-options-editor__conditional-block--disabled':
                          itemType === 'SQZ' &&
                          !dataItem.additionalProperties.cp &&
                          selectedTypesRequireCallerPosition
                      }
                    ]"
                  >
                    <div class="item-options-editor__label">
                      {{ localization.general.Diapason.op }}
                    </div>
                    <div
                      class="item-options-editor__value item-options-editor__value--btns"
                    >
                      <Toggler
                        v-model="dataItem.additionalProperties.op"
                        :items="possibleOpponentPositions"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="showOpponentBetSize"
                  class="item-options-editor__row"
                >
                  <div class="item-options-editor__label">
                    {{ opBetSizeLabelBasedOnItemType }}
                  </div>
                  <div class="item-options-editor__value">
                    <input
                      v-model.number="dataItem.additionalProperties.n"
                      class="item-options-editor__input form-input"
                      type="number"
                      @input="showCheck()"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </ExpanderItem>

      <!--TABS-->
      <PositionTabs
        :parts="parts"
        :activeTableParticipant="activeTableParticipant"
        @setActiveTableParticipant="setActiveTableParticipant"
        :dataItem="dataItem"
        :tableParticipants="tableParticipants"
        :parentLinkedIsActive="tabsIsOpen"
        @updated="console.log('updated')"
        @onChangePositionTab="onChagePositionUpdated"
        v-model="parts"
        @updateParts="reactiveUpdateParts"
        @setHeroPosition="setHeroPosition"
      />
    </Expander>
  </div>
</template>

<script>
/* eslint-disable */

import DropdownBtn from '@/components/UI/DropdownBtn';
import getTableParticipants from '@/utils/participants';
import { getCustomNameOfPosition, getTablePositions } from '@/utils/positions';
import { mapGetters } from 'vuex';
import Toggler from '@/components/UI/Toggler';
import ColorPicker from '@/components/UI/ColorPicker';
import ItemOptionsEditorMixin from '@/components/ItemOptionsEditor/ItemOptionsEditorMixin';
import rangeHasCheck from '@/utils/itemRange';
import Scroller from '@/components/UI/Scroller/Scroller';
import Btn from '@/components/UI/Btn';
import PositionTabs from '@/components/PositionTabs/PositionTabs';

import _ from 'lodash';
import Checkbox from "@/components/UI/Checkbox/Checkbox.vue";
import FastFaqButton from "@/components/FastFaqDialog/FastFaqButton/FastFaqButton.vue";

export default {
  name: 'ItemRangeOptionsEditor',
  props: {
    item: Object,
    linkedItemsProvider: Function,
    btnDisabled: Boolean,
    btnLoading: Boolean,
    btnColor: String,
    btnLabel: String,
    btnSaveDisabled: Boolean,
    btnSaveLoading: Boolean,
    btnSaveColor: String,
    btnSaveLabel: String,
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTableParticipant: null,
      localHeroPosition: '',
      opponentPositions: {},
      parentLinkedState: false,
      sdsek: false,
      tabsIsOpen: false,
      tableSizes: [2, 3, 6, 8],
      tableShortSizes: [5],
      testTypes: [
        {
          label: 'Unopened',
          value: 'RFI'
        },
        {
          label: 'VS RFI/Limp',
          value: 'VSOR'
        },
        {
          label: 'VS 3bet',
          value: 'VS3B'
        },
        {
          label: 'VS 4bet raise',
          value: 'VS4BET'
        },
        {
          label: 'VS 1raise+caller',
          value: 'VS1RC'
        },
        {
          label: 'VS SQZ',
          value: 'SQZ'
        }
      ],
      parts: {},
      hasCheck: false,

      dataItem: {
        name: null,
        linksGroup: null,
        additionalProperties: {
          type: null,
          op: null,
          cp: null,
          r: null,
          n: null,
          size: null,
          hp: null,
          sdsek: false,
        },
        participants: []
      },

      linkedItems: [],
      linkingMode: null,
      linkedItemsLoading: true
    };
  },
  components: {
    FastFaqButton,
    Checkbox,
    Scroller,
    ColorPicker,
    Toggler,
    Btn,
    PositionTabs,

    DropdownBtn
  },
  mixins: [ItemOptionsEditorMixin],
  computed: {
    ...mapGetters({
      globalPreferences: 'user/globalPreferences',
      // globalShortDesk: 'user/globalShortDesk',
      globalShortDesk() {
        return this.dataItem.additionalProperties.sdsek;
      }
    }),

    linkingModeBtnText() {
      return this.localization.general.ItemGroupOptions[
        this.linkingMode ? 'DeactivateLinkingMode' : 'ActivateLinkingMode'
        ];
    },

    showOpHpSpInput() {
      return this.itemType && this.itemType !== 'RFI';
    },

    showOpponentBetSize() {
      return (
        this.itemType === 'VSOR' ||
        (this.itemType === 'VS1RC' && this.dataItem.additionalProperties.cp) ||
        this.itemType === 'VS3B' ||
        (this.itemType === 'SQZ' && this.dataItem.additionalProperties.cp) ||
        this.itemType === 'VS4BET'
      );
    },

    selectedTypesRequireCallerPosition() {
      return ['SQZ', 'VS1RC'].includes(this.itemType);
    },

    heroBetSizeLabelBasedOnItemType() {
      if (this.itemType === 'VS4BET') {
        return this.localization.general.Diapason.vs4BetHeroBetSize;
      }

      return this.localization.general.Diapason.vs3BetHeroBetSize;
    },
    opBetSizeLabelBasedOnItemType() {
      if (this.itemType === 'VS4BET') {
        return this.localization.general.Diapason.vs4BetOpBetSize;
      }
      if (
        this.itemType === 'VS3B' ||
        (this.itemType === 'SQZ' && this.dataItem.additionalProperties.cp)
      ) {
        return this.localization.general.Diapason.vs3BetOpBetSize;
      }

      return this.localization.general.Diapason.openRaiseOpBetSize;
    },
    tableSizesBasedOnItemType() {
      // if (this.shortDesk) {
      if (this.dataItem.additionalProperties.sdsek) {
        this.$store.commit('user/SET_GLOBAL_SHORT_DESK_CHECK', true);
        // Iterate through all the keys of the this.parts object
        for (let key in this.parts) {
          // We check whether the object has the "stack" key and whether the "stack" value is equal to 100
          if (this.parts.hasOwnProperty(key) && this.parts[key].hasOwnProperty('stack') && this.parts[key].stack === 100) {
            // Change the "stack" value to 50
            this.parts[key].stack = 50;
          }
        }

        // this.opponentPositions = this.possibleOpponentPositions.filter(function(position) {
        //   return !/^ep\d*$/.test(position.value);
        // });
        return this.tableShortSizes;
      }

      if (this.itemType === 'VS1RC' || this.itemType === 'SQZ') {
        return this.tableSizes.filter(size => size !== 2 && size !== 3);
      }

      this.$store.commit('user/SET_GLOBAL_SHORT_DESK_CHECK', false);

      return this.tableSizes;
    },
    tableParticipants() {
      if (!this.itemSize) return [];
      return getTablePositions(this.itemSize);
    },
    possibleOpponentPositions() {
      if (!this.itemSize) return [];

      let tablePositions = getTablePositions(this.itemSize);
      const heroPositionIndex = tablePositions.findIndex(
        position => position === this.dataItem.additionalProperties.hp
      );
      if (heroPositionIndex === -1) return [];

      if (this.itemType === 'VS3B') {
        tablePositions = tablePositions.filter((_, i) => i > heroPositionIndex);
      } else if (this.itemType === 'SQZ') {
        let all = this.tableParticipants;
        let cpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.cp
        );
        tablePositions = all.slice(cpInAll + 1);
      } else if (this.itemType === 'VSOR' || this.itemType === 'VS4BET') {
        tablePositions = tablePositions.filter((_, i) => i < heroPositionIndex);
      } else if (this.itemType === 'VS1RC') {
        let all = this.tableParticipants;
        let hpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.hp
        );
        tablePositions = all.slice(0, hpInAll - 1);
      }
console.log('tablePositions: '+tablePositions)
      return this.makeModifiedItemsOfPosition(tablePositions);
    },

    possibleHeroPositions() {
      if (!this.itemSize) return [];

      const filterPositions = positionForExclude => {
        return getTablePositions(this.itemSize).filter(position => {
          let positionForExcludeIsArray = Array.isArray(positionForExclude);
          if (positionForExcludeIsArray) {
            return !positionForExclude.includes(position);
          }

          return position !== positionForExclude;
        });
      };

      let excludePositions = null;
console.log('itemType: ' + this.itemType)
      if (
        (this.itemType === 'RFI' && this.itemSize === 8) ||
        (this.itemType === 'RFI' && this.itemSize === 6) ||
        (this.itemType === 'RFI' && this.itemSize === 3) ||
        (this.itemType === 'RFI' && this.itemSize === 2) ||
        (this.itemType === 'VS3B' && this.itemSize === 8) ||
        (this.itemType === 'VS3B' && this.itemSize === 6) ||
        (this.itemType === 'VS3B' && this.itemSize === 3) ||
        (this.itemType === 'VS3B' && this.itemSize === 2) ||
        (this.itemType === 'SQZ' && this.itemSize === 3) ||
        (this.itemType === 'SQZ' && this.itemSize === 2)
      ) {
        excludePositions = 'bb';
      }else if ((this.itemType === 'RFI' && this.itemSize === 5)) {
        //Unopened
        excludePositions = ['bu'];
      }else if ((this.itemType === 'VS3B' && this.itemSize === 5)) {
        // VS 3bet
        excludePositions = ['bb'];
      }else if (
        (this.itemType === 'SQZ' && this.itemSize === 8) ||
        (this.itemType === 'SQZ' && this.itemSize === 6)
      ) {
        excludePositions = ['bb', 'sb'];
      }else if ((this.itemType === 'SQZ' && this.itemSize === 5)) {
        excludePositions = ['ep2', 'mp', 'co', 'bu'];
      }else if ((this.itemType === 'VSOR' && this.itemSize === 5)) {
        excludePositions = ['ep1', 'sb'];
      }else if (this.itemType === 'VSOR' && this.itemSize === 8) {
        excludePositions = 'ep1';
      } else if (this.itemType === 'VSOR' && this.itemSize === 6) {
        excludePositions = 'ep';
      }else if (this.itemType === 'VSOR' && this.itemSize === 3) {
        excludePositions = 'bu';
      } else if (this.itemType === 'VSOR' && this.itemSize === 2) {
        excludePositions = 'sb';
      } else if (this.itemType === 'VS1RC' && this.itemSize === 8) {
        excludePositions = ['ep1', 'ep2'];
      } else if (this.itemType === 'VS1RC' && this.itemSize === 6) {
        excludePositions = ['mp', 'ep'];
      } else if (this.itemType === 'VS1RC' && this.itemSize === 5) {
        excludePositions = ['ep1', 'ep2'];
      } else if (this.itemType === 'VS1RC' && this.itemSize === 3) {
        excludePositions = 'bu';
      } else if (this.itemType === 'VS1RC' && this.itemSize === 2) {
        excludePositions = 'sb';
      }

      let filteredPositions = [];
      if (this.itemType === 'VS4BET') {
        filteredPositions = getTablePositions(this.itemSize).slice(1);
      } else {

        filteredPositions = filterPositions(excludePositions);
      }
      console.log(
        this.makeModifiedItemsOfPosition(filteredPositions),
        ' filtered Positions'
      );
      return this.makeModifiedItemsOfPosition(filteredPositions);
    },
    possibleCallerPositions() {
      if (!this.selectedTypesRequireCallerPosition) return [];

      let tablePositions = [];
      let all = this.tableParticipants;

      if (this.itemType === 'SQZ') {
        let hpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.hp
        );
        tablePositions = all.slice(hpInAll + 1, -1);
      } else if (this.itemType === 'VS1RC') {
        let opInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.op
        );
        let hpInAll = all.findIndex(
          el => el === this.dataItem.additionalProperties.hp
        );

        tablePositions = all.slice(opInAll + 1, hpInAll);
      }
      return this.makeModifiedItemsOfPosition(tablePositions);
    },

    itemType() {
      return this.dataItem.additionalProperties.type;
    },
    itemSize() {
      return this.dataItem.additionalProperties.size;
    }
  },
  inject: ['tabletOrMobile'],

  methods: {
    onChagePositionUpdated() {
      console.log('UPDAVAILB onChangePositionUpdate');
      this.$emit('updated');
    },
    onFaqButtonClick(nodes) {
      this.$emit('faqToggler', nodes);
    },
    removeFromLinkedItems(index) {
      this.linkedItems.splice(index, 1);
      this.updateIsAvailable();
    },
    toggleLinkingMode() {
      this.$track.activateLinkingMode();

      this.linkingMode = this.linkingMode ? null : this.item.type;
      this.$emit('linkingModeToggle', this.linkingMode);
    },
    reactiveUpdateParts(payload) {
      if (!this.activeTableParticipant) {
        console.log(
          'ITEM RANGE EDITOR: no active participant',
          this.activeTableParticipant
        );
        return;
      }
      this.parts[this.activeTableParticipant] = payload;
    },
    linkItems(items) {
      this.linkedItems = items;
      this.updateIsAvailable();
    },
    turnOffLinkingMode() {
      this.linkingMode = null;
      this.$emit('linkingModeToggle', null);
    },
    expanderIsActive(state) {
      this.tabsIsOpen = state;
      console.log('workons', this.tabsIsOpen);

      this.getLinkedItems();
    },
    getCustomNameOfPosition,
    handleBtnAction() {
      this.$emit('btnAction');
    },
    setActiveTableParticipant(val) {
      this.activeTableParticipant = val;
      this.$emit('activePart', val);
    },

    setHeroPosition(val) {
      this.localHeroPosition = val;
      this.$emit('heroPos', val);
      console.log('hero pos', val);
    },
    makeModifiedItemsOfPosition(items) {
      if (items.length === 0) return [];
      console.log(items, 'debug items refresh');
      return items.map(position => {
        return {
          label: getCustomNameOfPosition(position),
          value: position
        };
      });
    },
    setHasCheckValue() {
      console.log(this.hasCheck, 'debug: setHasCheckValue');
      this.hasCheck = this.checkIfCurrentDataMustToggleCheck();
    },
    checkIfCurrentDataMustToggleCheck() {
      console.log(
        this.itemType,
        this.localHeroPosition || this.dataItem.additionalProperties.hp,
        this.dataItem.additionalProperties.n,
        'checkIfCurrentDataMustToggleCheck'
      );

      return rangeHasCheck(
        this.itemType,
        this.localHeroPosition,
        this.dataItem.additionalProperties.n
      );
    },
    updateParts(val) {
      console.log(this.item, 'this item');
      if (this.item == null) return;
      console.log('work after return');
      let initialParts = getTableParticipants(
        this.dataItem.additionalProperties.size
      );
      console.log(this.parts, 'Ithis parts');
      console.log(initialParts, 'INITIAL PARTS');
      this.parts = {};
      // this.setHasCheckValue();
      for (let key in initialParts) {
        console.log(key, 'THIS PARTS AFTER UPDATE');
        // console.log(initialParts[key], 'THIS PARTS AFTER UPDATE');
        console.log(this.dataItem.participants, 'dataitem in parts');

        if (Object.keys(this.dataItem.participants).includes(key)) {
          this.$set(
            this.parts,
            key,
            _.cloneDeep(_.omit(this.dataItem.participants[key], ['hands']))
          );
        } else {
          this.$set(this.parts, key, _.cloneDeep(initialParts[key]));
        }
      }
    },
    submit() {
      console.log('go to submit');
      const positionExists = (items, value) => {
        return !!items.find(item => item.value === value);
      };
      if (!this.dataItem?.name) {
        return this.$toast.error(this.localization.general.Simple.EnterName);
      }
      console.log('this.dataItem.additionalProperties: ');
      console.log(this.dataItem.additionalProperties);
      if (
        !this.dataItem.additionalProperties.hp ||
        !positionExists(
          this.possibleHeroPositions,
          this.dataItem.additionalProperties.hp
        )
      ) {
        return this.$toast.error(this.localization.general.Simple.EnterHP);
      }

      if (['VSOR', 'VS1RC', 'VS3B', 'VS4BET', 'SQZ'].includes(this.itemType)) {
        if (
          !this.dataItem.additionalProperties.op ||
          !positionExists(
            this.possibleOpponentPositions,
            this.dataItem.additionalProperties.op
          )
        ) {
          return this.$toast.error(this.localization.general.Simple.EnterOP);
        }
        if (!this.dataItem.additionalProperties.n) {
          return this.$toast.error(this.localization.general.Simple.EnterOOS);
        }
      }

      if (['SQZ', 'VS1RC'].includes(this.itemType)) {
        if (
          !positionExists(
            this.possibleCallerPositions,
            this.dataItem.additionalProperties.cp
          )
        ) {
          return this.$toast.error(this.localization.general.Simple.EnterCP);
        }
      }

      if (['VS3B', 'VS4BET', 'SQZ'].includes(this.itemType)) {
        if (!this.dataItem.additionalProperties.r) {
          return this.$toast.error(this.localization.general.Simple.EnterOHRS);
        }
      }

      if (this.itemType === 'RFI') {
        this.dataItem.additionalProperties.op = null;
      }

      let linkedItemsIDs = this.linkedItems.length > 0
        ? this.linkedItems.map(group => ({ id: group.id }))
        : [];

      return {
        validated: true,
        data: {...this.dataItem, linksGroup: {items: linkedItemsIDs}}
      };
    },
    async getLinkedItems() {
      if (!this.expanderIsActive || !this.item) return;

      this.linkedItemsLoading = true;
      console.log(this.item, 'THIS ITEM IN LINKED DIAPASON');
      if (this.item.linksGroup?.items.length === 0) {
        this.linkedItems = [];
        this.linkedItemsLoading = false;
        return;
      }
      // get linked items from prop
      let linkedItemsOfCurrentItem = this.item.linksGroup;
      console.log(this.item, 'ITEM GROUP OPTIONS EDITOR: this.item');
      console.log(
        linkedItemsOfCurrentItem,
        'ITEM RANGE OPTIONS EDITOR: linkedItemsOfCurrentItem1'
      );
      // load linked items info from IDs, gotten from prop
      if (linkedItemsOfCurrentItem) {
        let linkedItemsIDs = linkedItemsOfCurrentItem.items
          .map(linkedItem => linkedItem.id)
          .join(',');
        console.log(
          linkedItemsIDs,
          'ITEM RANGE OPTIONS EDITOR: linkedItemsOfCurrentItem'
        );
        this.linkedItems = await this.linkedItemsProvider(linkedItemsIDs);
      } else {
        this.linkedItems = [];
      }
      this.$emit('linkedItemsChange', this.linkedItems);
      this.linkedItemsLoading = false;
    },
    showCheck() {
      /*
        EMIT WILL FIRE IF:
          1. CONDITION FOR CHECK IS TRUE && PREVIOUS SETTED PROPERTIES FOR CHECK ARE FALSE
          1. CONDITION FOR CHECK IS FALSE && PREVIOUS SETTED PROPERTIES FOR CHECK ARE TRUE
       */

      let setCheck = this.checkIfCurrentDataMustToggleCheck();
      if (this.hasCheck || setCheck) {
        this.hasCheck = setCheck;
        console.log(setCheck, this.hasCheck, this.dataItem, 'showCheck method');

        this.$emit('checkWeightToggle', {
          setCheck: setCheck,
          type: this.itemType,
          hp: this.dataItem.additionalProperties.hp,
          op: this.dataItem.additionalProperties.op,
          cp: this.dataItem.additionalProperties.cp,
          r: this.dataItem.additionalProperties.r,
          n: this.dataItem.additionalProperties.n,
          sdsek: this.dataItem.additionalProperties.sdsek,
          name: this.dataItem?.name
        });
      }
      this.setHasCheckValue();
    },

    setFirstTableParticipantAsActive() {
      if (this.tableParticipants.length === 0) return;
      console.log(this.localHeroPosition, 'LOCAL HERO');
      this.activeTableParticipant = this.item.additionalProperties.hp;
      this.$emit('activePart', this.item.additionalProperties.hp);
      console.log(this.activeTableParticipant, 'active table first');
    }
  },

  watch: {
    localHeroPosition: {
      handler() {
        this.showCheck();
      }
    },
    'dataItem.additionalProperties.hp': function() {
      console.log(this.dataItem, 'dataItem on watch');
      this.showCheck();
    },
    parts: {
      handler(val) {
        // if (!this.watchForPartsUpdates) {
        //   this.watchForPartsUpdates = true;
        //   return;
        // }
        //
        this.watchForPartsUpdates = true;
        console.log('hello', val); //Здеь идет обработка tempParts
        this.$emit('getTempParts', val);
        // this.updateIsAvailable();
      },
      deep: true,
      immediate: false
    },
    item: {
      handler() {
        this.setFirstTableParticipantAsActive();
        this.updateParts();
        this.showCheck();
        this.getLinkedItems();
        console.log(this.tabletOrMobile, 'provide inject');
      },
      immediate: true
    },
    //watcher для дефолтного игрока при хвходе
    activeTableParticipant: {
      handler(val) {
        this.$emit('activePart', val);
      }
    }
  }
};
</script>
<style lang="scss" src="./ItemRangeOptionsEditor.scss" />
