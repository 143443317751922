var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{class:[
    'checkbox-container',
    {
      'checkbox-container--bordered': _vm.bordered,
      'checkbox-container--no-label': !_vm.label
    }
  ]},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataModel),expression:"dataModel"}],attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.dataModel)?_vm._i(_vm.dataModel,_vm.value)>-1:(_vm.dataModel)},on:{"change":[function($event){var $$a=_vm.dataModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.dataModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.dataModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.dataModel=$$c}},_vm.updateInput]}}),_c('span',{staticClass:"checkbox-container__checkmark"})])
}
var staticRenderFns = []

export { render, staticRenderFns }