<template>
  <div>
    <Expander
      :value="0"
      ref="expander"
      @changeExpanderItem="onChangeExpanderItem"
    >
      <ExpanderItem
        v-for="(item, index) in items"
        :key="item.id"
        :keyValue="index"
      >
        <template v-slot:header> {{ item.name }} </template>

        <template v-slot:header-buttons>
          <Btn
            class="range-editor__start-test-btn"
            :loading="false"
            height="38px"
            onmouseover="event.stopPropagation();"
            @click.prevent.stop="handleBtnAction(item)"
          >
            {{ localization.general.MainPage.StartTest }}
          </Btn>
        </template>

        <template v-slot:content>
          <!-- range-preview-dialog - класс для стилей таблицы, чтобы табличка помещалась на телефонах и планшетах -->

          <div class="range-preview-dialog diapason-list-table">
            <RangeTableEditor
              ref="rangeTable"
              :hands="
                item.diapason.participants[
                  item.diapason.additionalProperties.hp
                ].hands
              "
              :actionColors="actionColors"
              :isGameView="true"
              :isRange="true"
              :isReadOnly="true"
              :showStatistics="false"
            />
          </div>

          <div class="diapason-list__randomized">
            <div class="diapason-list__randomized-weight">
              <img
                class="diapason-list__randomized-weight__dice"
                src="/icons/dice.svg"
              />

              <div class="diapason-list__randomized-weight__percent">
                {{ computedRandomNumber() }}
              </div>
            </div>
          </div>
        </template>
      </ExpanderItem>
    </Expander>
  </div>
</template>

<script>
import Vue from 'vue';
import { Expander, ExpanderItem } from '@/components/UI/Expander';
import RangeTableEditor from '@/components/RangeTableEditor';
import Btn from '@/components/UI/Btn';
import { mapGetters } from 'vuex';
export default {
  name: 'DiapasonsList',
  components: {
    Expander,
    ExpanderItem,
    RangeTableEditor,
    Btn
  },
  props: {
    items: Array,
    refreshState: Function
  },

  data() {
    return {};
  },
  methods: {
    onChangeExpanderItem() {
      Vue.$track.viewFoundRange();
    },
    setHotKeyEvents(event) {
      if (event && event.keyCode >= 49 && event.keyCode <= 57) {
        const index = event.keyCode - 49;
        console.log(index);
        // Check if there is an ExpanderItem with the corresponding index
        if (this.$refs.expander && this.$refs.expander.$children[index]) {
          this.$refs.expander.$children.forEach(
            item => (item.isActive = false)
          );
          // Call the open method of the ExpanderItem to open it
          console.log(this.$refs.expander.$children[index], 'EXPANDER TO OPEN');
          this.$refs.expander.$children[index].isActive = true;
          Vue.$track.viewFoundRange();
        }
      } else if (event && event.keyCode === 27) {
        event.preventDefault();
        console.log('Escape key pressed');
        this.$emit('refreshState');
      }
    },
    async handleBtnAction(item) {
      let diapasonsForTest = [item.id];
      console.log(item, 'item to start');
      localStorage.setItem(
        'diapasonsForTest',
        JSON.stringify(diapasonsForTest)
      );
      Vue.$track.playFoundRange();
      this.$router.push({
        name: 'Game'
      });
    },
    onOpen() {
      console.log(this.$refs, 'this Refs');
      this.$refs.rangeTable[0].updateTable();
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    computedRandomNumber() {
      const randomNumber = this.getRandomInt(1, 100);
      return randomNumber;
    }
  },
  computed: {
    ...mapGetters({ actionColors: 'user/actionColors' })
  },
  mounted() {
    this.$nextTick(() => {
      this.childBottom = this.$el.offsetHeight + 'px';

      window.addEventListener('keydown', this.setHotKeyEvents);
    });
  }
};
</script>

<style lang="scss" src="./DiapasonsList.scss" />
