<template>
  <div class="ranges-selector">
    <form
      v-if="!premium"
      action="javascript:void(0);"
      class="ranges-selector__search"
      method="post"
    >
      <input
        id="search"
        v-model="search"
        autocomplete="on"
        class="form-input"
        name="search"
        type="email"
        @keyup.enter="searchUser()"
      />
      <Btn :disabled="!search" @click="searchUser()">
        Search
      </Btn>
    </form>
    <div v-if="user || premium" class="ranges-selector__container">
      <div class="ranges-selector__list">
        <List
          ref="list"
          :children-provider="getItems"
          :draggable="false"
          :multiple-select="multipleSelect"
          class="ranges-list-items"
          @currentItemChange="onCurrentItemChange"
          @selectedItemsChange="onSelectedItemsChange"
        >
          <template v-slot:card-body-content="item">
            <div v-if="item.purchaseInfo !== null" class="purchased" />
          </template>
          <template v-slot:card-inner="item">
            <div class="list__item__card__label">
              {{ item.name }}
            </div>

            <!-- <div class="list__item__card__diapason__info">
              <div
                v-if="
                  item.statistics.correct > 0 || item.statistics.incorrect > 0
                "
                class="list__item__card__diapason__info__answers"
              >
                <div class="incorrect">
                  {{ item.statistics.incorrect }}
                </div>
                <div class="correct">
                  {{ item.statistics.correct }}
                </div>
              </div>
            </div> -->
          </template>
        </List>
      </div>
      <div class="ranges-selector__viewer">
        <RangeViewer :is-blocked="!currentItem" :item="currentItem" />
      </div>
    </div>
  </div>
</template>

<script>
import List from '../UI/List';
import { SEARCH_ITEMS } from '@/api/items';
import { SEARCH_USER } from '@/api/users';
import { GET_PREMIUM_ITEMS } from '@/api/premiumItems';
import RangeViewer from '@/components/RangeEditor/RangeViewer';
import Btn from '@/components/UI/Btn';

export default {
  name: 'UserRangesSelector',
  components: { Btn, RangeViewer, List },
  props: {
    premium: {
      type: Boolean,
      default: false
    },
    isAvailable: {
      type: Boolean,
      default: false
    },
    types: String,
    itemSelectRequired: {
      type: Boolean,
      default: true
    },
    multipleSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentItem: null,
      selectedItems: [],

      search: '',
      user: null
    };
  },
  methods: {
    onCurrentItemChange(value) {
      this.currentItem = value;
    },
    onSelectedItemsChange(value) {
      this.selectedItems = value;
    },
    selectItems() {
      let itemsForEmit = [];

      if (this.selectedItems.length > 0) {
        itemsForEmit = this.selectedItems;
      } else if (this.currentItem) {
        itemsForEmit = [this.currentItem];
      }

      if (this.itemSelectRequired && itemsForEmit.length === 0) {
        this.$toast.error('Items not selected');
        return;
      }

      if (!this.user && !this.premium) {
        this.$toast.error('User not selected');
        return;
      }

      this.$emit('rangesSelected', {
        selectedItems: itemsForEmit,
        userId: this.user?.id,
        userEmail: this.user?.email
      });
    },
    async searchUser() {
      this.user = null;
      this.user = await SEARCH_USER({
        email: this.search
      });

      if (!this.user) {
        this.$toast.error('User not found');
        return false;
      }
    },
    async getItems(item) {
      let items = [];
      const parentId = item ? item.id : null;
      if (this.premium) {
        items = await GET_PREMIUM_ITEMS(parentId);
      } else {
        items = await SEARCH_ITEMS({
          userId: this.user.id,
          parentItemId: parentId,
          isAvailable: this.isAvailable
        });
      }

      if (this.types) {
        return items.filter(item => {
          return item.type === this.types;
        });
      }

      return items;
    }
  },
  mounted() {
    if (this.premium) {
      this.getItems();
    }
  }
};
</script>
<style lang="scss" src="./UserRangesSelector.scss" />
