<template>
  <div
    :class="[
      'game__prev',
      {
        'game__prev--win': game.result,
        'game__prev--lose': !game.result
      }
    ]"
  >
    <div class="game__prev__body">
      <div class="game__prev__hand">
        <div class="game__prev__cards">
          <Card
            v-for="({ m, card }, i) in game.hand.cards"
            :key="i"
            :name="card"
            :suit="m"
            class="game__prev__card"
          />
        </div>
      </div>
      <div v-if="showAnswer">
        <div class="game__prev__diapason-name" @click="openPrevDiapason()">
          {{ game.name }}
        </div>
        <div class="game__prev__result game__prev__highlight-text">
          {{
            game.result
              ? localization.general.Board.Win
              : localization.general.Board.Loose
          }}
        </div>
        <div class="game__prev__correct-answer">
          {{ localization.general.Board.prefixCorrectResult }}:
          {{ getTrueAnswer }}
        </div>
        <div class="game__prev__player-answer">
          {{ localization.general.Board.prefixResult }}:
          <b class="game__prev__highlight-text">
            {{ getAnswer }}
          </b>
        </div>
      </div>
      <div v-else class="game__prev__player-answer">
        {{ localization.general.Board.prefixResult }}:
        <span>
          {{ getAnswer }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Card from '@/components/Game/Card';

export default {
  name: 'PreviousGameInfo',
  components: { Card },
  props: ['game', 'showPrevRange'],
  data() {
    return {
      showAlreadyAnswered: false
    };
  },
  computed: {
    ...mapGetters({
      getGameMode: 'play/getGameMode'
    }),
    showAnswer() {
      return this.game != null;
    },
    getAnswer() {
      let string = '';
      Object.keys(this.game.answer)
        .filter(key => this.game.answer[key])
        .forEach(key => {
          string += key[0].toUpperCase();
          if (this.getGameMode === 'Advanced') {
            string += key.slice(1) + ' ' + this.game.answer[key] + '; ';
          } else if (this.getGameMode === 'Simple') {
            // SIMPLE
            string += key.slice(1) + '  ';
          } else if (this.getGameMode === 'Randomized') {
            // Randomizer
            string += key.slice(1) + '  ';
          }
        });

      return string ? string.slice(0, string.length - 2) : 'Timeout';
    },
    getTrueAnswer() {
      let answer;
      const { call, fold, raise, allin, check } = this.game.hand.weight;

      let string = '';

      if (this.getGameMode === 'Randomized')
        return this.game.correctAnswerRandomizer;

      if (call) string += 'Call ' + Math.round(call) + '; ';
      if (check) string += 'Check ' + Math.round(check) + '; ';
      if (fold) string += 'Fold ' + Math.round(fold) + '; ';
      if (raise) string += 'Raise ' + Math.round(raise) + '; ';
      if (allin) string += 'Allin ' + Math.round(allin) + '; ';

      if (!string) string += 'Fold 100; ';

      answer = string.slice(0, string.length - 2);
      return answer;
    }
  },
  methods: {
    openPrevDiapason() {
      this.$emit('showPrevRange');
    }
  }
};
</script>

<style lang="scss"></style>
