import axios from "axios";
import auth from "../auth";
import Vue from "vue";
import store from "../store";

// Create XHR instance
const requests = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_VERSION}/`,
});

requests.interceptors.request.use((config) => {
  // Do something before request is sent

  config.headers["Authorization"] = "Bearer " + auth.getToken();

  return config;
}, null);

requests.interceptors.response.use(null, function (error) {
  // Do something with response error
  if (error.response && error.response.status === 401) {
    auth.clearAuthorize();
    /*Vue.$toast.error(store.state.ui.localization.general.Simple.NeedAutorize);
    router.push({
      name: 'Sign In'
    });*/
  }
  return Promise.reject(error);
});
requests.interceptors.response.use(
  function (response) {
    if (response.config.method === "delete" && response.status === 204) {
      Vue.$toast.success(store.state.ui.localization.general.Alerts.SuccessfullyDeleted);
    }
    return response;
  },
  function (error) {
    const errorStatus = error.response.status;
    console.log(
      "ERROR OCCURRED:",
      `Status: ${errorStatus}`,
      'Headers:', error.response.headers,
      'Response data:', error.response.data,
      'Full Response', error.response
    );

    if(errorStatus !== 401){
      const message = store.state.ui.localization.general.ErrorCodes[error.response.data.Code] ??
        store.state.ui.localization.general.Simple.SomethingWentWrong;

      console.log(message);
      Vue.$toast.error(message);
    }

    return Promise.reject(error.response.data);
  }
)

export default requests;
