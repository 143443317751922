var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'fast-faq-button',
    _vm.isBlink && _vm.blinkFaq ? 'blink-idea' : '',
    _vm.isFaqActive ? 'isShow' : '',
    _vm.hasIdleLight && !_vm.isBlink && _vm.isClicked ? 'hasIdleLight' : '',
    _vm.isShouldLight && !_vm.isClicked ? 'idleLight' : ''
  ],on:{"click":_vm.toggleFaq}},[_c('img',{attrs:{"src":"/icons/idea_enabled.svg","alt":""}}),(_vm.isDarkButton)?_c('img',{attrs:{"src":"/icons/idea_disabled_dark.svg","alt":""}}):(_vm.isLightButton)?_c('img',{attrs:{"src":"/icons/idea_disabled_light.svg","alt":""}}):_c('img',{attrs:{"src":"/icons/idea_disabled.svg","alt":""}})])
}
var staticRenderFns = []

export { render, staticRenderFns }