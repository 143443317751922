<template>
  <div
    :class="[
      'card',
      {
        'card--undefined': undefinedCard,
        'card--no-card': !(name && suit)
      }
    ]"
    :style="{
      color: yellowColor4 ? '#fff' : '',
      'background-color': yellowColor4 ? computedColorSuit : '',
      'text-shadow': yellowColor4
        ? '-2px 0 2px black, 0 2px 2px black, 2px 0 2px black, 0 -2px 2px black'
        : ''
    }"
  >
    <template v-if="!undefinedCard">
      <img
        v-if="!yellowColor4"
        :src="iconUrl"
        class="card__suit-icon card__suit-icon--top"
      />
      <div class="card__name">
        {{ computedName }}
      </div>
      <img
        v-if="!yellowColor4"
        :src="iconUrl"
        class="card__suit-icon card__suit-icon--bottom"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Card',
  computed: {
    undefinedCard() {
      return !this.suit || !this.name;
    },
    computedName() {
      return this.name.toUpperCase();
    },
    computedColorSuit() {
      let color = '';
      switch (this.suit) {
        case 's':
          color = '#feb803';
          break;
        case 'd':
          color = '#031cff';
          break;
        case 'c':
          color = '#0a9d79';
          break;
        case 'h':
          color = '#ff0347';
          break;
      }
      return color;
    },
    iconUrl() {
      let fileName = '';
      switch (this.suit) {
        case 's':
          fileName = 'spades';
          break;
        case 'd':
          fileName = 'diamonds';
          break;
        case 'c':
          fileName = 'clubs';
          break;
        case 'h':
          fileName = 'hearts';
          break;
      }
      return '/icons/card-suits/' + fileName + '.svg';
    }
  },
  props: {
    suit: String,
    name: String,
    yellowColor4: { type: Boolean },
    width: [Number, String],
    height: [Number, String]
  }
};
</script>
