<template>
  <Dialog
    v-model="computedValue"
    class="modal-dialog--width-large modal-dialog--size-to-content"
  >
    <DialogHeader>
      Items to Premium Dialog
    </DialogHeader>
    <DialogContent>
      <div class="items-to-premium">
        <div class="items-to-premium__checkbox">
          <input
            id="recursively"
            v-model="recursively"
            type="checkbox"
          />
          <label for="recursively">
            Recursively
          </label>
        </div>
        <div class="items-to-premium__search">
          <label class="form-label-text">Owner:</label>
          <input
            v-model="search"
            :class='{
                  "items-to-premium_success": userFound,
                  "items-to-premium_error": userNotFound
              }'
            autocomplete
            class="form-input"
            type="email"
            @keyup.enter="searchUser()"
          />
          <Btn
            :disabled="!search"
            @click="searchUser()"
          >
            Search
          </Btn>
        </div>
        <div class="items-to-premium__user">
          User:
          <span v-if="user">
            {{ user.email }}
            <i class="fa fa-close" @click="clearUser()"/>
          </span>
          <span v-else>
            Not selected
          </span>
        </div>
      </div>
    </DialogContent>
    <DialogFooter>
      <Btn
        outlined
        @click="closeModal()"
      >
        {{ localization.general.Simple.Close }}
      </Btn>
      <Btn @click="submit()">
        Apply
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {SET_PREMIUM_ITEMS_ITEMS_TO_PREMIUM} from "@/api/premiumItems"
import {SEARCH_USER} from "@/api/users"
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "@/components/UI/Dialog/index";
import Btn from "@/components/UI/Btn";

export default {
  name: "ItemsToPremiumDialog",
  data: function () {
    return {
      recursively: true,
      search: '',
      user: '',

      userFound: false,
      userNotFound: false,
    }
  },
  props: {
    userId: String,
    items: Array,
    value: Boolean
  },
  components: {Btn, Dialog, DialogContent, DialogFooter, DialogHeader},

  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    clearUser() {
      this.user = null
    },
    async searchUser() {
      this.user = null;
      this.user = await SEARCH_USER({
        email: this.search
      });

      if (!this.user) {
        this.$toast.error('User not found');
        this.userFound = false;
        this.userNotFound = true;
      } else {
        this.$toast.success('User found');
        this.userFound = true;
        this.userNotFound = false;
      }
    },
    selectItems() {
      this.$refs.userRangeSelector.selectItems();
      this.closeModal();
    },
    onRangesSelected(value) {
      this.$emit('rangesSelected', value);
    },
    closeModal() {
      this.$emit('input', false);
    },
    async submit() {
      let itemsId = this.items.map(item => item.id);
      await SET_PREMIUM_ITEMS_ITEMS_TO_PREMIUM({
        userId: this.userId,
        parentId: '00000000-0000-0000-0000-000000000000',
        items: itemsId,
        recursively: this.recursively,
        createdByUserId: this.user ? {value: this.user.id} : null
      });

      this.$emit('setPremiumItems')

      this.$toast.success('Success');

      this.closeModal();
    }
  }
}
</script>
<style lang="scss" src="./ItemsToPremiumDialog.scss">
</style>