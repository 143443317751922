<template>
  <div class="store-product-descriptor-editor">
    <div
      v-for="descriptor in descriptorsType"
      :key="descriptor.type"
      class="store-product-descriptor-editor__item"
    >

      <div class="store-product-descriptor-editor__item__checkbox">

        <div class="list__item__checkbox">
          <label class="list__item__checkbox__container">
            <input
              :id="'checkbox' + descriptor.type"
              v-model="checkedDescriptions"
              :value="descriptor.type"
              type="checkbox"
            />
            <span class="list__item__checkbox__container__checkmark"></span>
          </label>
        </div>

        <span class="store-product-descriptor-editor__item__title">
          {{ descriptor.type }}
        </span>
      </div>

      <div
        class="store-product-descriptor-editor__line__action"
      >
        <div class="item-options-editor__row">
          <div class="item-options-editor__label">
            ID:
          </div>
          <div class="item-options-editor__value item-options-editor__value--btns">
            <input
              v-model="descriptor.Id"
              :disabled="isReadOnly(descriptor.type)"
              class="item-options-editor__input form-input"
            />
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreProductDescriptorEditor',
  props: {
    descriptors: Array,
  },
  data() {
    return {
      descriptorsType: [
        {
          type: "Fastspring",
          Id: ""
        }
      ],
      checkedDescriptions: [],
    };
  },

  methods: {
    isReadOnly(type) {
      return !this.checkedDescriptions.find(checkedDescription => checkedDescription === type);
    },
    buildDescriptors() {
      if (this.checkedDescriptions.length > 0) {

        let arr = [];
        for (const checkedDescription of this.checkedDescriptions) {
          const foundType = this.descriptorsType.find(item => item.type === checkedDescription);
          if (foundType) {
            arr.push(foundType);
          }
        }

        return arr
      }
      return [];
    },
    loadExistingDescriptors() {
      if (!this.descriptors) return
      this.checkedDescriptions = this.descriptors.map(descriptor => descriptor.type);
      this.descriptorsType.forEach(descriptorType => {
        const descriptor = this.descriptors.find(item => item.type === descriptorType.type);
        descriptorType.Id = descriptor ? descriptor.Id : null;
      })
    }

  },
  mounted() {
    this.loadExistingDescriptors();
  },
  watch: {
    descriptors() {
      this.loadExistingDescriptors();
    }
  }

};
</script>

<style lang="scss" scoped src="./StoreProductDescriptorEditor.scss"/>
