<template>
  <div>
    <h2 class="profile__title">
      {{ localization.general.Profile.ProfileHeader }}
    </h2>
    <div class="profile-tab profile-tab--change-password">
      <div class="profile-tab__block profile-tab__block--user-info">
        <form
          action="javascript:void(0);"
          method="POST"
          @submit="changePassword()"
        >
          <div class="profile-tab__list">
            <div class="profile-tab__list__item">
              <div class="profile-tab__list__item__label">
                {{ localization.general.Profile.WriteActivePassword }}
              </div>
              <div class="profile-tab__list__item__value">
                <input
                  id="pass"
                  v-model="password"
                  class="form-input"
                  required
                  type="password"
                />
              </div>
            </div>
            <div class="profile-tab__list__item">
              <div class="profile-tab__list__item__label">
                {{ localization.general.Profile.NewPassword }}
              </div>
              <div class="profile-tab__list__item__value">
                <input
                  v-model="newPassword"
                  class="form-input"
                  maxlength="32"
                  minlength="4"
                  required
                  type="password"
                />
              </div>
            </div>
            <div class="profile-tab__list__item">
              <div class="profile-tab__list__item__label">
                {{ localization.general.Profile.RepeatNewPassword }}
              </div>
              <div class="profile-tab__list__item__value">
                <input
                  v-model="confirmNewPassword"
                  class="form-input"
                  maxlength="32"
                  minlength="4"
                  required
                  type="password"
                />
              </div>
            </div>
          </div>
          <Btn
            :loading="requestPending"
            class="profile-tab--change-password__btn"
            type="submit"
          >
            {{ localization.general.Profile.SaveNewPassword }}
          </Btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/UI/Btn';

export default {
  name: 'ChangePassword',
  components: { Btn },
  data() {
    return {
      requestPending: false,
      opened: false,

      password: '',
      newPassword: '',
      confirmNewPassword: ''
    };
  },
  methods: {
    changePassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.$toast.error(
          this.localization.general.FormElements.PasswordNotConfirmed
        );
        return;
      }

      this.$track.changePassword();

      this.requestPending = true;
      this.$auth.changePassword(
        this.password,
        this.newPassword,
        (isSuccess, data) => {
          if (isSuccess) {
            this.password = '';
            this.newPassword = '';
            this.confirmNewPassword = '';
            this.$toast.success(
              this.localization.general.Profile.PasswordSuccessfullyChanged
            );
          } else {
            if (data.data.Code === 'invalid_login') {
              this.$toast.error(
                this.localization.general.ProfileChangePassword.Validations
                  .CurrentPasswordIsWrong
              );
            } else {
              this.$toast.error(
                this.localization.general.FormElements.CheckData
              );
            }
          }
          this.requestPending = false;
        }
      );
    }
  }
};
</script>
