var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"admin-title"},[_vm._v(" "+_vm._s(_vm.localization.general.Profile.Users)+" ")]),_c('div',{staticClass:"c-card"},[_c('div',{staticClass:"filter-block"},[_c('div',{staticClass:"form-block filter-block__item filter-block__item-w--100"},[_c('label',{staticClass:"form-label-text"},[_vm._v(" "+_vm._s(_vm.localization.general.Profile.SearchUser)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-input",attrs:{"placeholder":"Email","type":"text"},domProps:{"value":(_vm.email)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])]),(!_vm.ready)?_c('img',{staticClass:"loading-gif",attrs:{"src":"/img/loading.gif"}}):[_c('table',{staticClass:"c-simple-table c-simple-table--tr-is-link"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.localization.general.Profile.ColumnId)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.localization.general.Profile.ColumnEmail)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.localization.general.Profile .ColumnRegistrationDateTime)+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.localization.general.Profile .ColumnLastAccessDateTime)+" ")])])]),_c('tbody',_vm._l((_vm.users),function(user,index){return _c('router-link',{key:user.id,class:[
            'user',
            {
              pair: !!(index % 2)
            }
          ],attrs:{"to":{
            name: 'Admin Users Single',
            params: {
              id: user.id
            }
          },"tag":"tr"}},[_c('td',[_vm._v(_vm._s(user.id))]),_c('td',[_vm._v(_vm._s(user.email))]),_c('td',[_vm._v(_vm._s(new Date(user.registrationDateTime).toLocaleString()))]),_c('td',[_vm._v(_vm._s(new Date(user.lastAccessDateTime).toLocaleString()))])])}),1)]),(_vm.users.length === 0)?_c('div',{staticClass:"c-alert c-alert--warning"},[_vm._v(" Result is empty ")]):_vm._e()],_c('Pagination',{attrs:{"total":_vm.maxPage},on:{"input":function($event){return _vm.changePage(_vm.activePage)}},model:{value:(_vm.activePage),callback:function ($$v) {_vm.activePage=$$v},expression:"activePage"}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }