<template>
  <div
    :class="[
      'dropdown-menu',
      {
        'dropdown-menu--active': showDropdownBody,
        'dropdown-menu--bg-blue': !borderColor && backgroundColor === 'blue',
        'dropdown-menu--bg-dark': !borderColor && backgroundColor === 'dark',
        'dropdown-menu--outlined-gray':
          !backgroundColor && borderColor === 'gray'
      }
    ]"
  >
    <div
      :style="`--height: ${height}px`"
      class="dropdown-menu__header"
      @click="toggleDropdownBody()"
    >
      <svg
        v-if="headerIcon"
        :data-src="headerIcon"
        class="dropdown-menu__header__icon"
      />
      <div
        :class="[
          'dropdown-menu__header__label',
          {
            'dropdown-menu__header__label-fix': isMainMenu
          }
        ]"
        :style="{ 'font-size': fontSize ? fontSize + 'px' : null }"
      >
        {{ headerLabel }}
      </div>
      <div class="dropdown-menu__header__arrow">
        <svg data-src="/icons/arrow-down.svg" />
      </div>
    </div>
    <div class="dropdown-menu__body">
      <div
        v-for="(item, index) in dataItems"
        :key="index"
        :class="[
          'dropdown-menu__item',
          {
            'dropdown-menu__item--disabled': item.disabled,
            'dropdown-menu__item--loading': item.loading,
            'dropdown-menu__item--async': item.async
          }
        ]"
        @click="runHandler(item)"
      >
        <ProgressCircular
          v-if="item.async"
          :color="computeProgressCircularColor"
        />
        <div class="dropdown-menu__item-wrapper">
          <svg
            v-if="item.icon"
            :data-src="item.icon"
            class="dropdown-menu__item__icon"
          />
          <div class="dropdown-menu__item__label">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCircular from '@/components/UI/ProgressCircular';

export default {
  name: 'DropdownMenu',
  components: { ProgressCircular },
  data() {
    return {
      showDropdownBody: false,
      dataItems: []
    };
  },
  computed: {
    computeProgressCircularColor() {
      return this.borderColor ? '#404C61' : 'white';
    }
  },
  props: {
    isMainMenu: {
      default: false,
      type: Boolean
    },
    headerIcon: String,
    headerLabel: String,
    fontSize: {
      type: Number || String || null,
      default: null
    },
    height: {
      type: String,
      default: '48'
    },
    borderColor: String,
    backgroundColor: String,

    items: {
      type: Array,
      default: () => {
        return [
          {
            icon: '',
            label: '',
            handler: () => console.log('Run func')
          }
        ];
      }
    }
  },
  methods: {
    toggleDropdownBody() {
      this.showDropdownBody = !this.showDropdownBody;
    },
    async runHandler(item) {
      if (item.loading) return;

      if (item.async) {
        item.loading = true;
        await item.handler().then(() => {
          item.loading = false;
        });
      } else {
        item.handler();
      }

      this.showDropdownBody = false;
    },
    loadItems() {
      this.dataItems = this.items.map(item => {
        if (item.async) item.loading = false;

        return item;
      });
    },
    hideDropdownBodyIfClickIsOut(e) {
      if (!this.$el.contains(e.target) && this.showDropdownBody) {
        this.showDropdownBody = false;
      }
    }
  },

  created() {
    document.addEventListener('click', this.hideDropdownBodyIfClickIsOut);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideDropdownBodyIfClickIsOut);
  },
  async mounted() {
    this.loadItems();
  },
  watch: {
    items() {
      this.loadItems();
    }
  }
};
</script>
<style lang="scss" src="./DropdownMenu.scss" />
