<template>
  <AuthBlockComponent>
    <template v-slot:body>
      <template>
        <div class="auth__inner">
          <router-link
            class="not-found"
            :to="{
              name: 'Main'
            }"
          >
            {{ localization.general.NotFound.Code }}
            <span></span>
            {{ localization.general.NotFound.Text }}
          </router-link>
        </div>
      </template>
    </template>
    <template v-slot:footer>
      <div class="auth__additional-links">
        <router-link
          class="auth__additional-links__item"
          :to="{
            name: 'Main'
          }"
        >
          {{ localization.general.NotFound.GoToMainPage }}
        </router-link>
      </div>
    </template>
  </AuthBlockComponent>
</template>

<script>

import AuthBlockComponent from "@/components/Auth/AuthBlockComponent";

export default {
  name: 'NotFound',
  components: {AuthBlockComponent},
  data() {
    return {
    };
  }
};
</script>

