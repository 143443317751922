<template>
  <div
    :class="[
      'game-statistics',
      {
        'game-statistics--active': value
      }
    ]"
  >
    <div class="game-statistics__logo">
      <img class="game-statistics__logo__img" src="/img/logo.svg" />
    </div>
    <div class="game-statistics__block">
      <div class="game-statistics__row">
        <div class="game-statistics__item game-statistics__item--w-50">
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.TotalTime }}
          </h6>
          <h2 class="game-statistics__item__value">
            {{ totalMinutes }}
            {{ localization.general.TestResult.TotalMinutes }}
            {{ totalSeconds }}
            {{ localization.general.TestResult.TotalSeconds }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-50">
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.AverageAnswerTime }}
          </h6>
          <h2 class="game-statistics__item__value">
            {{ statData.averageAnswerTime }}
            {{ localization.general.TestResult.TotalSeconds }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-25">
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.TotalHands }}
          </h6>
          <h2 class="game-statistics__item__value">
            {{ statData.totalHands }}
          </h2>
        </div>
        <div
          class="game-statistics__item game-statistics__item--w-25 game-statistics__item--correct"
        >
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.CorrectAnswers }}
          </h6>
          <h2 class="game-statistics__item__value">
            {{ statFilteredFromBackend.correct }}
          </h2>
        </div>
        <div
          class="game-statistics__item game-statistics__item--w-25 game-statistics__item--incorrect"
        >
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.IncorrectAnswers }}
          </h6>
          <h2 class="game-statistics__item__value">
            {{ statFilteredFromBackend.incorrect }}
          </h2>
        </div>
        <div class="game-statistics__item game-statistics__item--w-25">
          <h6 class="game-statistics__item__label">
            {{ localization.general.TestResult.Quality }}
          </h6>
          <h2 class="game-statistics__item__value">{{ statData.quality }}%</h2>
        </div>
      </div>
      <div class="game-statistics__table">
        <div class="c-tabs game-statistics__table__tabs">
          <a
            @click.prevent="setCurrentTab(item.value)"
            v-for="item in statisticItems"
            :key="item.name"
            :class="[
              'c-tabs__item',
              'game-statistics__table__tabs-item',
              {
                'c-tabs__item--active': currentTab === item.value
              }
            ]"
          >
            {{ item.name }}
          </a>
        </div>

        <RangeTableEditor
          class="game-statistics__range-table"
          :is-read-inly="true"
          :heatMapHands="heatMapHands"
          :hands="heatMapHandsFiltered"
          :qualityHeatmapActive="isQualityHeatmapActive"
          :speedHeatMapActive="isSpeedHeatMapActive"
          :statistics="heatMapStatisticsFiltered"
          :activeStatTab="currentTab"
          :isHeatmapActive="true"
          :speedData="speedHandsObject"
        />
      </div>

      <Btn class="game-statistics__save" @click="save()">
        FINISH
      </Btn>
    </div>
    <Footer />
  </div>
</template>

<script>
import Btn from '@/components/UI/Btn';
import RangeTableEditor from '@/components/RangeTableEditor';

export default {
  name: 'GameStatisticsDialog',
  components: {
    Btn,
    RangeTableEditor
  },
  props: {
    heatMapHands: Array,
    statData: Object,
    value: Boolean
  },
  data() {
    return {
      currentTab: 'Quality',
      isSpeedHeatMapActive: false,
      isQualityHeatmapActive: false,
      statDataFiltered: {
        correct: 0,
        incorrect: 0
      }
    };
  },
  computed: {
    // heatMapHandsFiltered() {
    //   const obj = {};
    //   for (let i = 0; i < this.heatMapHands.length; i++) {
    //     const { handKey, ...rest } = this.heatMapHands[i];
    //     obj[handKey] = rest;
    //   }
    //   return obj;
    // },

    statFilteredFromBackend() {
      return this.heatMapHands.reduce(
        (stats, hand) => {
          stats.correct += hand.correct;
          stats.incorrect += hand.incorrect;
          stats.time += hand.time;
          return stats;
        },
        { correct: 0, incorrect: 0, time: 0 }
      );
    },

    speedHandsObject() {
      console.log(this.heatMapHands, 'answers come');

      if (this.heatMapHands.length === 1) {
        const answer = this.heatMapHands[0];
        return { [answer.handKey]: 1 };
      }

      // Находим максимальное время ответа

      let slowestTime = Math.max(
        ...this.heatMapHands.map(answer => answer.time)
      );
      let highestTime = Math.min(
        ...this.heatMapHands.map(answer => answer.time)
      );
      const range = slowestTime - highestTime;
      console.log(range, ' range');
      console.log(highestTime, 'highest time');
      console.log(slowestTime, 'slowest');

      // Вычисляем скорость ответов для каждой руки
      let speeds = {};
      if (range === 0) {
        for (let answer of this.heatMapHands) {
          let handKey = answer.handKey;
          speeds[handKey] = 1; // Set speed to 1 when range is zero
        }
      } else {
        for (let answer of this.heatMapHands) {
          let handKey = answer.handKey;
          let time = answer.time;

          let speed = 1 - (time - highestTime) / range;
          console.log(speed, 'speed');

          speed = Math.max(0, Math.min(speed, 1));
          speeds[handKey] = speed;
          console.log(speeds, 'speeds');
        }
      }

      console.log(speeds, 'final speeds');

      return speeds;
    },
    statisticItems() {
      return [
        {
          name: this.localization.general.TestResult.Quality,
          value: 'Quality',
          active: true
        },
        {
          name: this.localization.general.TestResult.Speed,
          value: 'Speed',
          active: false
        }
      ];
    },

    heatMapHandsFiltered() {
      const obj = {};

      for (let i = 0; i < this.heatMapHands.length; i++) {
        const { handKey, correct, incorrect, time } = this.heatMapHands[i];

        if (!obj.hasOwnProperty(handKey)) {
          obj[handKey] = {};
        }

        obj[handKey].correct = correct;
        obj[handKey].incorrect = incorrect;
        obj[handKey].time = time;

        console.log(obj, 'ggg');
        if (!obj[handKey].hasOwnProperty('statistics')) {
          obj[handKey].statistics = { correct, incorrect, time };
        }
      }

      console.log(obj, 'object filtered heatm');

      return obj;
    },
    heatMapStatisticsFiltered() {
      return { hands: this.heatMapHandsFiltered };
    },

    totalMinutes() {
      return Math.floor(this.statData.totalTime / 60);
    },
    totalSeconds() {
      return this.statData.totalTime - this.totalMinutes * 60;
    }
  },
  methods: {
    setCurrentTab(value) {
      this.isQualityHeatmapActive = value === 'Quality' ? true : false;
      this.isSpeedHeatmapActive = value === 'Speed' ? true : false;
      this.currentTab = value;
    },
    save() {
      this.$emit('finishGame');
    }
  },
  watch: {
    value(val) {
      if (!val) this.$emit('finishGame');
    }
  }
};
</script>

<style lang="scss" scoped src="./GameStatisticsDialog.scss" />
