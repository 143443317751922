<template>
  <div>
    <h2 class="admin-title">
      Bills
    </h2>
    <div class="c-card">
      <div class="filter-block">
        <div class="form-block filter-block__item">
          <label class="form-label-text">
            Type
          </label>
          <Select
            v-model="selected.type"
            :items="types"
            clearable
            @input="getBills()"
          />
        </div>
        <Btn @click="getBills()">
          {{ localization.general.Simple.Refresh }}
        </Btn>

      </div>

      <table class="c-simple-table c-simple-table--tr-is-link">
        <thead>
        <tr>
          <th>
            Created at
          </th>
          <th>
            Cart Id
          </th>
          <th>
            Price
          </th>
          <th>
            Discount
          </th>
          <th>
            Source
          </th>
          <th>
            State
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-if="loaded">
          <router-link
            v-for="bill in billsByDate"
            :key="bill.id"
            :to="{
              name: 'Admin Users Single',
              params: {
                id: bill.userId
              }
            }"
            tag="tr"
          >
            <td>
              {{
                new Date(bill.creationDateTime).toLocaleString()
              }}
            </td>
            <td>
              {{ bill.cartId }}
            </td>
            <td>
              {{ bill.totalPrice.discount !== null ? bill.totalPrice.discount.price : bill.totalPrice.value }}
            </td>
            <td>
              {{ bill.totalPrice.discount !== null ? bill.totalPrice.discount.value : 0 }}
            </td>
            <td>
              {{ bill.source }}
            </td>
            <td>
              {{ bill.state }}
            </td>
          </router-link>
        </template>
        </tbody>
      </table>
      <Pagination
        v-model="activePage"
        :total="maxPage"
        @input="getBills()"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import {SEARCH_BILLS} from '@/api/bills';
import Pagination from "@/components/UI/Pagination/Pagination";
import Select from "@/components/UI/Select/Select";
import Btn from "@/components/UI/Btn";

export default {
  name: 'AdminBills',
  components: {
    Btn,
    Select,
    Pagination
  },
  data() {
    return {
      types: [
        {
          value: 'LicenseKey',
          label: 'License Key',
        },
        {
          value: 'PremiumItem',
          label: 'Premium Item'
        },

      ],
      loaded: false,
      activePage: 0,
      maxPage: 1,
      itemsPerPage: 25,

      selected: {
        type: null
      },

      bills: [],
      isActive: true,
      activeTab: 1,
      active: false
    };
  },
  computed: {
    billsByDate() {
      if (this.bills) {
        const arrOr = this.bills;
        const newarr = arrOr.sort((a, b) => {
          return moment(b.creationDateTime).diff(a.creationDateTime);
        });

        return newarr;
      } else {
        return null;
      }
    }
  },
  methods: {
    nextPage() {
      this.activePage += 1;
      this.changePage();
    },
    prevPage() {
      this.activePage -= 1;
      this.changePage();
    },
    changePage(){
      this.getBills()
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.activePage
        }
      })
    },
    isNotActive(date) {
      return (
        moment(date).valueOf() < moment('01-01-2019', 'dd-mm-yyyy').valueOf()
      );
    },
    async getBills() {
      this.loaded = false;
      const bills = await SEARCH_BILLS({
        type: this.selected.type,
        pageNumber: this.activePage,
        itemsPerPage: this.itemsPerPage
      });
      this.bills = bills.data;
      this.activePage = bills.pageNumber;
      this.maxPage = bills.pagesCount;
      this.loaded = true;
    }
  },
  created() {
    if (this.$route.query.page) {
      this.activePage = this.$route.query.page;
    }
  },
  async mounted() {

    await this.getBills();

  },
};
</script>

<style>
.form-block label {
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 14px;
}

.vs__clear {
  height: inherit !important;
  max-width: inherit !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  width: inherit !important;
  font-size: inherit !important;
}

.notActive {
  border: 1px solid red;
}
</style>
