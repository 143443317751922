var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"range-table-editor"},[_c('table',{staticClass:"range-table-editor__matrix table-fixed-layout"},[_c('tbody',_vm._l((_vm.rowIterator),function(row,x){return _c('tr',{key:x,class:[
          'range-table-editor__row',
          _vm.isGameView ? 'range-table-editor__row--game' : ''
        ]},_vm._l((row),function(hand,y){return _c('td',{key:y,class:[
            'range-table-editor__cell',
            `range-table-editor__cell__${hand.name}`,
            {
              'range-table-editor__cell--selected-for-test': hand.useForTest  && _vm.hidden(x, y),
              'range-table-editor__cell--has-weight':
                _vm.isWeightValid(hand.weight) &&
                !_vm.isHeatmapActive &&
                !_vm.speedHeatMapActive && _vm.hidden(x, y),
              'range-table-editor__cell--highlighted':
                _vm.highlightHands && _vm.highlightHands.includes(hand.name) && _vm.hidden(x, y)
            }
          ],on:{"mousedown":function($event){_vm.hidden(x, y) && _vm.startSelection(hand)},"mouseover":function($event){_vm.hidden(x, y) && _vm.selectHand(hand)}}},[(_vm.isActiveLevelMode && _vm.isRange && hand.level && _vm.isHeroActive && _vm.hidden(x, y))?_c('div',{staticClass:"range-table-editor__level"},[_vm._v(" "+_vm._s(hand.level)+" ")]):_vm._e(),(_vm.hidden(x, y))?_c('div',{staticClass:"range-table-editor__background"}):_vm._e(),(
              _vm.isHeatmapActive && (hand.time || hand.correct || hand.incorrect) && _vm.hidden(x, y)
            )?_c('div',{staticClass:"range-table-editor__heatmap"},[_c('div',{style:({
                backgroundColor: _vm.getColorByFilter(
                  hand.correct,
                  hand.incorrect,
                  hand.name
                ),
                width: '100%',
                height: '100%'
              })})]):_vm._e(),(_vm.hidden(x, y))?_c('div',{staticClass:"range-table-editor__weight"},_vm._l((_vm.allPossibleWeights),function(weightName){return _c('span',{key:weightName,class:[
                'range-table-editor__weight-value',
                {
                  'range-table-editor__weight-value--visible':
                    hand !== 'undefined' &&
                    hand != null &&
                    hand.weight !== 'undefined' &&
                    hand.weight != null &&
                    hand.weight[weightName] > 0
                      ? hand.weight[weightName]
                      : 0
                }
              ],style:({
                width:
                  (hand !== 'undefined' &&
                  hand != null &&
                  hand.weight !== 'undefined' &&
                  hand.weight != null &&
                  hand.weight[weightName] > 0
                    ? hand.weight[weightName]
                    : 0) *
                    100 +
                  '%',
                'background-color': _vm.actionColors[weightName]
              }),attrs:{"weight-type":weightName}})}),0):_vm._e(),(_vm.hidden(x, y))?_c('div',{staticClass:"absolute-position range-table-editor__foreground"}):_vm._e(),_c('div',{staticClass:"absolute-position range-table-editor__info",class:[
            {'hidden-position-cart': !_vm.hidden(x, y)}
          ]},[_c('div',{staticClass:"range-table-editor__hand-name"},[_vm._v(" "+_vm._s(hand.name)+" ")]),(
                hand.statistics && _vm.hidden(x, y) &&
                  _vm.showStatistics &&
                  (hand.statistics.correct > 0 ||
                    hand.statistics.incorrect > 0) &&
                  _vm.activeStatTab !== 'Speed'
              )?_c('p',{class:[
                'range-table-editor__statistics',
                {
                  'range-table-editor__statistics--with-mistakes':
                    hand.statistics.incorrect > 0
                }
              ]},[_vm._v(" "+_vm._s(_vm.formatStatisticsValue(hand.statistics.incorrect))+" / "+_vm._s(_vm.formatStatisticsValue( hand.statistics.correct + hand.statistics.incorrect ))+" ")]):(
                _vm.activeStatTab === 'Speed' && _vm.hidden(x, y) &&
                  hand.statistics &&
                  _vm.showStatistics &&
                  (hand.statistics.correct > 0 ||
                    hand.statistics.incorrect > 0)
              )?_c('p',{class:['range-table-editor__statistics']},[_vm._v(" "+_vm._s((hand.time / (hand.correct + hand.incorrect)).toFixed(1))+" ")]):_vm._e()])])}),0)}),0)]),(!_vm.isMockTable && _vm.isRange)?_c('div',{staticClass:"range-table-editor__state"},_vm._l((_vm.weights),function(item,index){return _c('div',{key:index,staticClass:"range-table-editor__state__item"},[_c('b',[_vm._v(_vm._s(item.charAt(0).toUpperCase() + item.slice(1))+": ")]),_c('div',{staticClass:"range-table-editor__state__item__value"},[_vm._v(" "+_vm._s((parseFloat(_vm.rangeState[item]) * 100).toFixed(2))+"% ("+_vm._s(parseInt(_vm.rangeState[`${item}Count`]))+") ")])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }