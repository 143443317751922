var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.computedClasses,style:({
    'width': _vm.computeWidth,
    'height': _vm.computeHeight,
  })},[_c('div',{staticClass:"select__header",style:({
      '--height': _vm.height + 'px',
      'border-radius': _vm.rounded ? (_vm.height / 2) + 'px' : false
    }),on:{"click":function($event){return _vm.toggleDropdownBody()}}},[_c('div',{staticClass:"select__header__label"},[_vm._v(" "+_vm._s(_vm.selectedItemLabel)+" ")]),_c('div',{staticClass:"select__header__arrow"},[_c('svg',{attrs:{"data-src":"/icons/arrow-down.svg"}})]),(_vm.clearable)?_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"select__header__clear",attrs:{"data-src":"/icons/close.svg"},on:{"click":_vm.clearSelection}}):_vm._e()]),_c('div',{staticClass:"select__body"},_vm._l((_vm.items),function(item){return _c('div',{key:item[_vm.itemValue],staticClass:"select__item",on:{"click":function($event){return _vm.selectItem(item)}}},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }