<template>
  <Dialog
    v-model="computedValue"
    class="premium-to-user-dialog modal-dialog--size-to-content modal-dialog--width-medium"
  >
    <DialogHeader>
      Premium Items to User
    </DialogHeader>
    <DialogContent>
      <div class="form-block">
        <label class="form-label-text">
          Source
        </label>
        <select
          v-model="newData.source"
          class="form-input"
        >
          <option
            v-for="source in sources"
            :key="source"
            :value="source"
          >
            {{ source }}
          </option>
        </select>
      </div>
      <div class="form-block">
        <div class="form-group__title">
          <input
            v-model="newData.recursively"
            type="checkbox"
          />
          <label>
            Recursively
          </label>
        </div>
      </div>
      <div class="form-block">
        <div class="form-group__title">
          <input
            v-model="checkboxes.itemsAsRegular"
            type="checkbox"
            @change="checkIfItemsAsRegular()"
          />
          <label>
            As regular items
          </label>
        </div>
      </div>
      <template>
        <div class="form-block">
          <div class="form-group__title">
            <input
              v-model="checkboxes.price"
              :disabled="checkboxes.itemsAsRegular"
              type="checkbox"
              @change="togglePrice()"
            />
            <label>
              Price
            </label>
          </div>
          <input
            v-model.number="newData.totalPrice"
            :disabled="!checkboxes.price || checkboxes.itemsAsRegular"
            class="form-input"
            type="text"
          />
        </div>
      </template>
    </DialogContent>
    <DialogFooter>
      <Btn
        outlined
        @click="closeModal()"
      >
        {{ localization.general.Simple.Cancel }}
      </Btn>
      <Btn
        @click="setPremiumItemsToUser()"
      >
        Create
      </Btn>
    </DialogFooter>
  </Dialog>
</template>

<script>
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "@/components/UI/Dialog";
import Btn from "@/components/UI/Btn";

export default {
  name: "PremiumItemsToUserDialog",
  data() {
    return {

      sources: ['Fastspring', 'Other'],

      checkboxes: {
        price: false,
        itemsAsRegular: true
      },
      newData: {
        totalPrice: 0,
        cartId: null,
        recursively: true,
        source: "Other",
        userId: null,
        parentId: null,
        premiumItems: []
      }
    }
  },
  components: {Btn, Dialog, DialogContent, DialogFooter, DialogHeader},
  props: {
    value: Boolean,
    userId: String,
    toGroup: null,
    items: Array
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    }
  },
  methods: {
    checkIfItemsAsRegular() {
      if (this.checkboxes.itemsAsRegular) {
        this.newData.premiumItems = this.items.map(item => {
          return {
            id: item.id,
            purchaseInfo: null
          }
        });
        this.checkboxes.price = false;
        this.newData.totalPrice = null;
      } else {
        this.newData.premiumItems = this.items.map(item => {
          return {
            id: item.id,
            purchaseInfo: {
              cartItemId: null,
              price: {
                value: item.price,
                discount: null
              },
            }
          }
        });
      }
    },
    togglePrice() {
      if (this.checkboxes.price) {
        this.newData.premiumItems.forEach(item => {
          item.purchaseInfo = null;
        });
      } else {
        this.newData.totalPrice = null;

        this.newData.premiumItems.forEach((item, index) => {
          item.purchaseInfo = {
            price: {
              discount: null,
              value: this.items[index].price
            }
          }
        });
      }
    },
    setPremiumItemsToUser() {
      this.$emit('setPremiumItemsToUser', this.newData);

      this.closeModal();
    },

    closeModal() {
      this.$emit('input', false);
    }
  },
  created() {
  },
  watch: {
    items() {
      this.checkIfItemsAsRegular();
    },
    userId() {
      this.newData.userId = this.userId;
    },
    toGroup() {
      this.newData.parentId = this.toGroup ? this.toGroup.id : null;
    },

  }
}
</script>
<style lang="scss" src="./PremiumItemsToUserDialog.scss"/>