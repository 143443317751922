<template>
  <div>
    <div v-show="isBlocked" class="hide-all" />
    <div class="range-editor__title">
      <h4>{{ itemName }}</h4>
    </div>

    <slot name="BeforeRangeOptions" />

    <div v-show="isRange">
      {{ localization.general.MainPage.RangeOptions }}

      <ItemRangeOptionsEditor
        v-if="isRange"
        ref="itemRangeOptionsEditor"
        :item="rangeForItemOptionsEditor"
        :isNeedButton="false"
        :is-read-only="true"
        @heroPos="setHeroPos"
        @activePart="setActiveParticipant"
      />
    </div>
    <div v-show="isGroup">
      {{ localization.general.MainPage.RangesGroupOptions }}

      <ItemGroupOptionsEditor
        :isNeedButton="false"
        v-if="isGroup"
        ref="itemGroupOptionsEditor"
        :item="groupForItemOptionsEditor"
        :is-read-only="true"
      />
    </div>

    <slot name="BeforeTable" />

    <RangeTableEditor
      ref="rangeTableEditor"
      :is-read-only="true"
      :range="computedRangeInfo"
      :hands="computedRangeHands"
      :heroPosition="heroPosition"
      :activeParticipant="activeParticipant"
      :isRange="isRange"
      :isActiveLevelMode="true"
    />

    <slot name="AfterTable" />
  </div>
</template>

<script>
import ItemRangeOptionsEditor from '@/components/ItemOptionsEditor/ItemRangeOptionsEditor';
import RangeTableEditor from '@/components/RangeTableEditor';
import ItemGroupOptionsEditor from '@/components/ItemOptionsEditor/ItemGroupOptionsEditor';
import itemsMixin from '@/mixins/itemsMixin';

export default {
  name: 'RangeViewer',
  mixins: [itemsMixin],
  data() {
    return {
      heroPosition: 'sb'
    };
  },
  components: {
    RangeTableEditor,
    ItemRangeOptionsEditor,
    ItemGroupOptionsEditor
  }
};
</script>

<style lang="scss" src="./RangeViewer.scss" />
