<template>
  <div @click.stop="toggleTooltip">
    <stats-tooltip
      :active="isOpen"
      :player="activePlayer"
      :participant="participant"
      :statsIconPosition="statsIconPosition"
      :isMe="isMe"
      :isTopPlayer="isTopPlayer"
      ref="statsComponent"
    />
    <transition name="bounce">
      <div :class="[computedClasses, isOpen ? 'isShow' : '']">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </transition>
  </div>
</template>

<script>
import StatsTooltip from '@/components/Game/Stats/StatsTooltip';

//right 63px
export default {
  name: 'Stats',
  data() {
    return {
      isOpen: false
    };
  },
  components: {
    StatsTooltip
  },
  props: {
    activePlayer: String,
    participant: Object,
    statsIconPosition: String,
    isMe: Boolean,
    isTopPlayer: Boolean
  },
  methods: {
    toggleTooltip() {
      this.isOpen = !this.isOpen;
      this.$parent.$emit('closeOtherItems', this.activePlayer);
      console.log('TOGGLED', this.$refs);
    },
    closeItem() {
      this.isOpen = false;
    }
  },
  computed: {
    computedClasses() {
      let classes = ['game__player-position__stats'];
      if (this.statsIconPosition === 'left') {
        classes.push('game__player-position__stats-left');
      } else {
        classes.push('game__player-position__stats-right');
      }
      return classes;
    }
  }
};
</script>

<style scoped>
@keyframes bounce {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.isShow {
  /* transition: bounce 0.5s ease-in-out; */
  animation: bounce 0.5s 1;
}
</style>
