import http from "../utils/http";

const LOCALIZATION_GROUP_GENERAL = 'general';
const LOCALIZATION_GROUP_FAQ = 'faq';
const LOCALIZATION_GROUP_LANDING = 'landing';
const LOCALIZATION_GROUP_PRICE = 'price';
const LOCALIZATION_GROUP_GAME_HELP = 'gameHelp';
const LOCALIZATION_GROUP_STORE = 'store';

export const GET_LANG_OPTIONS = async () => {
  const {data} = await http.get('Configs/localizations');
  return data;
};

export const EDIT_LANG = async (dto, language, path) => {
  let url = `Configs/localizations/${language}`;

  if (path) url = url.concat(`?path=${path}`);

  await http.put(url, dto);
};


export const GET_LANG = async (lang, path) => {
  const {data} = await http.get(
    `/Configs/localizations/${lang}?path=${path}`
  );

  return data;
};
export const GET_LANGS = async () => {
  const {data} = await http.get(
    `/Configs/localizations`
  );

  return data;
};

export const EDIT_LANG_GENERAL = (dto, language) =>
  EDIT_LANG(dto, language, LOCALIZATION_GROUP_GENERAL);
export const EDIT_LANG_FAQ = (dto, language) =>
  EDIT_LANG(dto, language, LOCALIZATION_GROUP_FAQ);
export const EDIT_LANG_GAME_HELP = (dto, language) =>
  EDIT_LANG(dto, language, LOCALIZATION_GROUP_GAME_HELP);
export const EDIT_LANG_PRICE = (dto, language) =>
  EDIT_LANG(dto, language, LOCALIZATION_GROUP_PRICE);
export const EDIT_LANG_LANDING = (dto, language) =>
  EDIT_LANG(dto, language, LOCALIZATION_GROUP_LANDING);
export const EDIT_LANG_STORE = (dto, language) =>
  EDIT_LANG(dto, language, LOCALIZATION_GROUP_STORE);

export const GET_LANG_GENERAL = lang =>
  GET_LANG(lang, LOCALIZATION_GROUP_GENERAL);
export const GET_LANG_FAQ = lang =>
  GET_LANG(lang, LOCALIZATION_GROUP_FAQ);
export const GET_LANG_LANDING = lang =>
  GET_LANG(lang, LOCALIZATION_GROUP_LANDING);
export const GET_LANG_PRICE = lang =>
  GET_LANG(lang, LOCALIZATION_GROUP_PRICE);
export const GET_LANG_GAME_HELP = lang =>
  GET_LANG(lang, LOCALIZATION_GROUP_GAME_HELP);
export const GET_LANG_STORE = lang =>
  GET_LANG(lang, LOCALIZATION_GROUP_STORE);


