z
<template>
  <div class="row">
    <div class="descriptors">
      <div class="descriptors__list">
        <div class="descriptors__list__toolbar">
          <Btn
            color="blue"
            @click="openCreateDescriptor()"
          >
            Add
          </Btn>
          <Btn
            :disabled="!activeDescriptor.id && !activeDescriptor.new"
            color="blue"
            @click="saveDescriptor()"
          >
            Save
          </Btn>
        </div>
        <div
          v-for="descriptor in descriptors"
          :key="descriptor.id"
          :class="[
            'descriptors__list__item',
            {
              'descriptors__list__item--active': activeDescriptor.id === descriptor.id
            }
          ]"
          @click="selectDescriptor(descriptor)"
        >
          <div class="descriptors__list__item__title">
            {{ descriptor.id }}
          </div>
        </div>
      </div>
      <div class="descriptors__content">
        <div
          v-show="!activeDescriptor.id && !activeDescriptor.new"
          class="descriptors__content__hide"
        />
        <GroupBox>
          <template v-slot:header>
            Basic Info
          </template>
          <template v-slot:content>
            <div class="descriptors__content__basic-info">
              <div class="descriptors__content__input">
                <label>
                  Name
                </label>
                <input
                  v-model="activeDescriptor.name"
                  class="form-input"
                  type="text"
                />
              </div>
              <div class="descriptors__content__input"/>
              <div class="descriptors__content__input">
                <label>
                  Id
                </label>
                <input
                  v-model="activeDescriptor.id"
                  :disabled="!activeDescriptor.new"
                  class="form-input"
                  type="text"
                />
              </div>
              <div class="descriptors__content__input">
                <label>
                  Tag
                </label>
                <input
                  v-model="activeDescriptor.tag"
                  class="form-input"
                  type="text"
                />
              </div>
              <div class="descriptors__content__input descriptors__content__input_checkbox">
                <label for="subscribe">
                  Subscribe
                </label>
                <input
                  id="subscribe"
                  v-model="activeDescriptor.subscribe"
                  type="checkbox"
                />
              </div>
              <div class="descriptors__content__input">
                <label>
                  Max items per user
                </label>
                <input
                  v-model="activeDescriptor.maxItemsPerUser"
                  class="form-input"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="number"
                />
              </div>
              <div class="descriptors__content__input">
                <label>
                  Interval
                </label>
                <select
                  v-model="activeDescriptor.interval"
                  :disabled="!activeDescriptor.new"
                  class="form-input"
                >
                  <option
                    v-for="interval in intervals"
                    :key="interval"
                  >
                    {{ interval }}
                  </option>
                </select>
              </div>
              <div class="descriptors__content__input">
                <label>
                  Term
                </label>
                <input
                  v-model="activeDescriptor.term"
                  :disabled="!activeDescriptor.new"
                  class="form-input"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="number"
                />
              </div>

              <div class="descriptors__content__input">
                <label>
                  Refund interval
                </label>
                <input
                  v-model="activeDescriptor.refundInterval"
                  class="form-input"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="number"
                />
              </div>
            </div>
          </template>
        </GroupBox>
        <GroupBox>
          <template v-slot:header>
            Store Product Descriptors
          </template>
          <template v-slot:content>
            <StoreProductDescriptorEditor
              ref="storeProductDescriptorEditor"
              :descriptors="activeDescriptor.storeProductDescriptors"
            />
          </template>
        </GroupBox>
        <GroupBox>
          <template v-slot:header>
            Availability Layers
          </template>
          <template v-slot:content>
            <PremiumItemAvailabilityEditor
              ref="premiumItemAvailabilityEditor"
              :layer="activeDescriptor.availabilityLayer"
              :price="activeDescriptor.price"
              :price-modifier="activeDescriptor.priceModifier"
            />
          </template>
        </GroupBox>
        <GroupBox>
          <template v-slot:header>
            Localizations Editor
          </template>
          <template v-slot:content>
            <PremiumItemLocalizationsEditor
              ref="premiumItemLocalizationsEditor"
              :localization="activeDescriptor.localization"
            />
          </template>
        </GroupBox>
        <GroupBox>
          <template v-slot:header>
            Benefits Selector
          </template>
          <template v-slot:content>
            <BenefitsSelector
              ref="benefitsSelector"
              :passedBenefits="activeDescriptor.benefits"
            />
          </template>
        </GroupBox>

        <PremiumItemsSelector
          ref="premiumItemsSelector"
          :passedItems="activeDescriptor.premiumItems"
        />
        <Btn
          v-if="activeDescriptor.new"
          class="accept"
          @click="createDescriptor()"
        >
          Add
        </Btn>
        <Btn
          v-else
          class="accept"
          @click="updateDescriptor()"
        >
          Save
        </Btn>

      </div>
    </div>
  </div>
</template>

<script>
import {CREATE_LICENSE_DESCRIPTOR, GET_LICENSE_DESCRIPTORS, UPDATE_LICENSE_DESCRIPTOR} from '@/api/licenseDescriptors';
import GroupBox from "../../../components/GroupBox/GroupBox";
import PremiumItemAvailabilityEditor from "../../../components/PremiumItemAvailabilityEditor";
import PremiumItemLocalizationsEditor from "../../../components/PremiumItemLocalizationsEditor";
import BenefitsSelector from "../../../components/BenefitsSelector";
import StoreProductDescriptorEditor from "../../../components/StoreProductDescriptorEditor";
import PremiumItemsSelector from "../../../components/PremiumItemsSelector";
import Btn from "@/components/UI/Btn";

export default {
  components: {
    Btn,
    PremiumItemsSelector,
    StoreProductDescriptorEditor,
    BenefitsSelector,
    GroupBox,
    PremiumItemLocalizationsEditor,
    PremiumItemAvailabilityEditor
  },
  data() {
    return {
      intervals: ['Year', 'Quarter', 'Month', 'Day'],
      activeDescriptor: {},
      descriptors: []
    };
  },
  methods: {
    saveDescriptor() {
      if (this.activeDescriptor.new) {
        this.createDescriptor();
      } else {
        this.updateDescriptor();
      }
    },
    getNewDescriptor() {
      return this.descriptors.find(descriptor => descriptor.new);
    },
    openCreateDescriptor() {
      let newExists = this.getNewDescriptor()
      if (newExists) return

      this.descriptors.push({
        new: true,
        id: null,
        tag: null,
        subscribe: false,
        term: null,
        interval: null,
        maxItemsPerUser: null,
        name: null,
        refundInterval: null,
        storeProductDescriptors: [
          {
            type: 'Fastspring',
            Id: 'managed-subscription'
          }
        ],
        localization: {},
        benefits: [],
        premiumItems: [],
        availabilityLayer: null,
        priceModifier: null,
        price: null
      });
      this.activeDescriptor = this.descriptors[this.descriptors.length - 1];
    },
    selectDescriptor(descriptor) {
      if (this.activeDescriptor.new)
        this.descriptors.splice(this.descriptors.length - 1, 1);

      this.activeDescriptor = JSON.parse(JSON.stringify(descriptor))
    },
    getUpdates(forNew = false) {
      const appliedChangesToLocalizations = this.$refs.premiumItemLocalizationsEditor.saveLocalizations();
      const appliedChangesToBenefits = this.$refs.benefitsSelector.getChanges();
      const appliedChangesToAvailabilityEditor = this.$refs.premiumItemAvailabilityEditor.getChanges();
      const appliedChangesToDescriptorEditor = this.$refs.storeProductDescriptorEditor.buildDescriptors();
      const appliedChangesToPremiumItemsSelector = this.$refs.premiumItemsSelector.getItems();

      if (forNew) {
        return {
          id: this.activeDescriptor.id,
          tag: this.activeDescriptor.tag,
          subscribe: this.activeDescriptor.subscribe,
          term: this.activeDescriptor.term,
          interval: this.activeDescriptor.interval,
          maxItemsPerUser: this.activeDescriptor.maxItemsPerUser,
          name: this.activeDescriptor.name,
          refundInterval: this.activeDescriptor.refundInterval,
          storeProductDescriptors: appliedChangesToDescriptorEditor,
          localization: appliedChangesToLocalizations,
          benefits: appliedChangesToBenefits,
          premiumItems: appliedChangesToPremiumItemsSelector,
          availabilityLayer: appliedChangesToAvailabilityEditor.layer,
          priceModifier: appliedChangesToAvailabilityEditor.priceModifier,
          price: appliedChangesToAvailabilityEditor.price
        }
      } else {
        return {
          id: this.activeDescriptor.id,
          subscribe: this.activeDescriptor.subscribe,
          maxItemsPerUser: this.activeDescriptor.maxItemsPerUser,
          name: this.activeDescriptor.name,
          refundInterval: this.activeDescriptor.refundInterval,
          storeProductDescriptors: appliedChangesToDescriptorEditor,
          localization: appliedChangesToLocalizations,
          benefits: appliedChangesToBenefits,
          premiumItems: appliedChangesToPremiumItemsSelector,
          availabilityLayer: appliedChangesToAvailabilityEditor.layer,
          priceModifier: appliedChangesToAvailabilityEditor.priceModifier,
          price: appliedChangesToAvailabilityEditor.price
        }
      }
    },
    async createDescriptor() {
      const updates = this.getUpdates(true);

      const error = this.validateUpdates(updates);
      if (error) return

      await CREATE_LICENSE_DESCRIPTOR(updates);


      let newItem = this.getNewDescriptor();
      newItem.new = false;

      this.$toast.success("License descriptor created");

    },
    async updateDescriptor() {
      const updates = this.getUpdates();

      const error = this.validateUpdates(updates, 'update');
      if (error) return

      await UPDATE_LICENSE_DESCRIPTOR(updates);
      this.$toast.success("License descriptor updated");

      let updatedDescriptorIndex = this.descriptors.findIndex(item => item.id === updates.id);

      this.descriptors[updatedDescriptorIndex] = updates;
    },
    validateUpdates(updates, type) {

      let error = false;
      if (type !== 'update') {
        if (!updates.term) {
          this.$toast.error("Term is empty");
          error = true;
        }
        if (!updates.interval) {
          this.$toast.error("Interval is empty");
          error = true;
        }
        if (!updates.id) {
          this.$toast.error("ID is empty");
          error = true;
        }
      }

      if (!updates.maxItemsPerUser) {
        this.$toast.error("Max Items Per User is empty");
        error = true;
      }

      if (!updates.price) {
        this.$toast.error("Price is empty");
        error = true;
      }
      if (!updates.refundInterval) {
        this.$toast.error("Refund Interval is empty");
        error = true;
      }

      return error
    }
  },
  async created() {
    this.descriptors = await GET_LICENSE_DESCRIPTORS();
  },
};
</script>
<style lang="scss" scoped>
.descriptors {
  padding: 30px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;

  &__list {
    width: 30%;
    padding-right: 20px;

    &__toolbar {
      display: flex;
      margin-bottom: 30px;

      .piq-button {
        margin: 0 5px;
      }
    }

    &__item {
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
      border: 1px solid #ccc;
      cursor: pointer;
      display: flex;

      &--active {
        color: #fff;
        border-color: transparent;
        background: $blue !important;
      }
    }
  }

  &__content {
    position: relative;
    border-left: 1px solid #eeeeee;
    padding: 20px;
    padding-top: 0;
    width: 70%;

    &__hide {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 4;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__basic-info {
      display: flex;
      flex-wrap: wrap;
    }

    &__input {
      align-items: center;
      display: flex;
      width: 50%;
      padding: 0 15px;
      margin-bottom: 20px;

      label {
        margin-bottom: 0;
        font-size: 14px;
        width: 100px;
        padding-right: 12px;
      }

      input, select {
        width: calc(100% - 100px);
        height: 32px;
      }

      &_checkbox {
        input {
          height: 24px;
          width: 24px;
        }

        label {
          padding-left: 0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
