<template>
  <div class="promo">
    <section class="promo-header">
      <div
        ref="firstHeader"
        :class="[
          'promo-header__first',
          {
            'promo-header__first--fixed': headerIsFixed
          }
        ]"
      >
        <div class="container">
          <div class="promo-header__first-row row">
            <a class="promo-header__logo" href="javascript:void(0);">
              <img alt="LOGO" src="/img/logo.svg" />
            </a>
            <div class="promo-header__slogan">
              {{ localization.landing.Slogan }}
            </div>
            <LangSwitcher
              class="promo-header__langs"
              @changeLang="onChangeLang()"
            />

            <a
              class="promo-header__auth-btn"
              href="javascript:void(0);"
              @click="openSignIn()"
            >
              {{ localization.landing.SignIn }}
              <img src="/icons/arrow-right.svg" />
            </a>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row header__main-row">
          <h2
            class="promo-header__title"
            v-html="getColorizeText(localization.landing.About)"
          />
          <div class="promo-header__previewer">
            <div class="promo-header__previewer__wrapper">
              <div class="promo-header__previewer__container">
                <div class="promo-header__previewer__left">
                  <img src="/img/promo/header/ranges-list.png" />
                  <div class="promo-header__previewer__content">
                    <h4
                      class="promo-header__previewer__content__title section-title"
                      v-html="marked(localization.landing.ShortDescription)"
                    />
                    <div
                      class="promo-header__previewer__content__text"
                      v-html="marked(localization.landing.LongDescription)"
                    />
                  </div>
                </div>
                <div class="promo-header__previewer__right">
                  <img src="/img/promo/header/range-table-editor.png" />
                </div>
              </div>
              <a class="promo-header__previewer__btn" @click="openSignIn()">
                {{ localization.landing.TryForFree }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="responsive">
      <div class="container">
        <div class="row">
          <div class="responsive__content">
            <h4
              class="responsive__title section-title"
              v-html="marked(localization.landing.PlatformsHeader)"
            />
            <div
              class="responsive__subtitle"
              v-html="marked(localization.landing.Platforms)"
            />
          </div>
          <img
            class="responsive__prototype-desktop"
            src="/img/promo/responsive/responsive-desktop.png"
          />
          <img
            class="responsive__prototype-other"
            src="/img/promo/responsive/responsive-other.png"
          />
        </div>
      </div>
    </section>
    <section class="manual">
      <div class="container">
        <div class="row">
          <div class="manual__video">
            <div class="manual__video__wrapper">
              <div class="manual__video__player">
                <iframe
                  v-if="videoOpened"
                  :src="
                    `${localization.landing.VideoUrl}?rel=0&autoplay=1&vq=720`
                  "
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                  class="video-block"
                />
              </div>
              <div
                :class="[
                  'manual__video__preview',
                  {
                    'manual__video__preview--closed': videoOpened
                  }
                ]"
              >
                <img
                  class="manual__video__preview__img"
                  src="https://img.youtube.com/vi/2i0C9z7AWYg/hqdefault.jpg"
                />
                <div class="manual__video__play-btn" @click="openVideo()">
                  <div class="manual__video__play-btn__icon">
                    <img src="/icons/play.svg" />
                  </div>
                  <div class="manual__video__play-btn__duration">
                    1:29
                  </div>
                </div>
                <div class="manual__video__title">
                  {{ localization.landing.VideoTitle }}
                </div>
              </div>
            </div>
          </div>
          <div class="manual__content">
            <h4
              class="section-title manual__title"
              v-html="marked(localization.landing.AdvantagesHeader)"
            />
            <div
              class="manual__text"
              v-html="marked(localization.landing.Advantages)"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="prices">
      <h2 class="section-title prices__title">
        {{ localization.landing.PricesHeader }}
      </h2>
      <div class="container">
        <div class="row prices__row">
          <div v-for="format in prices" :key="format.id" class="prices__card">
            <div class="prices__card__header">
              <div class="prices__card__title">
                {{ format.header }}
              </div>
              <div v-if="format.discount" class="prices__card__discount">
                {{ format.discount }}
              </div>
              <div class="prices__card__subtitle">
                {{ format.textPrice }}
              </div>
            </div>
            <div class="prices__card__body">
              <div class="prices__card__list">
                <div
                  v-for="field in format.fields"
                  :key="field"
                  class="prices__card__list__item"
                >
                  {{ field }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="contacts">
      <div class="container">
        <div class="row">
          <div class="contacts__left">
            <h2 class="contacts__title section-title">
              {{ localization.landing.ContactUsTitle }}
            </h2>
            <p class="contacts__subtitle">
              {{ localization.landing.ContactUsSubtitle }}
            </p>
            <div class="contacts__list">
              <a
                v-for="socialMedia in socialMedias"
                :key="socialMedia.name"
                :href="socialMedia.link"
                class="contacts__list__item"
                target="_blank"
              >
                <img :src="socialMedia.iconUrl" />
                {{ socialMedia.name }}
              </a>
            </div>
          </div>
          <div class="contacts__channels">
            <div class="contacts__channels__item">
              <img
                class="contacts__channels__item__logo"
                src="/icons/socials/twitch.svg"
              />
              <div class="contacts__channels__item__content">
                <h3 class="contacts__channels__item__content__title">
                  {{ localization.landing.TwitchHeader }}
                </h3>
                <p class="contacts__channels__item__content__subtitle">
                  {{ localization.landing.AboutTwitch }}
                </p>
                <a
                  :href="localization.landing.TwitchUrl"
                  class="contacts__channels__item__content__btn"
                >
                  {{ localization.landing.OpenTwitch }}
                </a>
              </div>
            </div>
            <div class="contacts__channels__item">
              <img
                class="contacts__channels__item__logo"
                src="/icons/socials/discord.svg"
              />
              <div class="contacts__channels__item__content">
                <h3 class="contacts__channels__item__content__title">
                  {{ localization.landing.DiscordHeader }}
                </h3>
                <p class="contacts__channels__item__content__subtitle">
                  {{ localization.landing.AboutDiscord }}
                </p>
                <a
                  :href="localization.landing.DiscordUrl"
                  class="contacts__channels__item__content__btn"
                >
                  {{ localization.landing.OpenDiscord }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="promo-footer">
      <div class="container promo-footer__container">
        <a :href="`mailto:${localization.landing.Email}`" class="section-title">
          {{ localization.landing.Email }}
        </a>
        <div class="promo-footer__socials">
          <a
            class="promo-footer__socials__item"
            :href="localization.landing.SkypeUrl"
          >
            <img src="/icons/socials/skype.svg" />
          </a>
          <a
            class="promo-footer__socials__item"
            :href="localization.landing.TelegramUrl"
          >
            <img src="/icons/socials/telegram.svg" />
          </a>
        </div>
        <div class="promo-footer__ukraine">
          © PokerIQ, 2023. Made in Ukraine
          <img src="/icons/socials/map.svg" alt="UKRAINE" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import marked from 'marked';
import { GET_STORE_LICENSES } from '@/api/stores';
import LangSwitcher from '@/components/LangSwitcher';

export default {
  name: 'Promo',
  components: { LangSwitcher },
  data() {
    return {
      firstHeaderOffsetTop: 0,
      headerIsFixed: false,
      prices: [],
      videoOpened: false,

      licenses: []
    };
  },
  computed: {
    socialMedias() {
      return [
        {
          name: this.localization.landing.InstagramName,
          link: this.localization.landing.InstagramUrl,
          iconUrl: '/icons/socials/instagram.svg'
        },
        {
          name: this.localization.landing.FacebookName,
          link: this.localization.landing.FacebookUrl,
          iconUrl: '/icons/socials/fb.svg'
        },
        {
          name: this.localization.landing.PekarStasName,
          link: this.localization.landing.PekarStasUrl,
          iconUrl: '/icons/socials/pekarstas.png'
        },
        {
          name: this.localization.landing.GipsyName,
          link: this.localization.landing.GipsyUrl,
          iconUrl: '/icons/socials/gipsy.png'
        },
        {
          name: this.localization.landing.CardmatesName,
          link: this.localization.landing.CardmatesUrl,
          iconUrl: '/icons/socials/cardmates.png'
        }
      ];
    }
  },

  methods: {
    getColorizeText(text) {
      return text;
    },
    openSignIn() {
      this.$track.landingSignIn();

      this.$router.push({
        name: 'Sign In'
      });
    },
    openVideo() {
      this.videoOpened = true;
    },
    async loadPriceBlocks() {
      this.prices = this.translationsAndLicensesToPriceBlocks(
        this.localization.store,
        this.licenses
      );
    },

    marked(val) {
      if (val) return marked(val);

      return '';
    },
    onChangeLang() {
      this.loadPriceBlocks();
    }
  },
  async mounted() {
    this.licenses = await GET_STORE_LICENSES();
    await this.loadPriceBlocks();

    this.firstHeaderOffsetTop = this.$refs.firstHeader.offsetTop;
    window.addEventListener('scroll', () => {
      this.headerIsFixed = window.scrollY > this.firstHeaderOffsetTop;
    });
  },
  watch: {
    activeLang() {
      this.loadPriceBlocks();
    }
  }
};
</script>
<style lang="scss" src="../assets/scss/promo.scss" />
