import store from '@/store';

export const getTablePositions = size => {
  switch (size) {
    case 2:
      return ['sb', 'bb'];
    case 3:
      return ['bu', 'sb', 'bb'];
    case 5:
      return ['ep1', 'ep2', 'mp', 'co', 'bu'];
    case 6:
      return ['ep', 'mp', 'co', 'bu', 'sb', 'bb'];
    case 8:
      return ['ep1', 'ep2', 'ep3', 'mp', 'co', 'bu', 'sb', 'bb'];
  }
};

export const getCustomNameOfPosition = name => {
  if (typeof name !== 'string') return '';
  // console.log(store.state.user.globalPreferences, 'pppp');
  name = name.toLowerCase();
  // console.log(typeof name, 'name getCustomName');
  // console.log(store.state, 'store');
  let globalPreferencesPositions =
    store.state.user?.globalPreferences?.positions;

  // console.log(globalPreferencesPositions, 'Global pref pos');
  if (globalPreferencesPositions) {
    if (globalPreferencesPositions.hasOwnProperty(name)) {
      return globalPreferencesPositions[name];
    }
  }
  // console.log(name, 'name');
  return name;
};
