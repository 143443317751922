<template>
  <div>
    <table class="c-simple-table">
      <thead>
      <tr>
        <th>
          {{ localization.general.Profile.ColumnIdentifier }}
        </th>
        <th>
          {{ localization.general.Profile.ColumnRegistrationDate }}
        </th>
        <th>
          {{ localization.general.Profile.ColumnTotalProfit }}
        </th>
        <th>
          {{ localization.general.Profile.ColumnLastMonthProfit }}
        </th>
        <th>
          {{ localization.general.Profile.ColumnCurrentMonthProfit }}
        </th>
      </tr>
      </thead>
      <tbody>

      <tr
        v-for="referral in referrals"
        :key="referral.id"
      >
        <td>{{ referral.identifier }}</td>
        <td>{{ referral.registrationDate }}</td>
        <td>{{ referral.totalProfit }}</td>
        <td>{{ referral.lastMonthProfit }}</td>
        <td>{{ referral.currentMonthProfit }}</td>
      </tr>
      </tbody>
    </table>

    <Pagination
      v-model="activePage"
      :total="maxPage"
      @input="getReferrals(userId, activePage, itemsPerPage)"
    />
  </div>
</template>

<script>
import {GET_REFERRALS} from "@/api/referrals";
import Pagination from "@/components/UI/Pagination/Pagination";

export default {
  name: 'ReferralsTable',
  components: {Pagination},
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      referrals: [],
      activePage: 0,
      itemsPerPage: 10,
      maxPage: 1,
      userId: null,
    };
  },
  methods: {
    async getReferrals(pageNumber, itemsPerPage) {
      const data = await GET_REFERRALS(this.user.id, {
        pageNumber,
        itemsPerPage
      });
      this.referrals = data.data;
      this.activePage = data.pageNumber;
      this.maxPage = data.pagesCount;
      this.userId = data.userId;
    },
  },
  created() {
    this.getReferrals(this.activePage, this.itemsPerPage);
  },
};
</script>