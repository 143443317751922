<template>
  <div class="modal-dialog__footer">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "DialogFooter"
}
</script>
