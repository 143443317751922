import http from "../utils/http";
import {DEFAULT_PARENT_ID} from "@/constants/items";
import {ITEM_TYPES} from "@/constants/itemTypes";

export const GET_ALL_PREMIUM_ITEMS = async (params) => {
  const {groups, diapasons: diapasonsWithoutGroup} = await GET_PREMIUM_ITEM(params);

  return {
    groups,
    diapasonsWithoutGroup
  };
};
export const GET_PREMIUM_ITEMS_CHILDREN = async (id = DEFAULT_PARENT_ID, params) => {
  const {data} = await http.get('/PremiumItems/' + id + '/Children', {
    params
  });
  return data;
}
export const SET_PREMIUM_ITEMS_TO_USER = async data => await http.post('/PremiumItems/ToUser', data);
export const SET_PREMIUM_ITEMS_ITEMS_TO_PREMIUM = async data => await http.post('/PremiumItems/ItemsToPremium', data);
export const COPY_PREMIUM_ITEMS = async (
  items,
  parentId,
  recursively
) => {
  const {data} = await http.post('PremiumItems/Copy', {
    items: items,
    parentId: parentId,
    recursively: recursively
  });

  return data;
};

export const GET_PREMIUM_ITEMS_INFO = async (itemIDs) => {
  const {data} = await http.get('PremiumItems/' + itemIDs + '/Info')
  return data;
};
export const GET_PREMIUM_ITEMS = async (parentId, DTO) => {
  const {data} = await http.get('/PremiumItems/' + (parentId || DEFAULT_PARENT_ID) + '/Children', DTO);
  return data;
};
export const GET_PREMIUM_ITEMS_BY_ID = async ids => {
  const {data} = await http.get('/PremiumItems/' + ids);
  return data
};
export const MOVE_PREMIUM_ITEM = async DTO => await http.post('/PremiumItems/Move', DTO);
export const UPDATE_PREMIUM_ITEM_GROUP = async DTO => {
  return new Promise((resolve, reject) => {
    http.put('/PremiumItems/Group', DTO).then(response => {
      resolve(response.data)
    }).catch(error => {
      reject(error);
    });
  })
};
export const UPDATE_PREMIUM_ITEM_DIAPASON = async data => await http.put('/PremiumItems/Diapason', data);
export const SAVE_PREMIUM_ITEMS_MANY = async data => await http.patch('/PremiumItems/Many', data);
export const GET_PREMIUM_ITEM = async (params) => {
  const data = await GET_PREMIUM_ITEMS_CHILDREN(DEFAULT_PARENT_ID, params);

  const groups = data.filter(item => ITEM_TYPES[1] === item.type);
  const diapasons = data.filter(item => ITEM_TYPES[0] === item.type);
  return {
    groups,
    diapasons
  };
};
export const DELETE_PREMIUM_ITEMS = async (itemsString) => await http.delete(`PremiumItems/${itemsString}`)

export const CREATE_PREMIUM_ITEM_GROUP = async params => {
  const {data} = await http.post('PremiumItems/Group', params);
  return data;
};

export const CREATE_PREMIUM_ITEM_DIAPASON = async (params) => {
  const {data} = await http.post('PremiumItems/Diapason', params);
  return data;
};