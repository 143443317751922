import http from "../utils/http";

export const GET_LICENSES = async params => {
  const {data} = await http.get('Licenses/Search', {
    params
  });
  return data;
};

export const DELETE_LICENSE = async (id) => {
  await http.delete('/Licenses?id=' + id);
};
export const SET_LICENSE = async DTO => await http.post('Licenses', DTO);
export const SET_LICENSE_SPECIAL = async DTO =>
  await http.post('Licenses/Special', DTO);

export const GET_LICENSE_PRICES = async () => {
  const {data} = await http.get('Licenses/Prices');
  return data;
};