<template>
  <div>
    <h2 class="admin-title">
      {{ localization.general.Profile.Licenses }}
    </h2>
    <div class="c-card">
      <div class="filter-block">
        <div class="form-block filter-block__item">
          <div class="form-label-text">
            {{ localization.general.Simple.From }}
          </div>
          <datepicker
            v-model="from"
            :name="localization.general.Simple.From"
            format="dd-MM-yyyy"
          />
        </div>
        <div class="form-block filter-block__item">
          <div class="form-label-text">
            {{ localization.general.Simple.To }}
          </div>
          <datepicker
            v-model="to"
            :name="localization.general.Simple.To"
            format="dd-MM-yyyy"
          />
        </div>
        <Btn @click="getLicenses()">
          {{ localization.general.Simple.Refresh }}
        </Btn>
      </div>
      <img
        v-if="!ready"
        class="loading-gif"
        src="/img/loading.gif"
      />
      <template v-else>
        <table class="c-simple-table c-simple-table--tr-is-link">
          <thead>
          <tr>
            <th>
              {{ localization.general.Profile.ColumnUserId }}
            </th>
            <th>
              {{ localization.general.Profile.ColumnTag }}
            </th>
            <th>
              {{ localization.general.Profile.ColumnPrice }}
            </th>
            <th>
              {{ localization.general.Profile.ColumnEndTime }}
            </th>
            <th>
              {{ localization.general.Profile.ColumnCreationTime }}
            </th>
          </tr>
          </thead>
          <tbody>
          <router-link
            v-for="license in licensesByDate"
            :key="license.id"
            :to="{
              name: 'Admin Users Single',
              params: {
                id: license.userId
              }
            }"
            tag="tr"
          >
            <td>{{ license.userId }}</td>
            <td>{{ license.tag }}</td>
            <td>{{ license.price }}</td>
            <td>{{ new Date(license.endDateTime).toLocaleString() }}</td>
            <td>{{ new Date(license.creationDateTime).toLocaleString() }}</td>
          </router-link>
          </tbody>
        </table>
        <div
          v-if="licensesByDate.length === 0"
          class="c-alert c-alert--warning"
        >
          Result is empty
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import {GET_LICENSES} from '@/api/licenses';
import Datepicker from 'vuejs-datepicker';
import Btn from "@/components/UI/Btn";


export default {
  name: 'AdminLicenses',
  components: {
    Btn,
    Datepicker
  },
  data() {
    return {
      licenses: [],
      from: null,
      to: null,
      ready: false
    };
  },
  computed: {
    licensesByDate() {
      let arrayForSort = this.licenses;
      arrayForSort.sort((a, b) => {
        return moment(b.creationDateTime).diff(a.creationDateTime);
      });
      return arrayForSort
    },
  },
  methods: {
    isNotActive(date) {
      return (
        moment(date).valueOf() < moment('01-01-2019', 'dd-mm-yyyy').valueOf()
      );
    },
    async getLicenses() {
      this.ready = false;
      this.licenses = await GET_LICENSES({
        from: this.from.toISOString(),
        to: this.to.toISOString(),
      });
      this.ready = true;
    },
  },
  async created() {
    this.from = moment(new Date()).add(-10, 'd').toDate();
    this.to = moment(new Date()).toDate();

    await this.getLicenses();

  },
};
</script>
