<template>
  <div class="hand-type-picker">
    <div class="hand-type-picker__header" v-if="!isTouchpad">
      <div class="hand-type-picker__title">
        {{ this.localization.general.Board.StyleCardTitle }}
      </div>
    </div>

    <div
      class="hand-type-picker__container"
      @click="updateCardStyle('Classic')"
    >
      <h2>{{ this.localization.general.Board.Classic }}</h2>
      <div class="hand-type-picker__cards">
        <Card
          v-for="({ m, card }, i) in classicCards"
          :key="i"
          :name="card"
          :suit="m"
          :width="30"
          :height="50"
        />
      </div>
    </div>

    <div
      class="hand-type-picker__container"
      @click="updateCardStyle('YellowColor4')"
    >
      <h2>{{ this.localization.general.Board.YellowColor4 }}</h2>
      <div class="hand-type-picker__cards">
        <Card
          v-for="({ m, card }, i) in classicCards"
          :key="i"
          :name="card"
          :suit="m"
          :yellowColor4="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Game/Card';
export default {
  name: 'HandTypePicker',
  components: {
    Card
  },
  props: {
    colored: { type: Boolean, default: false },
    isTouchpad: Boolean
  },
  methods: {
    updateCardStyle(type) {
      console.log(type, this.localization, 'type card style');

      this.$emit('input', type);
    }
  },

  computed: {
    classicCards() {
      return [
        {
          card: 'A',
          m: 'd'
        },
        {
          card: 'A',
          m: 'h'
        },
        {
          card: 'A',
          m: 's'
        },
        {
          card: 'A',
          m: 'c'
        }
      ];
    }
  }
};
</script>

<style lang="scss" src="./HandTypePicker.scss" />
