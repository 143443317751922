<template>
  <div>
    <h2 class="admin-title">
      Edit Localizations
    </h2>
    <div class="c-card">
      <div class="form-block">
        <label class="form-label-text">
          Markdown:
        </label>
        <markdown-editor
          v-model="textMarkdown"
          :options="markdownEditorOptions"
        />
        <label class="form-label-text">
          Escaped Text:
        </label>
        <textarea
          class="localizations-editor__block localizations-editor__textarea"
          v-model="textEscaped"
        />
        <label class="form-label-text">
          Result:
        </label>
        <div class="localizations-editor__block" v-html="markdown"></div>
      </div>
    </div>
  </div>
</template>

<script>
import jsesc from 'jsesc';
import marked from 'marked';
import unescapeJs from 'unescape-js';

export default {
  data() {
    return {
      markdownEditorOptions: {
        lineNumbers: true,
        styleActiveLine: true,
        styleSelectedText: true,
        lineWrapping: false,
        indentWithTabs: false,
        tabSize: 2,
        indentUnit: 2
      },
      textMarkdown: '',
      textEscaped: ''
    };
  },
  computed: {
    markdown() {
      return marked(this.textMarkdown);
    }
  },
  methods: {},
  watch: {
    textEscaped(newValue) {
      this.textMarkdown = unescapeJs(newValue);
    },
    textMarkdown(newValue) {
      this.textEscaped = jsesc(newValue, {
        quotes: 'double',
        wrap: false,
        minimal: true,
        indent: '',
        indentLevel: 0
      });
    }
  }
};
</script>
<style>
@import 'https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.12.1/css/all.min.css';
@import '../../../../node_modules/v-markdown-editor/dist/v-markdown-editor.css';

.localizations-editor__block {
  border-color: black;
  border-width: 1px;
  border-style: inset;
}
.localizations-editor__textarea {
  width: 100%;
  height: 200px;
}

.v-md-container > .v-md-toolbar {
  display: flex;
  flex-wrap: wrap;
}

.btn-group {
  margin-right: 5px;
}

.label-class {
  justify-content: center;
  display: flex;
}

.wrap-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 250px;
  width: 43%;
  margin: 10px;
}

.faq-name {
  margin: 0 5px 5px 15px;
}

.wrap-top {
  display: flex;
}

.name-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-input {
  min-width: 350px;
  margin: 0 10px;
}

.editor-wrap {
  width: 85%;
}

.editor-container-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-buttons .admin-button {
  width: 100%;
  text-align: center;
}

.vtl .vtl-drag-disabled {
  background-color: #d0cfcf;
}

.vtl .vtl-drag-disabled:hover {
  background-color: #d0cfcf;
}

.vtl .vtl-disabled {
  background-color: #d0cfcf;
}

.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

.wrap-tree {
  width: 100%;
  overflow: scroll;
  height: 250px;
}
</style>
