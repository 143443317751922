<template>
  <section class="auth">
    <picture class="auth__bg">
      <source srcset="/img/auth/bg.webp" type="image/webp" />
      <source srcset="/img/auth/bg.png" type="image/png" />
      <div class="auth-cookies" v-if="!isCookieAccepted">
        <div
          class="auth-cookies--info"
          @click="changeCookieInfoState"
          v-html="underlinedText"
        ></div>

        <div class="auth-cookies--accept" @click="acceptCookies">
          {{ this.localization.landing.CookieAcceptButton }}
        </div>
      </div>
      <img src="/img/auth/bg.png" />
    </picture>
    <div class="auth__wrapper">
      <router-link
        :to="{
          name: 'Promo'
        }"
      >
        <img class="auth__logo" src="/img/logo.svg" />
      </router-link>
      <slot name="header" />
      <div class="auth__block">
        <slot name="body" />
      </div>
      <slot name="footer" />
      <LangSwitcher class="auth__langs" />
    </div>
    <Dialog v-model="isCookieInfoActive" class="auth__dialog"
      ><DialogContent>
        {{ this.localization.landing.CookieInfoLong }}</DialogContent
      >
      <DialogFooter>
        <Btn @click="acceptCookies()">
          {{ this.localization.general.Simple.Ok }}
        </Btn>
      </DialogFooter>
    </Dialog>
  </section>
</template>

<script>
import LangSwitcher from '@/components/LangSwitcher';
import { Dialog, DialogContent, DialogFooter } from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';

export default {
  name: 'AuthBlockComponent',
  components: { LangSwitcher, Dialog, DialogContent, DialogFooter, Btn },
  data() {
    return {
      isCookieAccepted: false,
      isCookieInfoActive: false
    };
  },
  methods: {
    acceptCookies() {
      this.isCookieAccepted = true;
      this.isCookieInfoActive = false;
      localStorage.setItem('isCookieAccepted', true);
    },
    changeCookieInfoState() {
      this.isCookieInfoActive = !this.isCookieInfoActive;
    }
  },

  computed: {
    underlinedText() {
      const text = this.localization.landing.CookieInfoShort;
      const words = text.split(' ');
      const lastWord = words[words.length - 1];
      const lastIndex = text.lastIndexOf(lastWord);
      return `${text.substring(0, lastIndex)}<span>${lastWord}</span>`;
    }
  },

  mounted() {
    let isCookie =
      JSON.parse(localStorage.getItem('isCookieAccepted')) ?? false;

    this.isCookieAccepted = isCookie;
  }
};
</script>

<style lang="scss" src="../../assets/scss/auth.scss" />
