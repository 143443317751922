/* eslint-disable */
import {
  EDIT_LANG_FAQ,
  EDIT_LANG_GAME_HELP,
  EDIT_LANG_GENERAL,
  EDIT_LANG_LANDING,
  EDIT_LANG_PRICE,
  EDIT_LANG_STORE,
} from '@/api/localizations';

const state = {
  isMenuOpen: false,
};

const getters = {};

const actions = {
  async LANG_EDIT_SAVE({state}, {dto, language, locType}) {
    // EDIT_LANG_FAQ(dto, language);
    if (locType == 'Landing') {
      EDIT_LANG_LANDING(dto, language);
    }
    if (locType == 'Price') {
      EDIT_LANG_PRICE(dto, language);
    }
    if (locType == 'General') {
      EDIT_LANG_GENERAL(dto, language);
    }
    if (locType == 'Store') {
      EDIT_LANG_STORE(dto, language);
    }

  },
  async LANG_FAQ_EDIT_SAVE({state}, {dto, language}) {
    EDIT_LANG_FAQ(dto, language);
  },
  async LANG_LANDING_EDIT_SAVE(
    {state},
    {dto, language}
  ) {
    EDIT_LANG_LANDING(dto, language);
  },

  async LANG_PRICE_EDIT_SAVE({state}, {dto, language}) {
    EDIT_LANG_PRICE(dto, language);
  },

  async LANG_GENERAL_EDIT_SAVE(
    {commit, dispatch, state},
    {dto, language}
  ) {
    EDIT_LANG_GENERAL(dto, language);
  },

  async LANG_GAME_HELP_EDIT_SAVE(
    {commit, dispatch, state},
    {dto, language}
  ) {
    EDIT_LANG_GAME_HELP(dto, language);
  },

  async LANG_STORE_EDIT_SAVE(
    {commit, dispatch, state},
    {dto, language}
  ) {
    EDIT_LANG_STORE(dto, language);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};