<template>
  <section
    :style="{
      'background-color': table.backgroundColor
    }"
    class="game"
  >
    <template v-if="!gameStatisticsDialog">
      <Dialog
        v-if="isTouchpad"
        v-model="colorPicker"
        class="modal-dialog--size-to-content"
      >
        <DialogHeader>
          {{ colorPickerTypeTitle }}
        </DialogHeader>
        <DialogContent>
          <ColorPicker
            :colors="backgroundColors"
            class="color-picker-popup__row"
            item-height="50px"
            item-width="50px"
            @input="setColor"
          />
        </DialogContent>
      </Dialog>
      <div
        v-if="!isTouchpad"
        :class="{
          'color-picker-popup--active': colorPicker
        }"
        :style="{
          top: colorPickerPosition.y,
          left: colorPickerPosition.x
        }"
        class="color-picker-popup"
      >
        <div
          class="color-picker-popup__overlay"
          @click="closeHandTypePicker()"
        />
        <div class="color-picker-popup__container">
          <div class="color-picker-popup__title">
            {{ colorPickerTypeTitle }}
          </div>
          <ColorPicker
            :colors="backgroundColors"
            class="color-picker-popup__row"
            item-height="50px"
            item-width="50px"
            @input="setColor"
          />
        </div>
      </div>

      <div class="game__header">
        <div class="game__header__info">
          <div class="game__timebank">
            <svg height="0" width="0">
              <defs>
                <linearGradient
                  id="cl1"
                  gradientUnits="objectBoundingBox"
                  x1="0"
                  x2="1"
                  y1="0"
                  y2="1"
                >
                  <stop stop-color="#e32a89" />
                  <stop offset="100%" stop-color="#498a98" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              class="game__timebank__svg"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g class="game__timebank__circle">
                <circle
                  class="game__timebank__path-elapsed"
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="url(#cl1)"
                />
                <path
                  ref="timebankRemaining"
                  :class="[
                    'game__timebank__path-remaining',
                    'game__timebank__path-remaining--' + timebankType
                  ]"
                  d="
                    M 50, 50
                    m -45, 0
                    a 45,45 0 0,0 90,0
                    a 45,45 0 0,0 -90,0
                  "
                  stroke-dasharray="283"
                />
              </g>
            </svg>
            <span ref="gameContentTimebankLabel" class="game__timebank__label">
              {{ timeLeft }}
            </span>
          </div>
          <div class="game__hands-remained" v-if="false">
            <img src="/img/game/hands-remained.svg" />
            {{ handsLeft }}
          </div>
        </div>
        <!-- <router-link
          class="game__logo"
          :to="{
            name: 'Main'
          }"
        > -->
        <div class="game__logo">
          <img src="/img/logo.svg" />
        </div>

        <!-- </router-link> -->
        <div class="game__header__btns-row">
          <Btn
            v-for="(btn, index) in gameHeaderBtns"
            :key="index"
            :class="[
              'game__header__btn--' + btn.classModificator,
              'game__header__btn'
            ]"
            color="white"
            height="38px"
            outlined
            @click="btn.handler"
          >
            <span class="game__header__btn__show-on-desktop">{{
              btn.label
            }}</span>
            <svg
              :data-src="btn.icon"
              class="game__header__btn__show-on-mobile game__header__btn__show-on-mobile__icon"
            />
          </Btn>
        </div>
      </div>
      <!-- hands type picker -->
      <!-- <HandTypePicker :colored="handTypePickerColored" /> -->
      <Dialog
        v-if="isTouchpad"
        v-model="isHandTypePickerActive"
        class="modal-dialog--size-to-content  "
      >
        <DialogHeader>
          {{ this.localization.general.Board.StyleCardTitle }}
        </DialogHeader>
        <DialogContent class="modal-dialog--htp-padding">
          <HandTypePicker
            :colored="isYellowCards"
            @input="setCardStyle"
            :isTouchpad="isTouchpad"
          />
        </DialogContent>
      </Dialog>

      <!-- Стиль карт для настольной версии экрана -->
      <div
        v-if="!isTouchpad"
        :class="{
          'hand-type-picker-popup--active': isHandTypePickerActive
        }"
        :style="{
          top: handTypePickerPosition.y,
          left: handTypePickerPosition.x
        }"
        class="hand-type-picker-popup"
      >
        <div class="color-picker-popup__overlay" @click="closeColorPicker()" />
        <div class="hand-type-picker-popup__container">
          <HandTypePicker
            :colored="isYellowCards"
            @input="setCardStyle"
            :isTouchpad="isTouchpad"
          />
        </div>
      </div>
    </template>
    <template v-if="isLoading">
      <div class="game--loading">
        <vue-loaders name="ball-grid-pulse"></vue-loaders>
      </div>
    </template>
    <template v-if="!isLoading && currentRange">
      <div
        ref="gameContent"
        class="game__content"
        @contextmenu="callColorPickerOnContextMenu($event, 'Main Background')"
      >
        <Board
          ref="board"
          :bgColor="computedBoardColor"
          :hand="hand"
          :isLinkedDiapasonActive="isLinkedDiapasonActive"
          :handCards="handCards"
          :isShuffle="isShuffle"
          :isWrong="isWrongAnswer"
          :participants="participants"
          :prevGame="prevGame"
          :range="currentRange.diapason"
          :timebank="timebank"
          @contextmenu.native="callColorPickerOnContextMenu($event, 'Board')"
          @openDiapasonInfo="onOpenDiapasonInfo"
          :isAvatarMode="isAvatarMode"
          @isOpAllin="isOpAllin"
          @checkOtherAllinStack="onCheckOtherAllinStack"
          :isColoredCards="isYellowCards"
          @clickHandTypePicker="callHandTypePickerContext"
        >
          <template v-slot:timer>
            <div class="game__timer">
              <div class="game__timer__row">
                <div
                  v-for="second in linearTimerIndicatorLength"
                  :key="second"
                  :class="[
                    'game__timer__item',
                    {
                      'game__timer__item--filled':
                        second <= computedLinearSeconds
                    }
                  ]"
                />
              </div>
            </div>
          </template>
        </Board>
      </div>

      <ButtonsForAnswer
        v-if="hand"
        :allWeight="currentRangeWeights"
        :isHaveAllin="isHaveAllin"
        :isHaveCheck="isHaveCheck"
        :randomNumber="randomNumber"
        :situation="situation"
        :weight="hand.weight"
        :isHintOptionActive="hintModeOption"
        :isLoadingLinkedHands="loadingLinkedHand"
        @finishAnswer="finishGame"
        :resultForHint="{
          hand,
          handCards,
          getGameMode,
          situation,
          randomNumber,
          isOpAllin,
          otherAllinStack,
          isInverse
        }"
        :isInverse="isInverse"
        :isFoldButtonActive="isFoldButton"
        :otherAllinStack="otherAllinStack"
        :additionalProperties="currentRange.diapason.additionalProperties"
        :opAllin="opAllin"
      >
        <template v-slot:prepend>
          <div
            v-if="prevGame"
            :class="[
              'game__info',
              {
                'game__info--active': gameInfoIsActive
              }
            ]"
          >
            <div
              :class="[
                'game__info__toggler',
                {
                  'game__info--loss': !prevGame.result,
                  'game__info--win': prevGame.result
                }
              ]"
              @click="toggleGameInfo()"
            >
              <img src="/icons/triangle-right.svg" />
            </div>
            <div class="game__info__content">
              <PreviousGameInfo
                ref="prevGame"
                :game="prevGame"
                @showPrevRange="showPrevRange"
              />
              <svg
                class="game__info__close"
                data-src="/icons/close.svg"
                @click="toggleGameInfo()"
              />
            </div>
          </div>
        </template>
      </ButtonsForAnswer>

      <RangePreviewDialog
        v-model="isRangePreviewOpened"
        :highlightHands="previewHand"
        :range="previewRange"
        :inverse="isInverse"
        :clickedHand="clickedHand"
      />
      <GameHelpDialog v-model="isHelpOpened" @changeGameMode="changeGameMode" />
    </template>

    <GameStatisticsDialog
      v-model="gameStatisticsDialog"
      :statData="statistics"
      @finishGame="completeGamesAndQuit"
      :heatMapHands="heatMapHands"
    />
  </section>
</template>

<script>
import 'vue-loaders/dist/vue-loaders.css';
import Board from '@/components/Game/Board';
import GameHelpDialog from '@/components/GameHelpDialog';
import RangePreviewDialog from '@/components/RangePreviewDialog';

import { v4 as uuidv4 } from 'uuid';

import { mapGetters } from 'vuex';
import { GET_CARDS_FROM_NAME } from '@/utils/colode';
import {
  // GET_ALL_WEIGHT,
  GET_ALL_WEIGHT_FROM_ARRAY,
  // GET_CARDS_FROM_OBJECT,
  GET_CARDS_FROM_ONE_OBJECT,
  // GET_CARDS_FROM_ARRAY,
  GET_CORRECT_ANSWER_RANDOMIZER,
  GET_RESULT
} from '@/utils/game';

import {
  GET_ITEMS_HANDS,
  GET_FULL_ITEM_INFO,
  INCLUDE_LINKED_GROUPS,
  GET_LINKED_ITEMS_HANDS,
  GET_HANDS_STORIES
  // GET_ITEMS_INFO
} from '@/api/items';

import { PATCH_GLOBAL_PREFERENCES } from '@/api/globalPreferences';

import { Dialog, DialogContent, DialogHeader } from '@/components/UI/Dialog';
import Btn from '@/components/UI/Btn';
import GameStatisticsDialog from '@/components/GameStatisticsDialog';
import ColorPicker from '@/components/UI/ColorPicker/ColorPicker';
import HandTypePicker from '@/components/HandTypePicker';
import ButtonsForAnswer from '@/components/Game/ButtonsForAnswer';
import PreviousGameInfo from '@/components/Game/PreviousGameInfo';
import { SHADE_COLOR } from '@/utils/colors';

import {
  RFI,
  VSOR,
  VS1RC,
  DEFAULT_PARENT_ID,
  VS4BET,
  VS3B,
  SQZ
} from '@/constants/items';

// import { ITEM_TYPE_RANGE } from '@/constants/itemTypes';
// import { computed } from 'vue';

// max sets for a hand
// const MAX_GAMES_PER_HAND = 5;

export default {
  name: 'Game',
  components: {
    PreviousGameInfo,
    ButtonsForAnswer,
    ColorPicker,
    HandTypePicker,
    Btn,

    DialogHeader,
    DialogContent,
    Dialog,

    Board,
    GameHelpDialog,
    GameStatisticsDialog,
    RangePreviewDialog
  },
  data() {
    return {
      gameStatisticsDialog: false,
      loaded: false,
      isLoading: false,
      levelHands: {},
      localActivePlayers: {},
      playingIDS: [],
      otherAllinStack: false,
      linkedItemsIDS: '',
      playingHand: {},
      isPlayLinkedGames: false,
      countPlayingHands: 0,
      sessionId: null,
      heatMapHands: [],

      startPlayingArrayLenght: 0,
      linkingModeOption: false,
      actualLevel: 0,
      hintModeOption: false,
      levelModeOption: false,
      firstIteration: true,
      clickedHand: '',
      isSendRequest: false,
      playerDisplayMode: '',
      isInverse: false,
      linkedGroups: [],
      isFalseLastResult: false,
      isLinkedDiapasonActive: false,
      loadingLinkedHand: false,
      linkedHandToPlay: [],
      linkedStage: 0,
      play3rdStage: false,
      isLinkedPlayActiveOnTable: false,

      opAllin: false,

      gameInfoIsActive: false,
      timestamps: {
        info: null,
        warning: null,
        alert: null
      },
      timebankType: null,
      handsLeft: null,
      answerTimer: null,
      timeLeft: 0,
      timePassed: 0,
      remainingPathColor: null,

      ready: false,
      selectedBackgroundColor: '#273142',
      backgroundColors: [
        ['#333', '#c34278', '#293042', '#5d6270'],
        ['#dbc34c', '#ce9137', '#db7a4c', '#c34242'],
        ['#4288c3', '#42bdc3', '#42c38d', '#097227']
      ],
      colorPicker: false,
      colorPickerType: '',
      colorPickerPosition: { x: 0, y: 0 },
      handTypePickerPosition: { x: 0, y: 0 },
      isHandTypePickerActive: false,
      cardStyle: '',
      handTypePickerColored: false,
      handsTreeToPlay: [],
      handsTreeRemaining: 0,
      cachedIds: [],

      randomNumber25: 0,
      fullDiapasonInfo: [],
      lastHandIndex: -1,
      isRangePreviewOpened: false,
      isHelpOpened: false,
      previewRange: null,
      previewHand: null,

      currentRange: null,
      currentRangeWeights: [],
      hand: null,
      participants: null,
      startTime: 0,
      endTime: 0,
      randomNumber: null,

      games: [],

      gamesForStatistics: [],
      countGamesPlayed: 0,
      statistics: {
        totalTime: 0,
        totalHands: 0,
        correctAnswers: 0,
        incorrectAnswers: 0,
        quality: 0,
        averageAnswerTime: 0
      }
    };
  },

  computed: {
    ...mapGetters({
      getGameMode: 'play/getGameMode',
      table: 'user/table',
      isYellowCards: 'user/cardStyle',
      globalPreferences: 'user/globalPreferences'
    }),

    isShuffle() {
      console.log(
        this.isLinkedPlayActiveOnTable,
        this.isLinkedDiapasonActive,
        'test1234321'
      );

      console.log(this.isPlayLinkedGames, 'ispLay in shuffle');
      if (!this.isPlayLinkedGames) {
        return true;
      }
      if (this.isLinkedPlayActiveOnTable && this.isLinkedDiapasonActive) {
        console.log('workelse');
        return false;
      }
      if (!this.isLinkedPlayActiveOnTable && this.isLinkedDiapasonActive) {
        console.log('workelse');
        return false;
      }
      if (this.isLinkedDiapasonActive) return false;
      if (this.isLinkedPlayActiveOnTable && !this.isLinkedDiapasonActive)
        return false;

      return true;
    },
    linearTimerIndicatorLength() {
      return screen.width > 575 ? 10 : 6;
    },
    isAvatarMode() {
      return this.playerDisplayMode === 'PhotoName' ? true : false;
    },
    isFoldButton() {
      let { op, hp, type, n } = this.currentRange.diapason.additionalProperties;
      if (op === 'sb' && hp === 'bb' && n === 1 && type === VSOR) {
        return false;
      }
      return true;
    },
    computedLinearSeconds() {
      const oneIndicatorValue = this.timebank / this.linearTimerIndicatorLength;
      return Math.ceil(this.timeLeft / oneIndicatorValue);
    },

    timebank() {
      return this.globalPreferences.game.timebank;
    },
    gameHeaderBtns() {
      return [
        {
          classModificator: 'help',
          handler: this.showHelp,
          label: this.localization.general.Game.Help,
          icon: '/icons/help.svg'
        },
        // {
        //   classModificator: 'current',
        //   handler: this.showCurrentRange,
        //   label: this.currentRangeName
        // },
        {
          classModificator: 'finish',
          handler: this.finishAndShowGameStatistics,
          label: this.localization.general.Game.Finish,
          icon: '/icons/finish.svg'
        }
      ];
    },
    colorPickerTypeTitle() {
      return this.colorPickerType === 'Board'
        ? this.localization.general.Board.TableColorSelector
        : this.localization.general.Board.GameBackgroundColorSelector;
    },
    isWrongAnswer() {
      // if (this.prevGame?.isLinkedHand) return false; // если игра была с привязкой и ответ неправильный, то стол не будет красным
      return this.prevGame != null ? !this.prevGame.result : false;
    },
    situation() {
      let isVsRFIr1hBB = this.isCheckAvailable(this.currentRange);

      if (isVsRFIr1hBB) return 'VsRFIr1hBB';

      return null;
    },

    isHaveCheck() {
      return this.isCheckAvailable(this.currentRange);
    },
    isHaveAllin() {
      let currentRangeWeights = GET_ALL_WEIGHT_FROM_ARRAY(
        this.handsTreeToPlay //TODO:
      );

      return currentRangeWeights.some(weight => weight.allin);
    },
    currentRangeName() {
      return this.currentRange.name ?? '';
    },
    isTouchpad() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    computedBoardColor() {
      const firstColor = this.table.tableColor;
      const secondColor = SHADE_COLOR(firstColor, -35);
      const thirdColor = SHADE_COLOR(firstColor, -25);

      return `radial-gradient(157.33% 157.33% at 50% 36.96%, rgba(0, 0, 0, 0) 0%, ${secondColor} 100%), radial-gradient(63.04% 63.04% at 50% 36.96%, ${firstColor} 0%, ${thirdColor} 100%)`;
    },
    handCards() {
      console.log(this.games, 'hand cards prev game');
      let lastGame = this.games[this.games.length - 1];
      if (this.isLinkedPlayActiveOnTable) {
        return this.prevGame.hand.cards;
      }
      console.log(lastGame, 'last game info');
      if (lastGame && !lastGame.result && !lastGame.isLinkedHand) {
        console.log('result games 1', this.games);
        console.log(
          'result games handcards',
          this.games[this.games.length - 1]
        );
        return lastGame.hand.cards;
      }

      console.log(this.hand, 'this handCards this.hand');

      if (this.hand) {
        console.log(this.prevGame, 'prevGame hand cards');
        return GET_CARDS_FROM_NAME(this.hand.name);
      }

      return null;
    },
    prevGame() {
      if (this.games.length === 0) return null;

      return this.games[this.games.length - 1];
    }
  },
  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    countFinalStatistics(data) {
      return data.reduce(
        (accumulator, current) => {
          accumulator.correct += current.correct;
          accumulator.incorrect += current.incorrect;
          return accumulator;
        },
        { correct: 0, incorrect: 0 }
      );
    },
    filterByLevel(array, level) {
      console.log('filteByLevel', array, level);
      return array.filter(obj => obj.hand.gamePreference.level === level);
    },

    checkActualLevelInResponse(levels, actualLevel) {
      return levels.some(obj => obj.hand.gamePreference.level === actualLevel);
    },
    async getLinkedHand(linksGroupIds, gameData, hand, handType) {
      const linkedHand = await GET_LINKED_ITEMS_HANDS(linksGroupIds, {
        size: Object.keys(gameData[0].diapason.participants).length,
        heroPosition: gameData[0].diapason.additionalProperties.hp,
        count: 1,
        hand: hand.name,
        type: this.getNextStage(handType),
        opponentPosition: gameData[0].diapason.additionalProperties.op
      });

      return linkedHand;
    },
    fillLevelsWithSameItems(items) {
      let corSerLength = this.$store.state.user.globalPreferences.game
        .correctSeriesLength;
      console.log(items, 'items comes to fillLevelsWithSameItems');

      console.log(this.levelHands, 'lvs');
      this.levelHands[items[0].hand.gamePreference.level] = [];

      items.forEach(hand => {
        let leftCorrectAnswers =
          corSerLength - hand.hand.statistics.correctSeries;
        console.log(
          hand,
          corSerLength,
          leftCorrectAnswers,
          this.levelHands,
          this.actualLevel,
          'item handsTree'
        );
        for (let i = 0; i < leftCorrectAnswers; i++) {
          this.levelHands[hand.hand.gamePreference.level].push(hand);
          console.log(
            i,
            leftCorrectAnswers,
            hand,
            hand.hand.handKey,
            hand.hand.gamePreference.level,
            'ggg'
          );
        }
      });
    },
    async fillLevelHandsNewApi() {
      this.isLoading = true;
      console.log(this.answerTimer, 'pause');
      if (this.answerTimer) {
        console.log(this.answerTimer, 'pause2');
        clearInterval(this.answerTimer);
        console.log(this.answerTimer, 'pause2 3');
      }

      await this.sendStatisticsToServer();
      let levels = await this.getNextLevelItemsHands(this.actualLevel);

      if (!levels.length) {
        this.finishAndShowGameStatistics();
      }

      this.actualLevel = levels[0].hand.gamePreference.level;

      this.fillLevelsWithSameItems(levels);

      this.isLoading = false;

      this.nextGame();
      this.startAnswerTimer();
    },
    async fillLevelHands() {
      this.isLoading = true;

      //Обновляем статистику на сервере
      await this.sendStatisticsToServer();
      // await new Promise(resolve => setTimeout(resolve, 1000));
      //Получаем новые руки в зависимости от новой статистики
      let levels = await this.getNextLevelItemsHands(this.actualLevel);

      //Проверяем есть ли на бекенде еще не доигранные руки с этого уровня
      let isFilledLevelHands = this.checkActualLevelInResponse(
        levels,
        this.actualLevel
      );

      console.log(isFilledLevelHands, 'is filled with levels');
      if (levels.length && isFilledLevelHands) {
        let newHands = this.filterByLevel(levels, this.actualLevel);

        this.fillLevelsWithSameItems(newHands);

        console.log(newHands, 'nhands level');

        this.nextGame();
        this.isLoading = false;
      } else {
        console.log('sdfsdfsd');
        // this.actualLevel++;
        this.actualLevel++;
        let levels = await this.getNextLevelItemsHands(this.actualLevel);
        let newHands2 = this.filterByLevel(levels, this.actualLevel);
        console.log(levels, 'levels come to second level');
        this.levelHands[this.actualLevel] = [];

        this.fillLevelsWithSameItems(newHands2);

        if (this.levelHands[this.actualLevel].length) {
          this.nextGame();
          this.isLoading = false;
        } else {
          this.finishAndShowGameStatistics();
        }
      }
      console.log(levels, 'this levels');
    },
    async getNextLevelItemsHands(nextLevel) {
      let correctSeriesLength = this.$store.state.user.globalPreferences.game
        .correctSeriesLength;
      console.log(correctSeriesLength, 'CORSELLEN');
      let response = await GET_ITEMS_HANDS(this.playingIDS, {
        levels: nextLevel,
        correctSeriesLength: correctSeriesLength
      });

      console.log(response, 'respone level hands');
      return response;
    },
    // getNextLevel(){
    //   const keys = Object.keys()
    // },
    changeLevel() {
      const levels = Object.keys(this.levelHands);
      const currentLevel = this.actualLevel;

      const nextIndex = levels.indexOf(currentLevel) + 1;
      console.log('changeLevel()', levels, currentLevel, nextIndex);
      if (nextIndex < levels.length) {
        return levels[nextIndex];
      }

      return currentLevel;
    },
    updateActualLevel(levelsFromBackend) {
      console.log('update levels', this.actualLevel, levelsFromBackend);
      const keys = Object.keys(levelsFromBackend);

      console.log(keys, keys.length, 'update levels keys');
      if (keys.length > 0) {
        const lowestLevel = Math.min(...keys);

        console.log(lowestLevel, 'update levels lowerls');

        if (lowestLevel > this.actualLevel) {
          this.actualLevel = lowestLevel;
        }
      }
      console.log('update levels 2', this.actualLevel);
    },
    async changeCorrectAnswers(isCorrect) {
      console.log('work change cor ans', isCorrect);
      //Если не левел мод - то выключаем удаление рук и всю логику
      if (!this.levelModeOption) {
        return;
      }

      if (isCorrect) {
        // this.levelHands[this.actualLevel][this.randomNumber25].correctAnswers++;

        this.levelHands[this.actualLevel].splice(this.randomNumber25, 1);

        console.log(this.levelHands, 'dfds');

        // if (
        //   this.levelHands[this.actualLevel].length < 1 &&
        //   !this.levelHands[this.actualLevel + 1]?.length
        // ) {
        //   this.finishAndShowGameStatistics();
        // }
        // console.log(this.levelHands[this.actualLevel].length, 'lengty');

        if (!this.levelHands[this.actualLevel].length) {
          console.log('send stats here, and get back');
          this.fillLevelHandsNewApi();
        } else {
          this.nextGame();
        }

        //TODO: Filling level hide
        // if (
        //   !this.levelHands[this.actualLevel].length &&
        //   this.levelHands[this.actualLevel + 1].length
        // ) {
        //   // this.changeLevel();
        //   console.log('changelevel');
        //   this.actualLevel++;
        //   // this.updateActualLevel(this.levelHands);
        // }

        console.log();
        console.log('CHANGECORRECTANSWERS: +++');
      } else {
        // this.levelHands[this.actualLevel][
        //   this.randomNumber25
        // ].correctAnswers = 0;
        this.nextGame();
        console.log('CHANGECORRECTANSWERS: 000');
      }
    },
    pauseAnswerTimer() {
      clearInterval(this.answerTimer);
    },
    onCheckOtherAllinStack(value) {
      this.otherAllinStack = value;
    },
    isOpAllin(value) {
      console.log(value, 'isOpAllin');
      this.opAllin = value;
    },
    getNextStage(stage) {
      switch (stage) {
        case RFI:
          return `${VS3B},${SQZ}`;
        case SQZ:
          return VS3B;
        case VSOR:
          return VS4BET;
        case VS1RC:
          return VS4BET;
        case VS3B:
          return VS3B;
        default:
          return false;
      }
    },

    closeStatsTooltip() {
      console.log(this.$refs.board, 'BOARDD');
      this.$refs.board.$refs.position.forEach(child =>
        child.$refs?.statistic?.closeItem()
      );
    },
    fillDiapasonInfo(hands, fullInfoArray) {
      let tempArr = fullInfoArray;
      hands.forEach(item => {
        console.log(item, 'itemmmmmm');
        if (tempArr.indexOf(item.id) === -1) {
          tempArr.push(item.id);
        }
      });
      console.log(tempArr, 'tempArr');
      return tempArr;
    },
    async addItemsToDiapasonFullInfo(hands, cacheParent = false) {
      let request = [];
      hands.forEach(item => {
        if (cacheParent) {
          if (this.cachedIds.indexOf(item.parentId) === -1) {
            request.push(item.parentId);
            this.cachedIds.push(item.parentId);
          }
        }
        if (this.cachedIds.indexOf(item.id) === -1) {
          request.push(item.id);
          this.cachedIds.push(item.id);
        }
      });
      return await this.getFullDiapasonInfo(request);
    },

    fillArrayHands(array) {
      let arr = array;
      const length = 25;
      const startLength = arr.length;
      for (let i = startLength; i < length; i++) {
        console.log(arr[Math.random(0, startLength)], 'MATH RANDOM INT');
        arr.push(arr[this.getRandomInt(0, startLength)]);
      }

      return arr;
    },
    onOpenDiapasonInfo(pos) {
      console.log(pos, 'POS IN GAME');
      this.showCurrentRangeOnClickPos(pos);
    },
    findIndex(arr, handName) {
      return arr.findIndex(obj => obj.hand.handKey === handName);
    },
    toggleGameInfo() {
      this.gameInfoIsActive = !this.gameInfoIsActive;
    },
    setCircleDasharray() {
      const rawTimeFraction = this.timeLeft / this.timebank;
      const circleDasharray = `${(
        (rawTimeFraction - (1 / this.timebank) * (1 - rawTimeFraction)) *
        283
      ).toFixed(0)} 283`;

      // console.log(this.$refs, 'this refs');
      this.$refs.timebankRemaining.setAttribute(
        'stroke-dasharray',
        circleDasharray
      );
    },
    async getFullDiapasonInfo(itemIDs) {
      console.log(itemIDs.length, 'itemIds length');
      let request = itemIDs.join(',');
      let data = await GET_FULL_ITEM_INFO(request);
      // let data = await GET_ITEMS_INFO(request);
      console.log(data, 'DATA IN FULL ITEMS DIAPASON');
      return data;
    },
    async getFullDiapasonInfoID(itemIDs) {
      let diapason = await GET_FULL_ITEM_INFO(itemIDs);
      return diapason;
    },
    startAnswerTimer() {
      this.timePassed = 0;
      this.timeLeft = this.timebank;

      this.answerTimer = setInterval(() => {
        // The amount of time passed increments by one
        this.timePassed++;
        this.timeLeft = this.timebank - this.timePassed;

        if (this.timeLeft <= this.timestamps.alert) {
          this.timebankType = 'alert';
        } else if (this.timeLeft <= this.timestamps.warning) {
          this.timebankType = 'warning';
        } else {
          this.timebankType = 'info';
        }

        if (
          this.isHelpOpened ||
          this.isRangePreviewOpened ||
          this.gameInfoIsActive
        ) {
          clearInterval(this.answerTimer);
        }

        if (this.timeLeft <= 0) {
          this.finishGame({
            raise: null,
            check: null,
            call: null,
            fold: null,
            allin: null
          });
          clearInterval(this.answerTimer);
          this.startAnswerTimer();
        }
        this.setCircleDasharray();
        // The time left label is updated
      }, 1000);
    },
    resumeAnswerTimer() {
      // Save the current time passed
      const savedTimePassed = this.timePassed;

      // Clear the existing interval timer
      clearInterval(this.answerTimer);

      this.answerTimer = setInterval(() => {
        // The amount of time passed increments by one
        this.timePassed++;
        this.timeLeft = this.timebank - this.timePassed;

        if (this.timeLeft <= this.timestamps.alert) {
          this.timebankType = 'alert';
        } else if (this.timeLeft <= this.timestamps.warning) {
          this.timebankType = 'warning';
        } else {
          this.timebankType = 'info';
        }

        if (
          this.isHelpOpened ||
          this.isRangePreviewOpened ||
          this.gameInfoIsActive
        ) {
          clearInterval(this.answerTimer);
        }

        if (this.timeLeft <= 0) {
          this.finishGame({
            raise: null,
            check: null,
            call: null,
            fold: null,
            allin: null
          });
          clearInterval(this.answerTimer);
          this.startAnswerTimer();
        }
        this.setCircleDasharray();
      }, 1000);

      // Set the saved time passed value
      this.timePassed = savedTimePassed;
    },
    setHotKeyEvents(event) {
      const hotKeys = this.globalPreferences.hotKeys;

      const nextRangeHotKey = hotKeys.currentRangePreview;
      const prevRangeHotKey = hotKeys.prevRangePreview;

      if (!nextRangeHotKey && !prevRangeHotKey) return;
      if (event.repeat) return;

      let modifierKey = null;

      if (event.ctrlKey) modifierKey = 'ctrl';
      else if (event.altKey) modifierKey = 'alt';
      else if (event.shiftKey) modifierKey = 'shift';

      const nextRangeKeyCodesAreSame =
        nextRangeHotKey.keyCode === event.keyCode;
      const prevRangeKeyCodesAreSame =
        prevRangeHotKey.keyCode === event.keyCode;

      const checkIfModifierKeysAreEmpty = obj => {
        let empty = true;
        Object.keys(obj).forEach(key => {
          if (key !== 'keyCode' && nextRangeHotKey[key]) {
            empty = false;
          }
        });
        return empty;
      };
      if (modifierKey) {
        if (nextRangeHotKey[modifierKey] && nextRangeKeyCodesAreSame) {
          event.preventDefault();
          console.log(this.prevGame, 'prev game at ...');
          this.showCurrentRange();
        }
        if (prevRangeHotKey[modifierKey] && prevRangeKeyCodesAreSame) {
          event.preventDefault();
          this.showPrevRange();
        }
      } else {
        if (
          nextRangeKeyCodesAreSame &&
          checkIfModifierKeysAreEmpty(nextRangeHotKey)
        ) {
          event.preventDefault();
          this.showCurrentRange();
        }
        if (
          prevRangeKeyCodesAreSame &&
          checkIfModifierKeysAreEmpty(nextRangeHotKey)
        ) {
          event.preventDefault();
          this.showPrevRange();
        }
      }
    },
    //if check is available then fold and call isn't
    isCheckAvailable(currentRange) {
      const diapasonData = currentRange.diapason.additionalProperties;
      console.log(diapasonData, 'DIAPASON DATA');
      return (
        diapasonData.hp === 'bb' &&
        diapasonData.n == '1' &&
        diapasonData.type === 'VSOR'
      );
    },
    closeColorPicker() {
      this.colorPicker = false;
    },
    closeHandTypePicker() {
      this.isHandTypePickerActive = false;
    },
    setCardStyle(type) {
      console.log(type, 'set card style method');
      this.cardStyle = type;
      this.handTypePickerColored = type === 'Classic' ? false : true;
      this.$store.commit('user/SET_CARD_STYLE', this.cardStyle);

      PATCH_GLOBAL_PREFERENCES({
        game: {
          value: {
            openRangePreview: this.globalPreferences.game.openRangePreview,
            timebank: this.globalPreferences.game.timebank,
            playerDisplayMode: this.globalPreferences.game.playerDisplayMode,
            cardStyle: this.cardStyle
          }
        }
      });
    },
    setColor(color) {
      this.colorPicker = false;

      let property =
        this.colorPickerType === 'Board' ? 'tableColor' : 'backgroundColor';
      this.table[property] = color;

      this.$store.commit('user/SET_TABLE_COLORS', this.table);

      PATCH_GLOBAL_PREFERENCES({
        table: {
          value: this.table
        }
      });
    },
    callHandTypePickerContext(elem) {
      this.isHandTypePickerActive = true;
      let handRect = elem;
      let bodyRect = document.body.getBoundingClientRect();
      console.log(handRect, bodyRect, 'rectangles');
      let offsetY = handRect.top - bodyRect.top;
      let offsetX = handRect.left - bodyRect.left;

      let ModifiedY = offsetY - 200;
      let ModifiedX = offsetX - 50;

      this.handTypePickerPosition.y = ModifiedY + `px`;
      this.handTypePickerPosition.x = ModifiedX + `px`;
    },
    callColorPickerOnContextMenu(e, type) {
      e.stopPropagation();
      e.preventDefault();

      this.colorPicker = true;
      this.colorPickerType = type;

      const x = e.clientX;
      const y = e.clientY;

      const colorPicker = document.querySelector('.color-picker-popup');
      const width = colorPicker.clientWidth;
      const height = colorPicker.clientHeight;

      const detectIfSpaceOnRightEnded = window.innerWidth - (x + width);
      const detectIfSpaceOnBottomEnded = window.innerHeight - (y + height);

      const ModifiedX =
        detectIfSpaceOnRightEnded < 0 ? x + detectIfSpaceOnRightEnded : x;
      const ModifiedY =
        detectIfSpaceOnBottomEnded < 0 ? y + detectIfSpaceOnBottomEnded : y;

      // Set the position for menu
      this.colorPickerPosition.y = ModifiedY + `px`;
      this.colorPickerPosition.x = ModifiedX + `px`;
    },
    async finishAndShowGameStatistics() {
      localStorage.removeItem('diapasonsForTest');
      console.log('interval worked');
      console.log(this.answerTimer, 'interval berfore');
      clearInterval(this.answerTimer);
      console.log(this.answerTimer, 'interval after clear');

      this.isLoading = true;
      await this.sendStatisticsToServer();
      let handsStories = await GET_HANDS_STORIES({ sessionId: this.sessionId });

      let answers = this.countFinalStatistics(handsStories);
      this.heatMapHands = handsStories;
      console.log(answers, 'answers');
      this.statistics.correctAnswers = answers.correct;
      this.statistics.incorrectAnswers = answers.incorrect;
      this.isLoading = false;
      console.log(handsStories, 'hstories ');

      if (this.statistics.totalHands) {
        this.statistics.quality = parseInt(
          (this.statistics.correctAnswers * 100) / this.statistics.totalHands
        );
        this.gameStatisticsDialog = true;
      } else {
        this.statistics.quality = 0;
        this.gameStatisticsDialog = true;
        // this.$router.push({
        //   name: 'Main'
        // });
      }
    },
    async sendStatisticsToServer() {
      await this.$store
        .dispatch('play/quitAndSaveStatistics', {
          //Добавить сюда второй массив с играми для отправки на сервер
          games: this.gamesForStatistics,
          id: this.sessionId
        })
        .then(() => {
          this.gamesForStatistics = [];
        });
    },

    async completeGamesAndQuit() {
      if (this.isLoading) {
        this.$router.push({
          name: 'Main'
        });
      } else {
        await this.$store
          .dispatch('play/quitAndSaveStatistics', {
            games: this.gamesForStatistics
          })
          .then(() => {
            this.$track.finishGame();

            this.$router.push({
              name: 'Main'
            });
          });
      }
    },
    finishGame(heroAnswer) {
      // process received result from buttons component
      this.stopTimer();
      //Close all statsTooltip after click on button
      this.closeStatsTooltip();

      // RESULT CALCULATION
      // getting result by utility script

      console.log(this.isInverse, 'isinverse in game');
      console.log(heroAnswer, 'HRO ANSWER');
      let prevGame = this.prevGame;

      const result = GET_RESULT(
        this.hand,
        heroAnswer,
        this.handCards,
        this.getGameMode,
        this.situation,
        this.randomNumber,
        this.isOpAllin,
        this.otherAllinStack,
        this.isInverse
      );

      const game = {
        answer: result.answer,
        result: result.result,
        time: this.timePassed,
        dateTime: new Date().toISOString(),

        hand: {
          name: result.item.name,
          use: result.item.use,
          weight: result.item.weight,
          cards: result.cards
        },
        isLinkedHand: this.isLinkedPlayActiveOnTable,

        id: this.currentRange.id,
        name: this.currentRange.name,
        range: this.currentRange,
        isHaveCheck: this.isHaveCheck,
        isHaveFold: !this.isHaveCheck,
        isHaveAllin: this.isHaveAllin,
        situation: this.situation,
        correctAnswerRandomizer: GET_CORRECT_ANSWER_RANDOMIZER()
      };

      console.log(
        game,
        this.prevGame,
        game?.hand?.name == this.prevGame?.hand?.name,
        'dsfsdfdsfds1'
      );

      if (
        game?.hand?.name == this.prevGame?.hand?.name &&
        this.prevGame.result === 0
      ) {
        this.statistics.correctAnswers--;
        this.statistics.incorrectAnswers++;
      }

      if (this.isLinkedPlayActiveOnTable && this.isLinkedDiapasonActive) {
        console.log('send statistics first if');
      }

      this.games.push(game);
      //Массив для сбора игр которые не были отправлены на сервер еще
      console.log(this.games[this.games.length - 1], 'last game');
      this.gamesForStatistics.push(this.games[this.games.length - 1]);

      if (game.result) {
        // if result == true (win)
        console.log('this.prevGame', this.prevGame);
        console.log('GAME RESUUULT', game);
        let { call, check, fold } = game.answer;
        console.log(call, check, fold, 'answers');
        if (check || fold || call) {
          this.isLinkedDiapasonActive = false;
        }

        if (this.prevGame == null) {
          //+
          this.statistics.correctAnswers++;
          this.statistics.totalHands++;
          if (!this.isLinkedDiapasonActive) {
            console.log('sendstat: 1');
            this.changeCorrectAnswers(true);
          }

          if (!this.isLinkedDiapasonActive) {
            this.countGamesPlayed++;
            console.log('sendstat: 1.1');
          }
        } else if (
          (this?.prevGame?.isLinkedHand && !this.isLinkedPlayActiveOnTable) ||
          (!this.isLinkedPlayActiveOnTable &&
            !this.prevGame.isLinkedHand &&
            this.prevGame.result !== 0)
        ) {
          console.log('sendstat 2');
          //+
          this.statistics.correctAnswers++;
          this.statistics.totalHands++;
          if (
            this.levelModeOption &&
            !this.isLinkedPlayActiveOnTable &&
            !this.isLinkedDiapasonActive
          ) {
            console.log('sendstat: 2.1');
            this.changeCorrectAnswers(true);
          }

          if (
            !this.isLinkedPlayActiveOnTable &&
            !this.prevGame.isLinkedHand &&
            this.prevGame.result !== 0
          ) {
            console.log('sendstat: 2.2'); //не нужен
            console.log(
              this.prevGame,
              game,
              this.levelModeOption,
              'sendstat: 2.2 options'
            );
            this.countGamesPlayed++;
          }
        }

        if (this.isLinkedPlayActiveOnTable && !this.isLinkedDiapasonActive) {
          this.countGamesPlayed++;
          console.log('sendstat 4 '); //берем
          this.changeCorrectAnswers(true);
        }

        console.log(
          this.prevGame?.hand?.name === game?.hand?.name,
          !this.prevGame.result === 0,
          this.levelModeOption,
          this.prevGame !== null,
          this.prevGame,
          game,
          'dsfsdfdsfds2'
        );

        if (
          prevGame?.hand?.name === game?.hand?.name &&
          !this.prevGame.result &&
          this.levelModeOption &&
          prevGame !== null
        ) {
          console.log('sendstat 5');
          this.changeCorrectAnswers(true);
        }

        // this.hand = null;

        console.log(this.gamesForStatistics, this.games, game, 'debug stats');

        console.log(this.gamesForStatistics, 'gamesForStatistiics');

        console.log('ANSWER TIMER IN IF');
        if (!this.levelModeOption) {
          this.nextGame();
        }

        console.log(
          this.isLinkedDiapasonActive,
          this.isLinkedPlayActiveOnTable,
          this.isPlayLinkedGames,
          'linkgdebyg'
        );

        if (
          this.isLinkedDiapasonActive &&
          this.isPlayLinkedGames &&
          this.levelModeOption
        ) {
          this.nextGame();
        }
      } else {
        // if result == false (loss)
        if (!this.isLinkedPlayActiveOnTable && this.isLinkedDiapasonActive) {
          this.isLinkedDiapasonActive = false;
        }

        if (this.prevGame == null || this.prevGame.result !== 0) {
          console.log('ANSWER TIMER IN ELSE');

          if (this.isLinkedPlayActiveOnTable) {
            this.statistics.incorrectAnswers++;
            this.statistics.correctAnswers--;
            this.countGamesPlayed++;
            if (this.levelModeOption) {
              this.changeCorrectAnswers(false);
            }
            let games = this.games;

            if (!games[games.length - 1].isLinkedHand) {
              this.games[this.games.length - 1].result = 0;
            } else {
              this.games[this.games.length - 1].result = 0;
              this.games[this.games.length - 2].result = 0;
            }
            console.log(
              'sendstat 6',
              this.prevGame,
              this.games[this.games.length - 1],
              this.games[this.games.length - 2]
            ); //берем
          } else {
            this.statistics.incorrectAnswers++;
            this.statistics.totalHands++;
            this.countGamesPlayed++;
            this.changeCorrectAnswers(false);
            console.log('sendstat 6.1');
          }

          // counts only if didn't answer wrong on previous show up

          // clearInterval(this.answerTimer);
          // this.startAnswerTimer();
        }
        //Если ответ неверный - то открывается таблица
        // if (this.globalPreferences.game.openRangePreview)
        //   this.showCurrentRange();

        // clearInterval(this.answerTimer);
        // this.startAnswerTimer();

        // this.nextGame();
        if (this.isLinkedPlayActiveOnTable) {
          //Если сейчас привязанная рука, и ответ неправильный - прекращаем играть с  привязкой
          //TODO:
          console.log('WORK THIS!');
          this.isLinkedDiapasonActive = false;
          this.nextGame();
          // this.isLinkedDiapasonActive = false;
          // this.linkedHandToPlay = {};

          // this.nextGame();
        }

        this.startTimer();
      }

      if (!this.isLoading) {
        clearInterval(this.answerTimer);
        this.startAnswerTimer();
      }
    },

    async nextGame() {
      console.log(
        this.isLinkedDiapasonActive,
        this.isLinkedPlayActiveOnTable,
        this.isPlayLinkedGames,
        this.linkedHandToPlay,
        'debuglinked'
      );
      // this.isSendRequest = false;
      console.log(
        this.levelModeOption &&
          Object.keys(this.levelHands).includes(this.actualLevel) &&
          this.levelHands[this.actualLevel].length === 0,
        'csdcds'
      );

      this.linkedStage = 0;
      this.startTimer();
      this.randomNumber = this.getRandomInt(1, 100);

      console.log(this.levelHands, 'lvlhnds');

      if (this.levelModeOption) {
        let maxRnd = this.levelHands[this.actualLevel].length - 1;
        console.log(maxRnd, 'dsfds');
        this.randomNumber25 = this.getRandomInt(0, maxRnd);
        console.log(
          !!this.levelHands[this.actualLevel + 1],
          this.levelHands,
          'is next level?'
        );
      } else {
        let maxRandomNumber = this.handsTreeToPlay.length - 1;
        this.randomNumber25 = this.getRandomInt(0, maxRandomNumber);
      }

      if (this.countPlayingHands > 24 && !this.levelModeOption) {
        if (this.firstIteration) {
          let maxRandomNumber = this.handsTreeToPlay.length - 1;
          this.randomNumber25 = this.getRandomInt(0, maxRandomNumber);
          //Удаление руки из играбельного массива
          this.lastHandIndex = this.randomNumber25;
          console.log('FIRS ITERATION : 1');
          this.firstIteration = false;
        } else {
          if (!this.isLinkedDiapasonActive) {
            this.handsTreeToPlay.splice(this.lastHandIndex, 1);
            this.lastHandIndex = this.randomNumber25;
            let maxRandomNumber = this.handsTreeToPlay.length - 1;
            this.randomNumber25 = this.getRandomInt(0, maxRandomNumber);
          }
        }
      }

      try {
        this.handsLeft = this.handsTreeRemaining;
        this.handsTreeRemaining = this.handsTreeToPlay.length;
        this.playingHand = this.handsTreeToPlay[this.randomNumber25];

        //Если в playingHand - парент - есть привязанные диапазоны, и она подходит под цепочки то подгружаем привзяку и если еще не начата игра с привязкой

        if (this.linkedHandToPlay.length > 0 && this.isLinkedDiapasonActive) {
          // if(this.currentRange.name === SQZ || this.currentRange.name )
          console.log('FIRST IF STATEMANT');
          this.isLinkedPlayActiveOnTable = true;
          console.group();
          this.linkedStage = 2;
          let gameDataLinked = this.fullDiapasonInfo.filter(item => {
            return this.linkedHandToPlay[0].id === item.id;
          });
          console.log(gameDataLinked, 'GAMEDATA LINKED');
          let handTypeInLinked =
            gameDataLinked[0].diapason.additionalProperties.type;
          console.log(handTypeInLinked, 'HAND TYPE PLAY');
          console.log(this.linkedHandToPlay, 'HAND TO PLAY LINKED');
          let handToPlay = GET_CARDS_FROM_ONE_OBJECT(this.linkedHandToPlay[0]);
          console.log(handToPlay, 'HAND TO PLAY');
          this.hand = handToPlay[0];
          console.log(this.hand, 'this hand');
          this.currentRangeWeights = GET_ALL_WEIGHT_FROM_ARRAY(
            this.linkedHandToPlay
          );
          console.log(this.currentRangeWeights, 'this current range weights');
          console.log('STAGE TEST 1');
          let range =
            gameDataLinked[[Math.floor(Math.random() * gameDataLinked.length)]]; //выбор рандомного range
          console.log(range, 'range current');
          this.currentRange = range;
          this.participants = range.diapason.participants;
          console.groupEnd();

          //Если есть 3 цепочка
          if (
            handTypeInLinked === SQZ ||
            (handTypeInLinked === VS3B && this.play3rdStage)
          ) {
            console.log(this.linkedHandToPlay, 'linked hand to play vs sqz');
            let linkedGroup = this.fullDiapasonInfo.find(
              el => el.id === this.linkedHandToPlay[0].parentId
            );
            console.log(linkedGroup, 'CONSOLE>LINKED GROUP');
            let linksGroupIds = linkedGroup.linksGroup.items.map(el => el.id);

            if (linksGroupIds.length) {
              console.log('links group ids', linksGroupIds);
              this.loadingLinkedHand = true; //Стейт загрузки руки
              let linkedHand = await GET_LINKED_ITEMS_HANDS(linksGroupIds, {
                size: Object.keys(gameDataLinked[0].diapason.participants)
                  .length,
                heroPosition:
                  gameDataLinked[0].diapason.additionalProperties.hp,
                count: 1,
                hand: this.hand.name,
                type: this.getNextStage(handTypeInLinked),
                opponentPosition:
                  gameDataLinked[0].diapason.additionalProperties.op
              });
              //если массив с рукой не пустой - то запрашиваем фулл инфу по его паренту и нему

              if (linkedHand.length) {
                this.linkedStage = 3;
                this.isLinkedDiapasonActive = true; //Стейт игры в диапазоне
                // this.linkedHandToPlay = linkedHand[0];
                console.log(linkedHand, 'LINKED HAND in 3stage');
                if (
                  !this.cachedIds.includes(linkedHand[0].id) ||
                  !this.cachedIds.includes(linkedHand[0].parentId)
                ) {
                  let payload = await this.addItemsToDiapasonFullInfo(
                    linkedHand,
                    true
                  );
                  console.log(payload, 'PAYLOAD IN LINKED 3 stage');
                  this.fullDiapasonInfo = [
                    ...this.fullDiapasonInfo,
                    ...payload
                  ];
                }
                this.loadingLinkedHand = false;
                this.linkedHandToPlay = linkedHand;
                this.play3rdStage = false;
              } else {
                this.isLinkedDiapasonActive = false;
                this.play3rdStage = false;
                this.loadingLinkedHand = false;
              }
            } else {
              this.isLinkedDiapasonActive = false;
              this.play3rdStage = false;
              this.loadingLinkedHand = false;
            }
          } else {
            console.log('work else');
            this.play3rdStage = false;
            this.linkedHandToPlay = [];
            this.linkedStage = 1;
            this.isLinkedDiapasonActive = false;
          }
        } else if (this.levelModeOption) {
          //Level Mode

          console.log('LEVEL MODE ACTIVE HERE');

          this.isLinkedPlayActiveOnTable = false;
          console.log(this.levelHands[this.actualLevel]);

          this.playingHand = this.levelHands[this.actualLevel][
            this.randomNumber25
          ];
          // if (!this.playingHand) {
          //   this.finishAndShowGameStatistics();
          //   return;
          // }
          console.log(
            this.playingHand,
            this.randomNumber25,
            this.actualLevel,
            this.levelHands,
            'buglevels'
          );

          let hands = GET_CARDS_FROM_ONE_OBJECT(this.playingHand);
          this.hand = hands[Math.floor(Math.random() * hands.length)];

          console.log(hands, this.hand, 'buglevels2');
          let gameData = this.fullDiapasonInfo.filter(item => {
            return this.playingHand.id === item.id;
          });
          console.log(gameData, 'game data next game');
          let handType = gameData[0].diapason.additionalProperties.type;
          let range = gameData[[Math.floor(Math.random() * gameData.length)]]; //выбор
          this.currentRange = range; //TODO: сменить рендж на игровой, который в руке

          console.log(gameData, range, handType, 'debug linked 1st');
          if (range.diapason && range.diapason.participants) {
            this.participants = range.diapason.participants;
          }

          this.currentRangeWeights = GET_ALL_WEIGHT_FROM_ARRAY(
            this.handsTreeToPlay
          );

          //Код отвечает за иру с привязкой и подгрузку рук

          this.linkedGroups.forEach(item => {
            if (this.playingHand.parentId === item.id) {
              console.log(item, 'item?');
              item.linksGroup.items.forEach(obj => {
                this.isPlayLinkedGames = this.linkedItemsIDS.includes(obj.id);
              });
            }
          });

          if (
            (handType === RFI ||
              handType === VSOR ||
              handType === VS1RC ||
              handType === VS3B) &&
            !this.isLinkedDiapasonActive &&
            this.playingHand.parentId != DEFAULT_PARENT_ID &&
            this.isPlayLinkedGames
          ) {
            //проверка есть ли в паренте связки
            let linkedGroup = this.linkedGroups.find(
              el => el.id === this.playingHand.parentId
            );
            if (linkedGroup.linksGroup.items.length) {
              //Включаем режим игры с привязкой
              console.log(linkedGroup, 'LLLLLL');
              let linksGroupIds = linkedGroup.linksGroup.items.map(el => el.id);

              this.loadingLinkedHand = true; //Стейт загрузки руки
              let linkedHand = await GET_LINKED_ITEMS_HANDS(linksGroupIds, {
                size: Object.keys(gameData[0].diapason.participants).length,
                heroPosition: gameData[0].diapason.additionalProperties.hp,
                count: 1,
                hand: this.hand.name,
                type: this.getNextStage(handType),
                opponentPosition: gameData[0].diapason.additionalProperties.op
              });
              //если массив с рукой не пустой - то запрашиваем фулл инфу по его паренту и нему

              if (linkedHand.length) {
                if (handType == RFI || handType == VS3B || handType == SQZ) {
                  this.play3rdStage = true;
                }
                this.linkedStage = 1;

                this.isLinkedDiapasonActive = true; //Стейт игры в диапазоне
                this.linkedHandToPlay = linkedHand[0];
                console.log(linkedHand, 'LINKED HAND');
                if (
                  !this.cachedIds.includes(linkedHand[0].id) ||
                  !this.cachedIds.includes(linkedHand[0].parentId)
                ) {
                  let payload = await this.addItemsToDiapasonFullInfo(
                    linkedHand,
                    true
                  );
                  console.log(payload, 'PAYLOAD IN LINKED');
                  this.fullDiapasonInfo = [
                    ...this.fullDiapasonInfo,
                    ...payload
                  ];
                }

                this.linkedHandToPlay = linkedHand;
              }
              this.loadingLinkedHand = false;

              //Подгружаем Items группы и его парента

              // let fullDiapason = await this.addItemsToDiapasonFullInfo(payload);
              // this.fullDiapasonInfo = [...this.fullDiaxpasonInfo, ...fullDiapason];
            }
          }
        } else {
          //Usual mode
          console.log('Outer iteration');
          console.group();
          console.log(this.playingHand, 'THIS PLAYING HAND');
          this.isLinkedPlayActiveOnTable = false;

          let hands = GET_CARDS_FROM_ONE_OBJECT(this.playingHand);
          console.log(hands, 'HANDS');
          this.hand = hands[Math.floor(Math.random() * hands.length)];
          console.log('this.hand', this.hand);

          console.log(
            this.fullDiapasonInfo,
            'full diapson info before gamedata'
          );
          let gameData = this.fullDiapasonInfo.filter(item => {
            return this.playingHand.id === item.id;
          });
          console.log(gameData, 'this.gameData');

          let handType = gameData[0].diapason.additionalProperties.type;
          console.log(handType, 'this.handType');
          console.groupEnd();
          //Проверка на начало цепочки для игры с привязанным диапазоном
          console.log('handType', handType);
          console.log('STAGE TEST 2');

          console.log(
            gameData[0].diapason.additionalProperties.type,
            'GameData1'
          );

          console.log(gameData, 'GAME VIEW: gameData before range');
          // current diapason to play
          let range = gameData[[Math.floor(Math.random() * gameData.length)]]; //выбор рандомного range

          console.log('GAME VIEW: range', range);
          console.log(
            'GAME VIEW: range diapason bb hands',
            range.diapason?.participants
          );

          this.currentRange = range; //TODO: сменить рендж на игровой, который в руке

          if (range.diapason && range.diapason.participants) {
            this.participants = range.diapason.participants;
          }
          console.log('GAME VIEW');
          //  array of all diapasons cells with filled weight - set to component
          this.currentRangeWeights = GET_ALL_WEIGHT_FROM_ARRAY(
            this.handsTreeToPlay
          );
          // this.currentRangeWeights = GET_ALL_WEIGHT(
          //   range.diapason.participants[currentRangePlayer].hands
          // );

          // let isDiapason = this.currentRange.type === 'Diapason';
          console.log(
            this.currentRangeWeights,
            'GAME VIEW: current range weights'
          );

          console.log(this.hand, 'HAND AFTER IMPLEMENTATION');

          this.linkedGroups.forEach(item => {
            if (this.playingHand.parentId === item.id) {
              console.log(item, 'item?');
              item.linksGroup.items.forEach(obj => {
                this.isPlayLinkedGames = this.linkedItemsIDS.includes(obj.id);
              });
            }
          });

          if (this.linkingModeOption) {
            console.group();
            console.log(this.playingHand, 'phand');
            console.log(this.linkedGroups);
            console.log(this.linkedGroups, 'dfdsfd');

            if (this.currentRange.linksGroup.items.length > 0) {
              this.isPlayLinkedGames = true;
            }

            console.groupEnd();
          }

          console.log(this.isPlayLinkedGames, 'ISHAVELINKED TO PLAY');

          if (
            (handType === RFI ||
              handType === VSOR ||
              handType === VS1RC ||
              handType === VS3B) &&
            !this.isLinkedDiapasonActive &&
            (this.playingHand.parentId != DEFAULT_PARENT_ID ||
              this.currentRange.type === 'Diapason') &&
            this.isPlayLinkedGames
          ) {
            console.log('statrrttr');
            //проверка есть ли в паренте связки
            let linksGroupIds = this.currentRange.linksGroup.items.map(
              el => el.id
            );
            let linkedHand = {};

            if (linksGroupIds.length) {
              console.log('diapason');
              this.loadingLinkedHand = true;

              console.log(linksGroupIds, 'dfd');
              linkedHand = await this.getLinkedHand(
                linksGroupIds,
                gameData,
                this.hand,
                handType
              );
              console.log(linkedHand, 'Linked diapason');
            }

            let linkedGroup = this.linkedGroups.find(
              el => el.id === this.playingHand.parentId
            );

            console.log(linkedGroup, 'no linkeds');

            if (linkedGroup?.linksGroup?.items?.length) {
              //Включаем режим игры с привязкой
              console.log(linkedGroup, 'LLLLLL');
              let linksGroupIds = linkedGroup.linksGroup.items.map(el => el.id);

              this.loadingLinkedHand = true; //Стейт загрузки руки
              console.log(
                linksGroupIds,
                linkedGroup.linksGroup.items,
                'links await'
              );

              linkedHand = await this.getLinkedHand(
                linksGroupIds,
                gameData,
                this.hand,
                handType
              );
            }
            //если массив с рукой не пустой - то запрашиваем фулл инфу по его паренту и нему

            if (linkedHand.length) {
              if (handType == RFI || handType == VS3B || handType == SQZ) {
                this.play3rdStage = true;
              }
              this.linkedStage = 1;

              this.isLinkedDiapasonActive = true; //Стейт игры в диапазоне
              this.linkedHandToPlay = linkedHand[0];
              console.log(linkedHand, 'LINKED HAND');
              if (
                !this.cachedIds.includes(linkedHand[0].id) ||
                !this.cachedIds.includes(linkedHand[0].parentId)
              ) {
                let payload = await this.addItemsToDiapasonFullInfo(
                  linkedHand,
                  true
                );
                console.log(payload, 'PAYLOAD IN LINKED');
                this.fullDiapasonInfo = [...this.fullDiapasonInfo, ...payload];
              }

              this.linkedHandToPlay = linkedHand;
            }
            this.loadingLinkedHand = false;

            //Подгружаем Items группы и его парента

            // let fullDiapason = await this.addItemsToDiapasonFullInfo(payload);
            // this.fullDiapasonInfo = [...this.fullDiapasonInfo, ...fullDiapason];
          }

          if (
            this.handsTreeRemaining < 11 &&
            this.countPlayingHands > 24 &&
            !this.isSendRequest
          ) {
            this.isSendRequest = true;
            console.log(this.fullDiapasonInfo, 'FULL DIAPASON: before querry');
            let payload = await GET_ITEMS_HANDS(this.playingIDS);
            let fullDiapason = await this.addItemsToDiapasonFullInfo(
              payload,
              true
            );
            this.fullDiapasonInfo = [...this.fullDiapasonInfo, ...fullDiapason];
            console.log(fullDiapason, 'FULL DIAPASON: AFTER NEXT GAME QUERRY');
            this.handsTreeToPlay = [...this.handsTreeToPlay, ...payload];
            this.isSendRequest = false;
          }
        }
      } catch (err) {
        console.error(err, 'ERROR');
        this.gameStatisticsDialog = true;
        this.$router.push({
          name: 'Main'
        });
      }
    },
    showCurrentRangeOnClickPos(pos) {
      let position = pos.toLowerCase();
      if (this.currentRange) {
        this.previewRange = this.currentRange;
        if (position === this.currentRange.diapason.additionalProperties.hp) {
          this.previewHand = [this.hand.name];
        } else {
          this.previewHand = null;
        }
        this.clickedHand = position;
        console.log(this.previewHand, 'PREVIEW HAND');
        this.isRangePreviewOpened = !this.isRangePreviewOpened;
      }
    },
    showCurrentRange() {
      if (this.currentRange) {
        this.previewRange = this.currentRange;
        // this.clickedHand =
        this.clickedHand = this.currentRange.diapason.additionalProperties.hp;
        console.log(this.hand, 'this hands1', this.currentRange);
        this.previewHand = [this.hand.name]; //TODO: hand.handKey

        console.log(this.previewHand, 'PREVIEW HAND');
        this.isRangePreviewOpened = !this.isRangePreviewOpened;
      }
    },
    showPrevRange() {
      if (this.prevGame?.range?.diapason) {
        this.previewRange = this.prevGame.range;

        this.clickedHand = this.prevGame.range.diapason.additionalProperties.hp;
        this.previewHand = [this.prevGame.hand.name];
        this.isRangePreviewOpened = !this.isRangePreviewOpened;
      }
    },

    showHelp() {
      this.isHelpOpened = true;
    },
    changeGameMode(mode) {
      this.$store.commit('play/SET_ACTIVE_MODE', mode);
    },
    startTimer() {
      this.startTime = performance.now();
    },
    stopTimer() {
      this.endTime = performance.now();
      let timeDiff = (this.endTime - this.startTime) / 1000;

      // get seconds
      let seconds = Math.ceil(timeDiff);
      this.statistics.totalTime += seconds;
      if (this.statistics.totalHands > 1)
        this.statistics.averageAnswerTime = Math.ceil(
          this.statistics.totalTime / this.statistics.totalHands
        );
      else {
        this.statistics.averageAnswerTime = Math.ceil(
          this.statistics.totalTime
        );
      }
    },

    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    setSizesOfBoard() {
      let container = this.$refs.gameContent;
      let board = this.$refs.board;
      let gameTable = this.$refs.gameTable;

      const findSize = (width, height) => {
        let TABLE_SIZES = [
          {
            size: 'xl',
            width: 1150,
            height: 560,
            offsetX: 520,
            offsetY: 250
          },
          {
            size: 'l',
            width: 700,
            height: 340,
            offsetX: 250,
            offsetY: 230
          },
          {
            size: 'm',
            width: 560,
            height: 560,
            offsetX: 130,
            offsetY: 280
          },
          {
            size: 's',
            width: 380,
            height: 475,
            offsetX: 90,
            offsetY: 200
          },
          {
            size: 'xs',
            width: 250,
            height: 380,
            offsetX: 0,
            offsetY: 0
          }
        ];

        TABLE_SIZES = TABLE_SIZES.map(size => {
          size.fullWidth = size.width + size.offsetX;
          size.fullHeight = size.height + size.offsetY;
          size.class = 'game--' + size.size;
          return size;
        });
        const LARGEST_TABLE_SIZE = TABLE_SIZES[0];

        console.log('Width & Height of container: ', width, height);

        let foundTableSize;

        let containerWidthIsLargest = LARGEST_TABLE_SIZE.fullWidth < width;
        let containerHeightIsLargest = LARGEST_TABLE_SIZE.fullHeight < height;

        if (containerHeightIsLargest && containerWidthIsLargest) {
          console.log('get largest table');

          return LARGEST_TABLE_SIZE;
        }

        /* if container width is largest, but height is not - select appropriate to screen */
        if (containerWidthIsLargest) {
          foundTableSize = TABLE_SIZES.find(size => size.fullHeight < height);
          console.log('get table size with largest width', foundTableSize);
        }
        /* if container height is largest, but width is not - select appropriate to screen */
        if (containerHeightIsLargest) {
          foundTableSize = TABLE_SIZES.find(size => size.fullWidth < width);
          console.log('get table size with largest height', foundTableSize);
        }

        if (!foundTableSize) {
          foundTableSize = TABLE_SIZES.find(
            size => width > size.fullWidth && height > size.fullHeight
          );
          console.log('found table size', foundTableSize, width, height);
        }

        if (!foundTableSize) {
          foundTableSize = TABLE_SIZES[TABLE_SIZES.length - 1];
          console.log(
            'Table size not found - getting smallest',
            foundTableSize
          );
        }

        return foundTableSize;
      };

      let containerWidth = container.clientWidth;
      let containerHeight = container.clientHeight;

      let foundTableSize = findSize(containerWidth, containerHeight);

      if (foundTableSize) {
        board.$el.style.width = foundTableSize.width + 'px';
        board.$el.style.height = foundTableSize.height + 'px';
        gameTable.className = 'game__table game__table--' + foundTableSize.size;
      } else {
        gameTable.className = 'game__table';
        board.$el.style.removeProperty('width');
        board.$el.style.removeProperty('height');
      }
    }
  },
  watch: {
    async actualLevel() {
      // console.log('watcher actual level');
      // const nextLevel = this.actualLevel + 1;
      // let hands = await this.getNextLevelItemsHands(nextLevel);
      // this.levelHands[nextLevel] = [];
      // // this.levelHands[nextLevel] = hands;
      // hands.forEach(hand => {
      //   hand.correctAnswers = 0;
      //   this.levelHands[nextLevel].push(hand);
      // });
      // console.log('nextHands', hands);
    },
    gameInfoIsActive() {
      if (!this.gameInfoIsActive) {
        this.resumeAnswerTimer();
      }
    },
    isHelpOpened() {
      if (!this.isHelpOpened) {
        this.resumeAnswerTimer();
      }
    },
    isRangePreviewOpened() {
      if (!this.isRangePreviewOpened) {
        this.resumeAnswerTimer();
      }
    }
    // countGamesPlayed: {
    //   handler(val) {
    //     if (val === 10) {

    //       this.countGamesPlayed = 0;
    //       this.sendStatisticsToServer();
    //       this.gamesForStatistics = [];
    //       console.log('sendSTATS');
    //     }
    //   }
    // }
  },
  created() {
    const diapasonsForTest =
      JSON.parse(localStorage.getItem('diapasonsForTest')) ?? [];
    console.log(diapasonsForTest, 'diapason for tests start game');
    for (let item of diapasonsForTest) {
      console.log(item, this.playingIDS, 'pids');
      let elemInArray = diapasonsForTest.indexOf(item);
      console.log(elemInArray, 'ELEM IN ARRAY');
      this.playingIDS = [...this.playingIDS, item];
    }
    this.$store.commit('play/START_GAME', diapasonsForTest);

    console.log(diapasonsForTest, 'GAME VIEW: DIAPASONS FOR TEST');

    this.$track.startGame();
  },
  async mounted() {
    /*window.onbeforeunload = function() {
      return confirm("Вы уверены? Вся статистика очистится после выхода");
    };*/
    this.isLoading = true;
    // const itemIDsPlay =
    //   JSON.parse(localStorage.getItem('diapasonsForTest')) ?? [];

    this.sessionId = uuidv4();

    const storedGameOptions =
      JSON.parse(localStorage.getItem('gameOptions')) ?? [];

    this.levelModeOption = storedGameOptions.find(
      option => option.id === 'levelMode'
    )?.value;
    this.linkingModeOption = storedGameOptions.find(
      option => option.id === 'linkingMode'
    )?.value;
    this.hintModeOption = storedGameOptions.find(
      option => option.id === 'hintMode'
    )?.value;
    let itemsTreeHands = [];

    if (this.levelModeOption) {
      this.actualLevel = 1;
      itemsTreeHands = await this.getNextLevelItemsHands(1);
    } else {
      itemsTreeHands = await GET_ITEMS_HANDS(this.playingIDS);
    }
    // itemsTreeHands = await GET_ITEMS_HANDS(this.playingIDS);

    if (this.linkingModeOption) {
      let includeLinkedGroups = await INCLUDE_LINKED_GROUPS(this.playingIDS);
      this.linkedItemsIDS = includeLinkedGroups.map(item => item.id);

      this.linkedGroups = includeLinkedGroups;
      console.log(includeLinkedGroups, 'INCLUDE LINKEd QUERY');
    }

    this.countPlayingHands = itemsTreeHands.length;
    if (itemsTreeHands.length < 1)
      //TODO:
      this.finishAndShowGameStatistics();

    this.startPlayingArrayLenght = itemsTreeHands.length;
    console.log(itemsTreeHands, 'zapolnenie: itemsTreeHandsAfterFetch');

    let itemsTreeTestDiapasonFullInfo = this.fillDiapasonInfo(
      itemsTreeHands,
      this.fullDiapasonInfo
    );
    this.cachedIds = itemsTreeTestDiapasonFullInfo;

    console.log(itemsTreeTestDiapasonFullInfo, 'items tree test after push');
    this.fullDiapasonInfo = await this.getFullDiapasonInfo(
      itemsTreeTestDiapasonFullInfo
    );

    console.log(this.fullDiapasonInfo, 'full diapason IDS');
    console.log(this.fullDiapasonInfo, 'FULL DIAPASON INFO AFTER LOADING');
    console.log(itemsTreeHands, 'zapolnenie: itemsTreeHandsAfterWhileLoop');

    //Set 2 arrays to play(second is temp array to load items )
    this.handsTreeToPlay = itemsTreeHands;

    this.handsTreeRemaining = itemsTreeHands.length;

    if (this.levelModeOption) {
      // if (this.handsTreeToPlay.length < 25) {
      console.log(this.handsTreeToPlay, 'handstrree');
      // if (!this.levelHands[0]) {
      //   this.levelHands[0] = [];
      // }

      for (let hand of this.handsTreeToPlay) {
        console.log(hand, 'hand mounted 1 ');
        // hand.correctAnswers = hand.hand.statistics.correctSeries;
        let level = hand.hand.gamePreference.level;
        console.log(level, hand.hand.handKey, 'lslls 1');
        if (!this.levelHands[level]) {
          this.levelHands[level] = [];
        }

        // this.levelHands[level].push(hand);
      }
      console.log(this.levelHands, 'after filing empty');
      // await this.fillLevelHands();

      await this.fillLevelHandsNewApi();
      // this.levelHands[1] = [];
      // } else {
      //   for (let hand of this.handsTreeToPlay) {
      //     console.log(hand, 'hand mounted ');
      //     hand.correctAnswers = hand.hand.statistics.correctSeries;
      //     let level = hand.hand.gamePreference.level;
      //     console.log(level, 'lslls');
      //     if (!this.levelHands[level]) {
      //       this.levelHands[level] = [];
      //     }

      //     this.levelHands[level].push(hand);

      //     console.log(this.levelHands, 'level hands mounted');

      if (Object.keys(this.levelHands).length === 0) {
        this.levelModeOption = false;
      }
      // }
      // }
      //Делаем
      this.updateActualLevel(this.levelHands);
      // }
    }

    console.log(itemsTreeHands, 'GAME VIEW: ITEM TREE HANDS');

    await this.$store.dispatch('user/LOAD_GLOBAL_PREFERENCES');
    this.playerDisplayMode = this.$store.state.user.globalPreferences.game.playerDisplayMode;
    let allInPositon = this.$store.state.user.globalPreferences.actionsOrder
      .Allin;
    //set vh innerHeight to fix height game in mobile device

    this.isInverse = allInPositon === 0 ? true : false;

    if (this.handsTreeRemaining) {
      this.loaded = true;
      this.isLoading = false;
      this.timestamps.warning = this.timebank / 2;
      this.timestamps.alert = this.timebank / 4;
      this.randomNumber = this.getRandomInt(1, 100);
      console.log('NEXT GAME', this.levelHands[this.actualLevel]);
      if (!this.levelModeOption) {
        this.nextGame();
        this.startAnswerTimer();
      }
      console.log('NEXT GAME 2');
      window.addEventListener('keydown', this.setHotKeyEvents);

      this.ready = true;
    } else {
      this.gameStatisticsDialog = true;
    }

    if (window.innerWidth > 767) {
      document.addEventListener('click', e => {
        if (!this.hand) return false;

        let colorPickerDiv = document.querySelector('.color-picker');
        let handTypePickerDiv = document.querySelector('.hand-type-picker');

        if (handTypePickerDiv) {
          if (
            !e.target.classList.contains('hand-type-picker') &&
            !colorPickerDiv.contains(e.target)
          ) {
            this.isHandTypePickerActive = false;
          }
        }

        if (colorPickerDiv) {
          if (
            !e.target.classList.contains('color-picker') &&
            !colorPickerDiv.contains(e.target)
          ) {
            this.colorPicker = false;
          }
        }
      });
    }
  },
  beforeDestroy() {
    console.log('berforedestroy');
    clearInterval(this.answerTimer);
    this.answerTimer = null;

    window.onbeforeunload = null;
    window.removeEventListener('keydown', this.setHotKeyEvents);
  }
};
</script>
<style lang="scss" src="../assets/scss/game.scss" />
