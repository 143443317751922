<template>
  <div class="options-selector">
    <span
      :class="{'options-selector__active': currentValue === options[0].key}"
      class="options-selector__display-switch-text options-selector__value-left"
      @click='setInput(options[0].key)'
    >
        {{ options[0].text }}
    </span>
    <label class="options-selector__slider">
      <input
        v-model="currentValue"
        :false-value="options[0].key"
        :true-value="options[1].key"
        type="checkbox"
        @change='optionChanged()'
      />
      <span class="options-selector__switch"></span>
    </label>

    <span
      :class="{'options-selector__active': currentValue === options[1].key}"
      class="options-selector__display-switch-text options-selector__value-right"
      @click='setInput(options[1].key)'
    >
       {{ options[1].text }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'OptionsSelector',
  props: {
    options: {
      // Key: string Text: string
      type: Array,
      default() {
        return [
          {
            key: 'left',
            text: '1 option'
          },
          {
            key: 'right',
            text: '2 option'
          }
        ]
      }
    },
  },
  data() {
    return {
      currentValue: null
    };
  },
  methods: {
    setInput(val) {
      this.currentValue = val;
      this.optionChanged();
    },
    optionChanged() {
      this.$emit('optionChanged', this.currentValue)
    }
  },
  mounted() {
    this.currentValue = this.options[0].key;
  },
};
</script>

<style lang="scss" src="./OptionsSelector.scss"/>