<template>
  <div class="game__actions">
    <div class="game__actions__wrapper">
      <slot name="prepend" />
      <PercentageSelectorsForAdvancedMode
        v-if="getGameMode === 'Advanced'"
        :btns="percentageBtns"
        :current="current"
        @changeBtn="onPercentageChange"
      />

      <WeightForRandomizedMode
        v-if="getGameMode === 'Randomized'"
        :value="randomNumber"
        :weights="percentageBtns"
      />

      <div class="game__actions__btns">
        <Btn
          v-for="btn in btnsForAnswer"
          :key="btn.label"
          :disabled="btn.disabled"
          class="game__actions__btn"
          :style="btnStyle(btn)"
          @click="btn.handler"
        >
          <div class="game__actions__btn__label">
            {{ btn.label }}
          </div>
          <div v-show="btn.value" class="game__actions__btn__value">
            {{ btn.value }}
          </div>
        </Btn>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_STATE_RADIO_BUTTONS } from '@/utils/answerButtonsInGame';
import { mapGetters } from 'vuex';
import Btn from '@/components/UI/Btn';
import { GET_RESULT } from '@/utils/game';

import WeightForRandomizedMode from '@/components/Game/WeightForRandomizedMode';
import PercentageSelectorsForAdvancedMode from '@/components/Game/PercentageSelectorsForAdvancedMode';

export default {
  name: 'ButtonsForAnswer',
  props: [
    'resultForHint',
    'isHintOptionActive',
    'isLoadingLinkedHands',
    'allWeight',
    'weight',
    'isHaveAllin',
    'isHaveCheck',
    'situation',
    'randomNumber',
    'isInverse',
    'isFoldButtonActive',
    'additionalProperties',
    'opAllin',
    'otherAllinStack'
  ],
  components: {
    PercentageSelectorsForAdvancedMode,
    WeightForRandomizedMode,
    Btn
  },
  data() {
    return {
      raise: null,
      call: null,
      check: null,
      fold: null,
      allin: null,
      current: null,
      checked: []
    };
  },
  methods: {
    setValues() {
      const { raise, call, fold, allin, check } = this;
      const obj = { raise, call, fold, allin, check };

      let checkValue = Object.entries(obj).filter(([, value]) => value);
      if (checkValue.length === 3) {
        let last = Object.entries(obj).find(([, value]) => !value);
        this[last[0]] = 100 - this.sum;
      }
    },
    onPercentageChange(val) {
      this.current = val;
    },
    next() {
      let answers = {
        raise: this.raise,
        check: this.check,
        call: this.call,
        fold: this.fold,
        allin: this.allin
      };
      window.console.log(answers);
      // send answer
      this.$emit('finishAnswer', answers);
      this.setDefault();
    },
    setDefault() {
      this.raise = null;
      this.check = null;
      this.call = null;
      this.fold = null;
      this.allin = null;
      this.current = null;
    },
    onSet(type) {
      console.log('ONSET SDSA:', type);
      console.log('ONSET SDSA', this.isHaveAllin);
      // if (type === 'allin' && !this.isHaveAllin) return;
      // if (type === 'fold' && this.isHaveCheck) return;

      if (!this.current) {
        if (this.sum - this[type] === 0) {
          this[type] = 100;
        } else {
          this[type] = 100 - this.sum;
        }
      } else {
        this[type] = this.current;
        this.current = null;
      }
    },
    setHotKeyEvents(event) {
      const hotkeys = this.globalPreferences.hotKeys;
      const check = hotkeys.check;
      const call = hotkeys.call;
      const callCheckIsSame = call.keyCode === check.keyCode;
      console.log(hotkeys, 'hotkeyz');
      console.log(event, 'ev');
      console.log(this.isHaveCheck, 'ishavecheck');

      if (event.repeat) return;

      let modifierKey = null;
      if (event.ctrlKey) modifierKey = 'ctrl';
      else if (event.altKey) modifierKey = 'alt';
      else if (event.shiftKey) modifierKey = 'shift';

      Object.keys(hotkeys).forEach(key => {
        if (hotkeys[key]) {
          if (hotkeys[key].keyCode === event.keyCode) {
            if (this.isHaveCheck && callCheckIsSame) {
              event.preventDefault();
              console.log('eventBug: check');
              this.onSet('check');
              return;
            } else if (!this.isHaveCheck && callCheckIsSame) {
              event.preventDefault();
              console.log(key, 'keuu');
              if (key === 'check') {
                this.onSet('Call');
                return;
              }
            }
            if (modifierKey) {
              if (hotkeys[key][modifierKey]) {
                event.preventDefault();

                console.log('eventBug: modifier', key);

                this.onSet(key);
              }
            } else {
              let checkIfAllEmpty = true;
              Object.keys(hotkeys[key]).forEach(innerKey => {
                if (innerKey !== 'keyCode') {
                  if (hotkeys[key][innerKey]) {
                    checkIfAllEmpty = false;
                  }
                }
              });

              if (checkIfAllEmpty) {
                event.preventDefault();
                console.log('eventBug: checkIfAllEmpty', key);
                this.onSet(key);
              }
            }
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      getGameMode: 'play/getGameMode',
      globalPreferences: 'user/globalPreferences'
    }),
    btnStyle() {
      return function(btn) {
        console.log(this.globalPreferences.actionColors, btn.label, 'globs');
        // console.log(GET_RESULT());
        //здесь проверяем кнопку и правильный ответ

        let handName = btn.label.toLowerCase();

        console.log(btn, 'btnd', this.weight);
        let {
          hand,
          handCards,
          getGameMode,
          situation,
          randomNumber,
          isOpAllin,
          otherAllinStack,
          isInverse
        } = this.resultForHint;

        console.log(this.weight[handName], handName, 'GFGDF');

        let resultWeight = { raise: 0, allin: 0, call: 0, check: 0, fold: 0 };

        resultWeight[handName] = 100;

        let result = GET_RESULT(
          hand,
          resultWeight,
          handCards,
          getGameMode,
          situation,
          randomNumber,
          isOpAllin,
          otherAllinStack,
          isInverse
        );

        console.log(result, 'RERER');

        const actionColors = this.globalPreferences.actionColors[handName];
        console.log(actionColors, 'actcolors');
        let style = {};
        if (this.isHintOptionActive && result.result === 1) {
          style = { backgroundColor: actionColors };
        }

        return style;
      };
    },

    foldText() {
      return `FOLD ${this.fold ?? ''}`;
    },
    callText() {
      return `CALL ${this.call ?? ''}`;
    },
    raiseText() {
      return `RAISE ${this.raise ?? ''}`;
    },
    allinText() {
      return `ALLIN ${this.allin ?? ''}`;
    },
    checkText() {
      return `CHECK ${this.check ?? ''}`;
    },
    btnsForAnswer() {
      let conditionalBtns = this.isHaveCheck
        ? [
            {
              value: this.check,
              label: 'Check',
              disabled: this.isLoadingLinkedHands,
              handler: () => this.onSet('check')
            }
          ]
        : [
            {
              value: this.call,
              label: 'Call',
              disabled: this.isLoadingLinkedHands,
              handler: () => this.onSet('call')
            },
            {
              value: this.fold,
              label: 'Fold',
              disabled: this.isLoadingLinkedHands,
              handler: () => this.onSet('fold')
            }
          ];

      let defaultBtns = [
        {
          value: this.allin,
          label: 'Allin',
          disabled: this.isLoadingLinkedHands,
          handler: () => this.onSet('allin')
        },
        {
          value: this.raise,
          label: 'Raise',
          disabled: this.isLoadingLinkedHands,
          handler: () => this.onSet('raise')
        }
      ];

      let opAllinButtons = [
        {
          value: this.fold,
          label: 'Fold',
          disabled: this.isLoadingLinkedHands,
          handler: () => this.onSet('fold')
        },
        {
          value: this.call,
          label: 'Call',
          disabled: this.isLoadingLinkedHands,
          handler: () => this.onSet('call')
        }
      ];

      let output = defaultBtns.concat(conditionalBtns);
      console.log(output, 'output');
      console.log(output, 'OUTPUT IS FOLD1');
      //Проверка в game компоненте нужна ли кнопка фолд, если фолс - то убирает ее из общего вывода.

      if (this.otherAllinStack) {
        return output.reverse();
      }
      if (this.opAllin) {
        return opAllinButtons;
      }

      if (!this.isFoldButtonActive) {
        console.log(output, 'OUTPUT IS FOLD');
        let withoutFold = output.filter(item => item.label != 'Fold');
        console.log(withoutFold, 'OUTPUT WITHOUT FOLD');
        return withoutFold.reverse();
      }
      //Если нужна инверсия кнопок на столе, нужно добавить проверку на инверсию, если ее нет, то возвращать просто output (this.isInverse)

      // if (this.isInverse) {
      //   return output;
      // }
      // if (this.isHaveAllin) {
      //   console.log('is have allin');
      //   return conditionalBtns;
      // }

      return output.reverse();
    },
    percentageBtns() {
      if (!this.allWeight || !this.weight) return [];
      // this.allWeight - all cells with weight
      // this.weight - current cell obj
      return GET_STATE_RADIO_BUTTONS(
        this.allWeight,
        this.weight,
        this.situation
      );
    },

    sum() {
      return this.raise + this.call + this.fold + this.allin + this.check;
    }
  },
  async created() {
    document.addEventListener('keydown', this.setHotKeyEvents);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.setHotKeyEvents);
  },

  watch: {
    sum() {
      // if 100 gathered then finish
      if (this.sum >= 100) this.next();
    },
    raise() {
      this.setValues();
    },
    call() {
      this.setValues();
    },
    check() {
      this.setValues();
    },
    fold() {
      this.setValues();
    },
    allin() {
      this.setValues();
    },
    percentageBtns() {
      this.setDefault();
    }
  }
};
</script>
